/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/export */
import React, { ReactNode } from "react";
import { CloseSquareOutlined } from "@ant-design/icons";
import { message } from "antd";
import { MessageType } from "antd/lib/message";
import { uniqueId } from "lodash";

export enum NotificationType {
  success,
  error,
  warning,
  info,
  loading,
}

export const showMessage = (
  content: ReactNode,
  type: NotificationType,
  key?: string,
  duration?: number
): MessageType => {
  const messageKey = key || uniqueId();
  const messageDuration = duration || 10;
  const icon = (
    <span
      className="custom-close-icon"
      data-testid={`notif-${key}-close`}
      onClick={() => message.destroy(messageKey)}
    >
      <CloseSquareOutlined />
    </span>
  );

  const messageConfig = {
    content: (
      <div data-testid={`notif-${key}`}>
        {content}
        {icon}
      </div>
    ),
    duration: messageDuration,
    key: messageKey,
  };

  switch (type) {
    case NotificationType.success:
      return message.success(messageConfig);
    case NotificationType.error:
      return message.error(messageConfig);
    case NotificationType.warning:
      return message.warning(messageConfig);
    case NotificationType.info:
      return message.info(messageConfig);
    case NotificationType.loading:
      return message.loading(messageConfig);
  }
};

export const showSuccessPopUp = (
  content: ReactNode,
  key?: string,
  duration?: number
) => {
  return showMessage(content, NotificationType.success, key, duration);
};

export const showErrorPopUp = (
  content: ReactNode,
  key?: string,
  duration?: number
) => {
  return showMessage(content, NotificationType.error, key, duration);
};

export const showWarningPopUp = (
  content: ReactNode,
  key?: string,
  duration?: number
) => {
  return showMessage(content, NotificationType.warning, key, duration);
};

export const showInfoPopUp = (
  content: ReactNode,
  key?: string,
  duration?: number
) => {
  return showMessage(content, NotificationType.info, key, duration);
};

export const showLoadingPopUp = (
  content: ReactNode,
  key?: string,
  duration?: number
) => {
  return showMessage(content, NotificationType.loading, key, duration);
};
