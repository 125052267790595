import React from "react";
import { convertToCurrency } from "@quest-finance/quest-fe-shared/dist/common/utils/number";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import "./AbaDetails.scss";

import { AbaData, AbaTransactions } from "../../types/Aba";

const formatCurrency = (value: number) => {
  return (
    "$" +
    convertToCurrency(value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};

export type AbaDetailsProps = {
  abaData: AbaData;
  fileName: string;
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

const AbaDetails: React.FC<AbaDetailsProps> = (props: AbaDetailsProps) => {
  const { fileName, abaData, loading, onSubmit, onCancel } = props;
  const columns: ColumnsType<AbaTransactions> = [
    {
      title: "#",
      dataIndex: "id",
      render(_, { id }, i) {
        return <span data-testid={`aba-trx-id-${i}`}>{id}</span>;
      },
    },
    {
      title: "BSB",
      dataIndex: "bsb",
      render(_, v, i) {
        return (
          <div className="act-details">
            <div className="name" data-testid={`aba-trx-act-name-${i}`}>
              {v.accountName}
            </div>
            <div className="others">
              <span data-testid={`aba-trx-bsb-${i}`}>{v.bsb}</span>
              <div>
                Acc :{" "}
                <span data-testid={`aba-trx-act-num-${i}`}>{v.accountNum}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Reference",
      dataIndex: "referenceNum",
      render(_, v, i) {
        return (
          <span data-testid={`aba-trx-ref-num-${i}`}>{v.referenceNum}</span>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right",
      render(_, v, i) {
        return (
          <span data-testid={`aba-trx-amount-${i}`}>
            {formatCurrency(v.amount)}
          </span>
        );
      },
    },
  ];

  const firstTrx = abaData.transactions[0];

  const labels = abaData.totalDirectCredit
    ? {
        trxType: "Credit",
        paymentIndicator: "from",
      }
    : {
        trxType: "Debit",
        paymentIndicator: "to",
      };

  return (
    <div className="aba-details" data-testid="aba-details">
      <div className="panel left-panel">
        <div className="heading" data-testid="trx-type">
          Direct {labels.trxType}
        </div>
        <div className="summary">
          <table>
            <tbody>
              <tr>
                <td>Filename</td>
                <td>
                  <span data-testid="aba-summary-file-name">{fileName}</span>
                </td>
              </tr>
              <tr>
                <td>Amount</td>
                <td data-testid="aba-summary-net-total-amount">
                  {formatCurrency(abaData.netTotalAmount)}
                </td>
              </tr>
              <tr>
                <td data-testid="pament-account-label">
                  Paid {labels.paymentIndicator} account
                </td>
                <td>
                  <div className="act-details">
                    <div
                      className="name"
                      data-testid="aba-summary-originator-act-name"
                    >
                      {firstTrx.originatorAccountName}
                    </div>
                    <div className="others">
                      <span data-testid="aba-summary-originator-bsb">
                        {firstTrx.originatorBsb}
                      </span>
                      <div>
                        Acc :{" "}
                        <span data-testid="aba-summary-originator-act-num">
                          {firstTrx.originatorAccountNum}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            className="qf-btn-green mb-2"
            data-testid="aba-submit"
            onClick={onSubmit}
            loading={loading}
            block
          >
            Submit
          </Button>
          <Button
            className="qf-btn-green-invert"
            data-testid="aba-cancel"
            onClick={onCancel}
            disabled={loading}
            block
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className="panel right-panel">
        <div className="heading">Transactions list</div>
        <Table
          bordered
          columns={columns}
          dataSource={abaData?.transactions}
          rowKey="id"
          size="small"
          className="aba-trx"
          pagination={false}
        />
      </div>
    </div>
  );
};

export default AbaDetails;
