import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { LabelValue } from "@quest-finance/quest-fe-shared/dist/common/types/LabelValue";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { convertToQueryParams } from "@quest-finance/quest-fe-shared/dist/common/utils/object";
import { AuditLog, AuditLogRequest } from "../types/AuditLog";

class AuditLogsService {
  public static async getAuditLogs(
    payload: AuditLogRequest
  ): Promise<ListResponse<AuditLog>> {
    const queryParams = convertToQueryParams(payload);
    const path = `/audit/audits${queryParams}`;

    const result = await APIService.jsonRequest<
      ListResponse<AuditLog>,
      unknown
    >(
      {
        method: "GET",
        path,
      },
      true
    );
    return result;
  }

  public static async getAuditCategories(): Promise<ListResponse<LabelValue>> {
    const result = await APIService.jsonRequest<
      ListResponse<LabelValue>,
      unknown
    >(
      {
        method: "GET",
        path: "/audit/audits/categories",
      },
      true
    );
    return result;
  }
}

export default AuditLogsService;
