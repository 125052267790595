import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { convertToQueryParams } from "@quest-finance/quest-fe-shared/dist/common/utils/object";
import { Method } from "axios";
import { InternalNotesListRequest } from "../../common/internal-notes/types/InternalNotesListRequest";
import { InternalNotesResponse } from "../../common/internal-notes/types/InternalNotesResponse";
import { InternalNotesSubmitRequest } from "../types/InternalNotesSubmitRequest";

class InternalNotesService {
  public static async getInternalNotes(
    entityId: number,
    payload: InternalNotesListRequest
  ): Promise<ListResponse<InternalNotesResponse>> {
    const queryParams = convertToQueryParams({
      ...payload,
      entityId,
    });
    const method: Method = "GET";
    const path = `/entity/notes${queryParams}`;

    const result = await APIService.jsonRequest<
      ListResponse<InternalNotesResponse>,
      unknown
    >(
      {
        method,
        path,
      },
      true
    );
    return result;
  }
  public static async submitInternalNotes(
    data: InternalNotesSubmitRequest
  ): Promise<SingleResponse<InternalNotesResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<InternalNotesResponse>,
      InternalNotesSubmitRequest
    >(
      {
        method: "POST",
        path: `/entity/notes`,
        data,
      },
      true
    );
    return result;
  }
}

export default InternalNotesService;
