import { createSelector } from "@reduxjs/toolkit";
import { AssessmentListState } from "../types/AssessmentListState";
import { AssessmentState } from "../types/AssessmentState";

export const assessmentListStateSelector = (
  state: AssessmentState
): AssessmentListState => state.assessment.assessmentList;

export const getAssessmentListSelector = createSelector(
  assessmentListStateSelector,
  (assessmentList) => assessmentList.assessments
);

export const getAssessmentCountSelector = createSelector(
  assessmentListStateSelector,
  (assessmentList) => assessmentList.assessmentCount
);

export const getLoadingSelector = createSelector(
  assessmentListStateSelector,
  (assessmentList) => assessmentList.isLoading
);

export const getStatusSelector = createSelector(
  assessmentListStateSelector,
  (assessmentList) => assessmentList.status
);

export const getResetPageSelector = createSelector(
  assessmentListStateSelector,
  (assessmentList) => assessmentList.resetPage
);

export const getLimitSelector = createSelector(
  assessmentListStateSelector,
  (assessmentList) => assessmentList.limit
);

export const getOrderSelector = createSelector(
  assessmentListStateSelector,
  (assessmentList) => assessmentList.order
);

export const getListPayloadSelector = createSelector(
  assessmentListStateSelector,
  (assessmentList) => ({
    status: assessmentList.status,
    limit: assessmentList.limit,
    offset: assessmentList.offset,
    order: assessmentList.order,
  })
);
