import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { convertToQueryParams } from "@quest-finance/quest-fe-shared/dist/common/utils/object";
import { Method } from "axios";
import { NoteForm, NoteAttributes, ListRequestParams } from "../types/Notes";

class NoteService {
  public static async create(
    assessmentId: string,
    data: NoteForm
  ): Promise<SingleResponse<NoteAttributes>> {
    const result = await APIService.jsonRequest<
      SingleResponse<NoteAttributes>,
      NoteForm
    >(
      {
        method: "POST",
        path: `/assessment/assessments/${assessmentId}/notes`,
        data,
      },
      true
    );
    return result;
  }

  public static async update(
    assessmentId: string,
    noteAssessmentId: string,
    data: NoteForm
  ): Promise<SingleResponse<NoteAttributes>> {
    const result = await APIService.jsonRequest<
      SingleResponse<NoteAttributes>,
      NoteForm
    >(
      {
        method: "PATCH",
        path: `/assessment/assessments/${assessmentId}/notes/${noteAssessmentId}`,
        data,
      },
      true
    );
    return result;
  }

  public static async getList(
    assessmentId: string,
    payload: ListRequestParams
  ): Promise<ListResponse<NoteAttributes>> {
    const queryParams = convertToQueryParams(payload);
    const method: Method = "GET";
    const path = `/assessment/assessments/${assessmentId}/notes${queryParams}`;

    const result = await APIService.jsonRequest<
      ListResponse<NoteAttributes>,
      unknown
    >(
      {
        method,
        path,
      },
      true
    );
    return result;
  }

  public static async delete(
    assessmentId: string,
    noteId: string
  ): Promise<SingleResponse<NoteAttributes>> {
    const result = await APIService.jsonRequest<
      SingleResponse<NoteAttributes>,
      unknown
    >(
      {
        method: "DELETE",
        path: `/assessment/assessments/${assessmentId}/notes/${noteId}`,
      },
      true
    );

    return result;
  }
}

export default NoteService;
