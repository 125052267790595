import React from "react";
import { PageRoute } from "@quest-finance/quest-fe-shared/dist/common/types/PageRoute";

const AbaProcess = React.lazy(() => import("../pages/aba/process"));

const routes: PageRoute[] = [
  {
    name: "Workflow Diagram",
    path: "/payment/aba/process",
    exact: true,
    component: AbaProcess,
  },
];

export default routes;
