import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import * as workflowActions from "../actions/types/workflow";
import { workflowTaskCountDefaultValue } from "../constants/workflow";
import { WorkflowDetailsState } from "../types/Workflow";

export const workflowDetails: WorkflowDetailsState = {
  workflowDetails: {
    data: [],
    status: LOADING_STATUS.IDLE,
  },
  workflowSummaryDetails: {
    data: {},
    status: LOADING_STATUS.IDLE,
  },
  workflowTasks: {
    data: {
      childWorkflows: {},
      tasks: [],
      countByState: workflowTaskCountDefaultValue,
    },
    status: LOADING_STATUS.IDLE,
  },
};

export const reducer = (
  state: WorkflowDetailsState = workflowDetails,
  action: workflowActions.WorkflowActions
): WorkflowDetailsState => {
  switch (action.type) {
    case workflowActions.RESET_WORKFLOW_DETAILS: {
      return {
        ...workflowDetails,
      };
    }
    case workflowActions.UPDATE_WORKFLOW_TASK: {
      const tasks = [...state.workflowTasks.data.tasks];
      const newTask = action.taskResponse;
      const taskIndex = tasks.findIndex((task) => task.id === newTask.id);
      if (taskIndex !== -1) {
        tasks[taskIndex] = {
          ...tasks[taskIndex],
          ...newTask,
        };

        return {
          ...state,
          workflowTasks: {
            ...state.workflowTasks,
            data: {
              ...state.workflowTasks.data,
              tasks,
            },
          },
        };
      }

      return {
        ...state,
      };
    }
    case workflowActions.GET_WORKFLOW_SUMMARY: {
      return {
        ...state,
        workflowSummaryDetails: {
          ...state.workflowSummaryDetails,
          status: LOADING_STATUS.LOADING,
          error: undefined,
        },
      };
    }
    case workflowActions.GET_WORKFLOW_SUMMARY_SUCCESS: {
      return {
        ...state,
        workflowSummaryDetails: {
          data: action.summaryResponse,
          status: action.status,
        },
      };
    }
    case workflowActions.GET_WORKFLOW_SUMMARY_FAILED: {
      return {
        ...state,
        workflowSummaryDetails: {
          ...state.workflowSummaryDetails,
          status: LOADING_STATUS.FAILED,
          error: action.error,
        },
      };
    }
    case workflowActions.GET_WORKFLOW_TASKS: {
      return {
        ...state,
        workflowTasks: {
          ...state.workflowTasks,
          status: LOADING_STATUS.LOADING,
          error: undefined,
        },
      };
    }
    case workflowActions.GET_WORKFLOW_TASKS_SUCCESS: {
      return {
        ...state,
        workflowTasks: {
          status: LOADING_STATUS.SUCCESS,
          data: {
            childWorkflows: action.childWorkflows,
            tasks: action.tasks,
            countByState: action.countByState,
          },
        },
      };
    }
    case workflowActions.GET_WORKFLOW_TASKS_FAILED: {
      return {
        ...state,
        workflowTasks: {
          ...state.workflowTasks,
          status: LOADING_STATUS.FAILED,
          error: action.error,
        },
      };
    }
    case workflowActions.RESET_WORKFLOW_TASKS: {
      return {
        ...state,
        workflowTasks: {
          data: {
            tasks: [],
            countByState: workflowTaskCountDefaultValue,
            childWorkflows: {},
          },
          status: LOADING_STATUS.IDLE,
          error: undefined,
        },
      };
    }
    default: {
      return state;
    }
  }
};
