import React from "react";
import { Route } from "react-router-dom";
import { creditAssessmentRoot } from "../constants/routes";
import Index from "./loan/admin/";

const CreditEngineRoot: React.FunctionComponent = () => {
  return (
    <Route exact path={creditAssessmentRoot}>
      <Index />
    </Route>
  );
};

export default CreditEngineRoot;
