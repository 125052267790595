import { AuditLogResponse } from "@quest-finance/quest-fe-shared/dist/audit";
import { LabelValue } from "@quest-finance/quest-fe-shared/dist/common/types/LabelValue";
import { ClientForm, clientFormDefaultValue } from "./ClientForm";
import { ClientResponse, clientResponseDefaultValue } from "./ClientResponse";

export interface ClientAdminState {
  client: ClientResponse;
  clientForm: ClientForm;
  getClientLoading: boolean;
  saveClientLoading: boolean;
  saveClientSuccess: boolean;
  configType: LabelValue[];
  clientAuditLogs: AuditLogResponse[];
  clientAuditLogsCount: number;
  clientAuditLogsLoading: boolean;
}

export const clientAdminStateDefaultValue: ClientAdminState = {
  client: clientResponseDefaultValue,
  clientForm: clientFormDefaultValue,
  getClientLoading: false,
  saveClientLoading: false,
  saveClientSuccess: false,
  configType: [],
  clientAuditLogs: [],
  clientAuditLogsCount: 0,
  clientAuditLogsLoading: false,
};
