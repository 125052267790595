import { Action } from "redux";
import {
  EntityIndividualRequest,
  EntityIndividualResponse,
} from "../../types/Entity";

export const GET_ENTITY_INDIVIDUAL = "GET_ENTITY_INDIVIDUAL";
export interface GetEntityIndividual extends Action {
  type: typeof GET_ENTITY_INDIVIDUAL;
  entityId: number;
}

export const GET_ENTITY_INDIVIDUAL_SUCCESS = "GET_ENTITY_INDIVIDUAL_SUCCESS";
export interface GetEntityIndividualSuccess extends Action {
  type: typeof GET_ENTITY_INDIVIDUAL_SUCCESS;
  individual: EntityIndividualResponse;
}

export const GET_ENTITY_INDIVIDUAL_FAILED = "GET_ENTITY_INDIVIDUAL_FAILED";
export interface GetEntityIndividualFailed extends Action {
  type: typeof GET_ENTITY_INDIVIDUAL_FAILED;
}

export const UPDATE_ENTITY_INDIVIDUAL = "UPDATE_ENTITY_INDIVIDUAL";
export interface UpdateEntityIndividual extends Action {
  type: typeof UPDATE_ENTITY_INDIVIDUAL;
  entityId: number;
  request: Partial<EntityIndividualRequest>;
}

export const UPDATE_ENTITY_INDIVIDUAL_SUCCESS =
  "UPDATE_ENTITY_INDIVIDUAL_SUCCESS";
export interface UpdateEntityIndividualSuccess extends Action {
  type: typeof UPDATE_ENTITY_INDIVIDUAL_SUCCESS;
}

export const UPDATE_ENTITY_INDIVIDUAL_FAILED =
  "UPDATE_ENTITY_INDIVIDUAL_FAILED";
export interface UpdateEntityIndividualFailed extends Action {
  type: typeof UPDATE_ENTITY_INDIVIDUAL_FAILED;
}

type UpdateEntityIndividualActions =
  | GetEntityIndividual
  | GetEntityIndividualSuccess
  | GetEntityIndividualFailed
  | UpdateEntityIndividual
  | UpdateEntityIndividualSuccess
  | UpdateEntityIndividualFailed;

export type EntityIndividualActions = UpdateEntityIndividualActions;
