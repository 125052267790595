import { UserData } from "@quest-finance/quest-fe-shared/dist/auth/types/UserData";

export enum AssigneeRoles {
  CREDIT_ANALYST = "CREDIT_ANALYST",
  SETTLEMENT_OFFICER = "SETTLEMENT_OFFICER",
}

export interface Assignee {
  id: number;
  role: AssigneeRoles;
  user: UserData;
}

export interface AssessmentAssigneeRequest {
  userId: number;
  role: AssigneeRoles;
}
