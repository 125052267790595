import React from "react";
import Contracts from "../../../components/contracts/Contracts";
import IntroducerNotes from "../../../components/notes/introducer-notes/IntroducerNotes";
import SupportingDocuments from "../../../components/supporting-documents/SupportingDocuments";

const NotesContracts: React.FunctionComponent = () => {
  return (
    <>
      <IntroducerNotes />
      <Contracts />
      <SupportingDocuments />
    </>
  );
};

export default NotesContracts;
