import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";

class PermissionService {
  public static async getPermissionList(): Promise<SingleResponse<Dictionary>> {
    const result = await APIService.jsonRequest<
      SingleResponse<Dictionary>,
      unknown
    >(
      {
        method: "GET",
        path: "/iam/permissions",
      },
      true
    );
    return result;
  }
}

export default PermissionService;
