import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import { actionCreator } from "@quest-finance/quest-fe-shared/dist/notification";
import { AxiosError } from "axios";
import { put, call, takeEvery, ForkEffect } from "redux-saga/effects";
import {
  getUserListError,
  getUserListSuccess,
} from "../actions/creators/userList";
import { GetUserList, GET_USER_LIST } from "../actions/types/userList";
import { NOTIFICATION_IDS } from "../constants/notificationIds";
import UserService from "../services/UserService";
import { UserResponse } from "../types/UserResponse";

const { setNotification, unsetNotification } = actionCreator;
function* getUserListWorker({ payload }: GetUserList) {
  try {
    yield put(unsetNotification(NOTIFICATION_IDS.IAM_USER_LIST));
    const results: ListResponse<UserResponse> = yield call(
      UserService.getUserList,
      payload
    );
    yield put(getUserListSuccess(results));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.IAM_USER_LIST,
        className: "qst-notif-danger",
        body,
      })
    );
    yield put(getUserListError());
  }
}

function* watchUserList(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(GET_USER_LIST, getUserListWorker);
}

export default watchUserList;
