import { ASSESSMENT_TYPES } from "../constants/assessmentTypes";
import { ApprovalConditionResponse } from "./ApprovalConditionResponse";
import { AssessmentDocument } from "./AssessmentDocument";
import { Assignee } from "./Assignee";

export interface AssessmentResponse<T = Record<string, unknown>> {
  id: string;
  clientId: string;
  externalId: string;
  type: string;
  status: string;
  data: T;
  analyzedBy: number | null;
  decisionNote: string | null;
  decisionBy: number | null;
  decisionAt: string | null;
  createdAt: string;
  updatedAt: string;
  settlementNote: string | null;
  settledBy: number | null;
  settledAt: string | null;
  documents?: AssessmentDocument[];
  approvalConditions?: ApprovalConditionResponse[];
  assignees: Assignee[];
}

export const assessmentDefaultValue: AssessmentResponse = {
  id: "",
  clientId: "",
  externalId: "",
  type: ASSESSMENT_TYPES.APPLICATION,
  status: "",
  data: {},
  analyzedBy: null,
  decisionNote: null,
  decisionBy: null,
  decisionAt: null,
  createdAt: "",
  updatedAt: "",
  settlementNote: null,
  settledBy: null,
  settledAt: null,
  documents: [],
  approvalConditions: [],
  assignees: [],
};
