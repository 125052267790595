import React, { useCallback, useEffect, useState } from "react";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { convertToCurrency } from "@quest-finance/quest-fe-shared/dist/common/utils/number";
import { Modal, Popover, Popconfirm, Spin, Button } from "antd";
import { renderToString } from "react-dom/server";
import { useParams } from "react-router";
import striptags from "striptags";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../common/utils/error";
import { resetAssessmentNotes } from "../../actions/creators/noteList";
import {
  FORM_FIELD_LABELS,
  MAX_ALLOWED_RECORDS,
} from "../../constants/creditReference";
import { useNoteListDispatch } from "../../dispatchers/";
import CreditRefService from "../../services/CreditRefService";
import NoteService from "../../services/NoteService";
import { CreditRefAttributes } from "../../types/CreditReference";
import { Sections } from "../../types/Notes";
import Form from "./Form";
import "./CreditReference.scss";

export const currencyOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

type CreditRefDetailsProps = {
  creditRef: CreditRefAttributes;
};
export const CreditRefDetails: React.FunctionComponent<CreditRefDetailsProps> = ({
  creditRef,
}: CreditRefDetailsProps) => {
  const _naf = "$" + convertToCurrency(creditRef.naf, currencyOptions);
  const _paymentAmount =
    "$" + convertToCurrency(creditRef.paymentAmount, currencyOptions);
  const _balloon = "$" + convertToCurrency(creditRef.balloon, currencyOptions);
  const _outstandingBalance =
    "$" + convertToCurrency(creditRef.outstandingBalance, currencyOptions);

  return (
    <ul>
      <li>
        {FORM_FIELD_LABELS.bank} - <strong>{creditRef.bank}</strong>
      </li>
      <li>
        {FORM_FIELD_LABELS.start} - <strong>{creditRef.start}</strong>
      </li>
      <li>
        {FORM_FIELD_LABELS.term} - <strong>{creditRef.term}</strong>
      </li>
      <li>
        {FORM_FIELD_LABELS.asset} - <strong>{creditRef.asset}</strong>
      </li>
      <li>
        {FORM_FIELD_LABELS.naf} - <strong>{_naf}</strong>
      </li>
      <li>
        {FORM_FIELD_LABELS.paymentAmount} - <strong>{_paymentAmount}</strong>
      </li>
      <li>
        {FORM_FIELD_LABELS.balloon} - <strong> {_balloon}</strong>
      </li>
      <li>
        {FORM_FIELD_LABELS.outstandingBalance} -{" "}
        <strong>{_outstandingBalance}</strong>
      </li>
      <li>
        {FORM_FIELD_LABELS.conduct} - <strong>{creditRef.conduct}</strong>
      </li>
    </ul>
  );
};

type CreditRefNoteProps = {
  refData?: CreditRefAttributes;
  notes: string;
  update: boolean;
};
const CreditRefNote: React.FunctionComponent<CreditRefNoteProps> = ({
  refData,
  notes,
  update,
}: CreditRefNoteProps) => {
  const type = update ? "Update" : "Create";
  const noteContent = striptags(notes) ? (
    <>
      <p>
        <br />
      </p>
      <p dangerouslySetInnerHTML={{ __html: notes }} />
    </>
  ) : null;

  return (
    <>
      <p>{type} credit reference</p>
      <p>
        <br />
      </p>
      <CreditRefDetails creditRef={refData as CreditRefAttributes} />
      {noteContent}
    </>
  );
};

const CreditReference: React.FunctionComponent = () => {
  const notesListDispatch = useNoteListDispatch();
  const { assessmentId } = useParams<{
    assessmentId: string;
  }>();
  const [creditRefs, setCreditRefs] = useState<CreditRefAttributes[]>([]);
  const [creditRefData, setCreditRefData] = useState<
    CreditRefAttributes | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recordsOnDelete, setRecordsOnDelete] = useState<number[]>([]);
  const formMode = creditRefData?.id ? "Update" : "Create";
  const limitReached = creditRefs.length >= MAX_ALLOWED_RECORDS;

  const getCreditRefs = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await CreditRefService.getList(assessmentId);
      setCreditRefs(data);
    } catch (e) {
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setIsLoading(false);
    }
  }, [assessmentId]);

  const deleteRecord = async (id: number) => {
    try {
      setRecordsOnDelete((state) => [...state, id]);
      await CreditRefService.delete(assessmentId, id);
      getCreditRefs();
    } catch (e) {
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setRecordsOnDelete((state) =>
        state.filter((recordId) => recordId !== id)
      );
    }
  };

  const updateRecord = (data: CreditRefAttributes) => {
    setCreditRefData(data);
    setIsModalVisible(true);
  };

  const createNew = () => {
    setIsModalVisible(true);
    setCreditRefData(undefined);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (assessmentId) {
      getCreditRefs();
    }
  }, [assessmentId, getCreditRefs]);

  const list = creditRefs.map((creditRef, i) => {
    const { id, bank, conduct, term, naf, paymentAmount } = creditRef;
    const _naf = "$" + convertToCurrency(naf, currencyOptions);
    const _paymentAmount =
      "$" + convertToCurrency(paymentAmount, currencyOptions);
    const detail = [
      <strong key="0">{conduct}</strong>,
      " - ",
      bank,
      " - ",
      _paymentAmount + "/month",
      " - ",
      `${_naf} funded`,
      " - ",
      term + " month(s)",
    ];

    const popoverContent = <CreditRefDetails creditRef={creditRef} />;

    return (
      <li key={i} data-testid={`ref-${i}`}>
        <Popover
          content={popoverContent}
          title={null}
          placement="topLeft"
          getPopupContainer={() =>
            document.querySelector(".credit-ref") as HTMLElement
          }
        >
          <span>{detail}</span>
        </Popover>
        <EditFilled
          className="action-btn edit"
          onClick={() => updateRecord(creditRef)}
          data-testid={`edit-btn-${i}`}
        />
        {!recordsOnDelete.includes(id) && (
          <Popconfirm
            title="Sure to delete?"
            okText={<span data-testid={`confirm-delete-${i}`}>Yes</span>}
            onConfirm={() => deleteRecord(id)}
            arrowPointAtCenter
          >
            <DeleteFilled
              className="action-btn"
              data-testid={`delete-btn-${i}`}
            />
          </Popconfirm>
        )}
      </li>
    );
  });

  const createNote = async (creditRef: CreditRefAttributes, note: string) => {
    const noteContent = renderToString(
      <CreditRefNote
        refData={creditRef}
        notes={note}
        update={!!creditRefData?.id}
      />
    );

    try {
      await NoteService.create(assessmentId, {
        note: noteContent,
        section: Sections.REF_STATEMENTS,
      });

      notesListDispatch(resetAssessmentNotes(Date.now()));
    } catch (e) {
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    }
  };

  const onSubmitSuccessHandler = async (
    creditRef: CreditRefAttributes,
    note: string
  ) => {
    await createNote(creditRef, note);
    getCreditRefs();
    setIsModalVisible(false);

    const action = creditRefData?.id ? "updated" : "added";
    showSuccessPopUp(`Credit reference has been successfully ${action}.`);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="credit-ref">
        {!!list.length && (
          <ol className="list" data-testid="ref-list">
            {list}
          </ol>
        )}
        <Button
          className="qf-btn-green"
          data-testid="add-ref-btn"
          onClick={createNew}
          disabled={limitReached}
        >
          Add Completed Reference
        </Button>
        <br />
        <i>
          {limitReached && `Maximum of ${MAX_ALLOWED_RECORDS} records reached.`}
        </i>
        <Modal
          title={`${formMode} Credit Reference`}
          visible={isModalVisible}
          onCancel={closeModal}
          destroyOnClose={true}
          width={700}
          footer={null}
        >
          <Form
            onSubmitSuccess={onSubmitSuccessHandler}
            refData={creditRefData}
          />
        </Modal>
      </div>
    </Spin>
  );
};

export default CreditReference;
