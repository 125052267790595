import React, { useState } from "react";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import { Button, Popconfirm, message } from "antd";
import cs from "classnames";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import LMSService from "../../services/LMSService";
import { FinpowerXmlOption } from "../../types";

export type SubmitApplicationBtnProps = {
  assessmentId: string;
  onSubmitSucess?: () => void;
  submittedToLms?: boolean;
};

const notifKey = "push-to-lms-notif";

const SubmitApplicationBtn: React.FC<SubmitApplicationBtnProps> = (
  props: SubmitApplicationBtnProps
) => {
  const { submittedToLms } = props;
  const [loading, setLoading] = useState(false);
  const onConfirmHandler = async (xmlOption = FinpowerXmlOption.ACCOUNT) => {
    setLoading(true);
    message.destroy(notifKey);

    try {
      await LMSService.submitToLms({
        assessmentId: props.assessmentId,
        xmlOption,
      });
      props.onSubmitSucess?.();
      showSuccessPopUp("Application successfully sent to LMS");
    } catch (e) {
      const content = processErrorMessage(e);
      showErrorPopUp(content, notifKey);
    } finally {
      setLoading(false);
    }
  };

  const confirmationText = submittedToLms ? (
    <>
      Application has already been pushed to LMS.
      <div>Do you want to re-push?</div>
    </>
  ) : (
    "Submit this application to LMS?"
  );

  return (
    <>
      <Popconfirm
        title={<div data-testid="confirmation-text">{confirmationText}</div>}
        okText="Submit"
        cancelText="Cancel"
        onConfirm={() => onConfirmHandler()}
        arrowPointAtCenter
      >
        <Button
          className={cs(
            "mr-2",
            submittedToLms ? "qf-btn-gray-invert" : "qf-btn-green"
          )}
          loading={loading || submittedToLms === undefined}
          data-testid="push-to-lms"
        >
          Push application to LMS
        </Button>
      </Popconfirm>
      <Popconfirm
        title="Submit this NC application to LMS?"
        okText="Submit"
        cancelText="Cancel"
        onConfirm={() =>
          onConfirmHandler(FinpowerXmlOption.NORTWEST_COLLEGE_ACCOUNT)
        }
        arrowPointAtCenter
      >
        <Button
          className="qf-btn-green d-none"
          loading={loading}
          data-testid="push-nortwwest-app-to-lms"
        >
          Push Nortwest College application to LMS
        </Button>
      </Popconfirm>
    </>
  );
};

export default SubmitApplicationBtn;
