import * as actionTypes from "../actions/types/internalNotes";
import {
  InternalNotesState,
  internalNotesStateDefaultValue,
} from "../types/InternalNotesState";

export const internalNotesState: InternalNotesState = internalNotesStateDefaultValue;

export const reducer = (
  state: InternalNotesState = internalNotesState,
  action: actionTypes.InternalNotesActions
): InternalNotesState => {
  switch (action.type) {
    case actionTypes.GET_ENTITY_NOTE_LIST: {
      return {
        ...state,
        internalNotesLoading: true,
        submitInternalNotesSuccess: false,
      };
    }
    case actionTypes.GET_ENTITY_NOTE_LIST_SUCCESS: {
      return {
        ...state,
        internalNotes: action.listResponse.data,
        internalNotesCount: action.listResponse.count
          ? action.listResponse.count
          : 0,
        internalNotesLoading: false,
      };
    }
    case actionTypes.GET_ENTITY_NOTE_LIST_ERROR: {
      return {
        ...state,
        internalNotesLoading: false,
      };
    }
    case actionTypes.SUBMIT_ENTITY_NOTES: {
      return {
        ...state,
        submitInternalNotesLoading: true,
      };
    }
    case actionTypes.SUBMIT_ENTITY_NOTES_SUCCESS: {
      return {
        ...state,
        submitInternalNotesLoading: false,
        submitInternalNotesSuccess: true,
      };
    }
    case actionTypes.SUBMIT_ENTITY_NOTES_FAILED: {
      return {
        ...state,
        submitInternalNotesLoading: false,
        submitInternalNotesSuccess: false,
      };
    }
    case actionTypes.RESET_ENTITY_INTERNAL_NOTES: {
      return internalNotesStateDefaultValue;
    }

    default: {
      return state;
    }
  }
};
