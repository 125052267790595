import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { AssessmentDetailState } from "../types/AssessmentDetail";
import { AssessmentTypes } from "../types/AssessmentType";

export const ASSESSMENT_DETAIL_DEFAULT_STATE: AssessmentDetailState = {
  assessment: {
    id: "",
    clientId: "",
    externalId: "",
    type: AssessmentTypes.APPLICATION,
    status: "",
    data: {},
    analyzedBy: null,
    decisionNote: null,
    decisionBy: null,
    decisionAt: null,
    createdAt: "",
    updatedAt: "",
    settlementNote: null,
    settledBy: null,
    settledAt: null,
    documents: [],
    assignees: [],
    approvalConditions: [],
  },
  status: LOADING_STATUS.IDLE,
};
