import { Action } from "redux";
import { ClientListRequest } from "../../types/ClientListRequest";
import { ClientResponse } from "../../types/ClientResponse";

export const GET_CLIENT_LIST = "GET_CLIENT_LIST";
export interface GetClientList extends Action {
  type: typeof GET_CLIENT_LIST;
  payload?: ClientListRequest;
}

export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS";
export interface GetClientlistSuccess extends Action {
  type: typeof GET_CLIENT_LIST_SUCCESS;
  clients: ClientResponse[];
  clientCount: number;
}

export const GET_CLIENT_LIST_ERROR = "GET_CLIENT_LIST_ERROR";
export interface GetClientListError extends Action {
  type: typeof GET_CLIENT_LIST_ERROR;
}

export type ClientListActions =
  | GetClientList
  | GetClientlistSuccess
  | GetClientListError;
