import { routes as authRoutes } from "@quest-finance/quest-fe-shared/dist/auth";
import { PageRoute } from "@quest-finance/quest-fe-shared/dist/common/types/PageRoute";
import { routes as userRoutes } from "@quest-finance/quest-fe-shared/dist/user";
import { routes as applicationRoutes } from "../application";
import { routes as assessmentRoutes } from "../assessment";
import { routes as iamRoutes } from "../iam";
import { routes as paymentRoutes } from "../payment";
import { routes as workflowRoutes } from "../workflow";

const privateRoutes: PageRoute[] = [
  ...assessmentRoutes,
  ...iamRoutes,
  ...userRoutes,
  ...applicationRoutes,
];
const publicRoutes: PageRoute[] = [...authRoutes];
const antdLayoutPrivateRoutes: PageRoute[] = [
  ...workflowRoutes,
  ...paymentRoutes,
];

export { privateRoutes, publicRoutes, antdLayoutPrivateRoutes };
