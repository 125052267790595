export const CREDIT_REFERENCE_DEFAULT_VALUE = {
  start: "",
  naf: "",
  paymentAmount: "",
  bank: "",
  term: "",
  asset: "",
  conduct: "",
  balloon: "",
  outstandingBalance: "",
  notes: "",
};

export const FORM_FIELD_LABELS = {
  bank: "Bank",
  start: "Start",
  term: "Term",
  naf: "NAF",
  asset: "Asset",
  paymentAmount: "Monthly payment amount",
  balloon: "Balloon",
  outstandingBalance: "Outstanding balance",
  conduct: "Conduct",
  notes: "Notes",
};

export const MAX_ALLOWED_RECORDS = 3;
