import { ApplicantRequest } from "@quest-finance/quest-fe-shared/dist/application/types/ApplicantRequest";
import { ApplicantResponse } from "@quest-finance/quest-fe-shared/dist/application/types/ApplicantResponse";
import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";

class ApplicantService {
  public static async update(
    applicationId: string,
    data: ApplicantRequest
  ): Promise<SingleResponse<ApplicantResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<ApplicantResponse>,
      ApplicantRequest
    >(
      {
        method: "POST",
        path: `/application/applications/${applicationId}/applicants/update`,
        data,
      },
      true
    );
    return result;
  }
}

export default ApplicantService;
