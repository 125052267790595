import { Action } from "redux";
import { RoleListRequest } from "../../types/RoleListRequest";
import { RoleResponse } from "../../types/RoleResponse";

export const GET_ROLE_LIST = "GET_ROLE_LIST";
export interface GetRoleList extends Action {
  type: typeof GET_ROLE_LIST;
  payload?: RoleListRequest;
}

export const GET_ROLE_LIST_SUCCESS = "GET_ROLE_LIST_SUCCESS";
export interface GetRoleListSuccess extends Action {
  type: typeof GET_ROLE_LIST_SUCCESS;
  roles: RoleResponse[];
  rolesCount: number;
}

export const GET_ROLE_LIST_ERROR = "GET_ROLE_LIST_ERROR";
export interface GetRoleListError extends Action {
  type: typeof GET_ROLE_LIST_ERROR;
}

export type RoleListActions =
  | GetRoleList
  | GetRoleListSuccess
  | GetRoleListError;
