import {
  AuditLogResponse,
  AuditService,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { HTTP_STATUS_CODES } from "@quest-finance/quest-fe-shared/dist/common/constants/httpStatusCodes";
import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import * as notification from "@quest-finance/quest-fe-shared/dist/notification";
import { AxiosError } from "axios";
import { put, call, ForkEffect, takeLatest } from "redux-saga/effects";
import {
  getPermissionsSuccess,
  getRoleAuditLogFailed,
  getRoleAuditLogSuccess,
  getRoleDetailsFailed,
  getRoleDetailsSuccess,
  saveRoleFailed,
  saveRoleSuccess,
} from "../actions/creators/roleAdmin";
import {
  GetRoleAuditLog,
  GetRoleDetails,
  GET_PERMISSIONS,
  GET_ROLE_AUDIT_LOG,
  GET_ROLE_DETAILS,
  SaveRole,
  SAVE_ROLE,
} from "../actions/types/roleAdmin";
import { NOTIFICATION_IDS } from "../constants/notificationIds";
import PermissionService from "../services/PermissionService";
import RoleService from "../services/RoleService";
import { RoleResponse, roleResponseDefaultValue } from "../types/RoleResponse";

const {
  actionCreator: { clearNotification, setNotification },
} = notification;

function* getRoleDetailsWorker({ roleId }: GetRoleDetails) {
  try {
    if (roleId) {
      const result: SingleResponse<RoleResponse> = yield call(
        RoleService.getDetails,
        roleId
      );
      yield put(getRoleDetailsSuccess(result.data));
    } else {
      yield put(getRoleDetailsSuccess(roleResponseDefaultValue));
    }
  } catch (error) {
    const apiError = error as AxiosError;
    const body = processErrorMessage(apiError);

    let notifId = NOTIFICATION_IDS.IAM_ROLE_DETAILS_ERROR + roleId;

    if (apiError.isAxiosError) {
      if (apiError.response?.status === HTTP_STATUS_CODES.NOT_FOUND) {
        notifId = NOTIFICATION_IDS.IAM_ROLE_NOT_FOUND + roleId;
      }
    }

    yield put(
      setNotification({
        id: notifId,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(getRoleDetailsFailed());
  }
}

function* saveRoleWorker({ roleForm, roleId }: SaveRole) {
  try {
    yield put(clearNotification());
    if (roleId) {
      yield call(RoleService.update, roleId, roleForm);
    } else {
      yield call(RoleService.create, roleForm);
    }

    yield put(
      setNotification({
        id: NOTIFICATION_IDS.IAM_ROLE_SAVE,
        className: "qst-notif-success",
        body: roleId
          ? "Role successfully updated"
          : "Role successfully created",
      })
    );
    yield put(saveRoleSuccess());
  } catch (error) {
    const apiError = error as AxiosError;
    const body = processErrorMessage(apiError);

    let id = NOTIFICATION_IDS.IAM_ROLE_SAVE;

    if (apiError.isAxiosError) {
      if (apiError.response?.status === HTTP_STATUS_CODES.NOT_FOUND) {
        id = NOTIFICATION_IDS.IAM_ROLE_NOT_FOUND;
      }
    }

    yield put(
      setNotification({
        id,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(saveRoleFailed());
  }
}

function* getPermissionsWorker() {
  try {
    const { data }: SingleResponse<Dictionary> = yield call(
      PermissionService.getPermissionList
    );
    const permissions = Object.keys(data).map((item) => ({
      label: data[item] as string,
      value: item,
    }));
    yield put(getPermissionsSuccess(permissions));
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: "permission-list-error",
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(saveRoleFailed());
  }
}

function* getRoleAuditLogWorker({ payload }: GetRoleAuditLog) {
  try {
    const result: ListResponse<AuditLogResponse> = yield call(
      AuditService.getAuditList,
      payload
    );
    yield put(getRoleAuditLogSuccess(result));
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: "role-audit-error",
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(getRoleAuditLogFailed());
  }
}

function* watchRoleAdmin(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(GET_ROLE_DETAILS, getRoleDetailsWorker);
  yield takeLatest(SAVE_ROLE, saveRoleWorker);
  yield takeLatest(GET_PERMISSIONS, getPermissionsWorker);
  yield takeLatest(GET_ROLE_AUDIT_LOG, getRoleAuditLogWorker);
}

export default watchRoleAdmin;
