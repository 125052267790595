import { AuditLogResponse } from "@quest-finance/quest-fe-shared/dist/audit";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { InternalNotesResponse } from "../../common/internal-notes/types/InternalNotesResponse";
import {
  assessmentDefaultValue,
  AssessmentResponse,
} from "./AssessmentResponse";

export interface AssessmentAdminState {
  assessment: AssessmentResponse;
  approvalConditions: string[];
  applicationAuditLogs: AuditLogResponse[];
  applicationAuditLogsCount: number;
  applicationAuditLogsLoading: boolean;
  assessmentAuditLogs: AuditLogResponse[];
  assessmentAuditLogsCount: number;
  assessmentAuditLogsLoading: boolean;
  accreditationAuditLogs: AuditLogResponse[];
  accreditationAuditLogsCount: number;
  accreditationAuditLogsLoading: boolean;
  funderAuditLogs: AuditLogResponse[];
  funderAuditLogsCount: number;
  funderAuditLogsLoading: boolean;
  getAssessmentDetailsLoading: boolean;
  internalNotes: InternalNotesResponse[];
  internalNotesCount: number;
  internalNotesLoading: boolean;
  submitInternalNotesLoading: boolean;
  submitInternalNotesSuccess: boolean;
  statusUpdate: LOADING_STATUS;
  uploadProgress: number;
}

export const assessmentAdminStateDefaultValue: AssessmentAdminState = {
  assessment: assessmentDefaultValue,
  approvalConditions: [],
  applicationAuditLogs: [],
  applicationAuditLogsCount: 0,
  applicationAuditLogsLoading: false,
  assessmentAuditLogs: [],
  assessmentAuditLogsCount: 0,
  assessmentAuditLogsLoading: false,
  accreditationAuditLogs: [],
  accreditationAuditLogsCount: 0,
  accreditationAuditLogsLoading: false,
  funderAuditLogs: [],
  funderAuditLogsCount: 0,
  funderAuditLogsLoading: false,
  getAssessmentDetailsLoading: false,
  internalNotes: [],
  internalNotesCount: 0,
  internalNotesLoading: false,
  submitInternalNotesLoading: false,
  submitInternalNotesSuccess: false,
  statusUpdate: LOADING_STATUS.IDLE,
  uploadProgress: 0,
};
