import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import * as actionTypes from "../actions/types/assessmentExtras";
import { ASSESSMENT_EXTRAS_DEFAULT_VALUE } from "../constants/assessmentExtras";
import {
  AssessmentExtrasState,
  GetAssessmentExtrasResponse,
} from "../types/AssessmentExtras";

const assessmentExtrasState: AssessmentExtrasState = ASSESSMENT_EXTRAS_DEFAULT_VALUE;

export const reducer = (
  state: AssessmentExtrasState = assessmentExtrasState,
  action: actionTypes.AssessmentExtrasActions
): AssessmentExtrasState => {
  switch (action.type) {
    case actionTypes.CE_GET_ASSESSMENT_EXTRAS: {
      const requestStatus = { ...state.requestStatus };

      if (action.requestId) {
        requestStatus[action.requestId] = {
          loading: true,
        };
      }

      return {
        ...state,
        requestStatus,
        status: LOADING_STATUS.LOADING,
      };
    }
    case actionTypes.CE_GET_ASSESSMENT_EXTRAS_SUCCESS: {
      const requestStatus = { ...state.requestStatus };
      if (action.requestId) {
        delete requestStatus[action.requestId];
      }

      return {
        ...state,
        status: LOADING_STATUS.SUCCESS,
        assessmentExtras: {
          ...state.assessmentExtras,
          ...action.response,
        },
      };
    }
    case actionTypes.CE_GET_ASSESSMENT_EXTRAS_FAILED: {
      const requestStatus = { ...state.requestStatus };
      let error = undefined;

      if (action.requestId) {
        requestStatus[action.requestId] = {
          loading: false,
          error: action.error,
        };
      } else {
        error = action.error;
      }

      return {
        ...state,
        error,
        status: LOADING_STATUS.FAILED,
        requestStatus,
      };
    }
    case actionTypes.CE_RESET_ASSESSMENT_EXTRAS: {
      const clearedProps: GetAssessmentExtrasResponse = {};
      action.key.forEach((key) => {
        clearedProps[key] = {};
      });

      return {
        ...state,
        assessmentExtras: {
          ...state.assessmentExtras,
          ...clearedProps,
        },
      };
    }
    default: {
      return state;
    }
  }
};
