export const dealerSaleConditions = [
  "Contracts and documentation to be executed in full",
  "Brokerage invoice - invoiced to Quest Finance Australia Pty Ltd",
  "Copy of tax invoice and deposit slip from a satisfactory supplier",
  "Asset pricing and description to be acceptable to Quest finance",
  "Clear copy of Guarantor's Licence",
  "Clear copy of Certified Guarantor's Licence",
  "Copy of Certified Trust Deed",
  "Certificate of Currency with Quest Finance Australia listed as interested party and VIN/Serial number of asset",
  "Confirmation of beneficial shareholder. ABC Pty Ltd does not beneficially hold the shares in XYZ Pty Ltd. If shares are held by a trust, please provide a letter from the accountant confirming the name of the Trustee, Trust, Appointer (if present) and beneficiaries.",
  "Prior to Documentation: Asset is subject to a Desktop Valuation. Please provide full asset specs plus photos and link for sale of asset (if available)",
  "Correspondence from accountant confirming predominant business use",
  "Deed of Guarantee required for ABC Pty Ltd (Guarantor). Please refer to Credit.",
  "Copy of Driver's Licence required for beneficial shareholders",
  "Proof of residential address",
];

export const privateSaleConditions = [
  "Copy of Current Registration Papers",
  "Clear copy of vendor licence",
  "Signed Tax Invoice from vendor or Invoice if not GST registered (if company vendor, all directors to sign)",
  "Current payout letter from existing financier (if encumbered)",
  "Vendor bank deposit slip or bank statement to confirm account details",
  "Private Sale Inspection including photos and Vin or serial number (Redbook, Olasio or Verimoto)",
  "Tenancy agreement or Rental statement from real estate agent",
  "Signed disbursement authority",
  "Original tax invoice",
  "Proof of payment",
  "Clear PPSR for asset, not to be a repairable write-off",
  "Clear PPSR for vendor (if ABN/ACN applicable). Waivers may be required",
];
