import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { getOffset } from "@quest-finance/quest-fe-shared/dist/common/utils/dataTable";
import * as qs from "query-string";
import { DATATABLE_MAX_ROWS } from "../../common/contstants/app";
import { AbaProcessPayload, AbaData, AbaHistory } from "../types/Aba";

class AbaService {
  public static processFile(params: {
    file: {
      name: string;
      contents: string;
    };
    submit?: boolean;
  }): Promise<SingleResponse<AbaData>> {
    const queryParams = qs.stringify({
      submit: params.submit,
    });

    return APIService.jsonRequest<SingleResponse<AbaData>, AbaProcessPayload>(
      {
        method: "POST",
        path: `/payment/aba/process?${queryParams}`,
        data: {
          fileContents: params.file.contents,
          fileName: params.file.name,
        },
      },
      true
    );
  }

  public static getHistory(
    page: number,
    limit = DATATABLE_MAX_ROWS
  ): Promise<ListResponse<AbaHistory>> {
    page = page > 0 ? page : 1;
    const queryParams = qs.stringify({
      limit,
      offset: getOffset(page, limit),
    });

    return APIService.jsonRequest<ListResponse<AbaHistory>, unknown>(
      {
        method: "GET",
        path: `/payment/aba/history?${queryParams}`,
      },
      true
    );
  }
}

export default AbaService;
