import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import { actionCreator } from "@quest-finance/quest-fe-shared/dist/notification";
import { AxiosError } from "axios";
import { put, call, takeEvery, ForkEffect } from "redux-saga/effects";
import {
  getClientListError,
  getClientListSuccess,
} from "../actions/creators/clientList";
import { GetClientList, GET_CLIENT_LIST } from "../actions/types/clientList";
import { NOTIFICATION_IDS } from "../constants/notificationIds";
import ClientService from "../services/ClientService";
import { ClientResponse } from "../types/ClientResponse";

const { setNotification } = actionCreator;
function* getClientListWorker({ payload }: GetClientList) {
  try {
    const results: ListResponse<ClientResponse> = yield call(
      ClientService.getClientList,
      payload
    );
    yield put(getClientListSuccess(results));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.IAM_CLIENT_LIST,
        className: "qst-notif-danger",
        body,
      })
    );
    yield put(getClientListError());
  }
}

function* watchClientList(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(GET_CLIENT_LIST, getClientListWorker);
}

export default watchClientList;
