import { createSelector } from "@reduxjs/toolkit";
import { AuditLogState } from "../types/AuditLogState";
import { EntityState } from "../types/EntityState";

export const auditLogSelector = (state: EntityState): AuditLogState =>
  state.entity.auditLogs;

export const getEntityAuditLogsSelector = createSelector(
  auditLogSelector,
  (auditLogSelector) => auditLogSelector.entityAuditLogs
);

export const getEntityAuditLogsLoadingSelector = createSelector(
  auditLogSelector,
  (auditLogSelector) => auditLogSelector.entityAuditLogsLoading
);

export const getEntityAuditLogsCountSelector = createSelector(
  auditLogSelector,
  (auditLogSelector) => auditLogSelector.entityAuditLogsCount
);
