import React from "react";
import { UserOutlined } from "@ant-design/icons";
import {
  logout,
  useAuthenticationDispatch,
} from "@quest-finance/quest-fe-shared/dist/auth";
import { Avatar, Dropdown, Menu } from "antd";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const UserNav: React.FunctionComponent = () => {
  const dispatch = useAuthenticationDispatch();
  const history = useHistory();
  const handleLogout = async () => {
    dispatch(logout());
    history.replace("/auth/login");
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to="/user/change-password">Profile</Link>
      </Menu.Item>
      <Menu.Item key="1">
        <span onClick={handleLogout}>Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      trigger={["click"]}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      overlayClassName="user-menu"
    >
      <Avatar className="avatar" icon={<UserOutlined />} />
    </Dropdown>
  );
};

export default UserNav;
