import React from "react";
import { PageRoute } from "@quest-finance/quest-fe-shared/dist/common/types/PageRoute";

const WorkflowTemplate = React.lazy(() => import("../pages/template/Template"));

const routes: PageRoute[] = [
  {
    name: "Workflow Diagram",
    path: "/workflow/diagram",
    exact: true,
    component: WorkflowTemplate,
  },
];

export default routes;
