import { combineReducers } from "redux";
import { reducer as auditLogs } from "./auditLogs";
import { reducer as entityIndividual } from "./entityIndividual";
import { reducer as internalNotes } from "./internalNotes";

export default combineReducers({
  internalNotes,
  auditLogs,
  entityIndividual,
});
