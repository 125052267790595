import "./NoteForm.scss";
import React, { useEffect, useState } from "react";
import RichTextEditor from "@quest-finance/quest-fe-shared/dist/common/components/RichTextEditor/RichTextEditor";
import { Button } from "antd";
import { useParams } from "react-router";
import striptags from "striptags";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../../common/utils/error";
import NoteService from "../../../services/NoteService";
import { NoteAttributes, NoteForm, Sections } from "../../../types/Notes";

interface NotesFormProps {
  maxLength?: number;
  section: Sections;
  noteEntry?: NoteAttributes;
  onSave?: (note: NoteAttributes) => void;
  onCancel?: () => void;
}

const NotesForm: React.FunctionComponent<NotesFormProps> = ({
  maxLength = 10000,
  section,
  onSave,
  onCancel,
  noteEntry,
}: NotesFormProps) => {
  const { assessmentId } = useParams<{
    assessmentId: string;
  }>();
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setNote(noteEntry?.note || "");
  }, [noteEntry]);

  useEffect(() => {
    setNote("");
  }, [section]);

  const saveNote = async () => {
    const plainText = striptags(note);
    if (!note) {
      return showErrorPopUp("Note content is required.");
    }
    if (plainText.length > maxLength) {
      return showErrorPopUp(
        "Note content already reached the maximum number of allowed characters."
      );
    }

    let savedNote;

    setIsLoading(true);
    try {
      if (noteEntry) {
        const { data } = await NoteService.update(assessmentId, noteEntry.id, {
          note,
        } as NoteForm);
        savedNote = data;
      } else {
        const { data } = await NoteService.create(assessmentId, {
          section,
          note,
        });
        savedNote = data;
      }

      showSuccessPopUp("Note has been saved.");
      setNote("");

      if (onSave) onSave(savedNote as NoteAttributes);
    } catch (e) {
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="asm-notes-form">
      <RichTextEditor value={note} onChange={setNote} toolbar={false} />
      <div className="char-count">
        {striptags(note).length} / {maxLength}
      </div>
      <div className="mt-3">
        <Button className="qf-btn-green" onClick={saveNote} loading={isLoading}>
          {noteEntry ? "Update" : "Add"} note
        </Button>
        {noteEntry && <Button onClick={onCancel}>Cancel</Button>}
      </div>
    </div>
  );
};

export default NotesForm;
