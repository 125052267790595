import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import {
  GET_ROLE_LIST,
  GET_ROLE_LIST_ERROR,
  GET_ROLE_LIST_SUCCESS,
  RoleListActions,
} from "../actions/types/roleList";
import { RoleListState } from "../types/RoleListState";

export const roleListState: RoleListState = {
  roles: [],
  rolesCount: 0,
  rolesLoading: false,
  resetPage: false,
};

export const reducer = (
  state: RoleListState = roleListState,
  action: RoleListActions
): RoleListState => {
  switch (action.type) {
    case GET_ROLE_LIST: {
      let resetPage = false;
      if (action.payload && action.payload.order !== state.order)
        resetPage = true;

      return {
        ...state,
        order: action.payload ? action.payload.order : undefined,
        limit: action.payload ? action.payload.limit : DATATABLE_MAX_ROWS,
        offset: action.payload ? action.payload.offset : state.offset,
        resetPage,
        rolesLoading: true,
      };
    }
    case GET_ROLE_LIST_SUCCESS: {
      return {
        ...state,
        rolesLoading: false,
        resetPage: false,
        roles: action.roles,
        rolesCount: action.rolesCount,
      };
    }
    case GET_ROLE_LIST_ERROR: {
      return {
        ...state,
        rolesLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
