import React, { useCallback, useState, useEffect } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { useDownloadDocument } from "@quest-finance/quest-fe-shared/dist/files";
import { Empty, Popconfirm, Spin } from "antd";
import lodash from "lodash";
import { useParams } from "react-router";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import { DATE_LIST_FORMAT } from "../../../common/contstants/app";
import { processError } from "../../../common/utils/error";
import WorkflowDocumentService from "../../services/WorkflowDocumentService";
import { WorkflowDocument } from "../../types/Document";
import "./DocumentList.scss";

const DocumentList: React.FunctionComponent = () => {
  const { assessmentId } = useParams<{
    assessmentId: string;
  }>();
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState<WorkflowDocument[]>([]);
  const { downloadFile } = useDownloadDocument();
  const handleFileDownload = (
    event: React.MouseEvent<HTMLAnchorElement>,
    { id, workflowTaskId }: WorkflowDocument
  ) => {
    event.preventDefault();
    downloadFile(`/workflow/task/${workflowTaskId}/documents/${id}/download`);
  };
  const handleFileDelete = async ({ id, workflowTaskId }: WorkflowDocument) => {
    setIsLoading(true);

    try {
      await WorkflowDocumentService.deleteDocument(workflowTaskId, id);
      showSuccessPopUp("Worfklow document has been deleted.");
      getDocuments();
    } catch (e) {
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
      setIsLoading(false);
    }
  };

  const renderFileNameColumn = (document: WorkflowDocument) => {
    return (
      <a
        href="/"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
          handleFileDownload(event, document)
        }
      >
        {document.originalFilename}
      </a>
    );
  };

  const renderDeleteButton = (doc: WorkflowDocument) => {
    return (
      <Popconfirm
        title="Sure to delete?"
        okText={<span data-testid={`confirm-delete-${doc.id}`}>Yes</span>}
        onConfirm={() => handleFileDelete(doc)}
        placement="left"
      >
        <DeleteFilled
          className="delete-button"
          data-testid={`doc-${doc.id}-delete-btn`}
        />
      </Popconfirm>
    );
  };

  const getDocuments = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await WorkflowDocumentService.getListForWorkflow({
        targetId: assessmentId,
        offset: 0,
        limit: 500,
      });

      setDocuments(result.data);
    } catch (e) {
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setIsLoading(false);
    }
  }, [setDocuments, assessmentId]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  let list = null;
  if (!isLoading) {
    list = !documents.length ? (
      <Empty description="No documents found" data-testid="no-docs-found" />
    ) : (
      <table>
        <tbody data-testid="doc-list">
          {documents.map((document) => (
            <tr key={document.id}>
              <td data-testid={`doc-${document.id}-created-at`}>
                {dateFormat(new Date(document.createdAt), DATE_LIST_FORMAT)}
              </td>
              <td data-testid={`doc-${document.id}-created-by`}>
                {lodash.isEmpty(document.creator)
                  ? "-"
                  : `${document.creator.firstName} ${document.creator.lastName}`}
              </td>
              <td data-testid={`doc-${document.id}-name`}>
                {renderFileNameColumn(document)}
              </td>
              <td className="action">{renderDeleteButton(document)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <Spin spinning={isLoading}>
      <div className="workflow-documents" data-testid="workflow-docs">
        {list}
      </div>
    </Spin>
  );
};

export default DocumentList;
