export enum Status {
  REFER = "REFER",
  PASS = "PASS",
  HOLD = "HOLD",
}

export type StatusCount = Record<Status, number>;

export interface ApprovalCondition {
  id: string;
  assessmentId: string;
  condition: string;
  status: Status;
  createdBy: number;
  createdAt: string;
}

export interface ApprovalConditionsResponse {
  approver: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
  conditions: ApprovalCondition[];
}

export interface ApprovalConditionsRequest {
  conditions: string[];
}

export interface UpdateStatusParams {
  status: Status;
}
