import { RoleResponse } from "./RoleResponse";

export enum USER_STATUS {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  CLOSED = "CLOSED",
}

export const userStatus: Record<string, string> = {
  [USER_STATUS.ACTIVE]: "Active",
  [USER_STATUS.SUSPENDED]: "Suspended",
  [USER_STATUS.CLOSED]: "Closed",
};

export type ManagedClient = {
  clientId: number;
  client: {
    name: string;
  };
  notificationEnabled: boolean;
};

export type ManagedUser = {
  userId: number;
  user: {
    firstName: string;
    lastName: string;
    email: string;
  };
  notificationEnabled: boolean;
};

export interface User {
  id: number;
  parentId: number | null;
  clientId: number | null;
  entityId: number;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  status: USER_STATUS;
  roles: RoleResponse[];
  managedClientManagementGroups: ManagedClient[];
  managedUserManagementGroups: ManagedUser[];
}

export const userDefaultValue: User = {
  id: 0,
  parentId: null,
  clientId: null,
  entityId: 0,
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  status: USER_STATUS.ACTIVE,
  roles: [],
  managedClientManagementGroups: [],
  managedUserManagementGroups: [],
};

export interface UserForm {
  clientId: number | null;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  status: USER_STATUS;
  parentId: number | null;
  creditEnriched?: boolean;
}

export const userFormDefaultValue: UserForm = {
  clientId: null,
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  status: USER_STATUS.ACTIVE,
  parentId: 0,
};

export interface AssignRolesRequest {
  roleIds: number[];
}

export type ClientMgtGroup = [
  {
    clientId: number;
    notificationEnabled: boolean;
  }
];

export type UserMgtGroup = [
  {
    userId: number;
    notificationEnabled: boolean;
  }
];
