import * as actionTypes from "../actions/types/funderDetails";
import {
  FunderDetailsState,
  funderDetailsStateDefaultValue,
} from "../types/FunderState";

const funderDetailsState = funderDetailsStateDefaultValue;

export const reducer = (
  state: FunderDetailsState = funderDetailsState,
  action: actionTypes.FunderDetailsActions
): FunderDetailsState => {
  switch (action.type) {
    case actionTypes.GET_FUNDER_DETAILS: {
      return {
        ...state,
        getFunderLoading: true,
      };
    }
    case actionTypes.GET_FUNDER_DETAILS_SUCCESS: {
      return {
        ...state,
        getFunderLoading: false,
        funder: action.funder,
      };
    }
    case actionTypes.GET_FUNDER_DETAILS_FAILED: {
      return {
        ...state,
        getFunderLoading: false,
      };
    }
    case actionTypes.SAVE_FUNDER: {
      return {
        ...state,
        saveFunderLoading: true,
      };
    }
    case actionTypes.SAVE_FUNDER_SUCCESS:
    case actionTypes.SAVE_FUNDER_FAILED: {
      return {
        ...state,
        saveFunderLoading: false,
      };
    }
    case actionTypes.RESET_FUNDER_DETAILS_STATE: {
      return funderDetailsStateDefaultValue;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
