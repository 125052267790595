import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { Slug } from "../types/Navigation";
import { Sections, NoteForm, ListState } from "../types/Notes";

export const SECTION_LABEL: Record<string, string> = {
  [Sections.QUOTE]: "Quote",
  [Sections.APPLICANT_GUARANTORS]: "Applicant & Guarantors",
  [Sections.REF_STATEMENTS]: "Ref & Statements",
  [Sections.SECURITY]: "Security",
  [Sections.NOTES_CONTRACTS]: "Notes & Contracts",
  [Sections.DECISION]: "Decision",
  [Sections.SETTLEMENT]: "Settlement",
  [Sections.ADMIN]: "Admin",
};

export const SECTION_KEY: Record<string, Sections> = {
  [Slug.QUOTE]: Sections.QUOTE,
  [Slug.APPLICANT_GUARANTORS]: Sections.APPLICANT_GUARANTORS,
  [Slug.REF_STATEMENTS]: Sections.REF_STATEMENTS,
  [Slug.SECURITY]: Sections.SECURITY,
  [Slug.NOTES_CONTRACTS]: Sections.NOTES_CONTRACTS,
  [Slug.DECISION]: Sections.DECISION,
  [Slug.SETTLEMENT]: Sections.SETTLEMENT,
};

export const DEFAULT_NOTE: NoteForm = {
  note: "",
  section: undefined,
};

export const LIST_STATE: ListState = {
  notes: [],
  count: 0,
  status: LOADING_STATUS.IDLE,
  error: {},
  lastResetTimestamp: 0,
};
