import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { Method } from "axios";
import * as queryString from "query-string";
import {
  AssessmentDetail,
  AssessmentSettlementDate,
  AssessmentStatusRequest,
  GetLinkedListParams,
  GetListParams,
} from "../types/AssessmentDetail";
import { AssessmentAssigneeRequest } from "../types/Assignee";
import { StatusReasonList } from "../types/Status";

class AssessmentService {
  public static async getList(
    listParams: GetListParams
  ): Promise<ListResponse<AssessmentDetail>> {
    const queryParams = queryString.stringify(listParams, {
      arrayFormat: "comma",
    });
    const method: Method = "GET";
    const path = `/assessment/assessments?${queryParams}`;

    const result = await APIService.jsonRequest<
      ListResponse<AssessmentDetail>,
      unknown
    >(
      {
        method,
        path,
      },
      true
    );
    return result;
  }

  public static async getDetails(
    assessmentId: string
  ): Promise<SingleResponse<AssessmentDetail>> {
    const result = await APIService.jsonRequest<
      SingleResponse<AssessmentDetail>,
      unknown
    >(
      {
        method: "GET",
        path: `/assessment/assessments/${assessmentId}`,
      },
      true
    );
    return result;
  }

  public static async setAssignee(
    assessmentId: string,
    data: AssessmentAssigneeRequest[]
  ): Promise<SingleResponse<AssessmentDetail>> {
    const result = await APIService.jsonRequest<
      SingleResponse<AssessmentDetail>,
      AssessmentAssigneeRequest[]
    >(
      {
        method: "PUT",
        path: `/assessment/assessments/${assessmentId}/assignee`,
        data,
      },
      true
    );
    return result;
  }

  public static async updateAssessmentStatus(
    assessmentId: string,
    data: AssessmentStatusRequest
  ): Promise<SingleResponse<AssessmentDetail>> {
    const result = await APIService.jsonRequest<
      SingleResponse<AssessmentDetail>,
      AssessmentStatusRequest
    >(
      {
        method: "PUT",
        path: `/assessment/assessments/${assessmentId}/status`,
        data,
      },
      true
    );
    return result;
  }

  public static async getIntroducerAssessmentTotal(
    introducerId: number
  ): Promise<SingleResponse<Dictionary>> {
    const result = await APIService.jsonRequest<
      SingleResponse<Dictionary>,
      unknown
    >(
      {
        method: "GET",
        path: `/assessment/assessments/user/${introducerId}/total-assessment`,
      },
      true
    );
    return result;
  }

  public static async getLinkedAccountsList(
    abn: string,
    listParams: GetLinkedListParams
  ): Promise<ListResponse<AssessmentDetail>> {
    const queryParams = queryString.stringify(listParams, {
      arrayFormat: "comma",
    });
    const method: Method = "GET";
    const path = `/assessment/assessments/linked-accounts?abn=${abn}&${queryParams}`;

    const result = await APIService.jsonRequest<
      ListResponse<AssessmentDetail>,
      unknown
    >(
      {
        method,
        path,
      },
      true
    );
    return result;
  }

  public static async getStatusReasons(
    statusGroup: string[]
  ): Promise<ListResponse<StatusReasonList>> {
    const result = await APIService.jsonRequest<
      ListResponse<StatusReasonList>,
      unknown
    >(
      {
        method: "GET",
        path: `/assessment/assessments/status-reason?statusGroups=${statusGroup}`,
      },
      true
    );
    return result;
  }

  public static async updateAssessmentSettlementDate(
    assessmentId: string,
    settledDate: string
  ): Promise<SingleResponse<AssessmentDetail>> {
    const result = await APIService.jsonRequest<
      SingleResponse<AssessmentDetail>,
      AssessmentSettlementDate
    >(
      {
        method: "PATCH",
        path: `/assessment/assessments/${assessmentId}/settled-date`,
        data: {
          settledDate,
        },
      },
      true
    );
    return result;
  }
}

export default AssessmentService;
