import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { InternalNotesListRequest } from "../../../common/internal-notes/types/InternalNotesListRequest";
import { InternalNotesResponse } from "../../../common/internal-notes/types/InternalNotesResponse";
import * as actionTypes from "../types/internalNotes";

export const getEntityNotesList = (
  entityId: number,
  payload: InternalNotesListRequest
): actionTypes.GetEntityNotesList => ({
  type: actionTypes.GET_ENTITY_NOTE_LIST,
  entityId,
  payload,
});

export const getInternalNotesListSuccess = (
  listResponse: ListResponse<InternalNotesResponse>
): actionTypes.GetEntityNotesListSuccess => ({
  type: actionTypes.GET_ENTITY_NOTE_LIST_SUCCESS,
  listResponse,
});

export const getInternalNotesListError = (): actionTypes.GetEntityNotesListError => ({
  type: actionTypes.GET_ENTITY_NOTE_LIST_ERROR,
});

export const submitEntityNotes = (
  entityId: number,
  note: string
): actionTypes.SubmitEntityNotes => ({
  type: actionTypes.SUBMIT_ENTITY_NOTES,
  entityId,
  note,
});

export const submitEntityNotesSuccess = (): actionTypes.SubmitEntityNotesSuccess => ({
  type: actionTypes.SUBMIT_ENTITY_NOTES_SUCCESS,
});

export const submitEntityNotesFailed = (): actionTypes.SubmitEntityNotesFailed => ({
  type: actionTypes.SUBMIT_ENTITY_NOTES_FAILED,
});

export const resetEntityInternalNotes = (): actionTypes.ResetEntityInternalNotes => ({
  type: actionTypes.RESET_ENTITY_INTERNAL_NOTES,
});
