import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { UserListRequest } from "../../types/UserListRequest";
import { UserResponse } from "../../types/UserResponse";
import * as types from "../types/userList";

export const getUserList = (payload: UserListRequest): types.GetUserList => ({
  type: types.GET_USER_LIST,
  payload,
});

export const getUserListSuccess = (
  userResponse: ListResponse<UserResponse>
): types.GetUserListSuccess => ({
  type: types.GET_USER_LIST_SUCCESS,
  users: userResponse.data,
  userCount: userResponse.count ? userResponse.count : 0,
});

export const getUserListError = (): types.GetUserListError => ({
  type: types.GET_USER_LIST_ERROR,
});

export const resetUserList = (): types.ResetUserList => ({
  type: types.RESET_USER_LIST,
});
