import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";
import { LabelValue } from "@quest-finance/quest-fe-shared/dist/common/types/LabelValue";
import { Button, Col, Form, Input, Row, Select } from "antd";
import * as lodash from "lodash";
import * as queryString from "query-string";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { AuditLogList } from "../../../audit";
import AuditLogsService from "../../../audit/services/AuditLogsService";
import { AuditLogParams } from "../../../audit/types/AuditLog";
import { showErrorPopUp } from "../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../common/utils/error";
import { AUDIT_MODELS } from "../../constants/auditModels";
import { assessmentResponseSelector } from "../../selectors/assessmentDetail";

const AsseessmentAudit: React.FunctionComponent = () => {
  const assessment = useSelector(assessmentResponseSelector);
  const location = useLocation();
  const history = useHistory();
  const [auditCategories, setAuditCategories] = useState<LabelValue[]>([]);
  const [categoryLabels, setCategoryLabels] = useState<Dictionary>();
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const urlQuery = useMemo<AuditLogParams>(() => {
    return queryString.parse(location.search, {
      parseNumbers: true,
      arrayFormat: "comma",
    });
  }, [location.search]);

  const modelNames = useMemo(() => {
    return [
      AUDIT_MODELS.APPLICATION,
      AUDIT_MODELS.ASSESSMENT,
      AUDIT_MODELS.FUNDER,
      AUDIT_MODELS.LMS_ASSESSMENT_SUBMISSION,
    ];
  }, []);

  const modelIds = useMemo(() => {
    const ids = [assessment.externalId, assessment.id];
    return ids.filter((id) => !!id);
  }, [assessment]);

  const getAuditCategories = useCallback(async () => {
    setCategoriesLoading(true);
    try {
      const result = await AuditLogsService.getAuditCategories();
      setAuditCategories(result.data);
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setCategoriesLoading(false);
    }
  }, []);

  useEffect(() => {
    getAuditCategories();
  }, [getAuditCategories]);

  useEffect(() => {
    if (auditCategories.length > 0) {
      const labels: Dictionary = {};
      auditCategories.forEach((category) => {
        labels[category.value] = category.label;
      });
      setCategoryLabels(labels);
    }
  }, [auditCategories]);

  const setParams = (params: AuditLogParams) => {
    const url = {
      ...urlQuery,
      ...params,
    };

    const query = queryString.stringify(url, { encode: false });

    history.push("?" + query);
  };

  const onPageChange = (page?: number) => {
    setParams({ page });
  };

  const handleCategoryChange = (value: string) => {
    setParams({
      category: value,
      page: undefined,
    });
  };

  const handleClearCategory = () => {
    setParams({
      category: undefined,
      page: undefined,
    });
  };

  return (
    <div className="audit-log-list">
      <div className="section-header-1">Audit Logs</div>
      <Row>
        <Col span={24} md={6}>
          <Form layout="vertical">
            <Form.Item label="Filter by category">
              <Input.Group>
                <Select
                  className="category-select"
                  disabled={categoriesLoading}
                  value={lodash.get(urlQuery, "category")}
                  onChange={handleCategoryChange}
                  style={{
                    width: "75%",
                  }}
                >
                  {auditCategories.map((category) => (
                    <Select.Option key={category.value}>
                      {category.label}
                    </Select.Option>
                  ))}
                </Select>
                {lodash.get(urlQuery, "category") && (
                  <Button onClick={handleClearCategory}>Clear</Button>
                )}
              </Input.Group>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <AuditLogList
        onPageChange={onPageChange}
        modelIds={modelIds}
        modelNames={modelNames}
        limit={DATATABLE_MAX_ROWS}
        page={lodash.get(urlQuery, "page", 1)}
        category={lodash.get(urlQuery, "category", "")}
        categoryLabels={categoryLabels}
      />
    </div>
  );
};

export default AsseessmentAudit;
