import React, { useState, useMemo } from "react";
import { SendOutlined } from "@ant-design/icons";
import { applicationStatuses } from "@quest-finance/quest-fe-shared/dist/application";
import { Popover, Modal } from "antd";
import classnames from "classnames";
import _ from "lodash";
import { useSelector } from "react-redux";
import { ApplicationResponse } from "../../../application";
import { QF_APPROVAL_ADVICE } from "../../constants/assessmentExtras";
import {
  assessmentDataSelector,
  assessmentStatusSelector,
} from "../../selectors/assessmentDetail";
import { assessmentExtrasSelector } from "../../selectors/assessmentExtras";
import ApprovalEmail, { ReApprovalEmail } from "./Approval/Approval";
import "./index.scss";

const { APPLICATION_STATUSES } = applicationStatuses;

export type EmailContent = {
  onAfterSubmit?: () => void;
};

const emailTemplates: Record<
  string,
  {
    title: string;
    Content: React.FC<EmailContent>;
    menuLabel: JSX.Element;
    status: string[];
  }
> = {
  approval: {
    title: "Approval for",
    Content: ApprovalEmail,
    menuLabel: <>&#64; Approval</>,
    status: [APPLICATION_STATUSES.APPROVED_WAITING_FOR_MORE_INFO],
  },
  "re-approval": {
    title: "Re-approval for",
    Content: ReApprovalEmail,
    menuLabel: <>&#64; Re-approval</>,
    status: [APPLICATION_STATUSES.APPROVED_WAITING_FOR_MORE_INFO],
  },
};

const Email: React.FC = () => {
  const [modalKey, setModalKey] = useState<null | string>(null);
  const assessmentStatus = useSelector(assessmentStatusSelector);
  const assessmentExtras = useSelector(assessmentExtrasSelector);
  const application = (useSelector(
    assessmentDataSelector
  ) as unknown) as ApplicationResponse;
  const approvalExpiry = _.get(
    assessmentExtras?.assessmentExtras?.[QF_APPROVAL_ADVICE],
    `expiryDate`,
    null
  ) as Record<string, unknown>;

  const onAfterSubmitHandler = () => {
    setModalKey(null);
  };

  const modalContent = useMemo(() => {
    if (modalKey && emailTemplates[modalKey]) {
      const { Content } = emailTemplates[modalKey];
      return <Content onAfterSubmit={onAfterSubmitHandler} />;
    }

    return null;
  }, [modalKey]);

  const popoverContent = Object.entries(emailTemplates).map(([key, option]) => {
    let enabled = option.status.includes(assessmentStatus);

    if (approvalExpiry && key === "approval") {
      enabled = false;
    }

    return (
      <div
        key={key}
        onClick={() => (enabled ? setModalKey(key) : null)}
        className={classnames("template-option", {
          enabled,
        })}
      >
        {option.menuLabel}
      </div>
    );
  });

  return (
    <div className="emailer">
      <Popover
        content={popoverContent}
        title="Available templates"
        placement="right"
        getPopupContainer={(node) => node.parentElement as HTMLElement}
      >
        <div className="menu-item">
          <SendOutlined />
          <span className="title">Send Communication</span>
        </div>
      </Popover>
      <Modal
        title={`${_.get(emailTemplates, modalKey + ".title", "")} ${
          application?.applicant?.entityName
        }`}
        visible={!!modalKey}
        onCancel={() => setModalKey(null)}
        maskClosable={false}
        destroyOnClose={true}
        footer={null}
        width={768}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default Email;
