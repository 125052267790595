import "./ConfirmLmsModal.scss";
import React, { useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { AxiosError } from "axios";
import {
  showErrorPopUp,
  showSuccessPopUp,
  showWarningPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import { ERROR_CODES } from "../../../common/contstants/errorCodes";
import { processError } from "../../../common/utils/error";
import AssessmentService from "../../services/AssessmentService";
import { AssessmentDetail } from "../../types/AssessmentDetail";

type ConfirmLmsModalProps = {
  assessment: AssessmentDetail;
  status: string;
  visible: boolean;
  onCancel: () => void;
  onSuccess: (data: AssessmentDetail) => void;
  onWarning: (status: string) => void;
};

const ConfirmLmsModal: React.FunctionComponent<ConfirmLmsModalProps> = ({
  assessment,
  status,
  visible,
  onCancel,
  onSuccess,
  onWarning,
}: ConfirmLmsModalProps) => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const updateStatus = async () => {
    setSubmitLoading(true);
    try {
      const { data } = await AssessmentService.updateAssessmentStatus(
        assessment.id,
        {
          status,
        }
      );
      onSuccess(data);
      showSuccessPopUp(
        "Successfully updated the status and submitted the application to LMS"
      );
    } catch (error) {
      const apiError = error as AxiosError;
      if (
        apiError.response?.data.errorCode ===
        ERROR_CODES.APPLICATION_ALREADY_SUBMITTED
      ) {
        onWarning(status);
        showWarningPopUp(
          "Application was previously submitted to finpower, status has been successfully updated"
        );
      } else {
        processError(error, (errorMessage) => {
          showErrorPopUp(errorMessage);
        });
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Modal
      title={
        <span className="f-bold f-quest-navy">
          Push {assessment.data.applicationNumber as string} to Finpower
        </span>
      }
      className="confirm-lms-modal"
      visible={visible}
      onCancel={onCancel}
      footer={false}
      destroyOnClose={true}
      closeIcon={<CloseCircleFilled />}
    >
      <div className="confirmation-text">
        This will push the application details to Finpower and cannot be undone.
        Are you sure you want to proceed?
      </div>
      <div className="confirmation-buttons">
        <Button className="qf-btn-red-invert" onClick={onCancel}>
          No
        </Button>{" "}
        <Button
          className="qf-btn-green"
          onClick={updateStatus}
          loading={submitLoading}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmLmsModal;
