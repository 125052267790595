import { ProgressEvent } from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import {
  DocumentService,
  UploadRequest,
} from "@quest-finance/quest-fe-shared/dist/files";

type UploadDocumentArgs = {
  path: string;
  formData: UploadRequest;
  onSuccess?: () => void;
  onError?: () => void;
  onUpload?: (event: ProgressEvent) => void;
};
export const uploadDocument = async (
  args: UploadDocumentArgs
): Promise<void> => {
  try {
    await DocumentService.uploadDocument(
      args.path,
      args.formData,
      args.onUpload
    );
    if (args.onSuccess) args.onSuccess();
  } catch (e) {
    if (args.onError) args.onError();
  }
};
