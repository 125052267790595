import React from "react";
import classNames from "classnames";
import NumberFormat, { NumberFormatProps } from "react-number-format";

export const Currency: React.FunctionComponent<NumberFormatProps> = (
  props: NumberFormatProps
) => {
  const { className, ...restProps } = props;
  return (
    <span className={classNames("ant-input-affix-wrapper", className)}>
      <span className="ant-input-prefix">$</span>
      <NumberFormat
        type="tel"
        autoComplete="off"
        displayType="input"
        allowNegative={false}
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={2}
        className="ant-input"
        {...restProps}
      />
    </span>
  );
};
