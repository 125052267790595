import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import * as types from "../types/ApprovalCondition";

class ApprovalConditionService {
  public static async getConditions(
    assessmentId: string
  ): Promise<SingleResponse<types.ApprovalConditionsResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<types.ApprovalConditionsResponse>,
      unknown
    >(
      {
        method: "GET",
        path: `/assessment/assessments/${assessmentId}/approval-conditions`,
      },
      true
    );
    return result;
  }

  public static async submitApprovalConditions(
    assessmentId: string,
    data: types.ApprovalConditionsRequest
  ): Promise<ListResponse<types.ApprovalCondition>> {
    const result = await APIService.jsonRequest<
      ListResponse<types.ApprovalCondition>,
      types.ApprovalConditionsRequest
    >(
      {
        method: "POST",
        path: `/assessment/assessments/${assessmentId}/approval-conditions`,
        data,
      },
      true
    );
    return result;
  }

  public static async updateStatus(
    assessmentId: string,
    conditionId: string,
    status: types.Status
  ): Promise<SingleResponse<types.ApprovalCondition>> {
    const data: types.UpdateStatusParams = { status };
    const result = await APIService.jsonRequest<
      SingleResponse<types.ApprovalCondition>,
      types.UpdateStatusParams
    >(
      {
        method: "PATCH",
        path: `/assessment/assessments/${assessmentId}/approval-conditions/${conditionId}`,
        data,
      },
      true
    );
    return result;
  }
}

export default ApprovalConditionService;
