import { AuditLogResponse } from "@quest-finance/quest-fe-shared/dist/audit";
import { LabelValue } from "@quest-finance/quest-fe-shared/dist/common/types/LabelValue";
import { RoleForm, roleFormDefaultValue } from "./RoleForm";
import { RoleResponse, roleResponseDefaultValue } from "./RoleResponse";

export interface RoleAdminState {
  role: RoleResponse;
  roleForm: RoleForm;
  getRoleLoading: boolean;
  saveRoleLoading: boolean;
  saveRoleSuccess: boolean;
  permissions: LabelValue[];
  getPermissionsLoading: boolean;
  roleAuditLogs: AuditLogResponse[];
  roleAuditLogsCount: number;
  roleAuditLogsLoading: boolean;
}

export const roleAdminStateDefaultValue: RoleAdminState = {
  role: roleResponseDefaultValue,
  roleForm: roleFormDefaultValue,
  getRoleLoading: false,
  saveRoleLoading: false,
  saveRoleSuccess: false,
  permissions: [],
  getPermissionsLoading: false,
  roleAuditLogs: [],
  roleAuditLogsCount: 0,
  roleAuditLogsLoading: false,
};
