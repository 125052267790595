export interface EntityIndividualRequest {
  firstName: string;
  middleName: string | null;
  lastName: string;
  dateOfBirth: string;
  driverLicenseNumber: string;
  driverLicenseState: string;
  creditEnriched?: boolean;
}

export const entityIndividualRequestDefaultValue: EntityIndividualRequest = {
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: "",
  driverLicenseNumber: "",
  driverLicenseState: "",
  creditEnriched: false,
};

export interface EntityIndividualResponse {
  id: number;
  entityId: number;
  firstName: string;
  middleName: string | null;
  lastName: string | null;
  dateOfBirth: string;
  driverLicenseNumber: string;
  driverLicenseState: string;
  creditEnriched: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export const entityIndividualResponseDefaultValue: EntityIndividualResponse = {
  id: 0,
  entityId: 0,
  firstName: "",
  middleName: null,
  lastName: "",
  dateOfBirth: "",
  driverLicenseNumber: "",
  driverLicenseState: "",
  creditEnriched: false,
};

export interface EntityIndividualState {
  individual: EntityIndividualResponse;
  getIndividualLoading: boolean;
  saveEntityLoading: boolean;
}

export const entityIndividualStateDefaultValue: EntityIndividualState = {
  individual: entityIndividualResponseDefaultValue,
  getIndividualLoading: false,
  saveEntityLoading: false,
};
