import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { AssessmentListRequest } from "../../types/AssessmentListRequest";
import { AssessmentResponse } from "../../types/AssessmentResponse";
import * as types from "../types/assessmentList";

export const getAssessmentList = (
  payload: AssessmentListRequest
): types.GetAssessmentList => ({
  type: types.GET_ASSESSMENT_LIST,
  payload,
});

export const getAssessmentListSuccess = (
  assessmentResponse: ListResponse<AssessmentResponse>
): types.GetAssessmentListSuccess => ({
  type: types.GET_ASSESSMENT_LIST_SUCCESS,
  assessments: assessmentResponse.data,
  assessmentCount: assessmentResponse.count ? assessmentResponse.count : 0,
});

export const getAssessmentListError = (): types.GetAssessmentListError => ({
  type: types.GET_ASSESSMENT_LIST_ERROR,
});
