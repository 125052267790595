import _ from "lodash";
import { workflowTaskCountDefaultValue } from "../constants/workflow";
import {
  WorkflowTaskCount,
  WorkflowTask,
  WorkflowsById,
  Workflow,
  WorkflowSummaryDetails,
} from "../types/Workflow";

export const getChildWorkflowDetails = (
  workflows: Workflow[]
): {
  tasks: WorkflowTask[];
  countByState: WorkflowTaskCount;
  childWorkflows: WorkflowsById;
} => {
  const childWorkflows: WorkflowsById = {};
  let tasks: WorkflowTask[] = [];
  let countByState: WorkflowTaskCount = workflowTaskCountDefaultValue;
  workflows.forEach((workflow) => {
    tasks = [...tasks, ...workflow.tasks];
    childWorkflows[workflow.id] = workflow;

    countByState = {
      FAILED:
        _.get(workflow, "count.FAILED", 0) + _.get(countByState, "FAILED", 0),
      HOLD: _.get(workflow, "count.HOLD", 0) + _.get(countByState, "HOLD", 0),
      PASS: _.get(workflow, "count.PASS", 0) + _.get(countByState, "PASS", 0),
      PENDING:
        _.get(workflow, "count.PENDING", 0) + _.get(countByState, "PENDING", 0),
      REFER:
        _.get(workflow, "count.REFER", 0) + _.get(countByState, "REFER", 0),
      TOTAL:
        _.get(workflow, "count.TOTAL", 0) + _.get(countByState, "TOTAL", 0),
    };
  });

  return {
    tasks,
    countByState: countByState,
    childWorkflows,
  };
};

export const getWorkflowByCode = (
  workflows: Workflow[]
): WorkflowSummaryDetails => {
  const workflowsObject: WorkflowSummaryDetails = {};

  workflows.forEach((workflow) => {
    if (!workflowsObject[workflow.templateRef.code]) {
      workflowsObject[workflow.templateRef.code] = [];
    }

    workflowsObject[workflow.templateRef.code].push({
      id: workflow.id,
      isClosed: workflow.isClosed,
      isSuccess: workflow.isSuccess,
    });
  });

  return workflowsObject;
};
