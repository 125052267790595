import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import {
  WorkflowSummaryDetails,
  WorkflowTask,
  WorkflowTaskCount,
  WorkflowGroupResponse,
  WorkflowsById,
} from "../../types/Workflow";
import { WorkflowListQueryParams } from "../../types/Workflow.v2";
import * as actionTypes from "../types/workflow";

export const resetWorkflowDetails = (): actionTypes.ResetWorkflowDetails => ({
  type: actionTypes.RESET_WORKFLOW_DETAILS,
});

export const updateWorkflowTask = (
  taskId: number,
  taskResponse: WorkflowTask
): actionTypes.UpdateWorkflowTask => ({
  type: actionTypes.UPDATE_WORKFLOW_TASK,
  taskId,
  taskResponse,
});

export const getWorkflowSummary = (
  queryParams: WorkflowListQueryParams
): actionTypes.GetWorkflowSummary => ({
  type: actionTypes.GET_WORKFLOW_SUMMARY,
  queryParams,
});

export const getWorkflowSummarySuccess = (
  summaryResponse: WorkflowSummaryDetails
): actionTypes.GetWorkflowSummarySuccess => ({
  type: actionTypes.GET_WORKFLOW_SUMMARY_SUCCESS,
  summaryResponse,
  status: LOADING_STATUS.SUCCESS,
});

export const getWorkflowSummaryFailed = (
  error: unknown
): actionTypes.GetWorkflowSummaryFailed => ({
  type: actionTypes.GET_WORKFLOW_SUMMARY_FAILED,
  error,
});

export const updateWorkflowGroup = (
  groupsResponse: WorkflowGroupResponse[]
): actionTypes.UpdateWorkflowGroup => ({
  type: actionTypes.UPDATE_WORKFLOW_GROUP,
  groupsResponse,
});

export const getWorkflowTasks = (
  queryParams: WorkflowListQueryParams
): actionTypes.GetWorkflowTasks => ({
  type: actionTypes.GET_WORKFLOW_TASKS,
  queryParams,
});

export const getWorkflowTasksSuccess = (
  childWorkflows: WorkflowsById,
  tasks: WorkflowTask[],
  countByState?: WorkflowTaskCount
): actionTypes.GetWorkflowTasksSuccess => ({
  type: actionTypes.GET_WORKFLOW_TASKS_SUCCESS,
  childWorkflows,
  tasks,
  countByState,
});

export const getWorkflowTasksFailed = (
  error: unknown
): actionTypes.GetWorkflowTasksFailed => ({
  type: actionTypes.GET_WORKFLOW_TASKS_FAILED,
  error,
});

export const resetWorkflowTasks = (): actionTypes.ResetWorkflowTasks => ({
  type: actionTypes.RESET_WORKFLOW_TASKS,
});
