import {
  EntityIndividualRequest,
  EntityIndividualResponse,
} from "../../types/Entity";
import * as types from "../types/entityIndividual";

export const getEntityIndividual = (
  entityId: number
): types.GetEntityIndividual => ({
  type: types.GET_ENTITY_INDIVIDUAL,
  entityId,
});

export const getEntityIndividualSuccess = (
  individual: EntityIndividualResponse
): types.GetEntityIndividualSuccess => ({
  type: types.GET_ENTITY_INDIVIDUAL_SUCCESS,
  individual,
});

export const getEntityIndividualFailed = (): types.GetEntityIndividualFailed => ({
  type: types.GET_ENTITY_INDIVIDUAL_FAILED,
});

export const updateEntityIndividual = (
  entityId: number,
  request: Partial<EntityIndividualRequest>
): types.UpdateEntityIndividual => ({
  type: types.UPDATE_ENTITY_INDIVIDUAL,
  entityId,
  request,
});

export const updateEntityIndividualSuccess = (): types.UpdateEntityIndividualSuccess => ({
  type: types.UPDATE_ENTITY_INDIVIDUAL_SUCCESS,
});

export const updateEntityIndividualFailed = (): types.UpdateEntityIndividualFailed => ({
  type: types.UPDATE_ENTITY_INDIVIDUAL_FAILED,
});
