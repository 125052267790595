import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { Action } from "redux";
import {
  AssessmentDocument,
  DocumentListRequest,
} from "../../types/AssessmentDocument";
import {
  GetAssessmentExtrasPayload,
  GetAssessmentExtrasResponse,
} from "../../types/AssessmentExtras";

export const UPDATE_APPROVAL_CONDITIONS = "UPDATE_APPROVAL_CONDITIONS";
export interface UpdateApprovalConditions extends Action {
  type: typeof UPDATE_APPROVAL_CONDITIONS;
  conditions: string[];
}

export const SUBMIT_APPROVAL_CONDITIONS = "SUBMIT_APPROVAL_CONDITIONS";
export interface SubmitApprovalConditions extends Action {
  type: typeof SUBMIT_APPROVAL_CONDITIONS;
  assessmentId: string;
  conditions: string[];
}

export const SUBMIT_APPROVAL_CONDITIONS_SUCCESS =
  "SUBMIT_APPROVAL_CONDITIONS_SUCCESS";
export interface SubmitApprovalConditionsSuccess extends Action {
  type: typeof SUBMIT_APPROVAL_CONDITIONS_SUCCESS;
}

export const SUBMIT_APPROVAL_CONDITIONS_FAILED =
  "SUBMIT_APPROVAL_CONDITIONS_FAILED";
export interface SubmitApprovalConditionsFailed extends Action {
  type: typeof SUBMIT_APPROVAL_CONDITIONS_FAILED;
}

type ApprovalConditionActions =
  | UpdateApprovalConditions
  | SubmitApprovalConditions
  | SubmitApprovalConditionsSuccess
  | SubmitApprovalConditionsFailed;

export const SUBMIT_APPLICATION_TO_LMS = "SUBMIT_APPLICATION_TO_LMS";
export interface SubmitApplicationToLms extends Action {
  type: typeof SUBMIT_APPLICATION_TO_LMS;
  assessmentId: string;
}

export const SUBMIT_APPLICATION_TO_LMS_SUCCESS =
  "SUBMIT_APPLICATION_TO_LMS_SUCCESS";
export interface SubmitApplicationToLmsSuccess extends Action {
  type: typeof SUBMIT_APPLICATION_TO_LMS_SUCCESS;
}

export const SUBMIT_APPLICATION_TO_LMS_FAILED =
  "SUBMIT_APPLICATION_TO_LMS_FAILED";
export interface SubmitApplicationToLmsFailed extends Action {
  type: typeof SUBMIT_APPLICATION_TO_LMS_FAILED;
}

export const GET_ASSESSMENT_EXTRAS = "GET_ASSESSMENT_EXTRAS";
export interface GetAssessmentExtras extends Action {
  type: typeof GET_ASSESSMENT_EXTRAS;
  assessmentId: string;
  data: GetAssessmentExtrasPayload;
}

export const GET_ASSESSMENT_EXTRAS_SUCCESS = "GET_ASSESSMENT_EXTRAS_SUCCESS";
export interface GetAssessmentExtrasSuccess extends Action {
  type: typeof GET_ASSESSMENT_EXTRAS_SUCCESS;
  response: GetAssessmentExtrasResponse;
}

export const GET_ASSESSMENT_EXTRAS_FAILED = "GET_ASSESSMENT_EXTRAS_FAILED";
export interface GetAssessmentExtrasFailed extends Action {
  type: typeof GET_ASSESSMENT_EXTRAS_FAILED;
}

export const RESET_ASSESSMENT_APPLICATION = "RESET_ASSESSMENT_APPLICATION";
export interface ResetAssessmentApplication extends Action {
  type: typeof RESET_ASSESSMENT_APPLICATION;
}

type ApplicationActions =
  | SubmitApplicationToLms
  | SubmitApplicationToLmsSuccess
  | SubmitApplicationToLmsFailed;

export const GET_REPORTS_DOCUMENTS = "GET_REPORTS_DOCUMENTS";
export interface GetReportsDocuments extends Action {
  type: typeof GET_REPORTS_DOCUMENTS;
  assessmentId: string;
  payload: DocumentListRequest;
}

export const GET_REPORTS_DOCUMENTS_SUCCESS = "GET_REPORTS_DOCUMENTS_SUCCESS";
export interface GetReportDocumentsSuccess extends Action {
  type: typeof GET_REPORTS_DOCUMENTS_SUCCESS;
  response: ListResponse<AssessmentDocument>;
}

export const GET_REPORTS_DOCUMENTS_FAILED = "GET_REPORTS_DOCUMENTS_FAILED";
export interface GetReportDocumentsFailed extends Action {
  type: typeof GET_REPORTS_DOCUMENTS_FAILED;
}

type DocumentActions =
  | GetReportsDocuments
  | GetReportDocumentsSuccess
  | GetReportDocumentsFailed;

export type AssessmentApplicationActions =
  | ApprovalConditionActions
  | ApplicationActions
  | GetAssessmentExtras
  | GetAssessmentExtrasSuccess
  | GetAssessmentExtrasFailed
  | ResetAssessmentApplication
  | DocumentActions;
