import "./index.scss";
import util from "util";
import React, { useEffect, useState } from "react";
import {
  ApplicationResponse,
  applicationStatuses,
  useApplicationFormDispatch,
  getApplicationDetails,
  getApplicationSelector,
  applicationSteps,
} from "@quest-finance/quest-fe-shared/dist/application";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { parse as qsParse } from "query-string";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import {
  Switch,
  Route,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import Emailer from "../../../../assessment-v2/components/Email";
import { showErrorPopUp } from "../../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../../common/utils/error";
import { ExtendedAntd, LayoutTypes } from "../../../../theme";
import { PORTALS } from "../../../../theme/constants/Layout";
import {
  useWorkflowDispatch,
  workflowActions,
  workflowSelectors,
} from "../../../../workflow";
import {
  getAssessmentDetail,
  resetAssessmentDetail,
} from "../../../actions/creators/assessmentDetail";
import ApplicationOverview from "../../../components/application-overview/ApplicationOverView";
import Navigation from "../../../components/navigation/Navigation";
import Notes from "../../../components/notes/";
import { NAV_ITEMS } from "../../../constants/navigation";
import {
  asmAplQuote,
  asmAplApplicantGuarantors,
  asmAplRefStatements,
  asmAplNotesContract,
  asmAplSettlement,
  asmAplSecurity,
  asmAplDecision,
  creditAssessment,
  asmAplAuditLogs,
  legacyPageMapping,
} from "../../../constants/routes";
import { useAssessmentDetailsDispatch } from "../../../dispatchers/index";
import { assessmentResponseSelector } from "../../../selectors/assessmentDetail";
import { AssessmentDetail } from "../../../types/AssessmentDetail";
import { Slug } from "../../../types/Navigation";
import ApplicantGuarantors from "./applicant-guarantors/ApplicantGuarantors";
import AuditLogs from "./AudtiLogs";
import Decision from "./Decision";
import NotesContracts from "./NotesContracts";
import QuotePage from "./Quote";
import RefStatements from "./RefStatements";
import Security from "./Security";
import Settlement from "./Settlement";

const { workflowSelector } = workflowSelectors;

const defaultBreadcrumb: LayoutTypes.BreadcrumbItem[] = [
  {
    label: "Home",
    url: "/assessment/assessments/applications",
  },
];

const CreditEngineRoot: React.FunctionComponent = () => {
  const { assessmentId, section } = useParams<{
    section: Slug;
    assessmentId: string;
  }>();
  const { search } = useLocation();
  const history = useHistory();
  const queryParams = qsParse(search) as {
    tab: applicationSteps.APPLICATION_STEPS;
  };
  const dispatchAssessment = useAssessmentDetailsDispatch();
  const workflowDispatch = useWorkflowDispatch();
  const { data } = useSelector(assessmentResponseSelector) as AssessmentDetail;
  const dispatchApplication = useApplicationFormDispatch();
  const application: ApplicationResponse = useSelector(getApplicationSelector);
  const assessment = useSelector(assessmentResponseSelector);
  const [mounted, setMounted] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState<LayoutTypes.Breadcrumbs>(
    defaultBreadcrumb
  );
  const {
    workflowDetails: { status: workflowStatus, error: workflowError },
  } = useSelector(workflowSelector);

  useEffect(() => {
    if (queryParams.tab) {
      const activeTab = legacyPageMapping[queryParams.tab];

      if (!activeTab) {
        history.replace("/assessment/assessments/applications");
      } else {
        history.replace(util.format(activeTab, assessmentId));
      }
    }
  }, [history, assessmentId, queryParams.tab]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (assessment.externalId) {
      dispatchApplication(getApplicationDetails(assessment.externalId));
    }
  }, [dispatchApplication, assessment]);

  useEffect(() => {
    if (workflowStatus === LOADING_STATUS.FAILED) {
      processError(workflowError, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    }
  }, [workflowStatus, workflowError]);

  useEffect(() => {
    return () => {
      dispatchAssessment(resetAssessmentDetail());
      workflowDispatch(workflowActions.resetWorkflowDetails());
    };
  }, [dispatchAssessment, workflowDispatch]);

  useEffect(() => {
    dispatchAssessment(getAssessmentDetail(assessmentId as string));
  }, [dispatchAssessment, assessmentId, section]);

  useEffect(() => {
    if (application.id && section) {
      const { applicant, applicationNumber, publicStatus } = application;
      const companyName = applicant.tradingName
        ? `${applicant.entityName} (t/a ${applicant.tradingName})`
        : applicant.entityName;
      const status =
        applicationStatuses.APPLICATION_PUBLIC_STATUS_LABELS[publicStatus];

      setBreadcrumb([
        ...defaultBreadcrumb,
        {
          label: `[${status}] - ` + applicationNumber + ": " + companyName,
          url: util.format(creditAssessment, assessmentId, Slug.QUOTE),
        },
        {
          label: NAV_ITEMS[section].label,
          url: "",
        },
      ]);
    }
  }, [data, section, assessmentId, application]);

  return (
    <ExtendedAntd.DefaultLayout className="loan-admin" breadCrumb={breadcrumb}>
      {mounted &&
        createPortal(
          <Emailer />,
          document.getElementById(PORTALS.EMAILER) as HTMLElement
        )}
      <Navigation />
      <ApplicationOverview />
      <Switch>
        <Route exact path={util.format(asmAplQuote, ":assessmentId")}>
          <QuotePage />
        </Route>
        <Route
          exact
          path={util.format(asmAplApplicantGuarantors, ":assessmentId")}
        >
          <ApplicantGuarantors />
        </Route>
        <Route exact path={util.format(asmAplRefStatements, ":assessmentId")}>
          <RefStatements />
        </Route>
        <Route exact path={util.format(asmAplSecurity, ":assessmentId")}>
          <Security />
        </Route>
        <Route exact path={util.format(asmAplNotesContract, ":assessmentId")}>
          <NotesContracts />
        </Route>
        <Route exact path={util.format(asmAplDecision, ":assessmentId")}>
          <Decision />
        </Route>
        <Route exact path={util.format(asmAplSettlement, ":assessmentId")}>
          <Settlement />
        </Route>
        <Route exact path={util.format(asmAplAuditLogs, ":assessmentId")}>
          <AuditLogs />
        </Route>
      </Switch>

      {![Slug.AUDIT_LOG, Slug.SETTLEMENT].includes(section) && <Notes />}
    </ExtendedAntd.DefaultLayout>
  );
};

export default CreditEngineRoot;
