import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { Action } from "redux";
import { NoteDocument, ListRequestParams } from "../../types/Notes";
import * as actionTypes from "../types/noteList";

export const RESET_ASSESSMENT_NOTES = "CE_RESET_ASSESSMENT_NOTES";
export interface ResetAssessmentNotes extends Action {
  type: typeof actionTypes.RESET_ASSESSMENT_NOTES;
  lastResetTimestamp: number;
}

export const GET_ASSESSMENT_NOTES = "CE_GET_ASSESSMENT_NOTES";
export interface GetAssessmentNotes extends Action {
  type: typeof actionTypes.GET_ASSESSMENT_NOTES;
  assessmentId: string;
  queryParams: ListRequestParams;
}

export const GET_ASSESSMENT_NOTES_SUCCESS = "CE_GET_ASSESSMENT_NOTES_SUCCESS";
export interface GetAssessmentNotesSuccess extends Action {
  type: typeof GET_ASSESSMENT_NOTES_SUCCESS;
  response: ListResponse<NoteDocument>;
}

export const GET_ASSESSMENT_NOTES_FAILED = "CE_GET_ASSESSMENT_NOTES_FAILED";
export interface GetAssessmentNotesFailed extends Action {
  type: typeof actionTypes.GET_ASSESSMENT_NOTES_FAILED;
  error: unknown;
}

export const GET_ASSESSMENT_NOTES_COMPLETED =
  "CE_GET_ASSESSMENT_NOTES_COMPLETED";
export interface GetAssessmentNotesCompleted extends Action {
  type: typeof actionTypes.GET_ASSESSMENT_NOTES_COMPLETED;
}

export const UPDATE_ASSESSMENT_NOTE = "CE_UPDATE_ASSESSMENT_NOTE";
export interface UpdateAssessmentNote extends Action {
  type: typeof actionTypes.UPDATE_ASSESSMENT_NOTE;
  note: NoteDocument;
}

export type AssessmentNotesListActions =
  | GetAssessmentNotesFailed
  | GetAssessmentNotesSuccess
  | GetAssessmentNotes
  | GetAssessmentNotesCompleted
  | ResetAssessmentNotes
  | UpdateAssessmentNote;
