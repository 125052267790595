import "./QuoteData.scss";
import React, { useMemo } from "react";
import { FormOutlined } from "@ant-design/icons";
import {
  ApplicationResponse,
  getApplicationSelector,
} from "@quest-finance/quest-fe-shared/dist/application";
import { AMOUNT_TYPES } from "@quest-finance/quest-fe-shared/dist/application/constants/amountTypes";
import { ASSET_TYPE_LIST } from "@quest-finance/quest-fe-shared/dist/application/constants/assetTypes";
import { REPAYMENT_TERM_OPTION_LABELS } from "@quest-finance/quest-fe-shared/dist/application/constants/repaymentTermOptions";
import { SUPPLIER_TYPE_LABELS } from "@quest-finance/quest-fe-shared/dist/application/constants/supplierTypes";
import {
  getBrokeragePercentage,
  getFutureValuePercentage,
} from "@quest-finance/quest-fe-shared/dist/application/utils/quote";
import { Col, Row, Collapse, Typography, Divider } from "antd";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { QfCollapse } from "../../../theme";

const { Text } = Typography;
const { Panel } = Collapse;

const QuoteData: React.FunctionComponent = () => {
  const application = useSelector(
    getApplicationSelector
  ) as ApplicationResponse;
  const { quote } = application;
  const category = ASSET_TYPE_LIST.find(
    (category) => category.value === quote.assetTypeCategory
  );
  const assetType = category?.subTypes.find(
    (type) => type.value === quote.assetType
  );

  const balloonPercentage = useMemo(() => {
    return quote.balloonType === AMOUNT_TYPES.PERCENTAGE
      ? quote.balloonAmount
      : getFutureValuePercentage(quote.purchaseAmount, quote.balloonAmount);
  }, [quote]);

  const brokeragePercentage = useMemo(() => {
    return quote.brokerageType === AMOUNT_TYPES.PERCENTAGE
      ? quote.brokerageAmount
      : getBrokeragePercentage(quote.amountFinanced, quote.brokerageAmount);
  }, [quote]);

  const netAmoundFinanced = useMemo(() => {
    return (
      quote.purchaseAmount -
      quote.depositAmount -
      quote.tradeInAmount +
      quote.tradePayoutAmount
    );
  }, [quote]);

  const financedFees = useMemo(() => {
    return quote.includeFees
      ? quote.applicationFeeWithGst + quote.brokerOriginationFeeAmountWithGst
      : 0;
  }, [quote]);

  return (
    <QfCollapse
      className="quote-expand"
      defaultActiveKey="quote-data"
      expandIconPosition="right"
      ghost
    >
      <Panel
        header={<div className="section-header-1">Quote Data</div>}
        key="quote-data"
        className="application-data"
      >
        <div className="quote-wrapper">
          <h4 className="header">
            Quote{" "}
            <Link to={`/application/applications/${application.id}/quotes`}>
              <FormOutlined className="edit-link" />
            </Link>
          </h4>
          <Row gutter={[32, 16]}>
            <Col span={24} md={6}>
              <h4 className="section-header">Asset Details</h4>
              <div className="info">
                <Text strong className="label">
                  Category
                </Text>
                <div>{category?.label}</div>
              </div>
              <div className="info">
                <Text strong className="label">
                  Type
                </Text>
                <div>{assetType?.label}</div>
              </div>
              <div className="info">
                <Text strong className="label">
                  Year
                </Text>
                <div>{quote.assetManufactureYear}</div>
              </div>
              <div className="info">
                <Text strong className="label">
                  Supplier
                </Text>
                <div>{SUPPLIER_TYPE_LABELS[quote.supplierType as string]}</div>
              </div>
              <div className="info">
                <Text strong className="label">
                  Property owner:
                </Text>
                <div>{quote.isPropertyOwner ? "Yes" : "No"}</div>
              </div>
            </Col>
            <Col span={24} md={6}>
              <h4 className="section-header">Fees and Rates</h4>
              <div className="info">
                <Text strong className="label">
                  Term (years)
                </Text>
                <div>{quote.repaymentTermMonth / 12}</div>
              </div>
              <div className="info">
                <Text strong className="label">
                  Brokerage % (ex GST)
                </Text>
                <div>
                  <NumberFormat
                    value={brokeragePercentage}
                    displayType="text"
                    fixedDecimalScale
                    thousandSeparator=","
                    decimalScale={2}
                    suffix="%"
                  />
                </div>
              </div>
              <div className="info">
                <Text strong className="label">
                  Brokerage $ (ex GST)
                </Text>
                <div>
                  <NumberFormat
                    value={quote.brokerageNominal}
                    prefix="$"
                    displayType="text"
                    fixedDecimalScale
                    thousandSeparator=","
                    decimalScale={2}
                  />
                </div>
              </div>
              <div className="info">
                <Text strong className="label">
                  Financier rate
                </Text>
                <div>
                  <NumberFormat
                    value={quote.financierRate}
                    displayType="text"
                    fixedDecimalScale
                    thousandSeparator=","
                    decimalScale={2}
                    suffix={"%"}
                  />
                </div>
              </div>
              <div className="info">
                <Text strong className="label">
                  Base rate
                </Text>
                <div>
                  <NumberFormat
                    value={quote.baseRate}
                    displayType="text"
                    fixedDecimalScale
                    thousandSeparator=","
                    decimalScale={2}
                    suffix={"%"}
                  />
                </div>
              </div>
              <div className="info">
                <Text strong className="label">
                  Financed Fees
                </Text>
                <div>{quote.includeFees ? "Yes" : "No"}</div>
              </div>
              <div className="info">
                <Text strong className="label">
                  Manual rate
                </Text>
                <div>{quote.isFinancierRateManual ? "Yes" : "No"}</div>
              </div>
            </Col>
            <Col span={24} md={6}>
              <h4 className="section-header">Financials</h4>
              <table className="numbers-table">
                <tbody>
                  <tr>
                    <td>Purchase price</td>
                    <td className="value">
                      <NumberFormat
                        value={quote.purchaseAmount}
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Cash deposit</td>
                    <td className="value">
                      (
                      <NumberFormat
                        value={quote.depositAmount}
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                      )
                    </td>
                  </tr>
                  <tr>
                    <td>Trade in</td>
                    <td className="value">
                      (
                      <NumberFormat
                        value={quote.tradeInAmount}
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                      )
                    </td>
                  </tr>
                  <tr>
                    <td>Payout amount</td>
                    <td className="value">
                      <NumberFormat
                        value={quote.tradePayoutAmount}
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Divider className="qf-divider" />
              <table className="numbers-table">
                <tbody>
                  <tr>
                    <td>Net purchase price</td>
                    <td className="value">
                      <NumberFormat
                        value={netAmoundFinanced}
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Financed fees</td>
                    <td className="value">
                      <NumberFormat
                        value={financedFees}
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Divider className="qf-divider" />
              <table className="numbers-table">
                <tbody>
                  <tr>
                    <td>Net amount financed (NAF)</td>
                    <td className="value">
                      <NumberFormat
                        value={quote.amountFinanced}
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Divider className="qf-divider" />
              <table className="numbers-table">
                <tbody>
                  <tr>
                    <td>Origination fee (ex GST)</td>
                    <td className="value">
                      <NumberFormat
                        value={quote.brokerOriginationFeeAmount}
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Estab fee (ex GST)</td>
                    <td className="value">
                      <NumberFormat
                        value={quote.applicationFee}
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col span={24} md={6}>
              <h4 className="section-header">Summary</h4>
              <table className="numbers-table">
                <tbody>
                  <tr>
                    <td>
                      {REPAYMENT_TERM_OPTION_LABELS[quote.repaymentTermOption]}{" "}
                      repayments{" "}
                    </td>
                    <td className="value">
                      <NumberFormat
                        value={quote.installmentAmount}
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>First instalment</td>
                    <td className="value">
                      <NumberFormat
                        value={quote.firstInstallmentAmount}
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Balloon </td>
                    <td className="value">
                      (
                      <NumberFormat
                        value={balloonPercentage}
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                        suffix="%"
                      />
                      ){" "}
                      <NumberFormat
                        value={quote.balloonNominal}
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Total payment to broker(inc GST) </td>
                    <td className="value">
                      <NumberFormat
                        value={quote.totalPaymentToBrokerWithGst}
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Amount funded</td>
                    <td className="value">
                      <NumberFormat
                        value={
                          quote.amountFinanced + quote.brokerageNominalWithGst
                        }
                        prefix="$"
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      </Panel>
    </QfCollapse>
  );
};

export default QuoteData;
