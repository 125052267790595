import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import assessmentDetail from "./assessmentDetail";
import assessmentExtras from "./assessmentExtras";
import documentList from "./documentList";
import notes from "./noteList";

export default function* assessmentSagas(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  yield all([fork(assessmentDetail)]);
  yield all([fork(assessmentExtras)]);
  yield all([fork(documentList)]);
  yield all([fork(notes)]);
}
