import _ from "lodash";
import * as actionTypes from "../actions/types/userAdmin";
import {
  UserAdminState,
  userAdminStateDefaultValue,
} from "../types/UserAdminState";

export const userAdminState: UserAdminState = userAdminStateDefaultValue;

export const reducer = (
  state: UserAdminState = userAdminState,
  action: actionTypes.UserAdminActions
): UserAdminState => {
  switch (action.type) {
    case actionTypes.GET_USER_DETAILS: {
      return {
        ...state,
        getUserLoading: true,
      };
    }
    case actionTypes.GET_USER_DETAILS_SUCCESS: {
      const user = { ...action.user };
      return {
        ...state,
        user,
        userForm: _.pick(user, [
          "firstName",
          "lastName",
          "mobile",
          "email",
          "status",
          "clientId",
          "parentId",
        ]),
        getUserLoading: false,
      };
    }
    case actionTypes.GET_USER_DETAILS_FAILED: {
      return {
        ...state,
        getUserLoading: false,
      };
    }
    case actionTypes.SAVE_USER: {
      return {
        ...state,
        saveUserLoading: true,
      };
    }
    case actionTypes.SAVE_USER_SUCCESS: {
      const user = { ...action.user };
      return {
        ...state,
        user,
        saveUserLoading: false,
        saveUserSuccess: true,
      };
    }
    case actionTypes.SAVE_USER_FAILED: {
      return {
        ...state,
        saveUserLoading: false,
      };
    }
    case actionTypes.RESET_FORM_STATE: {
      return userAdminStateDefaultValue;
    }
    case actionTypes.UPDATE_USER_FORM: {
      return {
        ...state,
        userForm: {
          ...state.userForm,
          ...action.userForm,
        },
      };
    }
    case actionTypes.ASSIGN_ROLES: {
      return {
        ...state,
        assignRolesLoading: true,
      };
    }
    case actionTypes.ASSIGN_ROLES_SUCCESS:
    case actionTypes.ASSIGN_ROLES_FAILED: {
      return {
        ...state,
        assignRolesLoading: false,
      };
    }
    case actionTypes.GET_USER_AUDIT_LOGS: {
      return {
        ...state,
        userAuditLogsLoading: true,
      };
    }
    case actionTypes.GET_USER_AUDIT_LOGS_SUCCESS: {
      const { data, count } = action.auditLogs;
      return {
        ...state,
        userAuditLogs: data,
        userAuditLogsCount: count ? count : 0,
        userAuditLogsLoading: false,
      };
    }
    case actionTypes.GET_USER_AUDIT_LOGS_FAILED: {
      return {
        ...state,
        userAuditLogsLoading: false,
      };
    }
    case actionTypes.GET_LOGIN_HISTORIES: {
      return {
        ...state,
        loginHistoriesLoading: true,
      };
    }
    case actionTypes.GET_LOGIN_HISTORIES_SUCCESS: {
      const { data, count } = action.loginHistories;
      return {
        ...state,
        loginHistories: data,
        loginHistoriesCount: count ? count : 0,
        loginHistoriesLoading: false,
      };
    }
    case actionTypes.GET_LOGIN_HISTORIES_FAILED: {
      return {
        ...state,
        loginHistoriesLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
