import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AssessmentDetailActions } from "../actions/types/assessmentDetail";
import { AssessmentExtrasActions } from "../actions/types/assessmentExtras";
import { AssessmentDocumentsActions } from "../actions/types/documentList";
import { AssessmentNotesListActions } from "../actions/types/noteList";

export const useAssessmentDetailsDispatch = (): Dispatch<
  AssessmentDetailActions
> => useDispatch();

export const useDocumentListDispatch = (): Dispatch<
  AssessmentDocumentsActions
> => useDispatch();

export const useNoteListDispatch = (): Dispatch<AssessmentNotesListActions> =>
  useDispatch();

export const useAssessmentExtrasDispatch = (): Dispatch<
  AssessmentExtrasActions
> => useDispatch();
