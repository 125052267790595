import * as actionTypes from "../actions/types/entityIndividual";
import {
  EntityIndividualState,
  entityIndividualStateDefaultValue,
} from "../types/Entity";

export const entityIndividualState: EntityIndividualState = entityIndividualStateDefaultValue;

export const reducer = (
  state: EntityIndividualState = entityIndividualState,
  action: actionTypes.EntityIndividualActions
): EntityIndividualState => {
  switch (action.type) {
    case actionTypes.GET_ENTITY_INDIVIDUAL: {
      return {
        ...state,
        getIndividualLoading: true,
      };
    }
    case actionTypes.GET_ENTITY_INDIVIDUAL_SUCCESS: {
      return {
        ...state,
        getIndividualLoading: false,
        individual: action.individual,
      };
    }
    case actionTypes.GET_ENTITY_INDIVIDUAL_FAILED: {
      return {
        ...state,
        getIndividualLoading: false,
      };
    }
    case actionTypes.UPDATE_ENTITY_INDIVIDUAL: {
      return {
        ...state,
        saveEntityLoading: true,
      };
    }
    case actionTypes.UPDATE_ENTITY_INDIVIDUAL_SUCCESS:
    case actionTypes.UPDATE_ENTITY_INDIVIDUAL_FAILED: {
      return {
        ...state,
        saveEntityLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
