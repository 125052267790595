import { createSelector } from "@reduxjs/toolkit";
import { DocumentListState } from "../types/AssessmentDocument";
import { AssessmentState } from "../types/AssessmentState";

export const documentListSelector = (
  state: AssessmentState
): DocumentListState => {
  return state.creditEngine.documentList;
};

export const documentsSelector = createSelector(
  documentListSelector,
  (documentList) => documentList.documents
);

export const documentsCountSelector = createSelector(
  documentListSelector,
  (documentList) => documentList.count
);

export const documentsStatusSelector = createSelector(
  documentListSelector,
  (documentList) => {
    return documentList.status;
  }
);
