import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import {
  UserListActions,
  GET_USER_LIST,
  GET_USER_LIST_ERROR,
  GET_USER_LIST_SUCCESS,
  RESET_USER_LIST,
} from "../actions/types/userList";
import { UserListState, userListDefaultValue } from "../types/UserListState";

export const userListState: UserListState = {
  users: [],
  userCount: 0,
  isLoading: false,
  resetPage: false,
};

export const reducer = (
  state: UserListState = userListState,
  action: UserListActions
): UserListState => {
  switch (action.type) {
    case GET_USER_LIST: {
      const { offset, limit } = action.payload;

      return {
        ...state,
        limit: limit ? limit : DATATABLE_MAX_ROWS,
        offset: offset ? offset : state.offset,
        isLoading: true,
      };
    }
    case GET_USER_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        resetPage: false,
        users: action.users,
        userCount: action.userCount,
      };
    }
    case GET_USER_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case RESET_USER_LIST: {
      return userListDefaultValue;
    }

    default: {
      return state;
    }
  }
};
