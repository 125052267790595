import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { AssessmentDocument } from "./AssessmentDocument";
import { GetAssessmentExtrasResponse } from "./AssessmentExtras";

export interface AssessmentApplicationState {
  approvalConditions: string[];
  submitApprovalConditionsLoading: boolean;
  submitToLmsStatus: LOADING_STATUS;
  assessmentExtras?: GetAssessmentExtrasResponse;
  assessmenExtrasLoading: boolean;
  creditReportDocuments: AssessmentDocument[];
  creditReportDocumentsCount: number;
  creditReportDocumentsLoading: boolean;
}

export const assessmentApplicationStateDefaultValue: AssessmentApplicationState = {
  approvalConditions: [],
  submitApprovalConditionsLoading: false,
  submitToLmsStatus: LOADING_STATUS.IDLE,
  assessmenExtrasLoading: false,
  creditReportDocuments: [],
  creditReportDocumentsCount: 0,
  creditReportDocumentsLoading: false,
};
