import { Action } from "redux";
import { UserListRequest } from "../../types/UserListRequest";
import { UserResponse } from "../../types/UserResponse";

export const GET_USER_LIST = "GET_USER_LIST";
export interface GetUserList extends Action {
  type: typeof GET_USER_LIST;
  payload: UserListRequest;
}

export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export interface GetUserListSuccess extends Action {
  type: typeof GET_USER_LIST_SUCCESS;
  users: UserResponse[];
  userCount: number;
}

export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR";
export interface GetUserListError extends Action {
  type: typeof GET_USER_LIST_ERROR;
}

export const RESET_USER_LIST = "RESET_USER_LIST";
export interface ResetUserList extends Action {
  type: typeof RESET_USER_LIST;
}

export type UserListActions =
  | GetUserList
  | GetUserListSuccess
  | GetUserListError
  | ResetUserList;
