import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { Action } from "redux";
import { InternalNotesListRequest } from "../../../common/internal-notes/types/InternalNotesListRequest";
import { InternalNotesResponse } from "../../../common/internal-notes/types/InternalNotesResponse";

export const GET_ENTITY_NOTE_LIST = "GET_ENTITY_NOTE_LIST";
export interface GetEntityNotesList extends Action {
  type: typeof GET_ENTITY_NOTE_LIST;
  entityId: number;
  payload: InternalNotesListRequest;
}

export const GET_ENTITY_NOTE_LIST_SUCCESS = "GET_ENTITY_NOTE_LIST_SUCCESS";
export interface GetEntityNotesListSuccess extends Action {
  type: typeof GET_ENTITY_NOTE_LIST_SUCCESS;
  listResponse: ListResponse<InternalNotesResponse>;
}

export const GET_ENTITY_NOTE_LIST_ERROR = "GET_ENTITY_NOTE_LIST_ERROR";
export interface GetEntityNotesListError extends Action {
  type: typeof GET_ENTITY_NOTE_LIST_ERROR;
}

export const SUBMIT_ENTITY_NOTES = "SUBMIT_ENTITY_NOTES";
export interface SubmitEntityNotes extends Action {
  type: typeof SUBMIT_ENTITY_NOTES;
  entityId: number;
  note: string;
}

export const SUBMIT_ENTITY_NOTES_SUCCESS = "SUBMIT_ENTITY_NOTES_SUCCESS";
export interface SubmitEntityNotesSuccess extends Action {
  type: typeof SUBMIT_ENTITY_NOTES_SUCCESS;
}

export const SUBMIT_ENTITY_NOTES_FAILED = "SUBMIT_ENTITY_NOTES_FAILED";
export interface SubmitEntityNotesFailed extends Action {
  type: typeof SUBMIT_ENTITY_NOTES_FAILED;
}

export const RESET_ENTITY_INTERNAL_NOTES = "RESET_ENTITY_INTERNAL_NOTES";
export interface ResetEntityInternalNotes extends Action {
  type: typeof RESET_ENTITY_INTERNAL_NOTES;
}

export type InternalNotesActions =
  | GetEntityNotesList
  | GetEntityNotesListSuccess
  | GetEntityNotesListError
  | SubmitEntityNotes
  | SubmitEntityNotesSuccess
  | SubmitEntityNotesFailed
  | ResetEntityInternalNotes;
