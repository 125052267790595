import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";

export enum WORKFLOW_SECTIONS {
  QUOTE = "QUOTE",
  APPLICANT = "APPLICANT",
  GUARANTOR = "GUARANTOR",
  REF_STATEMENTS = "REF_STATEMENTS",
  SECURITY = "SECURITY",
  NOTES_CONTRACTS = "NOTES_CONTRACTS",
}

export const WORKFLOW_SECTIONS_LABEL: Dictionary = {
  [WORKFLOW_SECTIONS.QUOTE]: "Quote",
  [WORKFLOW_SECTIONS.APPLICANT]: "Applicant",
  [WORKFLOW_SECTIONS.GUARANTOR]: "Guarantors",
  [WORKFLOW_SECTIONS.REF_STATEMENTS]: "Ref & Statements",
  [WORKFLOW_SECTIONS.SECURITY]: "Security",
  [WORKFLOW_SECTIONS.NOTES_CONTRACTS]: "Notes & Contracts",
};
