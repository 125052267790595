import React, { useEffect, useMemo, useState } from "react";
import {
  ApplicationResponse,
  getApplicationSelector,
} from "@quest-finance/quest-fe-shared/dist/application";
import { Row, Col, Button } from "antd";
import dayjs from "dayjs";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { EmailContent } from "../";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../../common/utils/error";
import { getAssessmentExtras } from "../../../actions/creators/assessmentExtras";
import { QF_APPROVAL_ADVICE } from "../../../constants/assessmentExtras";
import { NOTIFICATION_EMAIL_TEMPLATES } from "../../../constants/notification-email-templates";
import { useAssessmentExtrasDispatch } from "../../../dispatchers/index";
import { assessmentResponseSelector } from "../../../selectors/assessmentDetail";
import { assessmentExtrasSelector } from "../../../selectors/assessmentExtras";
import EmailService from "../../../services/EmailService";

import "./Approval.scss";

interface ApprovalEmailProps extends EmailContent {
  approval?: boolean;
}

const ApprovalEmail: React.FC<ApprovalEmailProps> = ({
  approval = true,
  onAfterSubmit,
}: ApprovalEmailProps) => {
  const { assessmentId } = useParams<{
    assessmentId: string;
  }>();
  const dispatchAssessmentExtra = useAssessmentExtrasDispatch();
  const assessment = useSelector(assessmentResponseSelector);
  const assessmentExtras = useSelector(assessmentExtrasSelector);
  const approvalExpiry = lodash.get(
    assessmentExtras?.assessmentExtras?.[QF_APPROVAL_ADVICE],
    `expiryDate`,
    null
  );
  const approvalStatus = approval ? "Approval" : "Re-approval";
  const emailTemplate = approval
    ? NOTIFICATION_EMAIL_TEMPLATES.APPROVAL
    : NOTIFICATION_EMAIL_TEMPLATES.REAPPROVAL;
  const [expiryDate, setExpiryDate] = useState<dayjs.Dayjs>(
    dayjs().add(90, "day")
  );
  const [isLoading, setIsLoading] = useState(false);
  const { user }: ApplicationResponse = useSelector(getApplicationSelector);
  const recipient = user?.email;
  const daysToExpiry = useMemo(() => {
    return expiryDate
      ? expiryDate.diff(dayjs(dayjs().format("YYYY-MM-DD")), "days")
      : 0;
  }, [expiryDate]);
  const isExpired = useMemo(() => {
    return dayjs().isAfter(expiryDate, "day");
  }, [expiryDate]);
  const [isExtended, setIsExtended] = useState(false);

  useEffect(() => {
    let date = dayjs().add(90, "days");
    if (approvalExpiry) {
      date = dayjs(approvalExpiry);
    }
    setExpiryDate(date);
  }, [approvalExpiry, setExpiryDate]);

  const sendHandler = async () => {
    setIsLoading(true);
    try {
      await EmailService.sendApprovalEmail(assessmentId, {
        template: emailTemplate,
        isExtended,
      });
      dispatchAssessmentExtra(
        getAssessmentExtras(assessmentId, {
          [QF_APPROVAL_ADVICE]: ["expiryDate"],
        })
      );
      showSuccessPopUp(
        <>
          You successfully sent {approvalStatus} notification for{" "}
          <b>{assessment?.data?.applicationNumber as string}</b> to{" "}
          <b>{recipient}</b>
        </>
      );
      if (onAfterSubmit) onAfterSubmit();
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetExtended = (extend: boolean) => {
    setIsExtended(extend);
    const newDate = dayjs(dayjs().format("YYYY-MM-DD")).add(90, "day");
    const expiry = extend
      ? newDate
      : approvalExpiry
      ? dayjs(approvalExpiry)
      : newDate;

    setExpiryDate(expiry);
  };

  const extendButton = () => {
    let text = "+ Extend date";
    if (isExtended) {
      text = "- Cancel extension";
    }

    return (
      <Button
        type="link"
        onClick={() => {
          handleSetExtended(!isExtended);
        }}
        className="extend-toggler"
      >
        {text}
      </Button>
    );
  };

  let daysToExpiryText = null;

  if (daysToExpiry !== undefined) {
    daysToExpiryText = (
      <span>
        (<i>{daysToExpiry} days to expiry</i>)
      </span>
    );

    if (isExpired) {
      daysToExpiryText = (
        <span className="expired">
          (<i>{`Expired ${Math.abs(daysToExpiry)} day(s) ago`}</i>)
        </span>
      );
    }
  }

  return (
    <div className="approval-email">
      <Row gutter={[0, 16]}>
        <Col span={8} className="label">
          Name{" "}
        </Col>
        <Col span={16} className="value">
          {`${user?.firstName} ${user?.lastName}`}
        </Col>
        <Col span={8} className="label">
          Email address{" "}
        </Col>
        <Col span={16} className="value">
          {recipient}
        </Col>
        <Col span={8} className="label">
          Approval expiry
        </Col>
        <Col span={16} className="value">
          {expiryDate?.format("DD/MM/YYYY")}
          {!approval && extendButton()}
          <br /> {daysToExpiryText}
          <br />
          {isExtended && (
            <span className="rerun-credit-file-warning">
              You must rerun the credit file before extending the expiry date
            </span>
          )}
        </Col>
        <Col span={8} className="label">
          Approval conditions{" "}
        </Col>
        <Col span={16} className="value">
          {assessment?.approvalConditions.length === 0 ? (
            "No approval conditions were set"
          ) : (
            <ul>
              {assessment?.approvalConditions.map((condition) => (
                <li key={condition.id}>{condition.condition}</li>
              ))}
            </ul>
          )}
        </Col>
      </Row>
      <Button
        className="qf-btn-green send-btn"
        disabled={!expiryDate || isExpired}
        onClick={sendHandler}
        loading={isLoading}
      >
        Send email
      </Button>
    </div>
  );
};

export const ReApprovalEmail: React.FC<ApprovalEmailProps> = (
  props: ApprovalEmailProps
) => {
  return <ApprovalEmail {...props} approval={false} />;
};

export default ApprovalEmail;
