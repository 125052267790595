import { HTTP_STATUS_CODES } from "@quest-finance/quest-fe-shared/dist/common/constants/httpStatusCodes";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import axios from "axios";

export const processError = (
  error: unknown,
  cb?: (errorMessage: string) => void
): void => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
      return;
    } else {
      const errorMessage = processErrorMessage(error);
      if (cb) cb(errorMessage);
    }
  }
};
