import React from "react";
import "./Footer.scss";

type TwoColFooterProps = {
  children?: React.ReactNode;
};

const TwoColFooter: React.FunctionComponent<TwoColFooterProps> = ({
  children,
}: TwoColFooterProps) => {
  return <div className="layout-footer"></div>;
};

export default TwoColFooter;
