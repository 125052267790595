import "./App.scss";

import React from "react";
import { useApiAuthInterceptor } from "@quest-finance/quest-fe-shared/dist/auth";
import { useIdleAppHandler } from "@quest-finance/quest-fe-shared/dist/common/hooks/idleAppHandler";
import { axiosInstance } from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import initTagManager from "@quest-finance/quest-fe-shared/dist/common/utils/gtm";
import { SentryService } from "@quest-finance/quest-fe-shared/dist/error-handler";
import { initAxiosInterceptor } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { Store } from "redux";
import createSagaMiddleware from "redux-saga";
import BaseRoute from "./components/BaseRoute";
import { errorBlackList, errorWhiteList } from "./errorListConfig";
import { rootReducer } from "./rootReducer";
import rootSaga from "./rootSaga";
import * as routes from "./routes";

SentryService.initMonitoring();
initTagManager(process.env.REACT_APP_GTM_ID ?? "");
initAxiosInterceptor(axiosInstance, {
  errorBlackList,
  errorWhiteList,
});

export const createStore = (): Store => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer,
    middleware: [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
      }),
      sagaMiddleware,
    ],
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

const store = createStore();
const App: React.FunctionComponent = () => {
  useApiAuthInterceptor();
  useIdleAppHandler(
    {
      hours: 8,
    },
    () => {
      window.location.reload();
    }
  );

  return (
    <Provider store={store}>
      <BaseRoute routes={routes} />
    </Provider>
  );
};

export default App;
