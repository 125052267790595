import React from "react";
import { FeatureService } from "../../../feature/";
import { WorkflowTemplate } from "../../../workflow";
import {
  WORKFLOW_SLUGS,
  WORKFLOW_STEPS_MAP,
} from "../../../workflow/constants/WorkflowSummary";
import { Slug } from "../../types/Navigation";

type WorkflowSectionProps = {
  code: string;
  activeStep: Slug;
};
const WorkflowSection: React.FunctionComponent<WorkflowSectionProps> = ({
  code,
  activeStep,
}: WorkflowSectionProps) => {
  const step = WORKFLOW_SLUGS[activeStep] as string;
  const workflowCodes = WORKFLOW_STEPS_MAP[step];

  return FeatureService.checkFeature(
    "workflow",
    <div className="workflow-section mt-3">
      <WorkflowTemplate
        code={code}
        workflowCodes={workflowCodes}
        showGroupHeaders={[Slug.APPLICANT_GUARANTORS, Slug.DECISION].includes(
          activeStep
        )}
      />
    </div>
  );
};

export default WorkflowSection;
