import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import {
  EntityIndividualRequest,
  EntityIndividualResponse,
} from "../types/Entity";

class IndividualService {
  public static async get(
    entityId: number
  ): Promise<SingleResponse<EntityIndividualResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<EntityIndividualResponse>,
      unknown
    >(
      {
        method: "GET",
        path: `/entity/individual/${entityId}`,
      },
      true
    );
    return result;
  }

  public static async update(
    entityId: number,
    data: Partial<EntityIndividualRequest>
  ): Promise<SingleResponse<EntityIndividualResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<EntityIndividualResponse>,
      Partial<EntityIndividualRequest>
    >(
      {
        method: "PUT",
        path: `/entity/individual/${entityId}`,
        data,
      },
      true
    );
    return result;
  }
}

export default IndividualService;
