import * as layoutTypes from "../actions/types/layout";
import { LAYOUT_DEFAULT_STATE } from "../constants/Layout";
import { Layout } from "../types/Layout";

export const layoutState = LAYOUT_DEFAULT_STATE;

export const reducer = (
  state: Layout = layoutState,
  action: layoutTypes.LayoutActions
): Layout => {
  switch (action.type) {
    case layoutTypes.SET_SIDEBAR_COLLAPSE: {
      const { isSidebarCollapsed } = action;
      return {
        ...state,
        isSidebarCollapsed,
      };
    }
    default: {
      return state;
    }
  }
};
