import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { ClientListRequest } from "../../types/ClientListRequest";
import { ClientResponse } from "../../types/ClientResponse";
import * as types from "../types/clientList";

export const getClientList = (
  payload?: ClientListRequest
): types.GetClientList => ({
  type: types.GET_CLIENT_LIST,
  payload,
});

export const getClientListSuccess = (
  clientResponse: ListResponse<ClientResponse>
): types.GetClientlistSuccess => ({
  type: types.GET_CLIENT_LIST_SUCCESS,
  clients: clientResponse.data,
  clientCount: clientResponse.count ? clientResponse.count : 0,
});

export const getClientListError = (): types.GetClientListError => ({
  type: types.GET_CLIENT_LIST_ERROR,
});
