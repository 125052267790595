import { applicationStatuses } from "@quest-finance/quest-fe-shared/dist/application";
import { createSelector } from "@reduxjs/toolkit";
import { AssessmentDetailState } from "../types/AssessmentDetail";
import { AssessmentState } from "../types/AssessmentState";

export const assessmentDetailSelector = (
  state: AssessmentState
): AssessmentDetailState => {
  return state.creditEngine.assessmentDetail;
};

export const assessmentResponseStatusSelector = createSelector(
  assessmentDetailSelector,
  (assessmentDetail) => assessmentDetail.status
);

export const assessmentResponseSelector = createSelector(
  assessmentDetailSelector,
  (assessmentDetail) => assessmentDetail.assessment
);

export const assessmentDataSelector = createSelector(
  assessmentDetailSelector,
  (assessmentDetail) => {
    return assessmentDetail.assessment.data;
  }
);

export const assessmentStatusSelector = createSelector(
  assessmentDetailSelector,
  (assessmentAdmin) => assessmentAdmin.assessment.status
);

export const assessmentIsLockedSelector = createSelector(
  assessmentDetailSelector,
  (assessmentAdmin) =>
    (applicationStatuses.APPLICATION_STATUS_GROUP.LOCKED as string[]).includes(
      assessmentAdmin.assessment.status
    )
);
