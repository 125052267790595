import "./WorkflowItem.scss";
import React, { useCallback, useState } from "react";
import { Tag } from "antd";
import cs from "classnames";
import WordLimitter, {
  CONTENT_TYPE,
} from "../../../common/components/WordLimitter/WordLimitter";
import {
  WORKFLOW_STATE,
  WORKFLOW_STATE_LABELS,
} from "../../constants/workflow";
import { WorkflowTask } from "../../types/Workflow";

type WorkflowItemProps = {
  code: string;
  filter?: WORKFLOW_STATE[];
  task: WorkflowTask;
  onClick?: (task: WorkflowTask) => void;
  active: boolean;
};

const WorkflowItem: React.FunctionComponent<WorkflowItemProps> = ({
  task,
  onClick,
  active,
}: WorkflowItemProps) => {
  const { templateRef, outcomeDescription } = task;
  const [expandNote, setExpandNote] = useState(false);

  const onClickItem = useCallback(
    (e) => {
      if (e.target.nodeName === "A") {
        setExpandNote(!expandNote);
      } else if (onClick) onClick(task);
    },
    [onClick, task, expandNote]
  );

  return (
    <tr
      className={cs("workflow-item", task.state.toLowerCase(), {
        active,
        failed: task.state === WORKFLOW_STATE.FAILED,
      })}
      onClick={onClickItem}
    >
      <td>
        <Tag className={cs("task-state", task.state.toLowerCase())}>
          {WORKFLOW_STATE_LABELS[task.state]}
        </Tag>
      </td>
      <td>{templateRef.text}</td>
      <td>{outcomeDescription}</td>
      <td>
        <WordLimitter
          text={task.lastNote}
          maxLines={1}
          type={CONTENT_TYPE.HTML}
          expanded={expandNote}
          setExpanded={() => setExpandNote(!expandNote)}
          className="ql-editor p-0"
        />
      </td>
    </tr>
  );
};

export default WorkflowItem;
