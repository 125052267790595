import "./Navigation.scss";
import util from "util";
import React, { useEffect, useMemo } from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import classNames from "classnames";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  useWorkflowDispatch,
  workflowSelectors,
  workflowActions,
} from "../../../workflow";
import { WorkflowSummaryItem } from "../../../workflow/types/Workflow";
import { NAV_ITEMS } from "../../constants/navigation";
import { creditAssessment } from "../../constants/routes";
import { Slug } from "../../types/Navigation";

const { getWorkflowSummary } = workflowActions;

const Navigation: React.FunctionComponent = () => {
  const { assessmentId } = useParams<{
    assessmentId: string;
  }>();
  const workflowDispatch = useWorkflowDispatch();

  useEffect(() => {
    if (assessmentId) {
      workflowDispatch(
        getWorkflowSummary({
          targetId: assessmentId,
        })
      );
    }
  }, [workflowDispatch, assessmentId]);

  const urlParams = useParams<{ section: string; assessmentId: string }>();
  const workflowSummary = useSelector(
    workflowSelectors.workflowSummaryDetailsSelector
  );

  const allSuccess = (workflow: WorkflowSummaryItem) => workflow.isSuccess;

  const applicantGuarantorsState = useMemo(() => {
    if (!lodash.isEmpty(workflowSummary)) {
      return (
        workflowSummary?.APPLICANT_WORKFLOW?.every(allSuccess) &&
        workflowSummary?.GUARANTOR_WORKFLOW?.every(allSuccess) &&
        workflowSummary?.APPLICANT_EQUIFAX_WORKFLOW?.every(allSuccess) &&
        workflowSummary?.GUARANTOR_SME_EQUIFAX_WORKFLOW?.every(allSuccess) &&
        workflowSummary?.GUARANTOR_INDIVIDUAL_EQUIFAX_WORKFLOW?.every(
          allSuccess
        ) &&
        workflowSummary?.GUARANTOR_ILLION_WORKFLOW?.every(allSuccess) &&
        workflowSummary?.GUARANTOR_FRANKIEONE_WORKFLOW?.every(allSuccess) &&
        workflowSummary?.GUARANTOR_COMMERCIAL_APPLY_WORKFLOW?.every(allSuccess)
      );
    }

    return false;
  }, [workflowSummary]);

  const navWorkflowGroups: Record<string, boolean> = {
    [Slug.QUOTE]: !!workflowSummary?.QUOTE_WORKFLOW?.every(allSuccess),
    [Slug.APPLICANT_GUARANTORS]: !!applicantGuarantorsState,
    [Slug.REF_STATEMENTS]: !!workflowSummary?.STATEMENTS_WORKFLOW?.every(
      allSuccess
    ),
    [Slug.SECURITY]: !!workflowSummary?.SECURITY_WORKFLOW?.every(allSuccess),
    [Slug.SETTLEMENT]: !!workflowSummary?.SETTLEMENT_WORKFLOW?.every(
      allSuccess
    ),
  };

  const navItems = Object.keys(NAV_ITEMS).map((slug) => {
    const { label, Icon } = NAV_ITEMS[slug as Slug];
    const active = slug === urlParams.section ? "active" : "";
    const complete = navWorkflowGroups[slug];
    const url = util.format(creditAssessment, urlParams.assessmentId, slug);

    return (
      <Link key={slug} to={url} className={classNames({ active, complete })}>
        {complete ? <CheckCircleFilled /> : <Icon />}
        {label}
      </Link>
    );
  });

  return (
    <div className="ce-navigation">
      <div className="inner">{navItems}</div>
    </div>
  );
};

export default Navigation;
