import "./WorkflowTaskOutcome.scss";
import React from "react";
import { WorklfowTaskTemplateRef } from "../../types/Workflow";
import { INPUT_TYPE_MAPPING } from "./input-types/InputyTypes";

type WorkflowTaskOutcomeProps = {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  taskTemplateRef: WorklfowTaskTemplateRef;
};

const WorkflowTaskOutcome: React.FunctionComponent<WorkflowTaskOutcomeProps> = ({
  value,
  onChange,
  disabled,
  taskTemplateRef,
}: WorkflowTaskOutcomeProps) => {
  const InputElement = INPUT_TYPE_MAPPING[taskTemplateRef.inputType]
    ? INPUT_TYPE_MAPPING[taskTemplateRef.inputType]
    : null;

  return (
    <div className="qf-outcome">
      {InputElement && (
        <InputElement
          value={value}
          onChange={onChange}
          disabled={disabled}
          outcomes={taskTemplateRef.outcomes}
        />
      )}
    </div>
  );
};

export default WorkflowTaskOutcome;
