import React, { ReactNode, useEffect, useState } from "react";
import { getUserDataSelector } from "@quest-finance/quest-fe-shared/dist/auth";
import { UserData } from "@quest-finance/quest-fe-shared/dist/auth/types/UserData";
import { isAllowedAccess } from "@quest-finance/quest-fe-shared/dist/auth/utils";
import { ERROR_CODES } from "@quest-finance/quest-fe-shared/dist/error-handler";
import { message } from "antd";
import _get from "lodash/get";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router";
import { permissions } from "../../../../iam";
import { ExtendedAntd, LayoutTypes } from "../../../../theme";
import AbaDetails from "../../../components/AbaDetails/AbaDetails";
import AbaFileLoader from "../../../components/AbaFileLoader/AbaFileLoader";
import AbaHistoryList from "../../../components/AbaHistoryList/AbaHistoryList";
import { ERROR_CODES as PAYMENT_ERROR_CODES } from "../../../constants/errors";
import * as notificationMessage from "../../../constants/notificationMessage";
import AbaService from "../../../services/AbaService";
import { AbaData, FileData } from "../../../types/Aba";
import "./index.scss";

export const pageTitle = "Process ABA File";
const breadcrumb: LayoutTypes.BreadcrumbItem[] = [
  {
    label: "Home",
    url: "/assessment/assessments/applications",
  },
  {
    label: pageTitle,
  },
];

const AbaProcess: React.FC<RouteComponentProps> = () => {
  const loggedInUser = useSelector(getUserDataSelector) as UserData;
  const history = useHistory();
  const [processing, setProcessing] = useState(false);
  const [fileData, setFileData] = useState<
    FileData & {
      data?: AbaData;
      loading?: boolean;
    }
  >();

  useEffect(() => {
    if (
      loggedInUser &&
      !isAllowedAccess(loggedInUser as UserData, [
        permissions.PERMISSION_IAM["PAYMENT.READ"],
      ])
    ) {
      history.push("/");
    }
  }, [history, loggedInUser]);

  const parseAbaFile = async (file: FileData, submit = false) => {
    let result;

    try {
      result = await AbaService.processFile({
        file,
        submit,
      });
    } catch (e) {
      let notifMsg: ReactNode = notificationMessage.GENERIC_ERROR;
      const errorCode = _get(e, "response.data.errorCode");
      const errorData = _get(e, "response.data.data");

      if (errorCode === PAYMENT_ERROR_CODES.PAYMENT_INVALID_ABA_FILE) {
        notifMsg = (
          <>
            {notificationMessage.PAYMENT_INVALID_ABA_FILE}
            <div className="mt-3">
              {(errorData as string[])?.map((msg, i) => (
                <div key={i}>{msg}</div>
              ))}
            </div>
          </>
        );
      } else if (errorCode === PAYMENT_ERROR_CODES.PAYMENT_DUPLICATE_ABA_FILE) {
        notifMsg = notificationMessage.PAYMENT_DUPLICATE_ABA_FILE;
      } else if (errorCode === ERROR_CODES.FORBIDDEN) {
        notifMsg = notificationMessage.FORBIDDEN;
      }

      message.open({
        type: "error",
        content: <span data-testid="aba-notification">{notifMsg}</span>,
      });
    }

    return result?.data;
  };

  const submitAba = async () => {
    if (fileData) {
      setProcessing(true);

      message.destroy();
      const data = await parseAbaFile(fileData, true);

      if (data) {
        if (data.failedRefIds?.length) {
          message.open({
            type: "info",
            content: (
              <span data-testid="aba-notification">
                {notificationMessage.PARTIAL_PROCESS}
              </span>
            ),
          });
        } else {
          message.open({
            type: "success",
            content: (
              <span data-testid="aba-notification">
                {notificationMessage.PROCESS_SUCCESSFUL}
              </span>
            ),
          });
        }
        setFileData(undefined);
        history.push("?");
      }

      setProcessing(false);
    }
  };

  const cancelAba = () => {
    setFileData(undefined);
  };

  const onUploadHandler = async (file: FileData) => {
    setFileData({
      name: "",
      contents: "",
      loading: true,
    });

    message.destroy();
    const data = await parseAbaFile(file);
    if (data) {
      message.open({
        type: "info",
        content: `Showing file contents for ${file.name}`,
      });
    }

    setFileData({
      ...file,
      data,
      loading: false,
    });
  };

  const onUploadError = () => setFileData(undefined);

  return (
    <ExtendedAntd.DefaultLayout
      className="payment-aba-process"
      breadCrumb={breadcrumb}
    >
      <div
        className="content-section process-section"
        data-testid="payment-aba-process-page"
      >
        <div className="header-1 mb-3">{pageTitle}</div>
        <AbaFileLoader
          onUpload={onUploadHandler}
          onError={onUploadError}
          loading={Boolean(fileData?.loading)}
        />
        {fileData?.data ? (
          <AbaDetails
            fileName={fileData.name}
            abaData={fileData.data}
            onSubmit={submitAba}
            onCancel={cancelAba}
            loading={processing}
          />
        ) : null}
      </div>
      <AbaHistoryList />
    </ExtendedAntd.DefaultLayout>
  );
};

export default AbaProcess;
