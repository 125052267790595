import { InternalNotesResponse } from "../../common/internal-notes/types/InternalNotesResponse";

export interface InternalNotesState {
  internalNotes: InternalNotesResponse[];
  internalNotesCount: number;
  internalNotesLoading: boolean;
  submitInternalNotesLoading: boolean;
  submitInternalNotesSuccess: boolean;
}

export const internalNotesStateDefaultValue: InternalNotesState = {
  internalNotes: [],
  internalNotesCount: 0,
  internalNotesLoading: false,
  submitInternalNotesLoading: false,
  submitInternalNotesSuccess: false,
};
