import React, { useCallback, useEffect } from "react";
import { applicationStatuses } from "@quest-finance/quest-fe-shared/dist/application";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import cs from "classnames";
import dayjs from "dayjs";
import lodash from "lodash";
import "./ApprovalExpiry.scss";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { showErrorPopUp } from "../../../../common/components/ShowNotification/showNotification";
import { getAssessmentExtras } from "../../../actions/creators/assessmentExtras";
import {
  QF_APPROVAL_ADVICE,
  REQUEST_KEY,
} from "../../../constants/assessmentExtras";
import { useAssessmentExtrasDispatch } from "../../../dispatchers/index";
import {
  assessmentStatusSelector,
  assessmentResponseStatusSelector,
} from "../../../selectors/assessmentDetail";
import { assessmentExtrasSelector } from "../../../selectors/assessmentExtras";

const { APPLICATION_STATUSES } = applicationStatuses;
const statusList: string[] = [
  APPLICATION_STATUSES.SETTLED,
  APPLICATION_STATUSES.WITHDRAWN_BY_INTRODUCER,
  APPLICATION_STATUSES.WITHDRAWN_BY_LENDER,
  APPLICATION_STATUSES.DECLINED,
];

const ApprovalExpiry: React.FC = () => {
  const { assessmentId } = useParams<{
    assessmentId: string;
  }>();
  const assessmentStatus = useSelector(assessmentStatusSelector);
  const assessmentExtras = useSelector(assessmentExtrasSelector);
  const approvalExpiry = lodash.get(
    assessmentExtras?.assessmentExtras?.[QF_APPROVAL_ADVICE],
    `expiryDate`,
    null
  );
  const showExpiryDate = !statusList.includes(assessmentStatus);
  const assessmentLoading = useSelector(assessmentResponseStatusSelector);
  const dispatchAssessmentExtra = useAssessmentExtrasDispatch();
  const error = lodash.get(
    assessmentExtras?.requestStatus,
    `${REQUEST_KEY.APPROVAL_EXPIRY}.error`,
    null
  );
  let info = null;

  const getApprovalExpiry = useCallback(async () => {
    dispatchAssessmentExtra(
      getAssessmentExtras(
        assessmentId,
        {
          [QF_APPROVAL_ADVICE]: ["expiryDate"],
        },
        REQUEST_KEY.APPROVAL_EXPIRY
      )
    );
  }, [dispatchAssessmentExtra, assessmentId]);

  useEffect(() => {
    if (error) {
      showErrorPopUp(
        "Failed to retrieve approval expiry date.",
        REQUEST_KEY.APPROVAL_EXPIRY
      );
    }
  }, [error]);

  useEffect(() => {
    if (showExpiryDate && assessmentLoading === LOADING_STATUS.SUCCESS)
      getApprovalExpiry();
  }, [showExpiryDate, assessmentLoading, getApprovalExpiry]);

  if (approvalExpiry && showExpiryDate) {
    const expiry = dayjs(approvalExpiry);
    const isExpired = dayjs().isAfter(expiry, "day");
    const label = isExpired ? (
      <>
        <span className="label">Approval expired</span> on
      </>
    ) : (
      <>
        <span className="label">Approval valid</span> to
      </>
    );
    info = (
      <div
        className={cs("approval-expiry", {
          expired: isExpired,
        })}
      >
        {label} {expiry.format("DD/MM/YYYY")}
      </div>
    );
  }

  return info;
};

export default ApprovalExpiry;
