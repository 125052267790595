import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { CreditRefData, CreditRefAttributes } from "../types/CreditReference";

class CreditReferenceService {
  public static async getList(
    assessmentId: string
  ): Promise<ListResponse<CreditRefAttributes>> {
    const result = await APIService.jsonRequest<
      ListResponse<CreditRefAttributes>,
      CreditRefAttributes
    >(
      {
        method: "GET",
        path: `/assessment/assessments/${assessmentId}/credit-references`,
      },
      true
    );
    return result;
  }

  public static async create(
    assessmentId: string,
    data: CreditRefData
  ): Promise<SingleResponse<CreditRefAttributes>> {
    const result = await APIService.jsonRequest<
      SingleResponse<CreditRefAttributes>,
      CreditRefData
    >(
      {
        method: "POST",
        path: `/assessment/assessments/${assessmentId}/credit-references`,
        data,
      },
      true
    );
    return result;
  }

  public static async update(
    refId: number,
    assessmentId: string,
    data: CreditRefData
  ): Promise<SingleResponse<CreditRefAttributes>> {
    const result = await APIService.jsonRequest<
      SingleResponse<CreditRefAttributes>,
      CreditRefData
    >(
      {
        method: "PATCH",
        path: `/assessment/assessments/${assessmentId}/credit-references/${refId}`,
        data,
      },
      true
    );
    return result;
  }

  public static async delete(
    assessmentId: string,
    id: number
  ): Promise<SingleResponse<CreditRefAttributes>> {
    const result = await APIService.jsonRequest<
      SingleResponse<CreditRefAttributes>,
      CreditRefAttributes
    >(
      {
        method: "DELETE",
        path: `/assessment/assessments/${assessmentId}/credit-references/${id}`,
      },
      true
    );
    return result;
  }
}

export default CreditReferenceService;
