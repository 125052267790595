import { AuditLogResponse } from "@quest-finance/quest-fe-shared/dist/audit";
import { LoginHistoryResponse } from "./LoginHistory";
import { User, userDefaultValue, UserForm, userFormDefaultValue } from "./User";

export interface UserAdminState {
  user: User;
  userForm: UserForm;
  getUserLoading: boolean;
  saveUserLoading: boolean;
  saveUserSuccess: boolean;
  assignRolesLoading: boolean;
  userAuditLogs: AuditLogResponse[];
  userAuditLogsCount: number;
  userAuditLogsLoading: boolean;
  loginHistories: LoginHistoryResponse[];
  loginHistoriesCount: number;
  loginHistoriesLoading: boolean;
}

export const userAdminStateDefaultValue: UserAdminState = {
  user: userDefaultValue,
  userForm: userFormDefaultValue,
  getUserLoading: false,
  saveUserLoading: false,
  saveUserSuccess: false,
  assignRolesLoading: false,
  userAuditLogs: [],
  userAuditLogsCount: 0,
  userAuditLogsLoading: false,
  loginHistories: [],
  loginHistoriesCount: 0,
  loginHistoriesLoading: false,
};
