import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import { actionCreator } from "@quest-finance/quest-fe-shared/dist/notification";
import { AxiosError } from "axios";
import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import { InternalNotesResponse } from "../../common/internal-notes/types/InternalNotesResponse";
import * as actionCreators from "../actions/creators/internalNotes";
import * as actionTypes from "../actions/types/internalNotes";
import { NOTIFICATION_IDS } from "../constants/notificationIds";
import InternalNotesService from "../services/InternalNotesService";
import { InternalNotesSubmitRequest } from "../types/InternalNotesSubmitRequest";

const { setNotification } = actionCreator;

function* getEntityNotesListWorker({
  entityId,
  payload,
}: actionTypes.GetEntityNotesList) {
  try {
    const result: ListResponse<InternalNotesResponse> = yield call(
      InternalNotesService.getInternalNotes,
      entityId,
      payload
    );
    yield put(actionCreators.getInternalNotesListSuccess(result));
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.INTERNAL_NOTES,
        className: "qst-notif-success",
        body,
      })
    );
    yield put(actionCreators.getInternalNotesListError());
  }
}

function* submitEntityNotesWorker({
  entityId,
  note,
}: actionTypes.SubmitEntityNotes) {
  try {
    const data: InternalNotesSubmitRequest = {
      entityId,
      note,
    };
    yield call(InternalNotesService.submitInternalNotes, data);
    yield put(actionCreators.submitEntityNotesSuccess());
    yield put(
      actionCreators.getEntityNotesList(entityId, {
        offset: 0,
        limit: DATATABLE_MAX_ROWS,
      })
    );
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.INTERNAL_NOTES,
        className: "qst-notif-success",
        body,
      })
    );
    yield put(actionCreators.submitEntityNotesFailed());
  }
}

function* watchInternalNotes(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(actionTypes.GET_ENTITY_NOTE_LIST, getEntityNotesListWorker);
  yield takeLatest(actionTypes.SUBMIT_ENTITY_NOTES, submitEntityNotesWorker);
}

export default watchInternalNotes;
