import React, { useCallback, useEffect, useState } from "react";
import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import { getTaskNotes, resetTaskNote } from "../../actions/creators/taskNote";
import { useTaskNoteDispatch } from "../../dispatchers";
import { TaskNoteAttributes } from "../../types/TaskNote";
import TaskNoteList from "./TaskNoteList";
import TaskNotesForm from "./TaskNotesForm";

type WorkflowTaskNoteProps = {
  workflowId: number;
  workflowTaskId: number;
  afterCreate?: undefined | (() => void);
  afterUpdate?: undefined | (() => void);
  disabled?: boolean;
};

const WorkflowTaskNote: React.FunctionComponent<WorkflowTaskNoteProps> = ({
  workflowId,
  workflowTaskId,
  afterCreate,
  afterUpdate,
  disabled,
}: WorkflowTaskNoteProps) => {
  const [page, setPage] = useState(1);
  const [note, setNote] = useState<TaskNoteAttributes>();
  const taskNoteDispatch = useTaskNoteDispatch();

  const onNoteSubmit = () => {
    if (note) {
      setNote(undefined);

      if (afterUpdate) afterUpdate();
    } else {
      if (page === 1) {
        taskNoteDispatch(
          getTaskNotes(workflowId, workflowTaskId, {
            limit: DATATABLE_MAX_ROWS,
          })
        );
      } else {
        setPage(1);
      }

      if (afterCreate) afterCreate();
    }
  };

  useEffect(() => {
    return () => {
      taskNoteDispatch(resetTaskNote());
    };
  }, [taskNoteDispatch]);

  useEffect(() => {
    setNote(undefined);
  }, [workflowTaskId]);

  const handleOnUpdate = useCallback((note: TaskNoteAttributes) => {
    setNote(note);
  }, []);

  const handleOnCancel = useCallback(() => {
    setNote(undefined);
  }, []);

  const onPageChange = (page: number) => {
    setPage(page);
    setNote(undefined);
  };

  return (
    <div className="workflow-task-notes">
      <TaskNotesForm
        workflowId={workflowId}
        workflowTaskId={workflowTaskId}
        note={note}
        onSave={onNoteSubmit}
        onCancel={handleOnCancel}
        disabled={disabled}
      />
      <TaskNoteList
        workflowId={workflowId}
        workflowTaskId={workflowTaskId}
        page={page}
        onPageChange={onPageChange}
        onNoteUpdate={handleOnUpdate}
        readOnly={disabled}
      />
    </div>
  );
};

export default WorkflowTaskNote;
