import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { SubmitToLMSRequest } from "../types";

class LMSService {
  public static async submitToLms(
    data: SubmitToLMSRequest
  ): Promise<SingleResponse<string>> {
    const result = await APIService.jsonRequest<
      SingleResponse<string>,
      SubmitToLMSRequest
    >(
      {
        method: "POST",
        path: `/lms/submit`,
        data,
      },
      true
    );

    return result;
  }

  public static async validateSubmit(
    assessmentId: string
  ): Promise<SingleResponse<boolean>> {
    const result = await APIService.jsonRequest<
      SingleResponse<boolean>,
      { assessmentId: string }
    >(
      {
        method: "POST",
        path: `/lms/validate-submit`,
        data: {
          assessmentId,
        },
      },
      true
    );

    return result;
  }
}

export default LMSService;
