import "./SettlementDate.scss";
import React, { useEffect, useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { Button, DatePicker, Form, Modal, Spin } from "antd";
import moment, { Moment } from "moment";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../common/utils/error";
import AssessmentService from "../../services/AssessmentService";
import { AssessmentDetail } from "../../types/AssessmentDetail";

type SettlementDateModalProps = {
  assessment: AssessmentDetail;
  visible: boolean;
  onCancel: () => void;
  onSuccess: (settledAt: AssessmentDetail["settledAt"]) => void;
};

const SettlementDateModal: React.FunctionComponent<SettlementDateModalProps> = ({
  assessment,
  visible,
  onCancel,
  onSuccess,
}: SettlementDateModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [date, setDate] = useState<Moment | null>(moment);

  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      if (date) {
        const { data } = await AssessmentService.updateAssessmentSettlementDate(
          assessment.id,
          date.format("YYYY-MM-DD")
        );

        onSuccess(data.settledAt);
        showSuccessPopUp("Settlement date successfuly updated");
      }
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleChangeDate = (newDate: Moment | null) => {
    setDate(newDate);
  };

  useEffect(() => {
    if (assessment.settledAt) {
      setDate(moment(assessment.settledAt));
    }

    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [assessment.settledAt]);

  return (
    <Modal
      title={
        <span data-testid="settlement-date-title">
          Change settlement date for{" "}
          <strong>{assessment.data.applicationNumber as string}</strong>
        </span>
      }
      visible={visible}
      destroyOnClose={true}
      footer={null}
      onCancel={onCancel}
      className="settlement-date-modal"
      closeIcon={<CloseCircleFilled />}
      data-testid="settlement-date-modal"
    >
      <Spin spinning={isLoading}>
        <Form layout="vertical">
          <Form.Item label="Settlement Date">
            <DatePicker
              disabledDate={(current) => {
                return current && current > moment().endOf("day");
              }}
              className="settlement-date w-100"
              data-testid="settlement-date"
              value={date}
              onChange={handleChangeDate}
              allowClear={false}
            />
          </Form.Item>

          <Button
            className="qf-btn-green settlement-date-save-btn"
            loading={submitLoading}
            onClick={handleSubmit}
            data-testid="settlement-date-save-modal"
          >
            Save
          </Button>
        </Form>
      </Spin>
    </Modal>
  );
};

export default SettlementDateModal;
