import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AssessmentDetailsActions } from "../actions/types/assessmentAdmin";
import { AssessmentApplicationActions } from "../actions/types/assessmentApplication";
import { AssessmentListActions } from "../actions/types/assessmentList";

export const useAssessmentDetailsDispatch = (): Dispatch<
  AssessmentDetailsActions
> => useDispatch();

export const useAssessmentApplicationDispatch = (): Dispatch<
  AssessmentApplicationActions
> => useDispatch();

export const useAssessmentListDispatch = (): Dispatch<AssessmentListActions> =>
  useDispatch();
