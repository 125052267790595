import { applicationStatuses } from "@quest-finance/quest-fe-shared/dist/application";
import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";
import {
  WorkflowTaskCount,
  WorkflowResponse,
  WorkflowSummaryDetails,
} from "../types/Workflow";

export enum WORKFLOW_STATE {
  PENDING = "PENDING",
  PASS = "PASS",
  REFER = "REFER",
  HOLD = "HOLD",
  FAIL = "FAIL",
  DISABLED = "DISABLED",
  FAILED = "FAILED",
}

export const WORKFLOW_STATE_LABELS: Dictionary = {
  [WORKFLOW_STATE.PENDING]: "Pending",
  [WORKFLOW_STATE.PASS]: "Pass",
  [WORKFLOW_STATE.REFER]: "Refer",
  [WORKFLOW_STATE.HOLD]: "Hold",
  [WORKFLOW_STATE.FAILED]: "Failed",
  [WORKFLOW_STATE.FAIL]: "Fail",
};

export const closedStatus: applicationStatuses.APPLICATION_STATUSES[] = [
  applicationStatuses.APPLICATION_STATUSES.SETTLED,
  applicationStatuses.APPLICATION_STATUSES.WITHDRAWN_BY_INTRODUCER,
  applicationStatuses.APPLICATION_STATUSES.WITHDRAWN_BY_LENDER,
  applicationStatuses.APPLICATION_STATUSES.DECLINED,
];

export const workflowResponseDefaultValue: WorkflowResponse = {
  id: 0,
  templateId: 0,
  code: "",
  assigneeId: "",
  targetId: "",
  state: "",
  isSuccess: false,
  isClosed: false,
  closedAt: "",
  closedBy: "",
  closedReason: "",
  createdAt: "",
  updatedAt: "",
  templateRef: {
    id: 0,
    code: "",
    text: "",
    title: "",
    description: "",
    clientId: 0,
    targetModel: "",
    versionNumber: 0,
    createdAt: "",
    updatedAt: "",
    startTaskTemplateCode: "",
    groupTemplates: [],
  },
  groups: {},
};

export const workflowTaskCountDefaultValue: WorkflowTaskCount = {
  FAILED: 0,
  HOLD: 0,
  PASS: 0,
  PENDING: 0,
  REFER: 0,
  TOTAL: 0,
};

export enum TASK_INPUT_TYPES {
  RADIO = "RADIO",
  SELECT = "SELECT",
}

export const workflowSummaryDetailsDefaultValue: WorkflowSummaryDetails = {};

export enum WORKFLOW_CODE {
  CREDIT_WORKFLOW = "CREDIT_WORKFLOW",
  SETTLEMENT_WORKFLOW = "SETTLEMENT_WORKFLOW",
}
