import {
  AuditLogListRequest,
  AuditLogResponse,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { LabelValue } from "@quest-finance/quest-fe-shared/dist/common/types/LabelValue";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { Action } from "redux";
import { RoleForm } from "../../types/RoleForm";
import { RoleResponse } from "../../types/RoleResponse";

export const GET_ROLE_DETAILS = "GET_ROLE_DETAILS";
export interface GetRoleDetails extends Action {
  type: typeof GET_ROLE_DETAILS;
  roleId?: number;
}

export const GET_ROLE_DETAILS_SUCCESS = "GET_ROLE_DETAILS_SUCCESS";
export interface GetRoleDetailsSuccess extends Action {
  type: typeof GET_ROLE_DETAILS_SUCCESS;
  role: RoleResponse;
}

export const GET_ROLE_DETAILS_FAILED = "GET_ROLE_DETAILS_FAILED";
export interface GetRoleDetailsFailed extends Action {
  type: typeof GET_ROLE_DETAILS_FAILED;
}

type GetRoleActions =
  | GetRoleDetails
  | GetRoleDetailsSuccess
  | GetRoleDetailsFailed;

export const SAVE_ROLE = "SAVE_ROLE";
export interface SaveRole extends Action {
  type: typeof SAVE_ROLE;
  roleForm: RoleForm;
  roleId?: number;
}

export const SAVE_ROLE_SUCCESS = "SAVE_ROLE_SUCCESS";
export interface SaveRoleSuccess extends Action {
  type: typeof SAVE_ROLE_SUCCESS;
}

export const SAVE_ROLE_FAILED = "SAVE_ROLE_FAILED";
export interface SaveRoleFailed extends Action {
  type: typeof SAVE_ROLE_FAILED;
}

type SaveRoleActions = SaveRole | SaveRoleSuccess | SaveRoleFailed;

export const UPDATE_ROLE_FORM = "UPDATE_ROLE_FORM";
export interface UpdateRoleForm extends Action {
  type: typeof UPDATE_ROLE_FORM;
  roleForm: Partial<RoleForm>;
}

export const RESET_ROLE_FORM_STATE = "RESET_ROLE_FORM_STATE";
export interface ResetRoleFormState extends Action {
  type: typeof RESET_ROLE_FORM_STATE;
}

export const GET_PERMISSIONS = "GET_PERMISSIONS";
export interface GetPermissions extends Action {
  type: typeof GET_PERMISSIONS;
}

export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export interface GetPermissionsSuccess extends Action {
  type: typeof GET_PERMISSIONS_SUCCESS;
  permissions: LabelValue[];
}

export const GET_PERMISSIONS_FAILED = "GET_PERMISSIONS_FAILED";
export interface GetPermissionsFailed extends Action {
  type: typeof GET_PERMISSIONS_FAILED;
}

type PermissionsActions =
  | GetPermissions
  | GetPermissionsSuccess
  | GetPermissionsFailed;

export const GET_ROLE_AUDIT_LOG = "GET_ROLE_AUDIT_LOG";
export interface GetRoleAuditLog extends Action {
  type: typeof GET_ROLE_AUDIT_LOG;
  payload: AuditLogListRequest;
}

export const GET_ROLE_AUDIT_LOG_SUCCESS = "GET_ROLE_AUDIT_LOG_SUCCESS";
export interface GetRoleAuditLogSuccess extends Action {
  type: typeof GET_ROLE_AUDIT_LOG_SUCCESS;
  auditLogs: ListResponse<AuditLogResponse>;
}

export const GET_ROLE_AUDIT_LOG_FAILED = "GET_ROLE_AUDIT_LOG_FAILED";
export interface GetRoleAuditLogFailed extends Action {
  type: typeof GET_ROLE_AUDIT_LOG_FAILED;
}

type AudiLogActions =
  | GetRoleAuditLog
  | GetRoleAuditLogSuccess
  | GetRoleAuditLogFailed;

export type RoleAdminActions =
  | GetRoleActions
  | SaveRoleActions
  | UpdateRoleForm
  | ResetRoleFormState
  | PermissionsActions
  | AudiLogActions;
