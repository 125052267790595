import { Action } from "redux";
import { AssessmentDetail } from "../../types/AssessmentDetail";
import * as actionTypes from "../types/assessmentDetail";

export const RESET_ASSESSMENT_DETAILS = "CE_RESET_ASSESSMENT_DETAILS";
export interface ResetAssessmentDetails extends Action {
  type: typeof actionTypes.RESET_ASSESSMENT_DETAILS;
}

export const GET_ASSESSMENT_DETAILS = "CE_GET_ASSESSMENT_DETAILS";
export interface GetAssessmentDetail extends Action {
  type: typeof actionTypes.GET_ASSESSMENT_DETAILS;
  assessmentId: string;
}

export const GET_ASSESSMENT_DETAILS_SUCCESS =
  "CE_GET_ASSESSMENT_DETAILS_SUCCESS";
export interface GetAssessmentDetailSuccess extends Action {
  type: typeof GET_ASSESSMENT_DETAILS_SUCCESS;
  assessment: AssessmentDetail;
}

export const GET_ASSESSMENT_DETAILS_FAILED = "CE_GET_ASSESSMENT_DETAILS_FAILED";
export interface GetAssessmentDetailFailed extends Action {
  type: typeof actionTypes.GET_ASSESSMENT_DETAILS_FAILED;
}

export const UPDATE_ASSESSMENT_DETAIL = "UPDATE_ASSESSMENT_DETAIL";
export interface UpdateAssessmentDetail extends Action {
  type: typeof UPDATE_ASSESSMENT_DETAIL;
  assessment: Partial<AssessmentDetail>;
}

export type AssessmentDetailActions =
  | GetAssessmentDetailFailed
  | GetAssessmentDetailSuccess
  | GetAssessmentDetail
  | ResetAssessmentDetails
  | UpdateAssessmentDetail;
