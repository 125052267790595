import {
  AuditLogListRequest,
  AuditLogResponse,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { Action } from "redux";
import {
  LoginHistoriesRequest,
  LoginHistoryResponse,
} from "../../types/LoginHistory";
import { UserForm } from "../../types/User";
import { UserResponse } from "../../types/UserResponse";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export interface GetUserDetails extends Action {
  type: typeof GET_USER_DETAILS;
  userId?: number;
}

export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export interface GetUserDetailsSuccess extends Action {
  type: typeof GET_USER_DETAILS_SUCCESS;
  user: UserResponse;
}

export const GET_USER_DETAILS_FAILED = "GET_USER_DETAILS_FAILED";
export interface GetUserDetailsFailed extends Action {
  type: typeof GET_USER_DETAILS_FAILED;
}

type GetUserActions =
  | GetUserDetails
  | GetUserDetailsSuccess
  | GetUserDetailsFailed;

export const SAVE_USER = "SAVE_USER";
export interface SaveUser extends Action {
  type: typeof SAVE_USER;
  user: UserForm;
  userId?: number;
}

export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export interface SaveUserSuccess extends Action {
  type: typeof SAVE_USER_SUCCESS;
  user: UserResponse;
}

export const SAVE_USER_FAILED = "SAVE_USER_FAILED";
export interface SaveUserFailed extends Action {
  type: typeof SAVE_USER_FAILED;
}

type SaveUserActions = SaveUser | SaveUserSuccess | SaveUserFailed;

export const UPDATE_USER_FORM = "UPDATE_USER_FORM";
export interface UpdateUserForm extends Action {
  type: typeof UPDATE_USER_FORM;
  userForm: Partial<UserForm>;
}

export const RESET_FORM_STATE = "RESET_FORM_STATE";
export interface ResetFormState extends Action {
  type: typeof RESET_FORM_STATE;
}

export const ASSIGN_ROLES = "ASSIGN_ROLES";
export interface AssignRoles extends Action {
  type: typeof ASSIGN_ROLES;
  userId: number;
  roleIds: number[];
}

export const ASSIGN_ROLES_SUCCESS = "ASSIGN_ROLES_SUCCESS";
export interface AssignRolesSuccess extends Action {
  type: typeof ASSIGN_ROLES_SUCCESS;
}

export const ASSIGN_ROLES_FAILED = "ASSIGN_ROLES_FAILED";
export interface AssignRolesFailed extends Action {
  type: typeof ASSIGN_ROLES_FAILED;
}

type AssignRolesAction = AssignRoles | AssignRolesSuccess | AssignRolesFailed;
export const GET_USER_AUDIT_LOGS = "GET_USER_AUDIT_LOGS";
export interface GetUserAuditLogs extends Action {
  type: typeof GET_USER_AUDIT_LOGS;
  payload: AuditLogListRequest;
}

export const GET_USER_AUDIT_LOGS_SUCCESS = "GET_USER_AUDIT_LOGS_SUCCESS";
export interface GetUserAuditLogsSuccess extends Action {
  type: typeof GET_USER_AUDIT_LOGS_SUCCESS;
  auditLogs: ListResponse<AuditLogResponse>;
}

export const GET_USER_AUDIT_LOGS_FAILED = "GET_USER_AUDIT_LOGS_FAILED";
export interface GetUserAuditLogsFailed extends Action {
  type: typeof GET_USER_AUDIT_LOGS_FAILED;
}

type UserAuditLogsActions =
  | GetUserAuditLogs
  | GetUserAuditLogsSuccess
  | GetUserAuditLogsFailed;

export const GET_LOGIN_HISTORIES = "GET_LOGIN_HISTORIES";
export interface GetLoginHistories extends Action {
  type: typeof GET_LOGIN_HISTORIES;
  payload: LoginHistoriesRequest;
}

export const GET_LOGIN_HISTORIES_SUCCESS = "GET_LOGIN_HISTORIES_SUCCESS";
export interface GetLoginHistoriesSuccess extends Action {
  type: typeof GET_LOGIN_HISTORIES_SUCCESS;
  loginHistories: ListResponse<LoginHistoryResponse>;
}

export const GET_LOGIN_HISTORIES_FAILED = "GET_LOGIN_HISTORIES_FAILED";
export interface GetLoginHistoriesFailed extends Action {
  type: typeof GET_LOGIN_HISTORIES_FAILED;
}

type LoginHistoriesAction =
  | GetLoginHistories
  | GetLoginHistoriesSuccess
  | GetLoginHistoriesFailed;

export type UserAdminActions =
  | GetUserActions
  | SaveUserActions
  | UpdateUserForm
  | ResetFormState
  | UserAuditLogsActions
  | LoginHistoriesAction
  | AssignRolesAction
  | UserAuditLogsActions;
