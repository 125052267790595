import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { convertToQueryParams } from "@quest-finance/quest-fe-shared/dist/common/utils/object";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import { DocumentService } from "@quest-finance/quest-fe-shared/dist/files";
import * as notification from "@quest-finance/quest-fe-shared/dist/notification";
import { call, ForkEffect, put, takeLatest } from "@redux-saga/core/effects";
import { AxiosError } from "axios";
import { submitToLms } from "../../lms";
import { getAssessmentDetails } from "../actions/creators/assessmentAdmin";
import * as actionCreators from "../actions/creators/assessmentApplication";
import * as actionTypes from "../actions/types/assessmentApplication";
import { NOTIFICATION_IDS } from "../constants/notificationIds";
import AssessmentExtrasService from "../services/AssessmentExtrasService";
import AssessmentService from "../services/AssessmentService";
import { ApprovalConditionsSubmitRequest } from "../types/ApprovalConditionsSubmitRequest";
import { AssessmentDocument } from "../types/AssessmentDocument";
import { GetAssessmentExtrasResponse } from "../types/AssessmentExtras";

const {
  actionCreator: { clearNotification, setNotification, unsetNotification },
} = notification;

function* submitApprovalConditionsWorker({
  assessmentId,
  conditions,
}: actionTypes.SubmitApprovalConditions) {
  try {
    yield put(clearNotification());
    const data: ApprovalConditionsSubmitRequest = {
      conditions,
    };
    yield call(AssessmentService.submitApprovalConditions, assessmentId, data);
    yield put(actionCreators.submitApprovalConditionsSuccess());
    yield put(getAssessmentDetails(assessmentId));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.APPROVAL_CONDITIONS_ERROR,
        className: "qst-notif-danger",
        body,
      })
    );
    yield put(actionCreators.submitApprovalConditionsFailed());
  }
}

function* submitToLmsWorker({
  assessmentId,
}: actionTypes.SubmitApplicationToLms) {
  try {
    yield put(clearNotification());
    yield call(submitToLms, { assessmentId });
    yield put(actionCreators.submitApplicationToLmsSuccess());
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.SUBMIT_TO_LMS,
        className: "qst-notif-success",
        body: "Application successfully sent to LMS",
      })
    );
    yield put(getAssessmentDetails(assessmentId));
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.SUBMIT_TO_LMS,
        className: "qst-notif-danger",
        body,
      })
    );
    yield put(actionCreators.submitApplicationToLmsFailed());
  }
}

function* getAssessmentExtrasWorker({
  assessmentId,
  data,
}: actionTypes.GetAssessmentExtras) {
  try {
    yield put(unsetNotification(NOTIFICATION_IDS.CREDIT_SCORE));
    const response: SingleResponse<GetAssessmentExtrasResponse> = yield call(
      AssessmentExtrasService.getData,
      assessmentId,
      data
    );
    yield put(actionCreators.getAssessmentExtrasSuccess(response.data));
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.CREDIT_SCORE,
        className: "qst-notif-danger",
        body,
      })
    );
    yield put(actionCreators.getAssessmentExtrasFailed());
  }
}

function* getReportsDocumentsWorker({
  assessmentId,
  payload,
}: actionTypes.GetReportsDocuments) {
  try {
    const queryParams = convertToQueryParams(payload);
    const path = `/assessment/assessments/${assessmentId}/documents${queryParams}`;
    yield put(unsetNotification(NOTIFICATION_IDS.CREDIT_SCORE));
    const response: ListResponse<AssessmentDocument> = yield call(
      DocumentService.getDocumentList,
      path
    );
    yield put(actionCreators.getReportDocumentsSuccess(response));
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.CREDIT_SCORE,
        className: "qst-notif-danger",
        body,
      })
    );
    yield put(actionCreators.getReportDocumentsFailed());
  }
}

function* watchAssessmentApplication(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(actionTypes.SUBMIT_APPLICATION_TO_LMS, submitToLmsWorker);
  yield takeLatest(
    actionTypes.SUBMIT_APPROVAL_CONDITIONS,
    submitApprovalConditionsWorker
  );
  yield takeLatest(
    actionTypes.GET_ASSESSMENT_EXTRAS,
    getAssessmentExtrasWorker
  );
  yield takeLatest(
    actionTypes.GET_REPORTS_DOCUMENTS,
    getReportsDocumentsWorker
  );
}

export default watchAssessmentApplication;
