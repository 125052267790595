import { IAM_STATUSES } from "../constants/iamStatuses";

export interface RoleResponse {
  id: number;
  name: string;
  permissions: string[];
  status: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date | null;
}

export const roleResponseDefaultValue: RoleResponse = {
  id: 0,
  name: "",
  status: IAM_STATUSES.ACTIVE,
  permissions: [],
};
