import "./SecurityData.scss";
import React, { useEffect, useState, useMemo } from "react";
import { FormOutlined, SaveOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  ApplicationResponse,
  getApplicationSelector,
  SecurityRequest,
  useApplicationFormDispatch,
} from "@quest-finance/quest-fe-shared/dist/application";
import { updateApplication } from "@quest-finance/quest-fe-shared/dist/application/actions/creators/applicationForm";
import { ASSET_TYPE_LIST } from "@quest-finance/quest-fe-shared/dist/application/constants/assetTypes";
import { SECURITY_DETAILS_INPUT_TYPE_OPTIONS } from "@quest-finance/quest-fe-shared/dist/application/constants/securityDetailsInputTypes";
import { SUPPLIER_TYPE_LABELS } from "@quest-finance/quest-fe-shared/dist/application/constants/supplierTypes";
import { USAGE_TYPE_LABELS } from "@quest-finance/quest-fe-shared/dist/application/constants/usageTypes";
import ApplicationService from "@quest-finance/quest-fe-shared/dist/application/services/ApplicationService";
import { SecurityResponse } from "@quest-finance/quest-fe-shared/dist/application/types/SecurityResponse";
import { parseNumber } from "@quest-finance/quest-fe-shared/dist/common/utils/number";
import { Button, Col, Form, Input, Row, Typography } from "antd";
import * as _ from "lodash";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Currency } from "../../../common/components/Input/Input";
import { showErrorPopUp } from "../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../common/utils/error";
import { calcualteAgeEOT, calculateLvr } from "../../utils/security";

const { Text } = Typography;

const SecurityData: React.FunctionComponent = () => {
  const application = useSelector(
    getApplicationSelector
  ) as ApplicationResponse;
  const dispatchApplication = useApplicationFormDispatch();
  const { quote } = application;
  const security = application.security as SecurityResponse;
  const category = ASSET_TYPE_LIST.find(
    (category) => category.value === security.assetTypeCategory
  );
  const assetType = category?.subTypes.find(
    (type) => type.value === security.assetType
  );
  const ageEOT = useMemo(() => {
    return calcualteAgeEOT(
      security.manufactureYear as number,
      quote.repaymentTermMonth
    );
  }, [quote, security]);

  const marketValueLvr = useMemo(() => {
    return calculateLvr(
      quote.amountFinanced,
      quote.brokerageNominalWithGst,
      security.agreedValue
    );
  }, [quote, security]);

  const glassLvr = useMemo(() => {
    return calculateLvr(
      quote.amountFinanced,
      quote.brokerageNominalWithGst,
      security.retailValue
    );
  }, [quote, security]);

  const [isSaving, setSaving] = useState(false);

  const [securityDetails, setSecurityDetails] = useState<
    Record<string, string | number | null>
  >({});

  useEffect(() => {
    if (security.id && _.isEmpty(securityDetails)) {
      const { agreedValue = 0 } = security;
      setSecurityDetails({
        agreedValue,
      });
    }
  }, [security, securityDetails]);

  const submitSecurity = async (request: Partial<SecurityRequest>) => {
    setSaving(true);
    try {
      const { data } = await ApplicationService.updateSecurity(
        request,
        application.id
      );

      dispatchApplication(
        updateApplication({
          ...application,
          security: {
            ...application.security,
            ...data,
          },
        })
      );
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setSaving(false);
    }
  };

  const onSecurityDetailsChange = (name: string, value: string) => {
    setSecurityDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveAgreedValue = () => {
    submitSecurity({
      ...securityDetails,
      agreedValue: parseNumber(securityDetails.agreedValue as string),
    });
  };

  return (
    <div className="security-wrapper application-data">
      <h4 className="header">
        Security{" "}
        <Link
          to={`/application/applications/${application.id}/security`}
          data-testid="edit-link"
        >
          <FormOutlined className="edit-link" />
        </Link>
      </h4>
      <Row>
        <Col span={24} md={12}>
          <h4 className="section-header">Supplier</h4>
          <Row className="supplier-row">
            <Col sm={12}>
              <Text strong className="label">
                Supplier type
              </Text>
              <div data-testid="supplierType">
                {SUPPLIER_TYPE_LABELS[security.supplierType as string]}
              </div>
            </Col>
            <Col sm={12}>
              <Text strong className="label">
                Supplier name
              </Text>
              <div data-testid="supplierName">{security.supplierName}</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h4 className="section-header">Security</h4>
          <Row>
            <Col span={24} md={12}>
              <Row className="info">
                <Col sm={12}>
                  <Text strong className="label">
                    Usage type
                  </Text>
                  <div data-testid="usageType">
                    {USAGE_TYPE_LABELS[security.usageType as string]}
                  </div>
                </Col>
                <Col sm={12}>
                  <Text strong className="label">
                    Age EOT
                  </Text>
                  <div data-testid="ageEOT">{ageEOT} Years</div>
                </Col>
              </Row>
              <Row className="info">
                <Col sm={12}>
                  <Text strong className="label">
                    Asset category
                  </Text>
                  <div data-testid="assetCategory">{category?.label}</div>
                </Col>
                <Col sm={12}>
                  <Text strong className="label">
                    Asset type
                  </Text>
                  <div data-testid="assetType">{assetType?.label}</div>
                </Col>
              </Row>
              <Row className="info">
                <Col span={24}>
                  <Text strong className="label">
                    Lookup type
                  </Text>
                  <div data-testid="securityDetailsInputType">
                    {
                      SECURITY_DETAILS_INPUT_TYPE_OPTIONS[
                        security.securityDetailsInputType as string
                      ]
                    }
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Text strong className="label">
                    Market value (override)
                  </Text>

                  <Row>
                    <Col span={14} xxl={12}>
                      <Form>
                        <Form.Item>
                          <Input.Group compact className="agreed-value">
                            <Currency
                              value={securityDetails.agreedValue}
                              onValueChange={({ value }) =>
                                onSecurityDetailsChange("agreedValue", value)
                              }
                              disabled={isSaving}
                              data-testid="agreedValue"
                            />
                            <Button
                              onClick={handleSaveAgreedValue}
                              disabled={isSaving}
                              data-testid="save-agreed-value"
                            >
                              {isSaving ? (
                                <LoadingOutlined />
                              ) : (
                                <SaveOutlined />
                              )}
                            </Button>
                          </Input.Group>
                        </Form.Item>
                      </Form>
                    </Col>
                    <Col span={3} className="market-value-lvr">
                      {marketValueLvr > 0 && (
                        <div>
                          (
                          <NumberFormat
                            suffix="%"
                            value={marketValueLvr}
                            displayType="text"
                            fixedDecimalScale
                            thousandSeparator=","
                            decimalScale={0}
                            data-testid="marketValueLvr"
                          />
                          )
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Text strong className="label">
                    Glass&apos; adjusted value
                  </Text>
                  <div data-testid="glassValues">
                    {security.retailValue ? (
                      <>
                        <NumberFormat
                          value={security.retailValue}
                          prefix="$"
                          displayType="text"
                          fixedDecimalScale
                          thousandSeparator=","
                          decimalScale={2}
                          data-testid="retailValue"
                        />{" "}
                        (
                        <NumberFormat
                          value={glassLvr}
                          suffix="%"
                          displayType="text"
                          fixedDecimalScale
                          thousandSeparator=","
                          decimalScale={0}
                          data-testid="glassLvr"
                        />
                        )
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={24} md={12}>
              <Row className="info">
                <Col span={24} md={12}>
                  <Text strong className="label">
                    Year, Make, Model
                  </Text>
                  <div data-testid="yearMakeModel">
                    {security.manufactureYear} {security.make} {security.model}
                  </div>
                </Col>
              </Row>
              <Row className="info">
                <Col span={24}>
                  <Text strong className="label">
                    Description
                  </Text>
                  <div data-testid="description">
                    {security.description ?? "-"}
                  </div>
                </Col>
              </Row>
              <Row className="info">
                <Col span={8}>
                  <Text strong className="label">
                    VIN or Serial
                  </Text>
                  <div data-testid="serialNumber">
                    {security.serialNumber ?? "-"}
                  </div>
                </Col>
                <Col sm={8}>
                  <Text strong className="label">
                    Rego #
                  </Text>
                  <div data-testid="registrationNumber">
                    {security.registrationNumber ?? ""}
                  </div>
                </Col>
                <Col sm={8}>
                  <Text strong className="label">
                    KM
                  </Text>
                  <div data-testid="actualKm">
                    {security.actualKm ? (
                      <NumberFormat
                        value={security.actualKm}
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator=","
                        decimalScale={0}
                      />
                    ) : (
                      "-"
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SecurityData;
