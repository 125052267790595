import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import { actionCreator } from "@quest-finance/quest-fe-shared/dist/notification";
import { AxiosError } from "axios";
import { put, call, takeEvery, ForkEffect } from "redux-saga/effects";
import {
  getRoleListError,
  getRoleListSuccess,
} from "../actions/creators/roleList";
import { GetRoleList, GET_ROLE_LIST } from "../actions/types/roleList";
import { NOTIFICATION_IDS } from "../constants/notificationIds";
import RoleService from "../services/RoleService";
import { RoleResponse } from "../types/RoleResponse";

const { setNotification } = actionCreator;
function* getRoleListWorker({ payload }: GetRoleList) {
  try {
    const results: ListResponse<RoleResponse> = yield call(
      RoleService.getRoleList,
      payload
    );
    yield put(getRoleListSuccess(results));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.IAM_CLIENT_LIST,
        className: "qst-notif-danger",
        body,
      })
    );
    yield put(getRoleListError());
  }
}

function* watchRoleList(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(GET_ROLE_LIST, getRoleListWorker);
}

export default watchRoleList;
