import "./WordLimitter.scss";
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

export enum CONTENT_TYPE {
  TEXT = "TEXT",
  HTML = "HTML",
}

interface WordLimitterProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
  maxLines?: number;
  expanded: boolean;
  type?: CONTENT_TYPE;
  setExpanded: (expanded: boolean) => void;
}

const WordLimitter: React.FunctionComponent<WordLimitterProps> = ({
  text,
  expanded,
  maxLines = 2,
  type = CONTENT_TYPE.TEXT,
  setExpanded,
  className,
}: WordLimitterProps) => {
  const textElement = useRef<HTMLDivElement | null>(null);
  const [expandable, setExpandable] = useState(false);

  useEffect(() => {
    if (textElement.current && textElement.current?.clientHeight > 44) {
      setExpandable(true);
    }
  }, [text]);

  const toggleExpanded = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const content =
    type === CONTENT_TYPE.TEXT ? (
      text
    ) : (
      <div dangerouslySetInnerHTML={{ __html: text }} />
    );

  return (
    <div className={classNames("word-limitter", className, { expandable })}>
      <div
        ref={textElement}
        className={classNames("text", { clamped: !expanded })}
        style={{
          WebkitLineClamp: !maxLines && !expanded ? "none" : maxLines,
          lineClamp: !maxLines && !expanded ? "none" : maxLines,
        }}
      >
        {content}
      </div>

      <a className="toggle-btn" href="/" onClick={toggleExpanded}>
        {expanded ? "view less" : "view more"}
      </a>
    </div>
  );
};

export default WordLimitter;
