import { combineReducers } from "redux";
import { reducer as clientAdminReducer } from "./clientAdmin";
import { reducer as clientListReducer } from "./clientList";
import { reducer as roleAdminReducer } from "./roleAdmin";
import { reducer as roleListReducer } from "./roleList";
import { reducer as userAdminReducer } from "./userAdmin";
import { reducer as userListReducer } from "./userList";

export default combineReducers({
  clientAdmin: clientAdminReducer,
  clientList: clientListReducer,
  userAdmin: userAdminReducer,
  userList: userListReducer,
  roleAdmin: roleAdminReducer,
  roleList: roleListReducer,
});
