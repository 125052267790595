import "./IntroducerDetails.scss";
import React, { useCallback, useEffect, useState } from "react";
import {
  ApplicationResponse,
  getApplicationSelector,
} from "@quest-finance/quest-fe-shared/dist/application";
import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import { Col, Row, Spin } from "antd";
import { AxiosError } from "axios";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { showErrorPopUp } from "../../../common/components/ShowNotification/showNotification";
import AssessmentService from "../../services/AssessmentService";

const IntroducerDetails: React.FC = () => {
  const application: ApplicationResponse = useSelector(getApplicationSelector);
  const [assessmentTotal, setAssessmentTotal] = useState<Dictionary>({});
  const [isFetchingTotals, setIsFetchingTotals] = useState(false);
  const { user } = application;
  const getAssessmentCount = useCallback(async () => {
    setIsFetchingTotals(true);

    if (application.user) {
      try {
        const { data } = await AssessmentService.getIntroducerAssessmentTotal(
          application.user.id
        );

        setAssessmentTotal(data);
      } catch (error) {
        showErrorPopUp(processErrorMessage(error as AxiosError));
      } finally {
        setIsFetchingTotals(false);
      }
    }
  }, [application]);

  useEffect(() => {
    getAssessmentCount();
  }, [getAssessmentCount]);

  const totals = lodash.isEmpty(assessmentTotal) ? null : (
    <Spin spinning={isFetchingTotals}>
      <table className="introducer-details">
        <tbody>
          <tr>
            <td>App(s) submitted :</td>
            <td data-testid="total-submitted">{assessmentTotal.submitted}</td>
          </tr>
          <tr>
            <td>App(s) In settlement/Settled :</td>
            <td data-testid="total-settled">{assessmentTotal.settled}</td>
          </tr>
        </tbody>
      </table>
    </Spin>
  );

  return (
    <Row>
      <Col span={24}>
        <label className="f-bold f-quest-navy">Introducer</label>
      </Col>
      <Col span={12}>
        {!!user && (
          <>
            <div data-testid="client">{user.client}</div>
            <div>
              <span data-testid="name">
                {`${user.firstName} ${user.lastName} `}
              </span>
              {user.mobile ? (
                <span data-testid="mobile">({user.mobile})</span>
              ) : null}
            </div>
            <div>
              <a href={`mailto:${user.email}`} data-testid="email">
                {user.email}
              </a>
            </div>
            {!!user.clientBdmName && (
              <div>
                BDM: <span data-testid="bdm">{user.clientBdmName}</span>
              </div>
            )}
          </>
        )}
      </Col>
      <Col span={12}>{totals}</Col>
    </Row>
  );
};

export default IntroducerDetails;
