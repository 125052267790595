import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import auditLogs from "./auditLogs";
import entityIndividual from "./entityIndividual";
import internalNotes from "./internalNotes";

export default function* entitySagas(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  yield all([fork(internalNotes)]);
  yield all([fork(auditLogs)]);
  yield all([fork(entityIndividual)]);
}
