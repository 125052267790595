import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import { actionCreator } from "@quest-finance/quest-fe-shared/dist/notification";
import { AxiosError } from "axios";
import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import * as actionCreators from "../actions/creators/entityIndividual";
import * as actionTypes from "../actions/types/entityIndividual";
import { NOTIFICATION_IDS } from "../constants/notificationIds";
import IndividualService from "../services/IndividualService";
import { EntityIndividualResponse } from "../types/Entity";

const { setNotification } = actionCreator;

function* updateEntityIndividualWorker({
  entityId,
  request,
}: actionTypes.UpdateEntityIndividual) {
  try {
    yield call(IndividualService.update, entityId, request);
    yield put(actionCreators.updateEntityIndividualSuccess());
    yield put(actionCreators.getEntityIndividual(entityId));

    let body = "User has been marked as Credit Enriched";
    if (!request.creditEnriched) {
      body = "User has been un-marked as Credit Enriched";
    }

    yield put(
      setNotification({
        id: NOTIFICATION_IDS.CREDIT_ENRICHED_SUCCESS,
        className: "qst-notif-success",
        body,
      })
    );
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.CREDIT_ENRICHED_SUCCESS,
        className: "qst-notif-success",
        body,
      })
    );
    yield put(actionCreators.updateEntityIndividualFailed());
  }
}

function* getEntityIndividualWorker({
  entityId,
}: actionTypes.GetEntityIndividual) {
  try {
    const result: SingleResponse<EntityIndividualResponse> = yield call(
      IndividualService.get,
      entityId
    );
    yield put(actionCreators.getEntityIndividualSuccess(result.data));
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.CREDIT_ENRICHED_SUCCESS,
        className: "qst-notif-success",
        body,
      })
    );
    yield put(actionCreators.getEntityIndividualFailed());
  }
}

function* watchEntityIndividual(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    actionTypes.UPDATE_ENTITY_INDIVIDUAL,
    updateEntityIndividualWorker
  );
  yield takeLatest(
    actionTypes.GET_ENTITY_INDIVIDUAL,
    getEntityIndividualWorker
  );
}

export default watchEntityIndividual;
