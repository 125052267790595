import util from "util";
import { applicationSteps } from "@quest-finance/quest-fe-shared/dist/application";
import { Slug } from "../types/Navigation";

export const creditAssessment = "/ce/admin/%s/%s";
export const creditAssessmentRoot = util.format(
  creditAssessment,
  ":assessmentId",
  ":section?"
);
export const asmAplQuote = util.format(creditAssessment, "%s", Slug.QUOTE);
export const asmAplApplicantGuarantors = util.format(
  creditAssessment,
  "%s",
  Slug.APPLICANT_GUARANTORS
);
export const asmAplRefStatements = util.format(
  creditAssessment,
  "%s",
  Slug.REF_STATEMENTS
);
export const asmAplSecurity = util.format(
  creditAssessment,
  "%s",
  Slug.SECURITY
);
export const asmAplNotesContract = util.format(
  creditAssessment,
  "%s",
  Slug.NOTES_CONTRACTS
);
export const asmAplDecision = util.format(
  creditAssessment,
  "%s",
  Slug.DECISION
);
export const asmAplSettlement = util.format(
  creditAssessment,
  "%s",
  Slug.SETTLEMENT
);
export const asmAplAuditLogs = util.format(
  creditAssessment,
  "%s",
  Slug.AUDIT_LOG
);

export const legacyPageMapping: Record<
  applicationSteps.APPLICATION_STEPS,
  string
> = {
  quotes: asmAplQuote,
  applicant: asmAplApplicantGuarantors,
  guarantors: asmAplApplicantGuarantors,
  security: asmAplSecurity,
  notes: asmAplNotesContract,
  contracts: asmAplNotesContract,
  admin: asmAplQuote,
};
