import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { Action } from "redux";
import {
  WorkflowSummaryDetails,
  WorkflowTask,
  WorkflowTaskCount,
  WorkflowGroupResponse,
  WorkflowsById,
} from "../../types/Workflow";
import { WorkflowListQueryParams } from "../../types/Workflow.v2";

export const RESET_WORKFLOW_DETAILS = "CE_RESET_WORKFLOW_DETAILS";
export interface ResetWorkflowDetails extends Action {
  type: typeof RESET_WORKFLOW_DETAILS;
}

export const RESET_WORKFLOW_GROUP_TASKS = "RESET_WORKFLOW_GROUP_TASKS";
export interface ResetWorkflowGroupTasks extends Action {
  type: typeof RESET_WORKFLOW_GROUP_TASKS;
}

export const UPDATE_WORKFLOW_TASK = "UPDATE_WORKFLOW_TASK";
export interface UpdateWorkflowTask {
  type: typeof UPDATE_WORKFLOW_TASK;
  taskId: number;
  taskResponse: WorkflowTask;
}

export const GET_WORKFLOW_SUMMARY = "GET_WORKFLOW_SUMMARY";
export interface GetWorkflowSummary {
  type: typeof GET_WORKFLOW_SUMMARY;
  queryParams: WorkflowListQueryParams;
}

export const GET_WORKFLOW_SUMMARY_SUCCESS = "GET_WORKFLOW_SUMMARY_SUCCESS";
export interface GetWorkflowSummarySuccess {
  type: typeof GET_WORKFLOW_SUMMARY_SUCCESS;
  summaryResponse: WorkflowSummaryDetails;
  status: LOADING_STATUS;
}

export const GET_WORKFLOW_SUMMARY_FAILED = "GET_WORKFLOW_SUMMARY_FAILED";
export interface GetWorkflowSummaryFailed {
  type: typeof GET_WORKFLOW_SUMMARY_FAILED;
  error: unknown;
}

export const UPDATE_WORKFLOW_GROUP = "UPDATE_WORKFLOW_GROUP";
export interface UpdateWorkflowGroup {
  type: typeof UPDATE_WORKFLOW_GROUP;
  groupsResponse: WorkflowGroupResponse[];
}

export const GET_WORKFLOW_TASKS = "GET_WORKFLOW_TASKS";
export interface GetWorkflowTasks {
  type: typeof GET_WORKFLOW_TASKS;
  queryParams: WorkflowListQueryParams;
}

export const GET_WORKFLOW_TASKS_SUCCESS = "GET_WORKFLOW_TASKS_SUCCESS";
export interface GetWorkflowTasksSuccess {
  type: typeof GET_WORKFLOW_TASKS_SUCCESS;
  childWorkflows: WorkflowsById;
  tasks: WorkflowTask[];
  countByState?: WorkflowTaskCount;
}

export const GET_WORKFLOW_TASKS_FAILED = "GET_WORKFLOW_TASKS_FAILED";
export interface GetWorkflowTasksFailed {
  type: typeof GET_WORKFLOW_TASKS_FAILED;
  error: unknown;
}

export const RESET_WORKFLOW_TASKS = "RESET_WORKFLOW_TASKS";
export interface ResetWorkflowTasks extends Action {
  type: typeof RESET_WORKFLOW_TASKS;
}

export type WorkflowActions =
  | ResetWorkflowDetails
  | UpdateWorkflowTask
  | GetWorkflowSummary
  | GetWorkflowSummarySuccess
  | GetWorkflowSummaryFailed
  | ResetWorkflowTasks
  | UpdateWorkflowGroup
  | GetWorkflowTasks
  | GetWorkflowTasksSuccess
  | GetWorkflowTasksFailed;
