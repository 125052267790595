import { createSelector } from "@reduxjs/toolkit";
import { FunderDetailsState, FunderState } from "../types/FunderState";

export const funderSelector = (state: FunderState): FunderDetailsState =>
  state.funder.funderDetails;

export const getFunderSelector = createSelector(
  funderSelector,
  (funderDetails) => funderDetails.funder
);
export const getFunderLoadingSelector = createSelector(
  funderSelector,
  (funderDetails) => funderDetails.getFunderLoading
);
export const getSaveFunderLoadingSelector = createSelector(
  funderSelector,
  (funderDetails) => funderDetails.saveFunderLoading
);
