import { createSelector } from "@reduxjs/toolkit";
import { AssessmentExtrasState } from "../types/AssessmentExtras";
import { AssessmentState } from "../types/AssessmentState";

export const assessmentExtrasSelector = (
  state: AssessmentState
): AssessmentExtrasState => {
  return state.creditEngine.assessmentExtras;
};

export const assessmentExtrasStatusSelector = createSelector(
  assessmentExtrasSelector,
  (assessmentDetail) => assessmentDetail.status
);

export const assessmentExtrasErrorSelector = createSelector(
  assessmentExtrasSelector,
  (assessmentDetail) => assessmentDetail.error
);

export const assessmentExtrasDataSelector = createSelector(
  assessmentExtrasSelector,
  (assessmentDetail) => {
    return assessmentDetail.assessmentExtras;
  }
);
