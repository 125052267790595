import { AuditLogResponse } from "@quest-finance/quest-fe-shared/dist/audit";

export interface AuditLogState {
  entityAuditLogs: AuditLogResponse[];
  entityAuditLogsCount: number;
  entityAuditLogsLoading: boolean;
}

export const auditLogStateDefaultValue: AuditLogState = {
  entityAuditLogs: [],
  entityAuditLogsCount: 0,
  entityAuditLogsLoading: false,
};
