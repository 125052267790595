import "./DocumentList.scss";
import React, { useCallback } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { Button, Empty, Popconfirm, Spin } from "antd";
import cs from "classnames";
import { DATE_LIST_FORMAT } from "../../../common/contstants/app";
import { WorkflowDocument } from "../../types/Document";

type DocumentListProps = {
  documents: WorkflowDocument[];
  onDelete: (documentId: string) => void;
  onDownload: (documentId: string) => void;
  isLoading?: boolean;
  readOnly?: boolean;
};

const DocumentList: React.FunctionComponent<DocumentListProps> = ({
  documents,
  onDelete,
  onDownload,
  isLoading,
  readOnly = false,
}: DocumentListProps) => {
  const handleDownload = useCallback(
    (documentId: string) => {
      onDownload(documentId);
    },
    [onDownload]
  );

  const handleDeleteDocument = useCallback(
    (documentId: string) => {
      if (!readOnly) onDelete(documentId);
    },
    [onDelete, readOnly]
  );

  const renderDeleteButton = (documentId: string) => {
    return (
      <Popconfirm
        title="Sure to delete this document?"
        onConfirm={() => handleDeleteDocument(documentId)}
      >
        <Button className="delete-button">
          <DeleteFilled />
        </Button>
      </Popconfirm>
    );
  };

  const onDownloadClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    documentId: string
  ) => {
    e.preventDefault();
    handleDownload(documentId);
  };

  return (
    <Spin spinning={isLoading}>
      {documents.length > 0 ? (
        <table
          className={cs("workflow-document-list", { "read-only": readOnly })}
        >
          <thead>
            <tr>
              <th style={{ width: "35%" }}>Time upload</th>
              <th style={{ width: "60%" }}>Document name</th>
              <th className="delete" style={{ width: "5%" }}></th>
            </tr>
          </thead>
          <tbody>
            {documents.map((document) => (
              <tr key={document.id}>
                <td>
                  {dateFormat(new Date(document.createdAt), DATE_LIST_FORMAT)}
                </td>
                <td>
                  <a onClick={(e) => onDownloadClick(e, document.id)} href="/">
                    {document.filename}
                  </a>
                </td>
                <td className="text-center delete">
                  {renderDeleteButton(document.id)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Empty description="No documents found" />
      )}
    </Spin>
  );
};

export default DocumentList;
