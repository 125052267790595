import React, { useEffect, useMemo, useState } from "react";
import { DownloadOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { convertToCurrency } from "@quest-finance/quest-fe-shared/dist/common/utils/number";
import {
  Empty,
  message,
  Popover,
  Table,
  TableColumnsType,
  Tooltip,
} from "antd";
import fileDownload from "js-file-download";
import * as qs from "query-string";
import { useHistory, useLocation } from "react-router";

import {
  DATATABLE_MAX_ROWS,
  DATE_LIST_FORMAT,
  SHORT_DATE_FORMAT,
} from "../../../common/contstants/app";
import AbaService from "../../services/AbaService";
import { AbaHistory } from "../../types/Aba";
import "./AbaHistory.scss";

export type AbaHistoryTableProps = {
  loading?: boolean;
  data: AbaHistory[];
  total?: number;
  currentPage?: number;
  pageSize?: number;
  onPageChange?: (page: number) => void;
  onDownload?: (abaHistory: AbaHistory) => void;
};

export const AbaHistoryTable: React.FC<AbaHistoryTableProps> = (
  props: AbaHistoryTableProps
) => {
  const {
    data,
    total,
    loading,
    currentPage,
    pageSize,
    onPageChange,
    onDownload,
  } = props;

  const columns: TableColumnsType<AbaHistory> = [
    {
      title: "Date Processed",
      width: 150,
      render(_, { createdAt }, i) {
        const date = new Date(createdAt);
        return (
          <Tooltip title={dateFormat(date, DATE_LIST_FORMAT)}>
            <span data-testid={`aba-history-date-${i}`}>
              {dateFormat(date, SHORT_DATE_FORMAT)}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "User",
      render(_, { user }, i) {
        return (
          <span
            data-testid={`aba-history-user-${i}`}
          >{`${user.firstName} ${user.lastName}`}</span>
        );
      },
    },
    {
      title: "Amount",
      align: "right",
      width: 150,
      render(_, item, i) {
        let prefix = "";
        let className = "debit";

        if (item.data.totalDirectCredit) {
          prefix = "-";
          className = "credit";
        }
        return (
          <span data-testid={`aba-history-amount-${i}`} className={className}>
            {prefix +
              "$" +
              convertToCurrency(item.data.netTotalAmount, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </span>
        );
      },
    },
    {
      title: "",
      align: "center",
      width: 90,
      render(_, aba, i) {
        const errors = aba.data.failedRefIds?.map((v, errorIndex) => (
          <li data-testid={`aba-history-error-${i}-${errorIndex}`} key={v}>
            {v}
          </li>
        ));
        const popOverContent = (
          <div data-testid={`aba-history-error-${i}-items`}>
            <div>Failed Transactions</div>
            <ul>{errors}</ul>
          </div>
        );

        return (
          <div className="download-col">
            <Tooltip title="Download ABA file">
              <DownloadOutlined
                className="download-icon"
                onClick={() => onDownload?.(aba)}
                data-testid={`aba-history-download-${i}`}
              />
            </Tooltip>
            {Boolean(errors?.length) && (
              <div className="error">
                <Popover content={popOverContent} destroyTooltipOnHide={true}>
                  <ExclamationCircleFilled
                    data-testid={`aba-history-error-${i}`}
                  />
                </Popover>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      size="small"
      loading={loading}
      className="aba-history"
      locale={{
        emptyText() {
          return (
            <Empty
              description="No records found."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          );
        },
      }}
      pagination={{
        simple: true,
        total: total,
        pageSize: pageSize,
        current: currentPage,
        onChange: onPageChange,
        hideOnSinglePage: true,
      }}
    />
  );
};

const AbaHistoryList: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [abaHistory, setAbaHistory] = useState<{
    loading?: boolean;
    data: AbaHistory[];
    total?: number;
  }>({
    loading: true,
    data: [],
    total: 20,
  });
  const queryParams = useMemo(() => {
    const data = qs.parse(location.search, { parseNumbers: true }) as {
      page: number;
    };
    const page = isNaN(data.page) ? 1 : data.page;

    return {
      page,
      limit: DATATABLE_MAX_ROWS,
    };
  }, [location]);

  const getAbaHistory = async (page: number, limit: number) => {
    setAbaHistory((state) => ({
      ...state,
      loading: true,
    }));

    try {
      const resp = await AbaService.getHistory(page, limit);
      setAbaHistory({
        data: resp.data,
        total: resp.count,
      });
    } catch (e) {
      message.open({
        type: "error",
        content: "error",
      });
    }

    setAbaHistory((state) => ({
      ...state,
      loading: false,
    }));
  };

  const downloadAba = (aba: AbaHistory) => {
    fileDownload(aba.fileContents, aba.fileName);
  };

  useEffect(() => {
    getAbaHistory(queryParams.page, queryParams.limit);
  }, [queryParams]);

  const onPageChange = (page: number) => {
    const urlParams = qs.stringify({
      page,
    });
    history.push(`?${urlParams}`);
  };

  return (
    <div className="content-section aba-history" data-testid="aba-history">
      <div className="header-1 mb-3">History</div>
      <div className="w-50">
        <AbaHistoryTable
          loading={abaHistory.loading}
          data={abaHistory.data}
          total={abaHistory.total}
          currentPage={queryParams.page}
          pageSize={queryParams.limit}
          onPageChange={onPageChange}
          onDownload={downloadAba}
        />
      </div>
    </div>
  );
};

export default AbaHistoryList;
