import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { TaskNoteActions } from "../actions/types/taskNote";
import { WorkflowActions } from "../actions/types/workflow";

export const useWorkflowDispatch = (): Dispatch<WorkflowActions> =>
  useDispatch();

export const useTaskNoteDispatch = (): Dispatch<TaskNoteActions> =>
  useDispatch();
