import { Status } from "../types/ApprovalCondition";

export const STATUS_LABELS = {
  [Status.PASS]: "Pass",
  [Status.HOLD]: "Hold",
  [Status.REFER]: "Refer",
};

export const STATUS_BTN_CLASS = {
  [Status.PASS]: "qf-btn-green",
  [Status.HOLD]: "qf-btn-orange",
  [Status.REFER]: "qf-btn-yellow",
};
