import { applicationStatuses } from "@quest-finance/quest-fe-shared/dist/application";

const { APPLICATION_STATUSES } = applicationStatuses;

export const REASON_TRIGGER_STATUSES = [
  APPLICATION_STATUSES.APPROVED_WAITING_FOR_MORE_INFO,
  APPLICATION_STATUSES.DECLINED,
  APPLICATION_STATUSES.WITHDRAWN_BY_INTRODUCER,
  APPLICATION_STATUSES.WITHDRAWN_BY_LENDER,
] as string[];

export const STATUS_GROUP_OTHER = "OTHER";

export const NON_SETTLEMENT_STAGE_STATUSES = [
  APPLICATION_STATUSES.QUOTED,
  APPLICATION_STATUSES.DRAFTED_NEW,
  APPLICATION_STATUSES.DRAFTED_AMENDED,
  APPLICATION_STATUSES.SUBMITTED_NEW,
  APPLICATION_STATUSES.SUBMITTED_AMENDED,
  APPLICATION_STATUSES.IN_PROGRESS_CREDIT_WAITING_FOR_MORE_INFO,
  APPLICATION_STATUSES.IN_PROGRESS_CREDIT_IN_PROGRESS,
  APPLICATION_STATUSES.IN_PROGRESS_CREDIT_REFERRED,
  APPLICATION_STATUSES.APPROVED_WAITING_FOR_MORE_INFO,
];
