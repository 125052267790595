import * as actionTypes from "../actions/types/clientAdmin";
import {
  ClientAdminState,
  clientAdminStateDefaultValue,
} from "../types/ClientAdminState";

export const clientAdminState: ClientAdminState = clientAdminStateDefaultValue;

export const reducer = (
  state: ClientAdminState = clientAdminState,
  action: actionTypes.ClientAdminActions
): ClientAdminState => {
  switch (action.type) {
    case actionTypes.GET_CLIENT_DETAILS: {
      return {
        ...state,
        getClientLoading: true,
      };
    }
    case actionTypes.GET_CLIENT_DETAILS_SUCCESS: {
      const {
        name,
        bdmId,
        dealRecipientClientId,
        status,
        configType,
      } = action.client;
      return {
        ...state,
        getClientLoading: false,
        client: action.client,
        clientForm: {
          name,
          bdmId,
          status,
          dealRecipientClientId,
          configType: configType ?? null,
        },
      };
    }
    case actionTypes.GET_CLIENT_DETAILS_FAILED: {
      return {
        ...state,
        getClientLoading: false,
      };
    }
    case actionTypes.SAVE_CLIENT: {
      return {
        ...state,
        saveClientLoading: true,
      };
    }
    case actionTypes.SAVE_CLIENT_SUCCESS: {
      return {
        ...state,
        saveClientLoading: false,
        saveClientSuccess: true,
      };
    }
    case actionTypes.SAVE_CLIENT_FAILED: {
      return {
        ...state,
        saveClientLoading: false,
      };
    }
    case actionTypes.RESET_FORM_STATE: {
      return clientAdminStateDefaultValue;
    }
    case actionTypes.UPDATE_CLIENT_FORM: {
      return {
        ...state,
        clientForm: {
          ...state.clientForm,
          ...action.clientForm,
        },
      };
    }
    case actionTypes.GET_CLIENT_AUDIT_LOGS: {
      return {
        ...state,
        clientAuditLogsLoading: true,
      };
    }
    case actionTypes.GET_CLIENT_AUDIT_LOGS_SUCCESS: {
      const { data, count } = action.auditLogs;
      return {
        ...state,
        clientAuditLogs: data,
        clientAuditLogsCount: count ? count : 0,
        clientAuditLogsLoading: false,
      };
    }
    case actionTypes.GET_CLIENT_AUDIT_LOGS_FAILED: {
      return {
        ...state,
        clientAuditLogsLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
