import querystring from "querystring";
import { UserData } from "@quest-finance/quest-fe-shared/dist/auth/types/UserData";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";

export enum Sections {
  QUOTE = "QUOTE",
  APPLICANT_GUARANTORS = "APPLICANT_GUARANTORS",
  REF_STATEMENTS = "REF_STATEMENTS",
  SECURITY = "SECURITY",
  NOTES_CONTRACTS = "NOTES_CONTRACTS",
  DECISION = "DECISION",
  SETTLEMENT = "SETTLEMENT",
  ADMIN = "ADMIN",
}

export interface NoteAttributes {
  id: string;
  section: Sections;
  note: string;
  createdBy: number;
  createdAt: string;
  creator?: Pick<UserData, "firstName" | "lastName">;
}

export interface NoteDocument {
  id: string;
  section: Sections;
  note: string;
  createdBy: number;
  createdAt: string;
  creator?: Pick<UserData, "firstName" | "lastName">;
}

export type NoteForm = {
  note: string;
  section: Sections | undefined;
};

export interface ListRequestParams extends querystring.ParsedUrlQueryInput {
  section?: string;
  offset?: number;
  limit?: number | undefined;
}

export interface NoteListParams {
  page?: number;
}

export interface ListState {
  notes: NoteDocument[];
  count: number;
  status: LOADING_STATUS;
  error: unknown;
  lastResetTimestamp: number;
}
