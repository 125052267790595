import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import * as assessmentDetailsTypes from "../actions/types/assessmentAdmin";
import {
  AssessmentAdminState,
  assessmentAdminStateDefaultValue,
} from "../types/AssessmentAdminState";
import { assessmentDefaultValue } from "../types/AssessmentResponse";

export const adminDataState: AssessmentAdminState = assessmentAdminStateDefaultValue;

export const reducer = (
  state: AssessmentAdminState = adminDataState,
  action: assessmentDetailsTypes.AssessmentDetailsActions
): AssessmentAdminState => {
  switch (action.type) {
    case assessmentDetailsTypes.RESET_ASSESSMENT_DETAILS: {
      return {
        ...adminDataState,
      };
    }
    case assessmentDetailsTypes.GET_ASSESSMENT_DETAILS: {
      return {
        ...state,
        getAssessmentDetailsLoading: true,
      };
    }
    case assessmentDetailsTypes.GET_ASSESSMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        assessment: {
          ...assessmentDefaultValue,
          ...action.assessment,
        },
        getAssessmentDetailsLoading: false,
      };
    }
    case assessmentDetailsTypes.GET_ASSESSMENT_DETAILS_FAILED: {
      return {
        ...state,
        getAssessmentDetailsLoading: false,
      };
    }
    case assessmentDetailsTypes.GET_INTERNAL_NOTES_LIST: {
      return {
        ...state,
        internalNotesLoading: true,
      };
    }
    case assessmentDetailsTypes.GET_INTERNAL_NOTES_LIST_SUCCESS: {
      return {
        ...state,
        internalNotes: action.listResponse.data,
        internalNotesCount: action.listResponse.count
          ? action.listResponse.count
          : 0,
        internalNotesLoading: false,
      };
    }
    case assessmentDetailsTypes.GET_INTERNAL_NOTES_LIST_ERROR: {
      return {
        ...state,
        internalNotesLoading: false,
      };
    }
    case assessmentDetailsTypes.SUBMIT_INTERNAL_NOTES: {
      return {
        ...state,
        submitInternalNotesLoading: true,
        submitInternalNotesSuccess: false,
      };
    }
    case assessmentDetailsTypes.SUBMIT_INTERNAL_NOTES_SUCCESS: {
      return {
        ...state,
        submitInternalNotesLoading: false,
        submitInternalNotesSuccess: true,
      };
    }
    case assessmentDetailsTypes.SUBMIT_INTERNAL_NOTES_FAILED: {
      return {
        ...state,
        submitInternalNotesLoading: false,
        submitInternalNotesSuccess: false,
      };
    }
    case assessmentDetailsTypes.UPDATE_ASSESSMENT_STATUS: {
      return {
        ...state,
        statusUpdate: LOADING_STATUS.IDLE,
      };
    }
    case assessmentDetailsTypes.UPDATE_ASSESSMENT_STATUS_SUCCESS: {
      return {
        ...state,
        statusUpdate: LOADING_STATUS.SUCCESS,
      };
    }
    case assessmentDetailsTypes.UPDATE_ASSESSMENT_STATUS_FAILED: {
      return {
        ...state,
        statusUpdate: LOADING_STATUS.FAILED,
      };
    }
    case assessmentDetailsTypes.GET_APPLICATION_AUDIT_LOG: {
      return {
        ...state,
        applicationAuditLogsLoading: true,
      };
    }
    case assessmentDetailsTypes.GET_APPLICATION_AUDIT_LOG_SUCCESS: {
      return {
        ...state,
        applicationAuditLogs: action.auditLogList.data,
        applicationAuditLogsCount: action.auditLogList.count
          ? action.auditLogList.count
          : 0,
        applicationAuditLogsLoading: false,
      };
    }
    case assessmentDetailsTypes.GET_APPLICATION_AUDIT_LOG_FAILED: {
      return {
        ...state,
        applicationAuditLogsLoading: false,
      };
    }
    case assessmentDetailsTypes.GET_ACCREDITATION_AUDIT_LOG: {
      return {
        ...state,
        accreditationAuditLogsLoading: true,
      };
    }
    case assessmentDetailsTypes.GET_ACCREDITATION_AUDIT_LOG_SUCCESS: {
      return {
        ...state,
        accreditationAuditLogs: action.auditLogList.data,
        accreditationAuditLogsCount: action.auditLogList.count
          ? action.auditLogList.count
          : 0,
        accreditationAuditLogsLoading: false,
      };
    }
    case assessmentDetailsTypes.GET_ACCREDITATION_AUDIT_LOG_FAILED: {
      return {
        ...state,
        accreditationAuditLogsLoading: false,
      };
    }
    case assessmentDetailsTypes.GET_ASSESSMENT_AUDIT_LOG: {
      return {
        ...state,
        assessmentAuditLogsLoading: true,
      };
    }
    case assessmentDetailsTypes.GET_ASSESSMENT_AUDIT_LOG_SUCCESS: {
      return {
        ...state,
        assessmentAuditLogs: action.auditLogList.data,
        assessmentAuditLogsCount: action.auditLogList.count
          ? action.auditLogList.count
          : 0,
        assessmentAuditLogsLoading: false,
      };
    }
    case assessmentDetailsTypes.GET_ASSESSMENT_AUDIT_LOG_FAILED: {
      return {
        ...state,
        assessmentAuditLogsLoading: false,
      };
    }
    case assessmentDetailsTypes.GET_FUNDER_AUDIT_LOGS: {
      return {
        ...state,
        funderAuditLogsLoading: true,
      };
    }
    case assessmentDetailsTypes.GET_FUNDER_AUDIT_LOGS_SUCCESS: {
      return {
        ...state,
        funderAuditLogs: action.auditLogList.data,
        funderAuditLogsCount: action.auditLogList.count
          ? action.auditLogList.count
          : 0,
        funderAuditLogsLoading: false,
      };
    }
    case assessmentDetailsTypes.GET_FUNDER_AUDIT_LOG_FAILED: {
      return {
        ...state,
        funderAuditLogsLoading: false,
      };
    }
    case assessmentDetailsTypes.SET_ASSESSMENT_UPLOAD_PROGRESS: {
      return {
        ...state,
        uploadProgress: action.progress,
      };
    }
    case assessmentDetailsTypes.SET_ASSIGNEE: {
      return {
        ...state,
        getAssessmentDetailsLoading: true,
      };
    }
    case assessmentDetailsTypes.SET_ASSIGNEE_SUCCESS: {
      return {
        ...state,
        getAssessmentDetailsLoading: false,
        assessment: action.assessment,
      };
    }
    case assessmentDetailsTypes.SET_ASSIGNEE_FAILED: {
      return {
        ...state,
        getAssessmentDetailsLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};
