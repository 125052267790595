import React, { useCallback, useEffect, useState } from "react";
import { Collapse } from "antd";
import { useParams } from "react-router-dom";
import { showErrorPopUp } from "../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../common/utils/error";
import { SubmitApplicationBtn } from "../../../lms";
import LMSService from "../../../lms/services/LMSService";
import { QfCollapse } from "../../../theme";
import SettlementApproval from "../settlement-approval/SettlementApproval";
import FunderSection from "./FunderSection/FunderSection";

const { Panel } = Collapse;

const InternalProcessing: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [submittedToLms, setSubmittedToLms] = useState(false);
  const { assessmentId } = useParams<{
    assessmentId: string;
  }>();
  const onSuccessfulLmsSubmit = () => {
    setSubmittedToLms(true);
  };

  const getLmsSubmission = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await LMSService.validateSubmit(assessmentId);
      setSubmittedToLms(data);
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    }
    setLoading(false);
  }, [assessmentId]);

  useEffect(() => {
    getLmsSubmission();
  }, [getLmsSubmission, assessmentId]);

  return !loading ? (
    <>
      <QfCollapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
        <Panel
          key="1"
          header={<div className="section-header-1">Internal Processing</div>}
        >
          <FunderSection submittedToLms={submittedToLms} />
          <SubmitApplicationBtn
            submittedToLms={submittedToLms}
            assessmentId={assessmentId}
            onSubmitSucess={onSuccessfulLmsSubmit}
          />
        </Panel>
      </QfCollapse>
      <SettlementApproval />
    </>
  ) : null;
};

export default InternalProcessing;
