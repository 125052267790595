export enum NOTIFICATION_IDS {
  IAM_ROLE_SAVE = "IAM_ROLE_SAVE",
  IAM_USER_SAVE = "IAM_USER_SAVE",
  IAM_USER_ASSIGN_ROLES = "IAM_USER_ASSIGN_ROLES",
  IAM_CLIENT_SAVE = "SAVE_CLIENT_SUCCESS",
  IAM_USER_LIST = "IAM_USER_LIST",
  IAM_USER_DETAILS_ERROR = "IAM_USER_DETAILS_ERROR",
  IAM_USER_NOT_FOUND = "IAM_USER_NOT_FOUND",
  IAM_CLIENT_LIST = "IAM_CLIENT_LIST",
  IAM_CLIENT_DETAILS_ERROR = "IAM_CLIENT_DETAILS_ERROR",
  IAM_CLIENT_NOT_FOUND = "IAM_CLIENT_NOT_FOUND",
  IAM_ROLE_LIST = "IAM_ROLE_LIST",
  IAM_ROLE_DETAILS_ERROR = "IAM_ROLE_DETAILS_ERROR",
  IAM_ROLE_NOT_FOUND = "IAM_ROLE_NOT_FOUND",
}
