import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import {
  AssessmentListActions,
  GET_ASSESSMENT_LIST,
  GET_ASSESSMENT_LIST_ERROR,
  GET_ASSESSMENT_LIST_SUCCESS,
} from "../actions/types/assessmentList";
import { ASSESSMENT_TYPES } from "../constants/assessmentTypes";
import { AssessmentListState } from "../types/AssessmentListState";

export const assessmentListState: AssessmentListState = {
  assessments: [],
  type: ASSESSMENT_TYPES.APPLICATION,
  assessmentCount: 0,
  isLoading: false,
  resetPage: false,
};

export const reducer = (
  state: AssessmentListState = assessmentListState,
  action: AssessmentListActions
): AssessmentListState => {
  switch (action.type) {
    case GET_ASSESSMENT_LIST: {
      const { offset, limit, status, order, type } = action.payload;

      let resetPage = false;
      if (order !== state.order || status !== state.status) resetPage = true;

      return {
        ...state,
        type,
        status: status ? status : undefined,
        order: order ? order : undefined,
        limit: limit ? limit : DATATABLE_MAX_ROWS,
        offset: offset ? offset : state.offset,
        resetPage,
        isLoading: true,
      };
    }
    case GET_ASSESSMENT_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        resetPage: false,
        // TODO: to be reverted once the api has retuned the `assignee` array
        assessments: action.assessments.map((assessment) => ({
          ...assessment,
          assignees: assessment.assignees || [],
        })),
        assessmentCount: action.assessmentCount,
      };
    }
    case GET_ASSESSMENT_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
