import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { EmailRequest, EmailResponse } from "../types/Email";

class EmailService {
  public static async sendApprovalEmail(
    assessmentId: string,
    params: EmailRequest
  ): Promise<SingleResponse<EmailResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<EmailResponse>,
      EmailRequest
    >(
      {
        method: "POST",
        path: `/assessment/assessments/${assessmentId}/send-approval-email`,
        data: params,
      },
      true
    );

    return result;
  }
}

export default EmailService;
