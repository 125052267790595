import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import * as assessmentApplicationType from "../actions/types/assessmentApplication";
import {
  AssessmentApplicationState,
  assessmentApplicationStateDefaultValue,
} from "../types/AssessmentApplicationState";

export const assessmentApplicationState: AssessmentApplicationState = assessmentApplicationStateDefaultValue;

export const reducer = (
  state: AssessmentApplicationState = assessmentApplicationState,
  action: assessmentApplicationType.AssessmentApplicationActions
): AssessmentApplicationState => {
  switch (action.type) {
    case assessmentApplicationType.UPDATE_APPROVAL_CONDITIONS: {
      return {
        ...state,
        approvalConditions: action.conditions,
      };
    }
    case assessmentApplicationType.SUBMIT_APPROVAL_CONDITIONS: {
      return {
        ...state,
        submitApprovalConditionsLoading: true,
      };
    }
    case assessmentApplicationType.SUBMIT_APPROVAL_CONDITIONS_SUCCESS:
    case assessmentApplicationType.SUBMIT_APPROVAL_CONDITIONS_FAILED: {
      return {
        ...state,
        submitApprovalConditionsLoading: false,
      };
    }
    case assessmentApplicationType.SUBMIT_APPLICATION_TO_LMS: {
      return {
        ...state,
        submitToLmsStatus: LOADING_STATUS.LOADING,
      };
    }
    case assessmentApplicationType.SUBMIT_APPLICATION_TO_LMS_SUCCESS: {
      return {
        ...state,
        submitToLmsStatus: LOADING_STATUS.SUCCESS,
      };
    }
    case assessmentApplicationType.SUBMIT_APPLICATION_TO_LMS_FAILED: {
      return {
        ...state,
        submitToLmsStatus: LOADING_STATUS.FAILED,
      };
    }
    case assessmentApplicationType.GET_ASSESSMENT_EXTRAS: {
      return {
        ...state,
        assessmenExtrasLoading: true,
      };
    }
    case assessmentApplicationType.GET_ASSESSMENT_EXTRAS_SUCCESS: {
      return {
        ...state,
        assessmenExtrasLoading: false,
        assessmentExtras: {
          ...state.assessmentExtras,
          ...action.response,
        },
      };
    }
    case assessmentApplicationType.GET_ASSESSMENT_EXTRAS_FAILED: {
      return {
        ...state,
        assessmenExtrasLoading: false,
      };
    }
    case assessmentApplicationType.RESET_ASSESSMENT_APPLICATION: {
      return assessmentApplicationStateDefaultValue;
    }
    case assessmentApplicationType.GET_REPORTS_DOCUMENTS: {
      return {
        ...state,
        creditReportDocumentsLoading: true,
      };
    }
    case assessmentApplicationType.GET_REPORTS_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        creditReportDocuments: action.response.data,
        creditReportDocumentsCount: action.response.count ?? 0,
        creditReportDocumentsLoading: false,
      };
    }
    case assessmentApplicationType.GET_REPORTS_DOCUMENTS_FAILED: {
      return {
        ...state,
        creditReportDocumentsLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};
