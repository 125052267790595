import React from "react";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { isObjectNotEmpty } from "@quest-finance/quest-fe-shared/dist/common/utils/object";
import { Button, Popconfirm } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import classNames from "classnames";
import { DATE_LIST_FORMAT } from "../../../../common/contstants/app";
import {
  FIRST_TIME_REQUEST_MESSAGE,
  SUBSEQUENT_REQUEST_MESSAGE,
} from "../../../constants/assessmentExtras";
import { ScoreResponse } from "../../../types/AssessmentExtras";

type CreditButtonProps = {
  className?: string;
  getPopupContainer: () => HTMLElement;
  popUpPlacement?: TooltipPlacement;
  onClick: () => void;
  requestLoading: boolean;
  allLoading?: boolean;
  label: string;
};

type CreditButtonSingleProps = CreditButtonProps & {
  data: ScoreResponse | null;
};

export const CreditButtonSingle: React.FunctionComponent<CreditButtonSingleProps> = ({
  className,
  getPopupContainer,
  popUpPlacement,
  onClick,
  data,
  requestLoading,
  allLoading,
  label,
}: CreditButtonSingleProps) => {
  const hasData = data && isObjectNotEmpty(data);
  const lastRequest =
    data && data.updatedAt
      ? dateFormat(new Date(data.updatedAt), DATE_LIST_FORMAT)
      : "";
  const message = hasData
    ? SUBSEQUENT_REQUEST_MESSAGE
    : FIRST_TIME_REQUEST_MESSAGE;

  const popupTitle = (
    <div className="confirm-message">
      <span>{message}</span> <br />
      <span>
        {lastRequest && <strong>{`Last request was on ${lastRequest}`}</strong>}
      </span>
    </div>
  );

  return (
    <Popconfirm
      getPopupContainer={getPopupContainer}
      title={popupTitle}
      onConfirm={() => {
        onClick();
      }}
      placement={popUpPlacement}
      disabled={allLoading}
    >
      <Button
        className={classNames(className, {
          "qf-btn-green": !hasData,
          "qf-btn-gray-invert": hasData,
        })}
        loading={requestLoading}
        disabled={allLoading}
      >
        {label}
      </Button>
    </Popconfirm>
  );
};

type CreditButtonMultipleProps = CreditButtonProps & {
  data: { guarantorNo: string; lastRequest: string }[];
};

export const CreditButtonMultiple: React.FunctionComponent<CreditButtonMultipleProps> = ({
  className,
  getPopupContainer,
  popUpPlacement,
  onClick,
  data,
  requestLoading,
  label,
}: CreditButtonMultipleProps) => {
  const popupTitle = (
    <div>
      {data.length === 0 ? (
        FIRST_TIME_REQUEST_MESSAGE
      ) : (
        <div>
          {SUBSEQUENT_REQUEST_MESSAGE}
          <ul>
            {data.map((scoreData) => (
              <li
                key={scoreData.guarantorNo}
              >{`Guarantor ${scoreData.guarantorNo} last request: ${scoreData.lastRequest}`}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  return (
    <Popconfirm
      getPopupContainer={getPopupContainer}
      title={popupTitle}
      onConfirm={() => {
        onClick();
      }}
      placement={popUpPlacement}
    >
      <Button
        className={classNames(className, {
          "qf-btn-green": data.length === 0,
          "qf-btn-gray-invert": data.length > 0,
        })}
        loading={requestLoading}
      >
        {label}
      </Button>
    </Popconfirm>
  );
};
