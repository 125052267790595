import * as actionTypes from "../actions/types/roleAdmin";
import {
  RoleAdminState,
  roleAdminStateDefaultValue,
} from "../types/RoleAdminState";

export const roleAdminState: RoleAdminState = roleAdminStateDefaultValue;

export const reducer = (
  state: RoleAdminState = roleAdminState,
  action: actionTypes.RoleAdminActions
): RoleAdminState => {
  switch (action.type) {
    case actionTypes.GET_ROLE_DETAILS: {
      return {
        ...state,
        getRoleLoading: true,
      };
    }
    case actionTypes.GET_ROLE_DETAILS_SUCCESS: {
      const { name, permissions, status } = action.role;
      return {
        ...state,
        getRoleLoading: false,
        role: action.role,
        roleForm: {
          name,
          permissions,
          status,
        },
      };
    }
    case actionTypes.GET_ROLE_DETAILS_FAILED: {
      return {
        ...state,
        getRoleLoading: false,
      };
    }
    case actionTypes.SAVE_ROLE: {
      return {
        ...state,
        saveRoleLoading: true,
      };
    }
    case actionTypes.SAVE_ROLE_SUCCESS: {
      return {
        ...state,
        saveRoleLoading: false,
        saveRoleSuccess: true,
      };
    }
    case actionTypes.SAVE_ROLE_FAILED: {
      return {
        ...state,
        saveRoleLoading: false,
      };
    }
    case actionTypes.RESET_ROLE_FORM_STATE: {
      return roleAdminStateDefaultValue;
    }
    case actionTypes.UPDATE_ROLE_FORM: {
      return {
        ...state,
        roleForm: {
          ...state.roleForm,
          ...action.roleForm,
        },
      };
    }
    case actionTypes.GET_PERMISSIONS: {
      return {
        ...state,
        getPermissionsLoading: true,
      };
    }
    case actionTypes.GET_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        permissions: action.permissions,
        getPermissionsLoading: false,
      };
    }
    case actionTypes.GET_PERMISSIONS_FAILED: {
      return {
        ...state,
        getPermissionsLoading: false,
      };
    }
    case actionTypes.GET_ROLE_AUDIT_LOG: {
      return {
        ...state,
        roleAuditLogsLoading: true,
      };
    }
    case actionTypes.GET_ROLE_AUDIT_LOG_SUCCESS: {
      return {
        ...state,
        roleAuditLogs: action.auditLogs.data,
        roleAuditLogsCount: action.auditLogs.count ? action.auditLogs.count : 0,
        roleAuditLogsLoading: false,
      };
    }
    case actionTypes.GET_ROLE_AUDIT_LOG_FAILED: {
      return {
        ...state,
        roleAuditLogsLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
