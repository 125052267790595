import "./MoveApplication.scss";
import React, { useEffect, useState, useCallback } from "react";
import {
  applicationStatuses,
  ApplicationService,
  ApplicationResponse,
} from "@quest-finance/quest-fe-shared/dist/application";
import { getUserDataSelector } from "@quest-finance/quest-fe-shared/dist/auth";
import { UserData } from "@quest-finance/quest-fe-shared/dist/auth/types/UserData";
import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";

import { HTTP_STATUS_CODES } from "@quest-finance/quest-fe-shared/dist/common/constants/httpStatusCodes";
import {
  Input,
  Col,
  Row,
  Divider,
  Button,
  Select,
  Popconfirm,
  Alert,
} from "antd";
import { AxiosError } from "axios";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../common/utils/error";
import {
  ClientService,
  UserService,
  USER_STATUS,
  isAllowedAccess,
  permissions,
} from "../../../iam";
import { ClientResponse } from "../../../iam/types/ClientResponse";
import { UserResponse } from "../../../iam/types/UserResponse";
import { ExtendedAntd, LayoutTypes } from "../../../theme/";

const defaultBreadcrumb: LayoutTypes.BreadcrumbItem[] = [
  {
    label: "Home",
    url: "/",
  },
];

const confirmationMsg = (
  <div data-testid="confirm-action">
    <b>Move this application?</b>
    <div>All application details will become visible to new users.</div>
  </div>
);

const lockedStatuses = [
  applicationStatuses.APPLICATION_PUBLIC_STATUSES.DECLINED,
  applicationStatuses.APPLICATION_PUBLIC_STATUSES.WITHDRAWN,
  applicationStatuses.APPLICATION_PUBLIC_STATUSES.SETTLED,
];

const { Option } = Select;
const selectProps = {
  notFoundContent: null,
  filterOption: false,
  showSearch: true,
  labelInValue: true,
};

export interface ApplicationPlus extends ApplicationResponse {
  client: {
    id: number;
    name: string;
  };
  submittedToLmsAt: string | null;
}

type SelectedValue =
  | undefined
  | {
      label: string;
      value: number;
    };

const MoveApplication: React.FunctionComponent<RouteComponentProps> = () => {
  const [breadcrumb, setBreadcrumb] = useState<LayoutTypes.Breadcrumbs>(
    defaultBreadcrumb
  );
  const history = useHistory();
  const loggedInUser = useSelector(getUserDataSelector);
  const [application, setApplication] = useState<Partial<ApplicationPlus>>({});
  const [isSearching, setIsSearching] = useState(false);
  const [newClient, setNewClient] = useState<SelectedValue>();
  const [newIntroducer, setNewIntroducer] = useState<SelectedValue>();
  const [moveInprogress, setMoveInprogress] = useState(false);
  const [client, setClient] = useState<{
    loading: boolean;
    options: ClientResponse[];
  }>({
    loading: false,
    options: [],
  });
  const [introducer, setIntroducer] = useState<{
    loading: boolean;
    options: UserResponse[];
  }>({
    loading: false,
    options: [],
  });
  let errorMsg = null;

  useEffect(() => {
    if (
      loggedInUser &&
      !isAllowedAccess(loggedInUser as UserData, [
        permissions.PERMISSION_IAM["IAM.USER.READ"],
        permissions.PERMISSION_IAM["APPLICATION.MOVE"],
        permissions.PERMISSION_IAM["APPLICATION.READ_ALL"],
      ])
    ) {
      return history.replace(`/`);
    }
  }, [loggedInUser, history]);

  if (application.id) {
    if (application.submittedToLmsAt) {
      errorMsg = "Application was already pushed to LMS and cannot be moved.";
    } else if (
      lockedStatuses.includes(
        application.publicStatus as applicationStatuses.APPLICATION_PUBLIC_STATUSES
      )
    ) {
      errorMsg = (
        <>
          Application is{" "}
          <b className="status">
            {
              applicationStatuses.APPLICATION_PUBLIC_STATUS_LABELS[
                application.publicStatus as applicationStatuses.APPLICATION_PUBLIC_STATUSES
              ]
            }
          </b>{" "}
          and cannot be moved.
        </>
      );
    }
  }

  const enableNewOwner = application.id && !errorMsg;

  useEffect(() => {
    setBreadcrumb([
      ...defaultBreadcrumb,
      {
        label: "Move Application",
        url: "/assessment/assessments/applications",
      },
    ]);
  }, []);

  const setSelectedApplication = (data: ApplicationPlus | undefined) => {
    if (data) {
      const {
        client: { id, name },
      } = data;
      setApplication(data);
      setNewClient({
        label: name,
        value: id,
      });
    } else {
      setApplication({});
      setNewClient(undefined);
    }
  };

  const onSearchApplication = async (applicationNum: string) => {
    if (!applicationNum) {
      showErrorPopUp("Please specify loan #");
      return;
    }

    onSearchClient();
    setIsSearching(true);
    setSelectedApplication(undefined);

    try {
      const { data } = await ApplicationService.getByApplicationNumber(
        applicationNum
      );

      setSelectedApplication(data as ApplicationPlus);
    } catch (e) {
      processError(e, (errorMessage) => {
        const error = e as AxiosError;
        const notif =
          error?.response?.status === HTTP_STATUS_CODES.NOT_FOUND
            ? `Application with loan # ${applicationNum} does not exist.`
            : errorMessage;

        showErrorPopUp(
          <span data-testid="application-not-found">{notif}</span>
        );
      });
    } finally {
      setIsSearching(false);
    }
  };

  const onSearchClient = async (keyword = "") => {
    setClient((state) => ({ ...state, loading: true, options: [] }));
    try {
      const { data } = await ClientService.getClientList({
        keyword,
        offset: 0,
        limit: DATATABLE_MAX_ROWS,
        order: "name",
      });

      setClient((state) => ({ ...state, options: data, loading: false }));
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
      setClient((state) => ({ ...state, loading: false }));
    }
  };

  const onSearchIntroducer = useCallback(
    async (keywords = "") => {
      if (!newClient) {
        return;
      }

      setIntroducer((state) => ({ ...state, loading: true, options: [] }));
      try {
        const { data } = await UserService.getUserList({
          keywords,
          offset: 0,
          clientIds: newClient?.value,
          limit: DATATABLE_MAX_ROWS,
          order: "firstName",
        });

        setIntroducer((state) => ({
          ...state,
          options: data,
          loading: false,
        }));
      } catch (error) {
        processError(error, (errorMessage) => {
          showErrorPopUp(errorMessage);
        });
        setIntroducer((state) => ({ ...state, loading: false }));
      }
    },
    [newClient]
  );

  const onSelectClient = (client: SelectedValue) => {
    setNewClient(client);
  };

  const moveApplication = async () => {
    setMoveInprogress(true);
    try {
      const { data } = await ApplicationService.moveApplication(
        application.applicationNumber as string,
        newClient?.value as number,
        newIntroducer?.value as number
      );

      setSelectedApplication(data as ApplicationPlus);
      const content = (
        <>
          You have successfully moved Application{" "}
          <b data-testid="move-success-notif">
            {application?.applicationNumber} to {newIntroducer?.label} at{" "}
            {newClient?.label}
          </b>
        </>
      );

      showSuccessPopUp(content);
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setMoveInprogress(false);
    }
  };

  useEffect(() => {
    setNewIntroducer(undefined);
    onSearchIntroducer();
  }, [newClient, onSearchIntroducer]);

  const onReset = () => {
    setSelectedApplication(application as ApplicationPlus);
  };

  const clientOptions = client.options.map(({ id, name, status }) => (
    <Option value={id} key={id} disabled={status === USER_STATUS.SUSPENDED}>
      {name}
    </Option>
  ));

  const introducerOptions = introducer.options.map(
    ({ id, firstName, lastName, status }) => (
      <Option
        value={id}
        key={id}
        disabled={status === USER_STATUS.SUSPENDED || id === application.userId}
      >
        {firstName + " " + lastName}
      </Option>
    )
  );

  return (
    <ExtendedAntd.DefaultLayout breadCrumb={breadcrumb}>
      <div className="move-application">
        <div className="panel-cont">
          <div className="panel">
            <Row gutter={[8, 8]}>
              <Col className="gutter-row loan-num" span={24}>
                Loan #
              </Col>
              <Col className="gutter-row" span={12}>
                <Input.Search
                  placeholder="Enter loan #"
                  onChange={() => setApplication({})}
                  onSearch={onSearchApplication}
                  loading={isSearching}
                  data-testid="loan-number"
                />
              </Col>
              <Col className="gutter-row" span={24}>
                <Input
                  readOnly
                  value={application?.applicant?.entityName as string}
                  data-testid="applicant-entity-name"
                />
              </Col>
            </Row>
            <Row gutter={[8, 8]}></Row>
          </div>
        </div>
        <div className="panel-cont">
          <Divider />
        </div>
        {errorMsg && (
          <Alert
            className="error-msg"
            message={errorMsg}
            type="error"
            showIcon
            data-testid="locked-application-notif"
          />
        )}
        <div className="panel-cont">
          <div className="panel source-account">
            <Row gutter={[8, 8]}>
              <Col className="gutter-row header" span={24}>
                Current Owner
              </Col>
              <Col className="gutter-row label" span={24}>
                Introducer Company
              </Col>
              <Col className="gutter-row" span={24}>
                <Input
                  readOnly
                  value={lodash.get(application, "client.name", "")}
                  data-testid="source-client"
                />
              </Col>
              <Col className="gutter-row label" span={24}>
                Introducer Agent
              </Col>
              <Col className="gutter-row" span={24}>
                <Input
                  readOnly
                  value={
                    application.user
                      ? application?.user?.firstName +
                        " " +
                        application?.user?.lastName
                      : ""
                  }
                  data-testid="source-introducer"
                />
              </Col>
            </Row>
            <Row gutter={[8, 8]}></Row>
          </div>
          <div className="panel new-owner">
            <Row gutter={[8, 8]}>
              <Col className="gutter-row header" span={24}>
                New Owner
              </Col>
              <Col className="gutter-row" span={24}>
                New Introducer Company
              </Col>
              <Col className="gutter-row" span={24}>
                <Select
                  {...selectProps}
                  value={newClient}
                  onSearch={onSearchClient}
                  placeholder="Select introducer company"
                  disabled={!enableNewOwner}
                  loading={client.loading}
                  onSelect={onSelectClient}
                  data-testid="new-client"
                  dropdownRender={(menu) => (
                    <div data-testid="new-client-menu">{menu}</div>
                  )}
                >
                  {clientOptions}
                </Select>
              </Col>
              <Col className="gutter-row" span={24}>
                New Introducer Agent
              </Col>
              <Col className="gutter-row" span={24}>
                <Select
                  {...selectProps}
                  value={newIntroducer}
                  onSearch={onSearchIntroducer}
                  placeholder="Select agent"
                  disabled={!enableNewOwner || !newClient?.value}
                  loading={introducer.loading}
                  onSelect={(introducer: SelectedValue) =>
                    setNewIntroducer(introducer)
                  }
                  data-testid="new-introducer"
                  dropdownRender={(menu) => (
                    <div data-testid="new-introducer-menu">{menu}</div>
                  )}
                >
                  {introducerOptions}
                </Select>
              </Col>
              <Col className="gutter-row btn-cont" span={24}>
                <Button
                  onClick={onReset}
                  disabled={!enableNewOwner || moveInprogress}
                  data-testid="reset-btn"
                >
                  Reset
                </Button>
                <Popconfirm
                  onConfirm={() => {
                    moveApplication();
                  }}
                  title={confirmationMsg}
                  cancelText={<span data-testid="cancel-move">Cancel</span>}
                  okText={<span data-testid="confirm-move">Yes, move it</span>}
                  disabled={!enableNewOwner || moveInprogress || !newIntroducer}
                  overlayClassName="move-confirm"
                  destroyTooltipOnHide
                >
                  <span>
                    <Button
                      className="qf-btn-green"
                      disabled={!enableNewOwner || !newIntroducer}
                      loading={moveInprogress}
                      data-testid="move-btn"
                    >
                      Move
                    </Button>
                  </span>
                </Popconfirm>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </ExtendedAntd.DefaultLayout>
  );
};

export default MoveApplication;
