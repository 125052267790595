import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { Method } from "axios";
import * as queryString from "query-string";
import {
  LoginHistoriesRequest,
  LoginHistoryResponse,
} from "../types/LoginHistory";
import {
  UserForm,
  AssignRolesRequest,
  ClientMgtGroup,
  UserMgtGroup,
} from "../types/User";
import { UserListRequest } from "../types/UserListRequest";
import { UserResponse } from "../types/UserResponse";

class UserService {
  public static async getUserList(
    payload?: UserListRequest
  ): Promise<ListResponse<UserResponse>> {
    const queryParams = payload
      ? `?${queryString.stringify(payload, { encode: false })}`
      : "";
    const method: Method = "GET";
    const path = `/iam/users${queryParams}`;

    const result = await APIService.jsonRequest<
      ListResponse<UserResponse>,
      unknown
    >(
      {
        method,
        path,
      },
      true
    );
    return result;
  }

  public static async getUserDetails(
    clientId: number
  ): Promise<SingleResponse<UserResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<UserResponse>,
      unknown
    >(
      {
        method: "GET",
        path: `/iam/users/${clientId}`,
      },
      true
    );
    return result;
  }

  public static async createUser(
    data: UserForm
  ): Promise<SingleResponse<UserResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<UserResponse>,
      UserForm
    >(
      {
        method: "POST",
        path: `/iam/users`,
        data,
      },
      true
    );
    return result;
  }

  public static async updateUser(
    userId: number,
    data: UserForm
  ): Promise<SingleResponse<UserResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<UserResponse>,
      UserForm
    >(
      {
        method: "PUT",
        path: `/iam/users/${userId}`,
        data,
      },
      true
    );
    return result;
  }

  public static async getLoginHistories(
    payload: LoginHistoriesRequest
  ): Promise<ListResponse<LoginHistoryResponse>> {
    const { userId, ...params } = payload;
    const queryParams = params
      ? `?${queryString.stringify(params, { encode: false })}`
      : "";
    const method: Method = "GET";
    const path = `/iam/users/${userId}/login-histories${queryParams}`;

    const result = await APIService.jsonRequest<
      ListResponse<LoginHistoryResponse>,
      unknown
    >(
      {
        method,
        path,
      },
      true
    );

    return result;
  }

  public static async assignRoles(
    userId: number,
    data: AssignRolesRequest
  ): Promise<SingleResponse<string>> {
    const result = await APIService.jsonRequest<
      SingleResponse<string>,
      AssignRolesRequest
    >(
      {
        method: "PUT",
        path: `/iam/users/${userId}/assign-roles`,
        data,
      },
      true
    );

    return result;
  }

  public static async addManagedClients(
    managerId: number,
    clients: ClientMgtGroup
  ): Promise<SingleResponse<ClientMgtGroup>> {
    return await APIService.jsonRequest<
      SingleResponse<ClientMgtGroup>,
      { clients: ClientMgtGroup }
    >(
      {
        method: "PATCH",
        path: `/iam/users/${managerId}/client-management-groups`,
        data: {
          clients,
        },
      },
      true
    );
  }

  public static async addManagedUsers(
    managerId: number,
    users: UserMgtGroup
  ): Promise<SingleResponse<UserMgtGroup>> {
    return await APIService.jsonRequest<
      SingleResponse<UserMgtGroup>,
      { users: UserMgtGroup }
    >(
      {
        method: "PATCH",
        path: `/iam/users/${managerId}/user-management-groups`,
        data: {
          users,
        },
      },
      true
    );
  }
}

export default UserService;
