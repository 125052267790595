import {
  AuditLogListRequest,
  AuditLogResponse,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { LabelValue } from "@quest-finance/quest-fe-shared/dist/common/types/LabelValue";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { RoleForm } from "../../types/RoleForm";
import { RoleResponse } from "../../types/RoleResponse";
import * as types from "../types/roleAdmin";

export const getRoleDetails = (roleId?: number): types.GetRoleDetails => ({
  type: types.GET_ROLE_DETAILS,
  roleId,
});

export const getRoleDetailsSuccess = (
  role: RoleResponse
): types.GetRoleDetailsSuccess => ({
  type: types.GET_ROLE_DETAILS_SUCCESS,
  role,
});

export const getRoleDetailsFailed = (): types.GetRoleDetailsFailed => ({
  type: types.GET_ROLE_DETAILS_FAILED,
});

export const saveRole = (
  roleForm: RoleForm,
  roleId?: number
): types.SaveRole => ({
  type: types.SAVE_ROLE,
  roleForm,
  roleId,
});

export const saveRoleSuccess = (): types.SaveRoleSuccess => ({
  type: types.SAVE_ROLE_SUCCESS,
});

export const saveRoleFailed = (): types.SaveRoleFailed => ({
  type: types.SAVE_ROLE_FAILED,
});

export const resetRoleFormState = (): types.ResetRoleFormState => ({
  type: types.RESET_ROLE_FORM_STATE,
});

export const updateRoleForm = (
  roleForm: Partial<RoleForm>
): types.UpdateRoleForm => ({
  type: types.UPDATE_ROLE_FORM,
  roleForm,
});

export const getPermissions = (): types.GetPermissions => ({
  type: types.GET_PERMISSIONS,
});

export const getPermissionsSuccess = (
  permissions: LabelValue[]
): types.GetPermissionsSuccess => ({
  type: types.GET_PERMISSIONS_SUCCESS,
  permissions,
});

export const getPermissionsFailed = (): types.GetPermissionsFailed => ({
  type: types.GET_PERMISSIONS_FAILED,
});

export const getRoleAuditLog = (
  payload: AuditLogListRequest
): types.GetRoleAuditLog => ({
  type: types.GET_ROLE_AUDIT_LOG,
  payload,
});

export const getRoleAuditLogSuccess = (
  auditLogs: ListResponse<AuditLogResponse>
): types.GetRoleAuditLogSuccess => ({
  type: types.GET_ROLE_AUDIT_LOG_SUCCESS,
  auditLogs,
});

export const getRoleAuditLogFailed = (): types.GetRoleAuditLogFailed => ({
  type: types.GET_ROLE_AUDIT_LOG_FAILED,
});
