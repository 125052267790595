import { UserData } from "@quest-finance/quest-fe-shared/dist/auth/types/UserData";

export enum DocumentList {
  INTERNAL = "INTERNAL",
  SUPPORTING = "SUPPORTING",
  SETTLEMENT = "SETTLEMENT",
  WORKFLOW = "WORKFLOW",
}

export interface Document {
  id: string;
  assessmentId: string;
  filename: string;
  originalFilename: string;
  purpose: string;
  type: string;
  createdAt: string;
  createdBy: Pick<UserData, "firstName" | "lastName">;
}
