import "./Settlement.scss";
import React from "react";
import { applicationStatuses } from "@quest-finance/quest-fe-shared/dist/application";
import { Collapse } from "antd";
import { useSelector } from "react-redux";
import { QfCollapse } from "../../../../theme";
import { WORKFLOW_CODE } from "../../../../workflow/constants/workflow";
import InternalProcessing from "../../../components/internal-processing";
import Notes from "../../../components/notes";
import SupportingDocuments from "../../../components/supporting-documents/SupportingDocuments";
import WorkflowSection from "../../../components/workflow-section/WorkflowSection";
import { NON_SETTLEMENT_STAGE_STATUSES } from "../../../constants/statusGroup";
import { assessmentResponseSelector } from "../../../selectors/assessmentDetail";
import { DocumentList } from "../../../types/Document";
import { Slug } from "../../../types/Navigation";

const { Panel } = Collapse;

const Settlement: React.FunctionComponent = () => {
  const assessment = useSelector(assessmentResponseSelector);

  return (
    <>
      <InternalProcessing />
      {!NON_SETTLEMENT_STAGE_STATUSES.includes(
        assessment.status as applicationStatuses.APPLICATION_STATUSES
      ) && (
        <WorkflowSection
          activeStep={Slug.SETTLEMENT}
          code={WORKFLOW_CODE.SETTLEMENT_WORKFLOW}
        />
      )}
      <QfCollapse
        className="mt-3"
        defaultActiveKey={["1"]}
        expandIconPosition="right"
        ghost
      >
        <Panel
          className="notes-section-collapsible"
          header={<div className="section-header-1">Notes</div>}
          key="1"
        >
          <Notes />
        </Panel>
      </QfCollapse>
      <SupportingDocuments defaultAtiveTab={DocumentList.SETTLEMENT} />
    </>
  );
};

export default Settlement;
