import React from "react";
import { FormOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { QfCollapse } from "../../../../theme";
import { assessmentResponseSelector } from "../../../selectors/assessmentDetail";
import "./IntroducerNotes.scss";

const { Panel } = Collapse;
const IntroducerNotes: React.FunctionComponent = () => {
  const assessment = useSelector(assessmentResponseSelector);

  return (
    <div className="introducer-notes">
      <QfCollapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
        <Panel
          header={<div className="section-header-1">Introducer Notes</div>}
          key="1"
        >
          <div className="section-header-3">
            Introducer Notes{" "}
            <Link
              to={`/application/applications/${assessment.externalId}/notes`}
              className="edit-link"
            >
              <FormOutlined />
            </Link>
          </div>
          <div
            className="note ql-editor"
            dangerouslySetInnerHTML={{
              __html: _.get(assessment, "data.note.supportingNotes") as string,
            }}
          />
        </Panel>
      </QfCollapse>
    </div>
  );
};

export default IntroducerNotes;
