import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import {
  AssessmentDocument,
  DocumentListRequest,
} from "../../types/AssessmentDocument";
import {
  GetAssessmentExtrasPayload,
  GetAssessmentExtrasResponse,
} from "../../types/AssessmentExtras";
import * as actionTypes from "../types/assessmentApplication";

export const updateApprovalConditions = (
  conditions: string[]
): actionTypes.UpdateApprovalConditions => ({
  type: actionTypes.UPDATE_APPROVAL_CONDITIONS,
  conditions,
});

export const submitApprovalConditions = (
  assessmentId: string,
  conditions: string[]
): actionTypes.SubmitApprovalConditions => ({
  type: actionTypes.SUBMIT_APPROVAL_CONDITIONS,
  assessmentId,
  conditions,
});

export const submitApprovalConditionsSuccess = (): actionTypes.SubmitApprovalConditionsSuccess => ({
  type: actionTypes.SUBMIT_APPROVAL_CONDITIONS_SUCCESS,
});

export const submitApprovalConditionsFailed = (): actionTypes.SubmitApprovalConditionsFailed => ({
  type: actionTypes.SUBMIT_APPROVAL_CONDITIONS_FAILED,
});

export const submitApplicationToLms = (
  assessmentId: string
): actionTypes.SubmitApplicationToLms => ({
  type: actionTypes.SUBMIT_APPLICATION_TO_LMS,
  assessmentId,
});

export const submitApplicationToLmsSuccess = (): actionTypes.SubmitApplicationToLmsSuccess => ({
  type: actionTypes.SUBMIT_APPLICATION_TO_LMS_SUCCESS,
});

export const submitApplicationToLmsFailed = (): actionTypes.SubmitApplicationToLmsFailed => ({
  type: actionTypes.SUBMIT_APPLICATION_TO_LMS_FAILED,
});

export const getAssessmentExtras = (
  assessmentId: string,
  data: GetAssessmentExtrasPayload
): actionTypes.GetAssessmentExtras => ({
  type: actionTypes.GET_ASSESSMENT_EXTRAS,
  assessmentId,
  data,
});

export const getAssessmentExtrasSuccess = (
  response: GetAssessmentExtrasResponse
): actionTypes.GetAssessmentExtrasSuccess => ({
  type: actionTypes.GET_ASSESSMENT_EXTRAS_SUCCESS,
  response,
});

export const getAssessmentExtrasFailed = (): actionTypes.GetAssessmentExtrasFailed => ({
  type: actionTypes.GET_ASSESSMENT_EXTRAS_FAILED,
});

export const resetAssessmentApplication = (): actionTypes.ResetAssessmentApplication => ({
  type: actionTypes.RESET_ASSESSMENT_APPLICATION,
});

export const getReportDocuments = (
  assessmentId: string,
  payload: DocumentListRequest
): actionTypes.GetReportsDocuments => ({
  type: actionTypes.GET_REPORTS_DOCUMENTS,
  assessmentId,
  payload,
});

export const getReportDocumentsSuccess = (
  response: ListResponse<AssessmentDocument>
): actionTypes.GetReportDocumentsSuccess => ({
  type: actionTypes.GET_REPORTS_DOCUMENTS_SUCCESS,
  response,
});

export const getReportDocumentsFailed = (): actionTypes.GetReportDocumentsFailed => ({
  type: actionTypes.GET_REPORTS_DOCUMENTS_FAILED,
});
