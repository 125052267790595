import { applicationSagas } from "@quest-finance/quest-fe-shared/dist/application";
import { authSagas } from "@quest-finance/quest-fe-shared/dist/auth";
import { errorHandlerSagas } from "@quest-finance/quest-fe-shared/dist/error-handler";
import { userSagas } from "@quest-finance/quest-fe-shared/dist/user";
import { versionManagerSagas } from "@quest-finance/quest-fe-shared/dist/version-manager";
import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import { assessmentSagas } from "../assessment";
import { assessmentSagas as creditEngine } from "../assessment-v2";
import { entitySagas } from "../entity";
import { funderSagas } from "../funder";
import { iamSagas } from "../iam";
import { workflowSagas } from "../workflow";

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  yield all([
    fork(applicationSagas),
    fork(authSagas),
    fork(assessmentSagas),
    fork(creditEngine),
    fork(iamSagas),
    fork(errorHandlerSagas),
    fork(userSagas),
    fork(entitySagas),
    fork(funderSagas),
    fork(versionManagerSagas),
    fork(workflowSagas),
  ]);
}
