import {
  AuditLogListRequest,
  AuditLogResponse,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import * as actionTypes from "../types/auditLogs";

export const getEntityAuditLogs = (
  payload: AuditLogListRequest
): actionTypes.GetEntityAuditLog => ({
  type: actionTypes.GET_ENTITY_AUDIT_LOG,
  payload,
});

export const getEntityAuditLogsSuccess = (
  auditLogList: ListResponse<AuditLogResponse>
): actionTypes.GetEntityAuditLogSuccess => ({
  type: actionTypes.GET_ENTITY_AUDIT_LOG_SUCCESS,
  auditLogList,
});

export const getEntityAuditLogsFailed = (): actionTypes.GetEntityAuditLogFailed => ({
  type: actionTypes.GET_ENTITY_AUDIT_LOG_FAILED,
});

export const resetEntityAuditLogState = (): actionTypes.ResetEntityAuditLogState => ({
  type: actionTypes.RESET_ENTITY_AUDIT_LOG_STATE,
});
