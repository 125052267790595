import joi from "joi";

export const funderDetailsSchema = joi.object({
  funderId: joi.number().allow(""),
  utilisationNumber: joi.string().allow(""),
  accountName: joi.string().max(100).allow(""),
  accountNumber: joi
    .string()
    .min(6)
    .max(9)
    .pattern(/^[0-9]+$/)
    .allow("")
    .messages({
      "string.min": "Account number can only have a minimum of 6 digits",
      "string.max": "Account number can only have a maximum of 9 digits",
      "string.pattern.base": "Account number should only contain numbers",
    }),
  bsb: joi
    .string()
    .length(6)
    .pattern(/^[0-9]+$/)
    .allow("")
    .messages({
      "string.length": "BSB should be 6 characters long",
      "string.pattern.base": "BSB should only contain numbers",
    }),
});
