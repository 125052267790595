import React from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Collapse, CollapseProps } from "antd";
import classNames from "classnames";
import "./Collapse.scss";

const QfCollapse: React.FunctionComponent<CollapseProps> = (
  props: CollapseProps
) => {
  const { className, ...restProps } = props;
  return (
    <Collapse
      {...restProps}
      className={classNames(className, "qf-collapse")}
      expandIcon={({ isActive }) =>
        isActive ? <UpOutlined /> : <DownOutlined />
      }
    />
  );
};

export default QfCollapse;
