import "./TaskNoteForm.scss";
import React, { useEffect, useState } from "react";
import RichTextEditor from "@quest-finance/quest-fe-shared/dist/common/components/RichTextEditor/RichTextEditor";
import { Button, Col, Row } from "antd";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../common/utils/error";
import { updateTaskNote } from "../../actions/creators/taskNote";
import { useTaskNoteDispatch } from "../../dispatchers";
import WorkflowTaskNoteService from "../../services/TaskNoteService";
import { TaskNoteAttributes } from "../../types/TaskNote";

type WorkflowTaskNotesFormProps = {
  workflowId: number;
  workflowTaskId: number;
  note?: TaskNoteAttributes;
  onSave?: (note: TaskNoteAttributes) => void;
  onCancel?: () => void;
  disabled?: boolean;
};

const WorkflowTaskNotesForm: React.FunctionComponent<WorkflowTaskNotesFormProps> = ({
  workflowId,
  workflowTaskId,
  onSave,
  onCancel,
  note,
  disabled,
}: WorkflowTaskNotesFormProps) => {
  const [noteText, setNoteText] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const taskNoteDispatch = useTaskNoteDispatch();

  useEffect(() => {
    setNoteText(note?.note || "");
  }, [note]);

  useEffect(() => {
    setNoteText("");
  }, [workflowTaskId]);

  const onInputChange = (value: string) => setNoteText(value);

  const handleSaveNote = async () => {
    if (!noteText) {
      return showErrorPopUp("Note content is required.");
    }

    let savedNote;

    setSubmitLoading(true);

    try {
      if (note) {
        const { data } = await WorkflowTaskNoteService.update(
          workflowId,
          workflowTaskId,
          note.id,
          {
            note: noteText,
          }
        );
        savedNote = data;
      } else {
        const { data } = await WorkflowTaskNoteService.create(
          workflowId,
          workflowTaskId,
          {
            note: noteText,
          }
        );
        savedNote = data;
      }
      setNoteText("");

      taskNoteDispatch(updateTaskNote(savedNote));

      if (onSave) onSave(savedNote);

      showSuccessPopUp("Successfully saved your note");
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  return !disabled ? (
    <Row className="workflow-task-note-form mb-3">
      <Col span={24}>
        <RichTextEditor
          value={noteText}
          onChange={onInputChange}
          toolbar={false}
          className="note-tasks-field"
        />
        <Button
          onClick={handleSaveNote}
          className="qf-btn-green mr-3"
          loading={submitLoading}
          disabled={disabled}
        >
          {note ? "Update" : "Add"} note
        </Button>
        {note && <Button onClick={onCancel}>Cancel</Button>}
      </Col>
    </Row>
  ) : null;
};

export default WorkflowTaskNotesForm;
