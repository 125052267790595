import {
  AuditOutlined,
  CalculatorOutlined,
  CarOutlined,
  EditOutlined,
  ExceptionOutlined,
  FileOutlined,
  UserOutlined,
  DiffOutlined,
} from "@ant-design/icons";
import { Slug, NavItems } from "../types/Navigation";

export const NAV_ITEMS: NavItems = {
  [Slug.QUOTE]: {
    label: "Quote",
    Icon: CalculatorOutlined,
  },
  [Slug.APPLICANT_GUARANTORS]: {
    label: "Applicant & Guarantors",
    Icon: UserOutlined,
  },
  [Slug.REF_STATEMENTS]: {
    label: "Ref & Statements",
    Icon: FileOutlined,
  },
  [Slug.SECURITY]: {
    label: "Security",
    Icon: CarOutlined,
  },
  [Slug.NOTES_CONTRACTS]: {
    label: "Notes & Contracts",
    Icon: EditOutlined,
  },
  [Slug.DECISION]: {
    label: "Decision",
    Icon: AuditOutlined,
  },
  [Slug.SETTLEMENT]: {
    label: "Settlement",
    Icon: ExceptionOutlined,
  },
  [Slug.AUDIT_LOG]: {
    label: "Audit Log",
    Icon: DiffOutlined,
  },
};
