import "./CreditScore.scss";
import React, { useCallback, useEffect, useState } from "react";
import {
  LoadingOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  SaveOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { ERROR_CODES } from "@quest-finance/quest-fe-shared/dist/error-handler";
import { Button, Col, Form, Input, Popconfirm, Popover, Row } from "antd";
import { AxiosError } from "axios";
import cs from "classnames";
import lodash from "lodash";
import { useSelector } from "react-redux";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../../common/components/ShowNotification/showNotification";
import { DATE_LIST_FORMAT } from "../../../../common/contstants/app";
import { processError } from "../../../../common/utils/error";
import {
  getAssessmentExtras,
  getAssessmentExtrasSuccess,
} from "../../../actions/creators/assessmentExtras";
import {
  APPLICANT_SCORE_KEY,
  APPLICANT_EQUIFAX_ENQUIRY_KEY,
  APPLICANT_EQUIFAX_TRADING_HISTORY_KEY,
  APPLICANT_EQUIFAX_INDEPTH_TRADING_HISTORY_KEY,
  FIRST_TIME_REQUEST_MESSAGE,
  SUBSEQUENT_REQUEST_MESSAGE,
} from "../../../constants/assessmentExtras";

import { useAssessmentExtrasDispatch } from "../../../dispatchers";
import {
  assessmentExtrasDataSelector,
  assessmentExtrasStatusSelector,
} from "../../../selectors/assessmentExtras";
import AssessmentExtrasService from "../../../services/AssessmentExtrasService";
import CreditService from "../../../services/CreditService";
import {
  GetAssessmentExtrasResponse,
  ScoreResponse,
} from "../../../types/AssessmentExtras";
import { EquifaxApplyReportResponse } from "../../../types/CreditReference";

const enum REPORT_TYPES {
  SCORED_ENQUIRY = "SCORED_ENQUIRY",
  TRADING_HISTORY = "TRADING_HISTORY",
  INDEPTH_TRADING_HISTORY = "INDEPTH_TRADING_HISTORY",
}

type EquifaxButtonType = {
  equifaxData: ScoreResponse;
  assessmentId: string;
  applicantEntityId: number;
  reportType: REPORT_TYPES;
  children?: React.ReactChild;
  className?: string;
};

const EquifaxButton: React.FC<EquifaxButtonType> = ({
  equifaxData,
  assessmentId,
  applicantEntityId,
  reportType,
  children,
  className,
}: EquifaxButtonType) => {
  const hasData = !lodash.isEmpty(equifaxData);
  const [equifaxRequestLoading, setEquifaxRequestLoading] = useState(false);
  const [refreshReportLoading, setRefreshReportLoading] = useState(false);
  const assessmentExtrasDispatch = useAssessmentExtrasDispatch();
  const showRedownload =
    reportType === REPORT_TYPES.INDEPTH_TRADING_HISTORY && hasData;

  const handleEquifaxError = (apiError: AxiosError) => {
    if (apiError.response?.data.errorCode === ERROR_CODES.TOO_MANY_REQUESTS) {
      showErrorPopUp(
        "An ongoing request is still in progress. It will be completed short while."
      );
    } else {
      processError(apiError, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    }
  };

  const sendEquifaxRequest = async () => {
    setEquifaxRequestLoading(true);

    try {
      let response;

      if (reportType === REPORT_TYPES.TRADING_HISTORY) {
        response = (await CreditService.getEquifaxTradingHistoryReport(
          assessmentId,
          [applicantEntityId]
        )) as EquifaxApplyReportResponse;
      } else if (reportType === REPORT_TYPES.INDEPTH_TRADING_HISTORY) {
        response = (await CreditService.getEquifaxTradingHistoryReport(
          assessmentId,
          [applicantEntityId],
          true
        )) as EquifaxApplyReportResponse;
      } else {
        response = (await CreditService.getEquifaxScoredEnquiryReport(
          assessmentId,
          [applicantEntityId]
        )) as EquifaxApplyReportResponse;
      }

      const errorData = response.data.errors;

      if (errorData.length > 0) {
        const error = errorData[0];
        error.errors.forEach((err, index) => {
          const key = `error${index}`;
          showErrorPopUp(err, key);
        });
      } else {
        assessmentExtrasDispatch(
          getAssessmentExtras(assessmentId, {
            [APPLICANT_SCORE_KEY]: ["equifax"],
            [APPLICANT_EQUIFAX_ENQUIRY_KEY]: ["score"],
            [APPLICANT_EQUIFAX_TRADING_HISTORY_KEY]: ["score"],
            [APPLICANT_EQUIFAX_INDEPTH_TRADING_HISTORY_KEY]: ["score"],
          })
        );
        showSuccessPopUp(
          `Successfully retrieved score/documents for Applicant`
        );
      }
    } catch (error) {
      handleEquifaxError(error as AxiosError);
    } finally {
      setEquifaxRequestLoading(false);
    }
  };

  const refreshIndepthReport = async () => {
    if (refreshReportLoading) return;

    setRefreshReportLoading(true);

    try {
      await CreditService.refreshIndepthReport(assessmentId);
      showSuccessPopUp(`Successfully refreshed In-depth history.`);
    } catch (error) {
      handleEquifaxError(error as AxiosError);
    } finally {
      setRefreshReportLoading(false);
    }
  };

  return (
    <Row gutter={[16, 0]}>
      <Col>
        <Popconfirm
          getPopupContainer={() =>
            document.querySelector(".applicant-credit-score") as HTMLElement
          }
          title={
            <div className="confirm-message">
              <div>
                {hasData
                  ? SUBSEQUENT_REQUEST_MESSAGE
                  : FIRST_TIME_REQUEST_MESSAGE}
              </div>
              {equifaxData.updatedAt && (
                <strong>{`Last request was on ${dateFormat(
                  new Date(equifaxData.updatedAt),
                  DATE_LIST_FORMAT
                )}`}</strong>
              )}
            </div>
          }
          onConfirm={() => {
            sendEquifaxRequest();
          }}
          disabled={equifaxRequestLoading || refreshReportLoading}
        >
          <Button
            className={cs(
              className,
              "equifax-btn in-depth",
              hasData ? "qf-btn-gray-invert" : "qf-btn-green"
            )}
            loading={equifaxRequestLoading}
            disabled={refreshReportLoading}
          >
            {children}
          </Button>
        </Popconfirm>
      </Col>
      {showRedownload && (
        <Col>
          <Popconfirm
            getPopupContainer={() =>
              document.querySelector(".applicant-credit-score") as HTMLElement
            }
            title={
              <div className="confirm-message">
                Sure you want to refresh in-depth history report?
              </div>
            }
            onConfirm={() => {
              refreshIndepthReport();
            }}
            disabled={equifaxRequestLoading || refreshReportLoading}
          >
            <Button
              className="equifax-btn qf-btn-green"
              disabled={equifaxRequestLoading}
            >
              {refreshReportLoading ? (
                <SyncOutlined spin />
              ) : (
                <ReloadOutlined />
              )}
            </Button>
          </Popconfirm>
        </Col>
      )}
    </Row>
  );
};

type CreditSectionType = {
  assessmentId: string;
  applicantEntityId: number;
};

const CreditSection: React.FC<CreditSectionType> = ({
  assessmentId,
  applicantEntityId,
}: CreditSectionType) => {
  const [equifaxScore, setEquifaxScore] = useState("");
  const [isSavingEquifax, setSavingEquifax] = useState(false);
  const assessmentExtras = useSelector(assessmentExtrasDataSelector);
  const assessmentExtrasStatus = useSelector(assessmentExtrasStatusSelector);
  const assessmentExtrasDispatch = useAssessmentExtrasDispatch();
  const equifaxScoredEnquiryData = lodash.get(
    assessmentExtras,
    APPLICANT_EQUIFAX_ENQUIRY_KEY,
    {}
  ) as ScoreResponse;
  const equifaxTradingHistoryData = lodash.get(
    assessmentExtras,
    APPLICANT_EQUIFAX_TRADING_HISTORY_KEY,
    {}
  ) as ScoreResponse;
  const equifaxIndepthTradingHistoryData = lodash.get(
    assessmentExtras,
    APPLICANT_EQUIFAX_INDEPTH_TRADING_HISTORY_KEY,
    {}
  ) as ScoreResponse;
  const setScore = useCallback((data: GetAssessmentExtrasResponse) => {
    const equifaxScore = lodash.get(
      data[APPLICANT_SCORE_KEY],
      "equifax",
      ""
    ) as string;

    setEquifaxScore(equifaxScore);
  }, []);

  useEffect(() => {
    if (assessmentExtras) {
      setScore(assessmentExtras);
    }
  }, [assessmentExtras, setScore]);

  const handleSaveEquifax = async () => {
    setSavingEquifax(true);

    try {
      const { data } = await AssessmentExtrasService.setData(assessmentId, [
        {
          type: APPLICANT_SCORE_KEY,
          data: {
            equifax: equifaxScore,
          },
        },
      ]);

      assessmentExtrasDispatch(getAssessmentExtrasSuccess(data));

      showSuccessPopUp(`Successfully saved applicant credit score`);
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setSavingEquifax(false);
    }
  };

  const popoverContent = (
    <div>
      Which Equifax report should I run?
      <ul>
        <li>
          <b>Scored Enquiry</b>: Equifax report containing company details only
          (~$20)
        </li>
        <li>
          <b>Trading History</b>: Equifax report containing company + directors
          details (~$60)
        </li>
        <li>
          <b>In-depth History</b>: Equifax report containing company + directors
          details + directors related interests (~$80)
        </li>
        <li>
          <b>Equifax Apply</b>: Equifax report containing individual details
          only (~$10)
        </li>
      </ul>
      <p>
        Note: The cost figures are not accurate and for rough comparison purpose
        only.
        <br />
        Once the credit report request is completed, the credit file can be
        found in <b>Notes & Contracts</b> tab under{" "}
        <b>Supporting Documents - Internal</b> section.
      </p>
    </div>
  );

  return (
    <div className="applicant-credit-score">
      <div className="section-header">Credit scores</div>
      <Form layout="vertical">
        <Row gutter={[16, 0]} align="bottom">
          <Col xxl={3}>
            <Form.Item label="Equifax">
              <Input
                value={equifaxScore}
                disabled={assessmentExtrasStatus === LOADING_STATUS.LOADING}
                onChange={(event) => {
                  setEquifaxScore(event.target.value);
                }}
                suffix={isSavingEquifax && <LoadingOutlined />}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button
                disabled={
                  assessmentExtrasStatus === LOADING_STATUS.LOADING ||
                  isSavingEquifax
                }
                onClick={handleSaveEquifax}
                className="score-button"
              >
                <SaveOutlined />
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item className="credit-button">
              <EquifaxButton
                assessmentId={assessmentId}
                applicantEntityId={applicantEntityId}
                reportType={REPORT_TYPES.SCORED_ENQUIRY}
                equifaxData={equifaxScoredEnquiryData}
              >
                Scored Enquiry
              </EquifaxButton>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item className="credit-button">
              <EquifaxButton
                assessmentId={assessmentId}
                applicantEntityId={applicantEntityId}
                reportType={REPORT_TYPES.TRADING_HISTORY}
                equifaxData={equifaxTradingHistoryData}
              >
                Trading History
              </EquifaxButton>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item className="credit-button">
              <EquifaxButton
                assessmentId={assessmentId}
                applicantEntityId={applicantEntityId}
                reportType={REPORT_TYPES.INDEPTH_TRADING_HISTORY}
                equifaxData={equifaxIndepthTradingHistoryData}
              >
                In-depth History
              </EquifaxButton>
            </Form.Item>
          </Col>
          <Col>
            <Popover content={popoverContent} title={null} placement="top">
              <QuestionCircleOutlined className="popover-icon" />
            </Popover>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreditSection;
