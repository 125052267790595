import {
  AuditLogListRequest,
  AuditLogResponse,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import {
  LoginHistoriesRequest,
  LoginHistoryResponse,
} from "../../types/LoginHistory";
import { UserForm } from "../../types/User";
import { UserResponse } from "../../types/UserResponse";
import * as types from "../types/userAdmin";

export const getUserDetails = (userId?: number): types.GetUserDetails => ({
  type: types.GET_USER_DETAILS,
  userId,
});

export const getUserDetailsSuccess = (
  user: UserResponse
): types.GetUserDetailsSuccess => ({
  type: types.GET_USER_DETAILS_SUCCESS,
  user,
});

export const getUserDetailsFailed = (): types.GetUserDetailsFailed => ({
  type: types.GET_USER_DETAILS_FAILED,
});

export const saveUser = (user: UserForm, userId?: number): types.SaveUser => ({
  type: types.SAVE_USER,
  user,
  userId,
});

export const saveUserSuccess = (user: UserResponse): types.SaveUserSuccess => ({
  type: types.SAVE_USER_SUCCESS,
  user,
});

export const saveUserFailed = (): types.SaveUserFailed => ({
  type: types.SAVE_USER_FAILED,
});

export const resetFormState = (): types.ResetFormState => ({
  type: types.RESET_FORM_STATE,
});

export const updateUserForm = (
  userForm: Partial<UserForm>
): types.UpdateUserForm => ({
  type: types.UPDATE_USER_FORM,
  userForm,
});

export const assignRoles = (
  userId: number,
  roleIds: number[]
): types.AssignRoles => ({
  type: types.ASSIGN_ROLES,
  userId,
  roleIds,
});

export const assignRolesSuccess = (): types.AssignRolesSuccess => ({
  type: types.ASSIGN_ROLES_SUCCESS,
});

export const assignRolesFailed = (): types.AssignRolesFailed => ({
  type: types.ASSIGN_ROLES_FAILED,
});

export const getUserAuditLogs = (
  payload: AuditLogListRequest
): types.GetUserAuditLogs => ({
  type: types.GET_USER_AUDIT_LOGS,
  payload,
});

export const getUserAuditLogsSuccess = (
  auditLogs: ListResponse<AuditLogResponse>
): types.GetUserAuditLogsSuccess => ({
  type: types.GET_USER_AUDIT_LOGS_SUCCESS,
  auditLogs,
});

export const getUserAuditLogsFailed = (): types.GetUserAuditLogsFailed => ({
  type: types.GET_USER_AUDIT_LOGS_FAILED,
});

export const getLoginHistories = (
  payload: LoginHistoriesRequest
): types.GetLoginHistories => ({
  type: types.GET_LOGIN_HISTORIES,
  payload,
});

export const getLoginHistoriesSuccess = (
  loginHistories: ListResponse<LoginHistoryResponse>
): types.GetLoginHistoriesSuccess => ({
  type: types.GET_LOGIN_HISTORIES_SUCCESS,
  loginHistories,
});

export const getLoginHistoriesFailed = (): types.GetLoginHistoriesFailed => ({
  type: types.GET_LOGIN_HISTORIES_FAILED,
});
