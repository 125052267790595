import { Action } from "redux";
import { AssessmentListRequest } from "../../types/AssessmentListRequest";
import { AssessmentResponse } from "../../types/AssessmentResponse";

export const GET_ASSESSMENT_LIST = "GET_ASSESSMENT_LIST";
export interface GetAssessmentList extends Action {
  type: typeof GET_ASSESSMENT_LIST;
  payload: AssessmentListRequest;
}

export const GET_ASSESSMENT_LIST_SUCCESS = "GET_ASSESSMENT_LIST_SUCCESS";
export interface GetAssessmentListSuccess extends Action {
  type: typeof GET_ASSESSMENT_LIST_SUCCESS;
  assessments: AssessmentResponse[];
  assessmentCount: number;
}

export const GET_ASSESSMENT_LIST_ERROR = "GET_ASSESSMENT_LIST_ERROR";
export interface GetAssessmentListError extends Action {
  type: typeof GET_ASSESSMENT_LIST_ERROR;
}

export type AssessmentListActions =
  | GetAssessmentList
  | GetAssessmentListSuccess
  | GetAssessmentListError;
