import { UserResponse } from "./UserResponse";

export interface UserListState {
  users: UserResponse[];
  userCount: number;
  isLoading: boolean;
  page?: number | null;
  order?: string | null;
  limit?: number | null;
  offset?: number | null;
  resetPage?: boolean | null;
}

export const userListDefaultValue: UserListState = {
  users: [],
  userCount: 0,
  isLoading: false,
  page: null,
  order: null,
  limit: null,
  offset: null,
  resetPage: null,
};
