import { Action } from "redux";
import { FunderRequest, FunderResponse } from "../../types/Funder";

export const GET_FUNDER_DETAILS = "GET_FUNDER_DETAILS";
export interface GetFunderDetails extends Action {
  type: typeof GET_FUNDER_DETAILS;
  assessmentId: string;
}

export const GET_FUNDER_DETAILS_SUCCESS = "GET_FUNDER_DETAILS_SUCCESS";
export interface GetFunderDetailsSuccess extends Action {
  type: typeof GET_FUNDER_DETAILS_SUCCESS;
  funder: FunderResponse | null;
}

export const GET_FUNDER_DETAILS_FAILED = "GET_FUNDER_DETAILS_FAILED";
export interface GetFunderDetailsFailed extends Action {
  type: typeof GET_FUNDER_DETAILS_FAILED;
}

export const SAVE_FUNDER = "SAVE_FUNDER";
export interface SaveFunder extends Action {
  type: typeof SAVE_FUNDER;
  assessmentId: string;
  data: FunderRequest;
}

export const SAVE_FUNDER_SUCCESS = "SAVE_FUNDER_SUCCESS";
export interface SaveFunderSuccess extends Action {
  type: typeof SAVE_FUNDER_SUCCESS;
}

export const SAVE_FUNDER_FAILED = "SAVE_FUNDER_FAILED";
export interface SaveFunderFailed extends Action {
  type: typeof SAVE_FUNDER_FAILED;
}

export const RESET_FUNDER_DETAILS_STATE = "RESET_FUNDER_DETAILS_STATE";
export interface ResetFunderDetailsState extends Action {
  type: typeof RESET_FUNDER_DETAILS_STATE;
}

export type FunderDetailsActions =
  | GetFunderDetails
  | GetFunderDetailsSuccess
  | GetFunderDetailsFailed
  | SaveFunder
  | SaveFunderSuccess
  | SaveFunderFailed
  | ResetFunderDetailsState;
