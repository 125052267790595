import {
  AuditService,
  AuditLogResponse,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import {
  createUploadFileChannel,
  DocumentService,
  UploadChannelEmittedData,
} from "@quest-finance/quest-fe-shared/dist/files";
import { actionCreator as notificationAction } from "@quest-finance/quest-fe-shared/dist/notification";
import { AxiosError } from "axios";
import { EventChannel } from "redux-saga";
import {
  put,
  call,
  ForkEffect,
  takeLatest,
  takeEvery,
  take,
} from "redux-saga/effects";
import { InternalNotesResponse } from "../../common/internal-notes/types/InternalNotesResponse";
import { InternalNotesSubmitRequest } from "../../common/internal-notes/types/InternalNotesSubmitRequest";
import * as actionCreators from "../actions/creators/assessmentAdmin";
import * as actionTypes from "../actions/types/assessmentAdmin";
import { NOTIFICATION_IDS } from "../constants/notificationIds";
import AssessmentService from "../services/AssessmentService";
import InternalNotesService from "../services/InternalNotesService";
import { AssessmentDocument } from "../types/AssessmentDocument";
import { AssessmentResponse } from "../types/AssessmentResponse";

const { clearNotification, setNotification } = notificationAction;
function* getAssessmentDetailsWorker({
  assessmentId,
}: actionTypes.GetAssessmentDetails) {
  try {
    const result: SingleResponse<AssessmentResponse> = yield call(
      AssessmentService.getAssessmentDetails,
      assessmentId
    );
    yield put(actionCreators.getAssessmentDetailsSuccess(result.data));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.ASSESSMENT_DETAILS_ERROR,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(actionCreators.getAssessmentDetailsFailed());
  }
}

function* getInternalNotesListWorker({
  assessmentId,
  payload,
}: actionTypes.GetInternalNotesList) {
  try {
    const result: ListResponse<InternalNotesResponse> = yield call(
      InternalNotesService.getInternalNotes,
      assessmentId,
      payload
    );
    yield put(actionCreators.getInternalNotesListSuccess(result));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.INTERNAL_NOTES_ERROR,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(actionCreators.getInternalNotesListError());
  }
}

function* submitInternalNotesWorker({
  assessmentId,
  note,
}: actionTypes.SubmitInternalNotes) {
  try {
    yield put(clearNotification());
    const data: InternalNotesSubmitRequest = {
      note,
    };
    yield call(InternalNotesService.submitInternalNotes, assessmentId, data);
    yield put(actionCreators.submitInternalNotesSuccess());
    yield put(actionCreators.getAssessmentDetails(assessmentId));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.INTERNAL_NOTES_ERROR,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(actionCreators.submitInternalNotesFailed());
  }
}

function* uploadDocumentWorker({
  document,
  assessmentId,
  purpose,
}: actionTypes.UploadAssessmentDocument) {
  const uploadChannel: EventChannel<UploadChannelEmittedData<
    AssessmentDocument
  >> = yield call(
    createUploadFileChannel,
    `/assessment/assessments/${assessmentId}/documents`,
    {
      document,
      purpose,
    }
  );
  try {
    while (true) {
      const {
        progress,
        result,
        error,
      }: UploadChannelEmittedData<AssessmentDocument> = yield take(
        uploadChannel
      );
      if (progress) {
        yield put(actionCreators.setUploadProgress(progress));
      }
      if (result) {
        yield put(actionCreators.uploadAssessmentDocumentSuccess(result.data));
        yield put(actionCreators.setUploadProgress(0));
        yield put(actionCreators.getAssessmentDetails(assessmentId));
      }
      if (error) {
        throw error;
      }
    }
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.DOCUMENT_ERROR,
        body,
        className: "qst-notif-danger",
      })
    );
  }
}

function* deleteAssessmentDocumentWorker({
  assessmentId,
  documentId,
}: actionTypes.DeleteAssessmentDocument) {
  try {
    yield put(clearNotification());
    yield call(
      DocumentService.deleteDocument,
      `/assessment/assessments/${assessmentId}/documents/${documentId}`
    );
    yield put(actionCreators.deleteAssessmentDocumentSuccess());
    yield put(actionCreators.getAssessmentDetails(assessmentId));
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.DOCUMENT_ERROR,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(actionCreators.deleteAssessmentDocumentFailed());
  }
}

function* updateAssessmentStatusWorker({
  assessmentId,
  status,
}: actionTypes.UpdateAssessmentStatus) {
  try {
    yield put(clearNotification());
    yield call(AssessmentService.updateAssessmentStatus, assessmentId, status);
    yield put(actionCreators.updateAssessmentStatusSuccess());
    yield put(actionCreators.getAssessmentDetails(assessmentId));
  } catch (error) {
    const body = processErrorMessage(error as AxiosError);
    yield put(
      setNotification({
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(actionCreators.updateAssessmentStatusFailed());
  }
}

function* getApplicationAuditLogWorker({
  payload,
}: actionTypes.GetApplicationAuditLog) {
  try {
    const result: ListResponse<AuditLogResponse> = yield call(
      AuditService.getAuditList,
      payload
    );
    yield put(actionCreators.getApplicationAuditLogSuccess(result));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(actionCreators.getApplicationAuditLogFailed());
  }
}

function* getAssessmentAuditLogLogWorker({
  payload,
}: actionTypes.GetAssessmentAuditLog) {
  try {
    const result: ListResponse<AuditLogResponse> = yield call(
      AuditService.getAuditList,
      payload
    );
    yield put(actionCreators.getAssessmentAuditLogSuccess(result));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(actionCreators.getAssessmentAuditLogFailed());
  }
}

function* getAccreditationAuditLogWorker({
  payload,
}: actionTypes.GetAssessmentAuditLog) {
  try {
    const result: ListResponse<AuditLogResponse> = yield call(
      AuditService.getAuditList,
      payload
    );
    yield put(actionCreators.getAccreditationAuditLogSuccess(result));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(actionCreators.getAccreditationAuditLogFailed());
  }
}

function* getFunderAuditLogWorker({ payload }: actionTypes.GetFunderAuditLogs) {
  try {
    const result: ListResponse<AuditLogResponse> = yield call(
      AuditService.getAuditList,
      payload
    );
    yield put(actionCreators.getFunderAuditLogSuccess(result));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(actionCreators.getFunderAuditLogFailed());
  }
}

function* setAssigneeWorker({
  assessmentId,
  assigneeRequest,
}: actionTypes.SetAssignee) {
  try {
    yield put(clearNotification());
    const { data }: SingleResponse<AssessmentResponse> = yield call(
      AssessmentService.setAssignee,
      assessmentId,
      assigneeRequest
    );
    yield put(actionCreators.setAssigneeSuccess(data));
    yield put(
      notificationAction.setNotification({
        id: NOTIFICATION_IDS.ASSIGNEE_UPDATED,
        className: "qst-notif-success",
        body: "Assignee has been successfully updated",
      })
    );
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(actionCreators.setAssigneeFailed());
  }
}

function* watchAdminData(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    actionTypes.GET_ASSESSMENT_DETAILS,
    getAssessmentDetailsWorker
  );
  yield takeLatest(
    actionTypes.GET_INTERNAL_NOTES_LIST,
    getInternalNotesListWorker
  );
  yield takeLatest(
    actionTypes.SUBMIT_INTERNAL_NOTES,
    submitInternalNotesWorker
  );
  yield takeEvery(actionTypes.UPLOAD_ASSESSMENT_DOCUMENT, uploadDocumentWorker);
  yield takeLatest(
    actionTypes.DELETE_ASSESSMENT_DOCUMENT,
    deleteAssessmentDocumentWorker
  );
  yield takeLatest(
    actionTypes.UPDATE_ASSESSMENT_STATUS,
    updateAssessmentStatusWorker
  );
  yield takeLatest(
    actionTypes.GET_APPLICATION_AUDIT_LOG,
    getApplicationAuditLogWorker
  );
  yield takeLatest(
    actionTypes.GET_ASSESSMENT_AUDIT_LOG,
    getAssessmentAuditLogLogWorker
  );
  yield takeLatest(
    actionTypes.GET_ACCREDITATION_AUDIT_LOG,
    getAccreditationAuditLogWorker
  );
  yield takeLatest(actionTypes.GET_FUNDER_AUDIT_LOGS, getFunderAuditLogWorker);
  yield takeLatest(actionTypes.SET_ASSIGNEE, setAssigneeWorker);
}

export default watchAdminData;
