import "./TaskNoteList.scss";
import React, { useCallback, useEffect, useState } from "react";
import {
  EditFilled,
  DeleteFilled,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { getUserDataSelector } from "@quest-finance/quest-fe-shared/dist/auth";
import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { getOffset } from "@quest-finance/quest-fe-shared/dist/common/utils/dataTable";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { Button, Col, Popconfirm, Row, Table, Tooltip } from "antd";
import { ColumnsType, TableProps } from "antd/lib/table";
import { useSelector } from "react-redux";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import WordLimitter, {
  CONTENT_TYPE,
} from "../../../common/components/WordLimitter/WordLimitter";
import {
  DATE_LIST_FORMAT,
  SHORT_DATE_FORMAT,
} from "../../../common/contstants/app";
import { processError } from "../../../common/utils/error";
import { getTaskNotes } from "../../actions/creators/taskNote";
import { useTaskNoteDispatch } from "../../dispatchers";
import {
  taskNotesCountSelector,
  taskNotesSelector,
  taskNoteStatusSelector,
} from "../../selectors/taskNote";
import TaskNoteService from "../../services/TaskNoteService";
import { TaskNoteAttributes } from "../../types/TaskNote";

type WorkflowTaskNoteListProps = {
  workflowId: number;
  workflowTaskId: number;
  page: number;
  onPageChange: (page: number) => void;
  onNoteUpdate: (note: TaskNoteAttributes) => void;
  readOnly?: boolean;
};

const WorkflowTaskNoteList: React.FunctionComponent<WorkflowTaskNoteListProps> = ({
  workflowId,
  workflowTaskId,
  page,
  onPageChange,
  onNoteUpdate,
  readOnly,
}: WorkflowTaskNoteListProps) => {
  const taskNotes = useSelector(taskNotesSelector);
  const count = useSelector(taskNotesCountSelector);
  const status = useSelector(taskNoteStatusSelector);
  const user = useSelector(getUserDataSelector);
  const [deleting, setDeleting] = useState(false);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [expandedNotes, setExpandedNotes] = useState<number[]>([]);
  const taskNoteDispatch = useTaskNoteDispatch();

  const getTaskNoteList = useCallback(
    (page: number) => {
      taskNoteDispatch(
        getTaskNotes(workflowId, workflowTaskId, {
          limit: DATATABLE_MAX_ROWS,
          offset: getOffset(page, DATATABLE_MAX_ROWS),
        })
      );
    },
    [taskNoteDispatch, workflowId, workflowTaskId]
  );

  useEffect(() => {
    getTaskNoteList(page);
    setIsAllExpanded(false);
  }, [getTaskNoteList, page]);

  const deleteRecord = async (id: number) => {
    setDeleting(true);
    try {
      await TaskNoteService.delete(workflowId, workflowTaskId, id);

      getTaskNoteList(page);

      showSuccessPopUp("Note has been successfully deleted.");
    } catch (e) {
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setDeleting(false);
    }
  };

  const toggleNoteItem = (id: number, expanded: boolean) => {
    setExpandedNotes((previousState) => {
      if (expanded) {
        return [...previousState, id];
      }

      return previousState.filter((noteId) => noteId !== id);
    });
  };

  const toggleExpandedState = (expand: boolean) => {
    setIsAllExpanded(expand);
    setExpandedNotes(() => (expand ? taskNotes.map(({ id }) => id) : []));
  };

  let toggleAll = null;

  if (status === LOADING_STATUS.IDLE && taskNotes.length) {
    toggleAll = (
      <Button
        className="collapse-expand-toggler"
        type="link"
        onClick={() => toggleExpandedState(!isAllExpanded)}
      >
        {isAllExpanded ? "Collapse all" : "Expand all"}
        {isAllExpanded ? <UpOutlined /> : <DownOutlined />}
      </Button>
    );
  }

  const onTableChangeHandler: TableProps<TaskNoteAttributes>["onChange"] = (
    pagination
  ) => {
    onPageChange(pagination.current as number);
    toggleExpandedState(false);
  };

  let columns: ColumnsType<TaskNoteAttributes> = [
    {
      title: "Date",
      className: "date",
      dataIndex: "date",
      sorter: false,
      width: "15%",
      render(_, { createdAt }) {
        const date = new Date(createdAt);
        return (
          <Tooltip title={dateFormat(date, DATE_LIST_FORMAT)}>
            {dateFormat(date, SHORT_DATE_FORMAT)}
          </Tooltip>
        );
      },
    },
    {
      title: "User",
      className: "user",
      width: 40,
      render(_, { creator, userId }) {
        return userId ? (
          <Tooltip title={creator?.firstName + " " + creator?.lastName}>
            {creator?.firstName?.charAt(0) + creator?.lastName?.charAt(0)}
          </Tooltip>
        ) : (
          "System"
        );
      },
    },
    {
      title: "Note",
      className: "note",
      render(_, { id, note }) {
        return (
          <WordLimitter
            text={note}
            maxLines={2}
            expanded={expandedNotes.includes(id)}
            setExpanded={(expanded: boolean) => toggleNoteItem(id, expanded)}
            type={CONTENT_TYPE.HTML}
            className="ql-editor p-0"
          />
        );
      },
    },
    {
      title: "",
      className: "action-btn",
      width: "8%",
      render(_, note) {
        const { id, userId } = note;

        return (
          user?.id === userId && (
            <>
              <Popconfirm
                title="Sure to delete?"
                okText="Yes"
                placement="left"
                cancelText="Cancel"
                onConfirm={() => {
                  deleteRecord(id);
                }}
                arrowPointAtCenter
              >
                <DeleteFilled disabled={readOnly} />
              </Popconfirm>
              <EditFilled
                className="edit-button"
                onClick={() => onNoteUpdate(note)}
                disabled={readOnly}
              />
            </>
          )
        );
      },
    },
  ];

  columns = readOnly ? columns.slice(0, 3) : columns;

  return taskNotes.length ? (
    <Row className="task-note-list">
      <Col span={24}>
        {toggleAll}
        <Table
          className="workflow-notes-table"
          loading={status === LOADING_STATUS.LOADING || deleting}
          columns={columns}
          dataSource={taskNotes}
          pagination={{
            simple: true,
            total: count,
            current: page,
          }}
          onChange={onTableChangeHandler}
          showHeader={false}
          rowKey="id"
          size="small"
        />
      </Col>
    </Row>
  ) : null;
};

export default WorkflowTaskNoteList;
