import {
  AuditLogListRequest,
  AuditLogResponse,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { InternalNotesListRequest } from "../../../common/internal-notes/types/InternalNotesListRequest";
import { InternalNotesResponse } from "../../../common/internal-notes/types/InternalNotesResponse";
import { DOCUMENT_PURPOSES } from "../../constants/documentPurposes";
import { AssessmentAssigneeRequest } from "../../types/AssessmentAssigneeRequest";
import { AssessmentDocument } from "../../types/AssessmentDocument";
import { AssessmentResponse } from "../../types/AssessmentResponse";
import * as actionTypes from "../types/assessmentAdmin";

export const resetAssessmentDetails = (): actionTypes.ResetAssessmentDetails => ({
  type: actionTypes.RESET_ASSESSMENT_DETAILS,
});

export const getAssessmentDetails = (
  assessmentId: string
): actionTypes.GetAssessmentDetails => ({
  type: actionTypes.GET_ASSESSMENT_DETAILS,
  assessmentId,
});

export const getAssessmentDetailsSuccess = (
  assessment: AssessmentResponse
): actionTypes.GetAssessmentDetailsSuccess => ({
  type: actionTypes.GET_ASSESSMENT_DETAILS_SUCCESS,
  assessment,
});

export const getAssessmentDetailsFailed = (): actionTypes.GetAssessmentDetailsFailed => ({
  type: actionTypes.GET_ASSESSMENT_DETAILS_FAILED,
});

export const updateAssessmentStatus = (
  assessmentId: string,
  status: string
): actionTypes.UpdateAssessmentStatus => ({
  type: actionTypes.UPDATE_ASSESSMENT_STATUS,
  assessmentId,
  status,
});

export const updateAssessmentStatusSuccess = (): actionTypes.UpdateAssessmentStatusSuccess => ({
  type: actionTypes.UPDATE_ASSESSMENT_STATUS_SUCCESS,
});

export const updateAssessmentStatusFailed = (): actionTypes.UpdateAssessmentStatusFailed => ({
  type: actionTypes.UPDATE_ASSESSMENT_STATUS_FAILED,
});

export const getInternalNotesList = (
  assessmentId: string,
  payload: InternalNotesListRequest
): actionTypes.GetInternalNotesList => ({
  type: actionTypes.GET_INTERNAL_NOTES_LIST,
  assessmentId,
  payload,
});

export const getInternalNotesListSuccess = (
  listResponse: ListResponse<InternalNotesResponse>
): actionTypes.GetInternalNotesListSuccess => ({
  type: actionTypes.GET_INTERNAL_NOTES_LIST_SUCCESS,
  listResponse,
});

export const getInternalNotesListError = (): actionTypes.GetInternalNotesListError => ({
  type: actionTypes.GET_INTERNAL_NOTES_LIST_ERROR,
});

export const submitInternalNotes = (
  assessmentId: string,
  note: string
): actionTypes.SubmitInternalNotes => ({
  type: actionTypes.SUBMIT_INTERNAL_NOTES,
  assessmentId,
  note,
});

export const submitInternalNotesSuccess = (): actionTypes.SubmitInternalNotesSuccess => ({
  type: actionTypes.SUBMIT_INTERNAL_NOTES_SUCCESS,
});

export const submitInternalNotesFailed = (): actionTypes.SubmitInternalNotesFailed => ({
  type: actionTypes.SUBMIT_INTERNAL_NOTES_FAILED,
});

export const uploadAssessmentDocument = (
  assessmentId: string,
  document: File,
  purpose: DOCUMENT_PURPOSES
): actionTypes.UploadAssessmentDocument => ({
  type: actionTypes.UPLOAD_ASSESSMENT_DOCUMENT,
  assessmentId,
  document,
  purpose,
});

export const setUploadProgress = (
  progress: number
): actionTypes.SetAssessmentUploadProgress => ({
  type: actionTypes.SET_ASSESSMENT_UPLOAD_PROGRESS,
  progress,
});

export const uploadAssessmentDocumentSuccess = (
  document: AssessmentDocument
): actionTypes.UploadAssessmentDocumentSuccess => ({
  type: actionTypes.UPLOAD_ASSESSMENT_DOCUMENT_SUCCESS,
  document,
});

export const deleteAssessmentDocument = (
  assessmentId: string,
  documentId: string
): actionTypes.DeleteAssessmentDocument => ({
  type: actionTypes.DELETE_ASSESSMENT_DOCUMENT,
  assessmentId,
  documentId,
});

export const deleteAssessmentDocumentSuccess = (): actionTypes.DeleteAssessmentDocumentSuccess => ({
  type: actionTypes.DELETE_ASSESSMENT_DOCUMENT_SUCCESS,
});

export const deleteAssessmentDocumentFailed = (): actionTypes.DeleteAssessmentDocumentFailed => ({
  type: actionTypes.DELETE_ASSESSMENT_DOCUMENT_FAILED,
});

export const getApplicationAuditLogs = (
  payload: AuditLogListRequest
): actionTypes.GetApplicationAuditLog => ({
  type: actionTypes.GET_APPLICATION_AUDIT_LOG,
  payload,
});

export const getApplicationAuditLogSuccess = (
  auditLogList: ListResponse<AuditLogResponse>
): actionTypes.GetApplicationAuditLogSuccess => ({
  type: actionTypes.GET_APPLICATION_AUDIT_LOG_SUCCESS,
  auditLogList,
});

export const getApplicationAuditLogFailed = (): actionTypes.GetApplicationAuditLogFailed => ({
  type: actionTypes.GET_APPLICATION_AUDIT_LOG_FAILED,
});

export const getAssessmentAuditLogs = (
  payload: AuditLogListRequest
): actionTypes.GetAssessmentAuditLog => ({
  type: actionTypes.GET_ASSESSMENT_AUDIT_LOG,
  payload,
});

export const getAssessmentAuditLogSuccess = (
  auditLogList: ListResponse<AuditLogResponse>
): actionTypes.GetAssessmentAuditLogSuccess => ({
  type: actionTypes.GET_ASSESSMENT_AUDIT_LOG_SUCCESS,
  auditLogList,
});

export const getAssessmentAuditLogFailed = (): actionTypes.GetAssessmentAuditLogFailed => ({
  type: actionTypes.GET_ASSESSMENT_AUDIT_LOG_FAILED,
});

export const getAccreditationAuditLogs = (
  payload: AuditLogListRequest
): actionTypes.GetAccreditationAuditLog => ({
  type: actionTypes.GET_ACCREDITATION_AUDIT_LOG,
  payload,
});

export const getAccreditationAuditLogSuccess = (
  auditLogList: ListResponse<AuditLogResponse>
): actionTypes.GetAccreditationAuditLogSuccess => ({
  type: actionTypes.GET_ACCREDITATION_AUDIT_LOG_SUCCESS,
  auditLogList,
});

export const getAccreditationAuditLogFailed = (): actionTypes.GetAccreditationAuditLogFailed => ({
  type: actionTypes.GET_ACCREDITATION_AUDIT_LOG_FAILED,
});

export const getFunderAuditLogs = (
  payload: AuditLogListRequest
): actionTypes.GetFunderAuditLogs => ({
  type: actionTypes.GET_FUNDER_AUDIT_LOGS,
  payload,
});

export const getFunderAuditLogSuccess = (
  auditLogList: ListResponse<AuditLogResponse>
): actionTypes.GetFunderAuditLogSuccess => ({
  type: actionTypes.GET_FUNDER_AUDIT_LOGS_SUCCESS,
  auditLogList,
});

export const getFunderAuditLogFailed = (): actionTypes.GetFunderAuditLogFailed => ({
  type: actionTypes.GET_FUNDER_AUDIT_LOG_FAILED,
});

export const setAssignee = (
  assessmentId: string,
  assigneeRequest: AssessmentAssigneeRequest[]
): actionTypes.SetAssignee => ({
  type: actionTypes.SET_ASSIGNEE,
  assessmentId,
  assigneeRequest,
});

export const setAssigneeSuccess = (
  assessment: AssessmentResponse
): actionTypes.SetAssigneeSuccess => ({
  type: actionTypes.SET_ASSIGNEE_SUCCESS,
  assessment,
});

export const setAssigneeFailed = (): actionTypes.SetAssigneeFailed => ({
  type: actionTypes.SET_ASSIGNEE_FAILED,
});
