import "./WorkflowTemplate.scss";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { Button, Col, Collapse, Empty, Row, Spin, Tooltip } from "antd";
import cs from "classnames";

import * as lodash from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../common/utils/error";
import { QfCollapse } from "../../../theme";
import {
  getWorkflowTasks,
  resetWorkflowTasks,
  getWorkflowSummary,
} from "../../actions/creators/workflow";
import { WORKFLOW_STATE } from "../../constants/workflow";
import { useWorkflowDispatch } from "../../dispatchers";
import * as workflowSelectors from "../../selectors/workflow";
import WorkflowService from "../../services/WorkflowService";
import {
  WorkflowTaskCount,
  WorkflowTask,
  WorkflowSummaryItem,
} from "../../types/Workflow";
import WorkflowItem from "../workflow-item/WorkflowItem";
import WorkflowTaskDetails from "./workflow-task-details/WorkflowTaskDetails";
const { Panel } = Collapse;

type FilterProps = {
  countBySate: WorkflowTaskCount | undefined;
  onReRunClick: (() => void) | null;
  filterTasks: (state: WORKFLOW_STATE[]) => void;
  reRunLoading: boolean;
  filter: WORKFLOW_STATE[];
  disabled?: boolean;
};

const Menu: React.FunctionComponent<FilterProps> = ({
  countBySate,
  onReRunClick,
  reRunLoading,
  filterTasks,
  filter,
  disabled,
}: FilterProps) => {
  const reRunWorkflow = useCallback(() => {
    if (onReRunClick) onReRunClick();
  }, [onReRunClick]);

  const isFilterActive = (state: WORKFLOW_STATE) => filter.includes(state);

  return (
    <div className="workflow-menu">
      {countBySate && (
        <div className="filters">
          <Tooltip title="Failed">
            <div
              className={cs("state-btn failed", {
                active: isFilterActive(WORKFLOW_STATE.FAILED),
              })}
              onClick={() => filterTasks([WORKFLOW_STATE.FAILED])}
            >
              {lodash.get(countBySate, "FAILED", 0)}
            </div>
          </Tooltip>
          <Tooltip title="On Hold">
            <div
              className={cs("state-btn on-hold", {
                active: isFilterActive(WORKFLOW_STATE.HOLD),
              })}
              onClick={() => filterTasks([WORKFLOW_STATE.HOLD])}
            >
              {lodash.get(countBySate, "HOLD", 0)}
            </div>
          </Tooltip>
          <Tooltip title="Refer">
            <div
              className={cs("state-btn refer", {
                active: isFilterActive(WORKFLOW_STATE.REFER),
              })}
              onClick={() => filterTasks([WORKFLOW_STATE.REFER])}
            >
              {lodash.get(countBySate, "REFER", 0)}
            </div>
          </Tooltip>
          <Tooltip title="Pending">
            <div
              className={cs("state-btn pending", {
                active: isFilterActive(WORKFLOW_STATE.PENDING),
              })}
              onClick={() => filterTasks([WORKFLOW_STATE.PENDING])}
            >
              {lodash.get(countBySate, "PENDING", 0)}
            </div>
          </Tooltip>
          <Tooltip title="Clear filter">
            <div
              className={cs("clear-filter", {
                active: !filter.length,
              })}
              onClick={() => filterTasks([])}
            >
              Show all
            </div>
          </Tooltip>
        </div>
      )}
      <div className="rerun-wrapper">
        {countBySate && (
          <Button
            loading={reRunLoading}
            className="qf-btn-green rerun-btn"
            disabled={disabled || !onReRunClick}
            onClick={reRunWorkflow}
          >
            Rerun items
          </Button>
        )}
      </div>
    </div>
  );
};

type WorkflowTemplateProps = {
  code: string;
  workflowCodes: string[];
  showGroupHeaders: boolean;
};

const WorkflowTemplate: React.FunctionComponent<WorkflowTemplateProps> = ({
  code,
  workflowCodes,
  showGroupHeaders,
}: WorkflowTemplateProps) => {
  const { assessmentId } = useParams<{
    assessmentId: string;
  }>();
  const workflowSummary = useSelector(
    workflowSelectors.workflowSummaryDetailsSelector
  );
  const [filter, setFilter] = useState<WORKFLOW_STATE[]>([]);
  const workflowDispatch = useWorkflowDispatch();
  const [reRunLoading, setReRunLoading] = useState(false);
  const childWorkflow = useSelector(workflowSelectors.childWorkflowsSelector);
  const hasWorkflowsCreated = !!Object.keys(
    lodash.pick(workflowSummary, workflowCodes)
  ).length;
  const workflow = lodash.get(
    workflowSummary,
    `${code}.0`,
    {}
  ) as WorkflowSummaryItem;

  const tasks = useSelector(workflowSelectors.workflowTasksSelector);
  const countBySate = useSelector(workflowSelectors.workflowTasksCountSelector);
  const taskStatus = useSelector(
    workflowSelectors.workflowGroupTasksStatusSelector
  );
  const taskError = useSelector(
    workflowSelectors.workflowGroupTasksErrorSelector
  );
  const [activeTask, setActiveTask] = useState<WorkflowTask>();
  const isWorkflowDisabled = workflow?.isClosed;
  const fetchWorkflowTasks = useCallback(async () => {
    workflowDispatch(
      getWorkflowTasks({
        targetId: assessmentId,
        codes: workflowCodes,
        taskStates: filter.length ? filter : [],
        includeTasks: true,
      })
    );
  }, [workflowDispatch, assessmentId, workflowCodes, filter]);

  useEffect(() => {
    if (taskStatus === LOADING_STATUS.FAILED) {
      processError(taskError, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    }
  }, [taskStatus, taskError]);

  useEffect(() => {
    if (workflowCodes.length) {
      fetchWorkflowTasks();
    }

    return () => {
      workflowDispatch(resetWorkflowTasks());
    };
  }, [workflowDispatch, fetchWorkflowTasks, workflowCodes, filter]);

  useEffect(() => {
    if (tasks.length > 0) {
      if (!activeTask) {
        setActiveTask(tasks[0]);
      }
    } else {
      setActiveTask(undefined);
    }
  }, [tasks, activeTask, filter]);

  const handleWorkflowFilter = (filter: WORKFLOW_STATE[]) => {
    setFilter(filter);
  };

  const reRunWorkflow = async () => {
    setReRunLoading(true);
    try {
      await WorkflowService.reRun(workflow.id as number, workflowCodes);
      setFilter([]);

      showSuccessPopUp("Successfully re-running the workflow group.");
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setReRunLoading(false);
    }
  };

  const handleTaskUpdateSuccess = (task: WorkflowTask) => {
    setActiveTask((prevState) => ({
      ...prevState,
      ...task,
    }));
    fetchWorkflowTasks();
    workflowDispatch(
      getWorkflowSummary({
        targetId: assessmentId,
      })
    );
  };

  const refreshTaskList = () => {
    fetchWorkflowTasks();
  };

  const taskGroupIds: number[] = [];
  let workflowSection = <Empty description="No workflow found" />;

  if (hasWorkflowsCreated) {
    workflowSection = countBySate ? (
      <>
        <Menu
          countBySate={countBySate}
          onReRunClick={reRunWorkflow}
          reRunLoading={reRunLoading}
          filterTasks={handleWorkflowFilter}
          filter={filter}
          disabled={isWorkflowDisabled}
        />
        <Spin spinning={taskStatus === LOADING_STATUS.LOADING || reRunLoading}>
          <Row gutter={[16, 16]}>
            <Col span={24} xl={14} className="workflow-table-wrapper">
              <table
                className={cs("workflow-list-table", {
                  "with-tasks": tasks.length,
                })}
              >
                <thead>
                  <tr>
                    <th style={{ width: "75px" }}>State</th>
                    <th style={{ width: "40%" }}>Workflow item</th>
                    <th style={{ width: "150px" }}>Outcome</th>
                    <th style={{ width: "40%" }}>Last note</th>
                  </tr>
                </thead>
                <tbody>
                  {!tasks.length && (
                    <tr>
                      <td colSpan={5}>
                        <Empty description="No workflow tasks found" />
                      </td>
                    </tr>
                  )}
                  {tasks
                    .filter((task) => task.state !== WORKFLOW_STATE.DISABLED)
                    .map((task) => {
                      let header = null;
                      if (
                        showGroupHeaders &&
                        !taskGroupIds.includes(task.workflowId)
                      ) {
                        taskGroupIds.push(task.workflowId);

                        if (childWorkflow[task.workflowId]) {
                          const { text, title } = childWorkflow[
                            task.workflowId
                          ].templateRef;
                          header = (
                            <tr>
                              <td colSpan={4} className="group-header">
                                {title || text}
                              </td>
                            </tr>
                          );
                        }
                      }

                      return (
                        <Fragment key={task.id}>
                          {header}
                          <WorkflowItem
                            filter={filter}
                            code={code}
                            task={task}
                            onClick={setActiveTask}
                            active={task.id === activeTask?.id}
                          />
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            </Col>
            <Col span={24} xl={10}>
              {activeTask ? (
                <WorkflowTaskDetails
                  workflowTask={activeTask}
                  onTaskUpdateSuccess={handleTaskUpdateSuccess}
                  refreshTaskList={refreshTaskList}
                  isDisabled={isWorkflowDisabled}
                />
              ) : (
                <Empty description="No active workflow task" />
              )}
            </Col>
          </Row>
        </Spin>
      </>
    ) : (
      <Empty description="No workflow tasks found" />
    );
  }

  return (
    <QfCollapse
      defaultActiveKey={["workflow"]}
      className="workflow-wrapper"
      expandIconPosition="right"
      ghost
    >
      <Panel
        header={<div className="section-header-1">Workflow</div>}
        key={"workflow"}
        className="workflow-list"
      >
        {workflowSection}
      </Panel>
    </QfCollapse>
  );
};

export default WorkflowTemplate;
