import { Action } from "redux";
import {
  GetAssessmentExtrasPayload,
  GetAssessmentExtrasResponse,
} from "../../types/AssessmentExtras";

export const CE_GET_ASSESSMENT_EXTRAS = "CE_GET_ASSESSMENT_EXTRAS";
export interface GetAssessmentExtras extends Action {
  type: typeof CE_GET_ASSESSMENT_EXTRAS;
  assessmentId: string;
  data: GetAssessmentExtrasPayload;
  requestId?: string;
}

export const CE_GET_ASSESSMENT_EXTRAS_SUCCESS =
  "CE_GET_ASSESSMENT_EXTRAS_SUCCESS";
export interface GetAssessmentExtrasSuccess extends Action {
  type: typeof CE_GET_ASSESSMENT_EXTRAS_SUCCESS;
  response: GetAssessmentExtrasResponse;
  requestId?: string;
}

export const CE_GET_ASSESSMENT_EXTRAS_FAILED =
  "CE_GET_ASSESSMENT_EXTRAS_FAILED";
export interface GetAssessmentExtrasFailed extends Action {
  type: typeof CE_GET_ASSESSMENT_EXTRAS_FAILED;
  error: unknown;
  requestId?: string;
}

export const CE_RESET_ASSESSMENT_EXTRAS = "CE_RESET_ASSESSMENT_EXTRAS";
export interface ResetAssessmentExtras extends Action {
  type: typeof CE_RESET_ASSESSMENT_EXTRAS;
  key: string[];
}

export type AssessmentExtrasActions =
  | GetAssessmentExtras
  | GetAssessmentExtrasSuccess
  | GetAssessmentExtrasFailed
  | ResetAssessmentExtras;
