import "./ApplicationDocuments.scss";
import React, { useCallback, useEffect, useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { documentPurposes } from "@quest-finance/quest-fe-shared/dist/application";
import { ApplicationDocument } from "@quest-finance/quest-fe-shared/dist/application/types/ApplicationDocument";
import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import { getOffset } from "@quest-finance/quest-fe-shared/dist/common/utils/dataTable";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import {
  DocumentService,
  useDownloadDocument,
} from "@quest-finance/quest-fe-shared/dist/files";
import { Empty, Popconfirm, Spin, Pagination } from "antd";
import * as lodash from "lodash";
import * as qs from "query-string";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import { DATE_LIST_FORMAT } from "../../../common/contstants/app";
import { processError } from "../../../common/utils/error";
import { DOCUMENT_PURPOSE_LABEL } from "../../constants/documentPurposeLabels";
import { Documents } from "../../types/ApplicationDocument";

type ApplicationDocumentsProps = {
  applicationId: string;
  purpose: documentPurposes.DOCUMENT_PURPOSES;
  lastUpload: number;
};

const ApplicationDocuments: React.FunctionComponent<ApplicationDocumentsProps> = ({
  applicationId,
  purpose,
  lastUpload,
}: ApplicationDocumentsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [documents, setDocuments] = useState<Documents[]>([]);
  const [documentCount, setDocumentCount] = useState(0);
  const { downloadFile } = useDownloadDocument();

  const onPageChange = (page: number) => {
    getDocuments(page);
  };

  const getDocuments = useCallback(
    async (page = 1) => {
      setIsLoading(true);
      setPage(page);

      try {
        const queryParams = qs.stringify({
          purposes: purpose,
          offset: getOffset(page, DATATABLE_MAX_ROWS),
          limit: DATATABLE_MAX_ROWS,
        });
        const res = await DocumentService.getDocumentList<Documents>(
          `/application/applications/${applicationId}/documents?${queryParams}`
        );

        setDocuments(res?.data as Documents[]);
        setDocumentCount(res?.count as number);
      } catch (e) {
        processError(e, (errorMessage) => {
          showErrorPopUp(errorMessage);
        });
      } finally {
        setIsLoading(false);
      }
    },
    [setDocuments, applicationId, purpose]
  );

  const handleFileDownload = (
    event: React.MouseEvent<HTMLAnchorElement>,
    { id, applicationId }: Documents
  ) => {
    event.preventDefault();
    downloadFile(
      `/application/applications/${applicationId}/documents/${id}/download`
    );
  };

  const handleFileDelete = async ({
    id,
    applicationId,
  }: ApplicationDocument) => {
    setIsLoading(true);

    try {
      await DocumentService.deleteDocument(
        `/application/applications/${applicationId}/documents/${id}`
      );
      getDocuments();
      showSuccessPopUp(`${DOCUMENT_PURPOSE_LABEL[purpose]} has been deleted.`);
    } catch (e) {
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderFileNameColumn = (document: Documents) => {
    return (
      <a
        href="/"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
          handleFileDownload(event, document)
        }
      >
        {document.originalFilename}
      </a>
    );
  };

  const renderDeleteButton = (doc: ApplicationDocument) => {
    return (
      <Popconfirm
        title="Sure to delete?"
        okText={<span data-testid={`confirm-delete-${doc.id}`}>Yes</span>}
        onConfirm={() => handleFileDelete(doc)}
        placement="left"
      >
        <DeleteFilled
          className="delete-button"
          data-testid={`doc-${doc.id}-delete-btn`}
        />
      </Popconfirm>
    );
  };

  useEffect(() => {
    if (applicationId) {
      getDocuments();
    }
  }, [applicationId, lastUpload, getDocuments]);

  let list = null;
  if (!isLoading) {
    list = !documents.length ? (
      <Empty description="No documents found" data-testid="no-docs-found" />
    ) : (
      <>
        <table>
          <tbody data-testid="doc-list">
            {documents.map((document) => (
              <tr key={document.id}>
                <td data-testid={`doc-${document.id}-created-at`}>
                  {dateFormat(new Date(document.createdAt), DATE_LIST_FORMAT)}
                </td>
                <td data-testid={`doc-${document.id}-created-by`}>
                  {lodash.isEmpty(document)
                    ? "-"
                    : `${document.createdBy.firstName} ${document.createdBy.lastName}`}
                </td>
                <td data-testid={`doc-${document.id}-name`}>
                  {renderFileNameColumn(document)}
                </td>
                <td className="action">{renderDeleteButton(document)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          className="list-pagination"
          data-testid="pagination"
          current={page}
          onChange={onPageChange}
          pageSize={DATATABLE_MAX_ROWS}
          total={documentCount}
          hideOnSinglePage
          simple
        />
      </>
    );
  }
  return (
    <Spin spinning={isLoading}>
      <div className="application-documents" data-testid="applicatio-docs">
        {list}
      </div>
    </Spin>
  );
};

export default ApplicationDocuments;
