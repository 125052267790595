import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import {
  ClientListActions,
  GET_CLIENT_LIST,
  GET_CLIENT_LIST_ERROR,
  GET_CLIENT_LIST_SUCCESS,
} from "../actions/types/clientList";
import { ClientListState } from "../types/ClientListState";

export const clientListState: ClientListState = {
  clients: [],
  clientCount: 0,
  isLoading: false,
  resetPage: false,
};

export const reducer = (
  state: ClientListState = clientListState,
  action: ClientListActions
): ClientListState => {
  switch (action.type) {
    case GET_CLIENT_LIST: {
      let resetPage = false;
      if (action.payload && action.payload.order !== state.order)
        resetPage = true;

      return {
        ...state,
        order: action.payload ? action.payload.order : undefined,
        limit: action.payload ? action.payload.limit : DATATABLE_MAX_ROWS,
        offset: action.payload ? action.payload.offset : state.offset,
        resetPage,
        isLoading: true,
      };
    }
    case GET_CLIENT_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        resetPage: false,
        clients: action.clients,
        clientCount: action.clientCount,
      };
    }
    case GET_CLIENT_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
