export enum STATUS {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export interface Funder {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  status: STATUS;
}

export interface FunderRequest {
  funderId?: number;
  utilisationNumber?: string;
  accountName: string;
  accountNumber: string;
  bsb: string;
}

export interface Submitter {
  firstName: string;
  lastName: string;
}

export interface FunderResponse {
  id: number;
  funderId: number;
  assessmentId: string;
  funder: Funder | null;
  utilisationNumber: string;
  accountName: string;
  accountNumber: string;
  bsb: string;
  submittedAt: string;
  submittedBy: number | Submitter;
}
