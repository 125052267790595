import { createSelector } from "@reduxjs/toolkit";
import { Layout } from "../types/Layout";
import { ThemeState } from "../types/ThemeState";

export const layoutSelector = (state: ThemeState): Layout => {
  return state.theme.layout;
};

export const isSidebarCollapsedSelector = createSelector(
  layoutSelector,
  (layout) => layout.isSidebarCollapsed
);
