import "./WorkflowTaskDetails.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { documentPurposes } from "@quest-finance/quest-fe-shared/dist/application";
import { useDownloadDocument } from "@quest-finance/quest-fe-shared/dist/files";
import { Button, Collapse, Dropdown, Menu, Spin } from "antd";
import classNames from "classnames";
import { AuditLogList } from "../../../../audit";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../../common/utils/error";
import { Uploader } from "../../../../document";
import { QfCollapse } from "../../../../theme";
import { updateWorkflowTask } from "../../../actions/creators/workflow";
import { AUDIT_CATEGORY_LABELS, MODEL_NAME } from "../../../constants/audit";
import {
  WORKFLOW_STATE,
  WORKFLOW_STATE_LABELS,
} from "../../../constants/workflow";
import { useWorkflowDispatch } from "../../../dispatchers";
import WorkflowDocumentService from "../../../services/WorkflowDocumentService";
import WorkflowService from "../../../services/WorkflowService";
import { WorkflowDocument } from "../../../types/Document";
import { WorkflowTask } from "../../../types/Workflow";
import DocumentList from "../../document-list/DocumentList";
import WorkflowTaskNotes from "../../task-notes";
import WorkflowTaskOutcome from "../../workflow-task-outcome/WorkflowTaskOutcome";

const { Panel } = Collapse;

type WorkflowTaskProps = {
  workflowTask: WorkflowTask;
  isDisabled?: boolean;
  onTaskUpdateSuccess?: (task: WorkflowTask) => void;
  refreshTaskList?: () => void;
};

const WorkflowTaskDetails: React.FunctionComponent<WorkflowTaskProps> = ({
  workflowTask,
  isDisabled,
  onTaskUpdateSuccess,
  refreshTaskList,
}: WorkflowTaskProps) => {
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [page, setPage] = useState<undefined | number>(1);
  const [lastRequest, setLasRequest] = useState(0);

  const modelIds = useMemo(() => {
    return [workflowTask.id];
  }, [workflowTask]);

  const onPageChange = (page?: number) => {
    setPage(page);
  };

  const workflowDispatch = useWorkflowDispatch();

  const getDocuments = useCallback(async () => {
    try {
      setDocumentsLoading(true);
      const { data } = await WorkflowDocumentService.getList(workflowTask.id);
      setDocuments(data);
      setDocumentsLoading(false);
    } catch (e) {
      setDocumentsLoading(false);
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    }
  }, [workflowTask]);

  const isPass = workflowTask.state === WORKFLOW_STATE.PASS;
  const isRefer = workflowTask.state === WORKFLOW_STATE.REFER;
  const isHold = workflowTask.state === WORKFLOW_STATE.HOLD;
  const isFailed = workflowTask.state === WORKFLOW_STATE.FAILED;

  const [documents, setDocuments] = useState<WorkflowDocument[]>([]);

  const [selectedOutcome, setOutcome] = useState<string>("");
  const [requestOutcomeLoading, setRequestOutcomeLoading] = useState(false);
  const [taskUpdateLoading, setTaskUpdateLoading] = useState(false);
  const { downloadFile } = useDownloadDocument();

  const taskUpdateHandler = async (state: WORKFLOW_STATE) => {
    if (workflowTask.state !== state && !isDisabled) {
      try {
        setTaskUpdateLoading(true);
        const result = await WorkflowService.updateTask(
          workflowTask.workflowId,
          workflowTask.id,
          { state }
        );
        if (onTaskUpdateSuccess) onTaskUpdateSuccess(result.data);
        workflowDispatch(
          updateWorkflowTask(workflowTask.id, {
            ...workflowTask,
            ...result.data,
          })
        );

        showSuccessPopUp("Successfully updated task state.");
      } catch (error) {
        processError(error, (errorMessage) => {
          showErrorPopUp(errorMessage);
        });
      } finally {
        setTaskUpdateLoading(false);
      }
    }
  };

  const setOutcomeRequest = async (outcome: string) => {
    try {
      setRequestOutcomeLoading(true);
      const result = await WorkflowService.updateTask(
        workflowTask.workflowId,
        workflowTask.id,
        { outcome }
      );
      if (onTaskUpdateSuccess) onTaskUpdateSuccess(result.data);
      workflowDispatch(
        updateWorkflowTask(workflowTask.id, {
          ...workflowTask,
          ...result.data,
        })
      );

      showSuccessPopUp("Successfully changed the outcome of the task");
    } catch (e) {
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setRequestOutcomeLoading(false);
    }
  };

  useEffect(() => {
    getDocuments();

    return () => {
      setDocuments([]);
    };
  }, [getDocuments]);

  useEffect(() => {
    setOutcome(workflowTask.outcome);
  }, [workflowTask]);

  const handleSendRequest = (outcome: string) => {
    setOutcome(outcome);
    setOutcomeRequest(outcome);
  };

  const handleSelectOutcome = (value: string) => {
    handleSendRequest(value);
  };

  const handleOnUploadComplete = () => {
    getDocuments();
  };

  const handleDocumentDelete = async (documentId: string) => {
    try {
      setDocumentsLoading(true);
      await WorkflowDocumentService.deleteDocument(workflowTask.id, documentId);
      setDocumentsLoading(false);
      getDocuments();
      showSuccessPopUp("Successfully deleted task document");
    } catch (e) {
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
      setDocumentsLoading(false);
    }
  };

  const handleDownload = (documentId: string) => {
    downloadFile(
      `/workflow/task/${workflowTask.id}/documents/${documentId}/download`
    );
  };

  const onAfterNotesCreateOrUpdate = () => {
    if (refreshTaskList) refreshTaskList();
    setLasRequest(Date.now());
  };

  const stateOptions = (
    <Menu>
      <Menu.Item key={0}>
        <Button
          className={classNames("state-btn", {
            "qf-btn-green-invert": !isPass,
            "qf-btn-green": isPass,
          })}
          onClick={() => taskUpdateHandler(WORKFLOW_STATE.PASS)}
          disabled={isDisabled}
        >
          Pass
        </Button>
      </Menu.Item>
      <Menu.Item key={1}>
        <Button
          className={classNames("state-btn", {
            "qf-btn-orange-invert": !isHold,
            "qf-btn-orange": isHold,
          })}
          onClick={() => taskUpdateHandler(WORKFLOW_STATE.HOLD)}
          disabled={isDisabled}
        >
          Hold
        </Button>
      </Menu.Item>
      <Menu.Item key={2}>
        <Button
          className={classNames("state-btn", {
            "qf-btn-yellow-invert": !isRefer,
            "qf-btn-yellow": isRefer,
          })}
          onClick={() => taskUpdateHandler(WORKFLOW_STATE.REFER)}
          disabled={isDisabled}
        >
          Refer
        </Button>
      </Menu.Item>
      <Menu.Item key={3}>
        <Button
          className={classNames("state-btn", {
            "qf-btn-red-invert": !isFailed,
            "qf-btn-red": isFailed,
          })}
          onClick={() => taskUpdateHandler(WORKFLOW_STATE.FAILED)}
          disabled={isDisabled}
        >
          Failed
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="workflow-task-details">
      <Spin spinning={requestOutcomeLoading || taskUpdateLoading}>
        <div
          className={classNames("task-header", {
            pass: isPass,
            hold: isHold,
            refer: isRefer,
            fail: isFailed,
          })}
        >
          <h4 className="f-quest-navy f-bold">
            {workflowTask.templateRef.text}
          </h4>
        </div>
        <div className="task-update-section">
          <WorkflowTaskOutcome
            value={selectedOutcome}
            onChange={handleSelectOutcome}
            taskTemplateRef={workflowTask.templateRef}
            disabled={requestOutcomeLoading || isDisabled}
          />
          <div className="state-btn-content">
            <Dropdown
              placement="bottomCenter"
              overlay={stateOptions}
              trigger={["click"]}
              getPopupContainer={(trigger) =>
                trigger.parentElement as HTMLElement
              }
              disabled={workflowTask.outcome === null || isDisabled}
            >
              <Button
                className={classNames("state-btn", {
                  "qf-btn-green": isPass,
                  "qf-btn-orange": isHold,
                  "qf-btn-yellow": isRefer,
                  "qf-btn-red": isFailed,
                })}
              >
                {WORKFLOW_STATE_LABELS[workflowTask.state]}
              </Button>
            </Dropdown>
          </div>
        </div>
        <WorkflowTaskNotes
          workflowId={workflowTask.workflowId}
          workflowTaskId={workflowTask.id}
          afterCreate={onAfterNotesCreateOrUpdate}
          afterUpdate={onAfterNotesCreateOrUpdate}
          disabled={isDisabled}
        />
        <QfCollapse
          className="workflow-notes-wrapper workflow-task-collapse-section"
          expandIconPosition="right"
          ghost
        >
          <Panel
            header={<h4 className="f-quest-navy f-bold">Related Documents</h4>}
            key="related-documents"
          >
            <section className="uploader-container">
              {!isDisabled && (
                <Uploader
                  documentPurpose={
                    documentPurposes.DOCUMENT_PURPOSES.INTERNAL_DOCUMENT
                  }
                  uploadPath={`workflow/task/${workflowTask.id}/documents`}
                  className="mb-3"
                  onUploadComplete={handleOnUploadComplete}
                  disabled={isDisabled}
                />
              )}
            </section>
            <DocumentList
              isLoading={documentsLoading}
              documents={documents}
              onDelete={handleDocumentDelete}
              onDownload={handleDownload}
              readOnly={isDisabled}
            />
          </Panel>
          <Panel
            header={
              <h4 className="f-quest-navy f-bold">Credit Policy Guide</h4>
            }
            key="credit-policy-guide"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: workflowTask.templateRef.description,
              }}
            />
          </Panel>
          <Panel
            header={<h4 className="f-quest-navy f-bold">Audit History</h4>}
            key="audit-task-history"
          >
            <AuditLogList
              modelNames={MODEL_NAME}
              modelIds={modelIds}
              onPageChange={onPageChange}
              page={page}
              categoryLabels={AUDIT_CATEGORY_LABELS}
              lastRequest={lastRequest}
            />
          </Panel>
        </QfCollapse>
      </Spin>
    </div>
  );
};

export default WorkflowTaskDetails;
