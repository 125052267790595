import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { UploadChangeParam } from "antd/es/upload";

import { FileData } from "../../types/Aba";

export type AbaFileLoaderProps = {
  onUpload: (file: FileData) => void;
  onError: () => void;
  loading: boolean;
};

const AbaFileLoader: React.FC<AbaFileLoaderProps> = ({
  onUpload,
  onError,
  loading,
}: AbaFileLoaderProps) => {
  const onFileUpload = (info: UploadChangeParam<File>) => {
    if (info.file) {
      const reader = new FileReader();
      reader.readAsText(info.file, "UTF-8");
      reader.onload = async (evt) => {
        onUpload({
          name: info?.file.name,
          contents: evt.target?.result as string,
        });
      };
      reader.onerror = () => {
        onError();
      };
    }
  };

  return (
    <div className="aba-file-upload">
      <Upload
        onChange={onFileUpload}
        disabled={loading}
        showUploadList={false}
        beforeUpload={() => false}
        data-testid="aba-file-uploader"
      >
        <Button
          icon={<UploadOutlined />}
          loading={loading}
          disabled={loading}
          className="upload-btn"
          data-testid="file-upload-btn"
        >
          Click to Upload
        </Button>
      </Upload>
    </div>
  );
};

export default AbaFileLoader;
