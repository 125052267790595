import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import {
  AssessmentDocument,
  DocumentPurpose,
} from "../../types/AssessmentDocument";
import * as actionTypes from "../types/documentList";

export const getAssessmentDocuments = (
  assessmentId: string,
  purpose: DocumentPurpose[] | undefined,
  limit: number,
  offset: number
): actionTypes.GetAssessmentDocuments => ({
  type: actionTypes.GET_ASSESSMENT_DOCUMENTS,
  purpose,
  assessmentId,
  limit,
  offset,
});

export const getAssessmentDocumentsSuccess = (
  response: ListResponse<AssessmentDocument>
): actionTypes.GetAssessmentDocumentsSuccess => ({
  type: actionTypes.GET_ASSESSMENT_DOCUMENTS_SUCCESS,
  response,
});

export const getAssessmentDocumentsFailed = (): actionTypes.GetAssessmentDocumentsFailed => ({
  type: actionTypes.GET_ASSESSMENT_DOCUMENTS_FAILED,
});

export const getAssessmentDocumentsCompleted = (): actionTypes.GetAssessmentDocumentsCompleted => ({
  type: actionTypes.GET_ASSESSMENT_DOCUMENTS_COMPLETED,
});

export const resetAssessmentDocuments = (): actionTypes.ResetAssessmentDocuments => ({
  type: actionTypes.RESET_ASSESSMENT_DOCUMENTS,
});
