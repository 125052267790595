import { FunctionComponent } from "react";

export enum Slug {
  QUOTE = "quote",
  APPLICANT_GUARANTORS = "applicant-guarantors",
  REF_STATEMENTS = "ref-statements",
  SECURITY = "security",
  NOTES_CONTRACTS = "notes-contracts",
  DECISION = "decision",
  SETTLEMENT = "settlement",
  AUDIT_LOG = "audit-log",
}

export interface NavItem {
  label: string;
  Icon: FunctionComponent;
}

export type NavItems = {
  [key in Slug]: NavItem;
};
