import { AssessmentDetail } from "../../types/AssessmentDetail";
import * as actionTypes from "../types/assessmentDetail";

export const getAssessmentDetail = (
  assessmentId: string
): actionTypes.GetAssessmentDetail => ({
  type: actionTypes.GET_ASSESSMENT_DETAILS,
  assessmentId,
});

export const getAssessmentDetailSuccess = (
  assessment: AssessmentDetail
): actionTypes.GetAssessmentDetailSuccess => ({
  type: actionTypes.GET_ASSESSMENT_DETAILS_SUCCESS,
  assessment,
});

export const getAssessmentDetailFailed = (): actionTypes.GetAssessmentDetailFailed => ({
  type: actionTypes.GET_ASSESSMENT_DETAILS_FAILED,
});

export const resetAssessmentDetail = (): actionTypes.ResetAssessmentDetails => ({
  type: actionTypes.RESET_ASSESSMENT_DETAILS,
});

export const updateAssessmentDetail = (
  assessment: Partial<AssessmentDetail>
): actionTypes.UpdateAssessmentDetail => ({
  type: actionTypes.UPDATE_ASSESSMENT_DETAIL,
  assessment,
});
