import React from "react";
import {
  ProfileOutlined,
  IdcardOutlined,
  AuditOutlined,
  TeamOutlined,
  LeftSquareOutlined,
  RightSquareOutlined,
  EnvironmentOutlined,
  PayCircleOutlined,
} from "@ant-design/icons";
import { getUserDataSelector } from "@quest-finance/quest-fe-shared/dist/auth";
import { UserData } from "@quest-finance/quest-fe-shared/dist/auth/types/UserData";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isAllowedAccess, permissions } from "../../../../iam";
import * as abaProcess from "../../../../payment/pages/aba/process";
import { PORTALS } from "../../../../theme/constants/Layout";
import { setSidebarCollapse } from "../../../actions/creators/layout";
import { useLayoutDispatch } from "../../../dispatchers/";
import "./Sidebar.scss";

interface TwoColSidebarProps {
  collapsed?: boolean;
}

const TwoColSidebar: React.FunctionComponent<TwoColSidebarProps> = ({
  collapsed = false,
}: TwoColSidebarProps) => {
  const userData = useSelector(getUserDataSelector) as UserData;
  const layoutDispatch = useLayoutDispatch();

  const onToggleHandler = () => {
    layoutDispatch(setSidebarCollapse(!collapsed));
  };

  return (
    <div className="layout-sider">
      <div className="links">
        <div className="toggle-btn" onClick={onToggleHandler}>
          {collapsed ? <RightSquareOutlined /> : <LeftSquareOutlined />}
        </div>
        {isAllowedAccess(userData, [
          permissions.PERMISSION_IAM["ASSESSMENT.APPLICATION.READ"],
        ]) && (
          <Link to="/">
            <AuditOutlined />
            <span className="title">Applications</span>
          </Link>
        )}

        {isAllowedAccess(userData, [
          permissions.PERMISSION_IAM.IAM_ADMIN,
          permissions.PERMISSION_IAM.IAM_INTRODUCER_MANAGE,
          permissions.PERMISSION_IAM["IAM.CLIENT.CREATE"],
          permissions.PERMISSION_IAM["IAM.CLIENT.UPDATE_INTRODUCER"],
        ]) && (
          <Link to="/iam/clients">
            <TeamOutlined />
            <span className="title">Clients</span>
          </Link>
        )}
        {isAllowedAccess(userData as UserData, [
          permissions.PERMISSION_IAM.IAM_ADMIN,
          permissions.PERMISSION_IAM["IAM.ROLE.CREATE"],
        ]) && (
          <Link to="/iam/roles">
            <ProfileOutlined />
            <span className="title">Roles</span>
          </Link>
        )}
        {isAllowedAccess(userData as UserData, [
          permissions.PERMISSION_IAM.IAM_ADMIN,
          permissions.PERMISSION_IAM["IAM.USER.CREATE"],
          permissions.PERMISSION_IAM.IAM_INTRODUCER_MANAGE,
          permissions.PERMISSION_IAM["IAM.USER.CREATE_INTRODUCER"],
        ]) && (
          <Link to="/iam/users">
            <IdcardOutlined />
            <span className="title">Users</span>
          </Link>
        )}
        {isAllowedAccess(userData as UserData, [
          permissions.PERMISSION_IAM["IAM.USER.READ"],
          permissions.PERMISSION_IAM["APPLICATION.MOVE"],
          permissions.PERMISSION_IAM["APPLICATION.READ_ALL"],
        ]) && (
          <Link to="/application/applications/move">
            <EnvironmentOutlined />
            <span className="title">Move Application</span>
          </Link>
        )}
        {isAllowedAccess(userData as UserData, [
          permissions.PERMISSION_IAM["PAYMENT.READ"],
        ]) && (
          <Link to="/payment/aba/process">
            <PayCircleOutlined />
            <span className="title">{abaProcess.pageTitle}</span>
          </Link>
        )}
        <div id={PORTALS.EMAILER}></div>
      </div>
    </div>
  );
};

export default TwoColSidebar;
