import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { put, call, ForkEffect, takeLatest } from "redux-saga/effects";
import * as actionCreators from "../actions/creators/workflow";
import * as actionTypes from "../actions/types/workflow";
import WorkflowService from "../services/WorkflowService";
import { Workflow, WorkflowTaskResponse } from "../types/Workflow";
import { getChildWorkflowDetails, getWorkflowByCode } from "../utils/workflow";

function* getWorkflowSummaryWorker({
  queryParams,
}: actionTypes.GetWorkflowSummary) {
  try {
    const result: ListResponse<Workflow> = yield call(
      WorkflowService.getWorkflows,
      queryParams
    );
    const workflowSummary = getWorkflowByCode(result.data);

    yield put(actionCreators.getWorkflowSummarySuccess(workflowSummary));
  } catch (e) {
    yield put(actionCreators.getWorkflowSummaryFailed(e));
  }
}

function* getWorkflowTasksWorker({
  queryParams,
}: actionTypes.GetWorkflowTasks) {
  try {
    const result: WorkflowTaskResponse = yield call(
      WorkflowService.getWorkflows,
      queryParams
    );

    const { tasks, countByState, childWorkflows } = getChildWorkflowDetails(
      result.data
    );

    yield put(
      actionCreators.getWorkflowTasksSuccess(
        childWorkflows,
        tasks,
        countByState
      )
    );
  } catch (e) {
    console.log(e);
    yield put(actionCreators.getWorkflowTasksFailed(e));
  }
}

function* watchAdminData(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(actionTypes.GET_WORKFLOW_SUMMARY, getWorkflowSummaryWorker);
  yield takeLatest(actionTypes.GET_WORKFLOW_TASKS, getWorkflowTasksWorker);
}

export default watchAdminData;
