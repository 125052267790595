import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { Action } from "redux";
import {
  AssessmentDocument,
  DocumentPurpose,
} from "../../types/AssessmentDocument";
import * as actionTypes from "../types/documentList";

export const RESET_ASSESSMENT_DOCUMENTS = "CE_RESET_ASSESSMENT_DOCUMENTS";
export interface ResetAssessmentDocuments extends Action {
  type: typeof actionTypes.RESET_ASSESSMENT_DOCUMENTS;
}

export const GET_ASSESSMENT_DOCUMENTS = "CE_GET_ASSESSMENT_DOCUMENTS";
export interface GetAssessmentDocuments extends Action {
  type: typeof actionTypes.GET_ASSESSMENT_DOCUMENTS;
  purpose: DocumentPurpose[] | undefined;
  assessmentId: string;
  limit: number;
  offset: number;
}

export const GET_ASSESSMENT_DOCUMENTS_SUCCESS =
  "CE_GET_ASSESSMENT_DOCUMENTS_SUCCESS";
export interface GetAssessmentDocumentsSuccess extends Action {
  type: typeof GET_ASSESSMENT_DOCUMENTS_SUCCESS;
  response: ListResponse<AssessmentDocument>;
}

export const GET_ASSESSMENT_DOCUMENTS_FAILED =
  "CE_GET_ASSESSMENT_DOCUMENTS_FAILED";
export interface GetAssessmentDocumentsFailed extends Action {
  type: typeof actionTypes.GET_ASSESSMENT_DOCUMENTS_FAILED;
}

export const GET_ASSESSMENT_DOCUMENTS_COMPLETED =
  "CE_GET_ASSESSMENT_DOCUMENTS_COMPLETED";
export interface GetAssessmentDocumentsCompleted extends Action {
  type: typeof actionTypes.GET_ASSESSMENT_DOCUMENTS_COMPLETED;
}

export type AssessmentDocumentsActions =
  | GetAssessmentDocumentsFailed
  | GetAssessmentDocumentsSuccess
  | GetAssessmentDocuments
  | GetAssessmentDocumentsCompleted
  | ResetAssessmentDocuments;
