import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import assessmentAdmin from "./assessmentAdmin";
import assessmentApplication from "./assessmentApplication";
import assessmentList from "./assessmentList";

export default function* assessmentSagas(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  yield all([fork(assessmentAdmin)]);
  yield all([fork(assessmentApplication)]);
  yield all([fork(assessmentList)]);
}
