import React from "react";
import { PageRoute } from "@quest-finance/quest-fe-shared/dist/common/types/PageRoute";

const AssessmentApplicationList = React.lazy(
  () => import("../pages/application/list")
);
const AssessmentApplicationAdmin = React.lazy(
  () => import("../pages/application/admin")
);

const AssessmentAccreditationList = React.lazy(
  () => import("../pages/accreditation/list")
);
const AssessmentAccreditationAdmin = React.lazy(
  () => import("../pages/accreditation/admin")
);
const AssessmentDetailsAccreditation = React.lazy(
  () => import("../pages/accreditation/details")
);

const routes: PageRoute[] = [
  {
    name: "Assessment Application Admin",
    path: "/assessment/assessments/:assessmentId/application/admin",
    exact: true,
    component: AssessmentApplicationAdmin,
  },
  {
    name: "Assessment Accreditation Admin",
    path: "/assessment/assessments/:assessmentId/accreditation/admin",
    exact: true,
    component: AssessmentAccreditationAdmin,
  },
  {
    name: "Assessment Details Accreditation",
    path: "/assessment/assessments/:assessmentId/accreditation",
    exact: true,
    component: AssessmentDetailsAccreditation,
  },
  {
    name: "Application List",
    path: "/assessment/assessments/applications",
    exact: true,
    component: AssessmentApplicationList,
  },
  {
    name: "Accreditation List",
    path: "/assessment/assessments/accreditations",
    exact: true,
    component: AssessmentAccreditationList,
  },
];

export default routes;
