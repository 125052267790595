import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AuditLogActions } from "../actions/types/auditLogs";
import { EntityIndividualActions } from "../actions/types/entityIndividual";
import type { InternalNotesActions } from "../actions/types/internalNotes";

export const useInternalNotesDispatch = (): Dispatch<InternalNotesActions> =>
  useDispatch();

export const useAuditLogsDispatch = (): Dispatch<AuditLogActions> =>
  useDispatch();

export const useEntityIndividualDispatch = (): Dispatch<
  EntityIndividualActions
> => useDispatch();
