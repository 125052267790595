import joi from "joi";

export const creditReferenceSchema = joi.object({
  bank: joi.string().max(100).required().messages({
    "string.empty": "Bank is required",
    "string.required": "Bank is required",
  }),
  naf: joi.string().required().messages({
    "string.empty": "NAF is required",
    "string.required": "NAF is required",
  }),
  start: joi.string().required().messages({
    "string.empty": "Start is required",
    "string.required": "Start is required",
  }),
  term: joi.string().max(100).required().messages({
    "string.empty": "Term is required",
    "string.required": "Term is required",
  }),
  asset: joi.string().max(100).required().messages({
    "string.empty": "Asset is required",
    "string.required": "Asset is required",
  }),
  paymentAmount: joi.string().required().messages({
    "string.empty": "Payment amount is required",
    "string.required": "Payment amount is required",
  }),
  balloon: joi.string().required().messages({
    "string.empty": "Balloon is required",
    "string.required": "Balloon is required",
  }),
  outstandingBalance: joi.string().required().messages({
    "string.empty": "Outstanding balance is required",
    "string.required": "Outstanding balance is required",
  }),
  conduct: joi.string().max(100).messages({
    "string.empty": "Conduct is required",
    "string.required": "Conduct is required",
  }),
  notes: joi.string().max(500).allow(""),
});
