import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import * as queryString from "query-string";
import { ApprovalConditionResponse } from "../types/ApprovalConditionResponse";
import { ApprovalConditionsSubmitRequest } from "../types/ApprovalConditionsSubmitRequest";
import { AssessmentAssigneeRequest } from "../types/AssessmentAssigneeRequest";
import { AssessmentListRequest } from "../types/AssessmentListRequest";
import { AssessmentResponse } from "../types/AssessmentResponse";
import { AssessmentStatusUpdateRequest } from "../types/AssessmentStatusUpdateRequest";

class AssessmentService {
  public static async getAssessmentList(
    payload: AssessmentListRequest
  ): Promise<ListResponse<AssessmentResponse>> {
    const queryParams = queryString.stringify(payload, {
      arrayFormat: "comma",
    });

    const result = await APIService.jsonRequest<
      ListResponse<AssessmentResponse>,
      unknown
    >(
      {
        method: "GET",
        path: `/assessment/assessments?${queryParams}`,
      },
      true
    );
    return result;
  }

  public static async getAssessmentDetails(
    assessmentId: string
  ): Promise<SingleResponse<AssessmentResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<AssessmentResponse>,
      unknown
    >(
      {
        method: "GET",
        path: `/assessment/assessments/${assessmentId}`,
      },
      true
    );
    return result;
  }

  public static async submitApprovalConditions(
    assessmentId: string,
    data: ApprovalConditionsSubmitRequest
  ): Promise<ListResponse<ApprovalConditionResponse>> {
    const result = await APIService.jsonRequest<
      ListResponse<ApprovalConditionResponse>,
      ApprovalConditionsSubmitRequest
    >(
      {
        method: "POST",
        path: `/assessment/assessments/${assessmentId}/approval-conditions`,
        data,
      },
      true
    );
    return result;
  }

  public static async updateAssessmentStatus(
    assessmentId: string,
    status: string
  ): Promise<SingleResponse<AssessmentResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<AssessmentResponse>,
      AssessmentStatusUpdateRequest
    >(
      {
        method: "PUT",
        path: `/assessment/assessments/${assessmentId}/status`,
        data: {
          status,
        },
      },
      true
    );
    return result;
  }

  public static async setAssignee(
    assessmentId: string,
    data: AssessmentAssigneeRequest[]
  ): Promise<SingleResponse<AssessmentResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<AssessmentResponse>,
      AssessmentAssigneeRequest[]
    >(
      {
        method: "PUT",
        path: `/assessment/assessments/${assessmentId}/assignee`,
        data,
      },
      true
    );
    return result;
  }

  public static async getIntroducerAssessmentTotal(
    brokerId: number
  ): Promise<SingleResponse<Dictionary>> {
    const result = await APIService.jsonRequest<
      SingleResponse<Dictionary>,
      unknown
    >(
      {
        method: "GET",
        path: `/assessment/assessments/user/${brokerId}/total-assessment`,
      },
      true
    );
    return result;
  }
}

export default AssessmentService;
