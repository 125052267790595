import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import * as assessmentNoteTypes from "../actions/types/noteList";
import { LIST_STATE } from "../constants/notes";
import { ListState } from "../types/Notes";

export const notesListState: ListState = LIST_STATE;

export const reducer = (
  state: ListState = notesListState,
  action: assessmentNoteTypes.AssessmentNotesListActions
): ListState => {
  switch (action.type) {
    case assessmentNoteTypes.RESET_ASSESSMENT_NOTES: {
      const { lastResetTimestamp } = action;
      return {
        ...notesListState,
        lastResetTimestamp,
      };
    }
    case assessmentNoteTypes.GET_ASSESSMENT_NOTES: {
      return {
        ...state,
        status: LOADING_STATUS.LOADING,
      };
    }
    case assessmentNoteTypes.GET_ASSESSMENT_NOTES_SUCCESS: {
      return {
        ...state,
        notes: action.response.data,
        count: action.response.count ? action.response.count : 0,
        status: LOADING_STATUS.SUCCESS,
      };
    }
    case assessmentNoteTypes.GET_ASSESSMENT_NOTES_FAILED: {
      return {
        ...state,
        error: action.error,
        status: LOADING_STATUS.FAILED,
      };
    }
    case assessmentNoteTypes.GET_ASSESSMENT_NOTES_COMPLETED: {
      return {
        ...state,
        status: LOADING_STATUS.IDLE,
      };
    }
    case assessmentNoteTypes.UPDATE_ASSESSMENT_NOTE: {
      const notes = [...state.notes];
      const noteIndex = notes.findIndex((note) => note.id === action.note.id);

      notes[noteIndex] = {
        ...notes[noteIndex],
        ...action.note,
      };

      return {
        ...state,
        notes,
      };
    }
    default: {
      return state;
    }
  }
};
