import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import {
  updateAssessmentNote,
  resetAssessmentNotes,
} from "../../actions/creators/noteList";
import { SECTION_KEY } from "../../constants/notes";
import { useNoteListDispatch } from "../../dispatchers/";
import { Slug } from "../../types/Navigation";
import { NoteDocument } from "../../types/Notes";
import NoteForm from "./note-form/NoteForm";
import NoteList from "./note-list/NoteList";

import "./index.scss";

const Notes: React.FunctionComponent = () => {
  const notesListDispatch = useNoteListDispatch();
  const [lastUpdated, setLastUpdated] = useState<NoteDocument>();
  const [note, setNote] = useState<NoteDocument | undefined>();
  const { section } = useParams<{
    section: string;
  }>();

  const onPageChangeHandler = () => {
    setNote(undefined);
  };

  const listSection = useMemo(() => {
    return !([Slug.DECISION, Slug.SETTLEMENT] as string[]).includes(section)
      ? SECTION_KEY[section]
      : "";
  }, [section]);

  const onUpdateHandler = useCallback((note: NoteDocument) => {
    setNote(note);
  }, []);

  const onCancelHandler = useCallback(() => {
    setNote(undefined);
  }, []);

  const onSaveHandler = (savedNote: NoteDocument) => {
    if (note) {
      notesListDispatch(updateAssessmentNote(savedNote));
      setLastUpdated(savedNote);
    } else {
      notesListDispatch(resetAssessmentNotes(Date.now()));
    }

    setNote(undefined);
  };

  return (
    <div className="notes-section content-section">
      <NoteForm
        section={SECTION_KEY[section]}
        maxLength={10000}
        noteEntry={note}
        onCancel={onCancelHandler}
        onSave={onSaveHandler}
      />
      <NoteList
        section={listSection}
        showSectionDetail={([
          Slug.DECISION,
          Slug.SETTLEMENT,
        ] as string[]).includes(section)}
        onUpdate={onUpdateHandler}
        onPageChange={onPageChangeHandler}
        lastUpdated={lastUpdated}
      />
    </div>
  );
};

export default Notes;
