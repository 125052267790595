import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import taskNote from "./taskNote";
import workflow from "./workflow";

export default function* assessmentSagas(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  yield all([fork(workflow)]);
  yield all([fork(taskNote)]);
}
