export enum NOTIFICATION_IDS {
  ACCREDITATION_STATUS_UPDATE = "ACCREDITATION_STATUS_UPDATE",
  APPLICATION_STATUS_UPDATE = "APPLICATION_STATUS_UPDATE",
  SUBMIT_TO_LMS = "SUBMIT_TO_LMS",
  ASSIGNEE_UPDATED = "ASSIGNEE_UPDATED",
  ASSESSMENT_APPLICATION_ERROR = "ASSESSMENT_APPLICATION_ERROR",
  ASSESSMENT_DETAILS_ERROR = "ASSESSMENT_DETAILS_ERROR",
  INTERNAL_NOTES_ERROR = "INTERNAL_NOTES_ERROR",
  DOCUMENT_ERROR = "DOCUMENT_ERROR",
  APPLICATION_LIST_ERROR = "APPLICATION_LIST_ERROR",
  APPROVAL_CONDITIONS_ERROR = "APPROVAL_CONDITIONS_ERRORS",
  INTRODUCER_DETAILS = "INTRODUCER_DETAILS",
  CREDIT_SCORE = "CREDIT_SCORE",
  CREDIT_REPORT = "CREDIT_REPORT",
  FUNDER_LIST_ERROR = "FUNDER_LIST_ERROR",
}
