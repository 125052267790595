import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import * as taskNoteActions from "../actions/types/taskNote";
import { TaskNoteState } from "../types/TaskNote";

export const taskNote: TaskNoteState = {
  taskNotes: [],
  count: 0,
  status: LOADING_STATUS.IDLE,
};

export const reducer = (
  state: TaskNoteState = taskNote,
  action: taskNoteActions.TaskNoteActions
): TaskNoteState => {
  switch (action.type) {
    case taskNoteActions.RESET_TASK_NOTE: {
      return {
        ...taskNote,
      };
    }
    case taskNoteActions.GET_TASK_NOTES: {
      return {
        ...state,
        status: LOADING_STATUS.LOADING,
      };
    }
    case taskNoteActions.GET_TASK_NOTES_SUCCESS: {
      return {
        ...state,
        taskNotes: action.taskNotes,
        count: action.count,
        status: LOADING_STATUS.SUCCESS,
      };
    }
    case taskNoteActions.GET_TASK_NOTES_FAILED: {
      return {
        ...state,
        status: LOADING_STATUS.FAILED,
      };
    }
    case taskNoteActions.GET_TASK_NOTES_COMPLETED: {
      return {
        ...state,
        status: LOADING_STATUS.IDLE,
      };
    }
    case taskNoteActions.UPDATE_TASK_NOTE: {
      const newTaskNotes = [...state.taskNotes];
      const taskNoteIndex = newTaskNotes.findIndex(
        (note) => note.id === action.note.id
      );

      newTaskNotes[taskNoteIndex] = {
        ...newTaskNotes[taskNoteIndex],
        ...action.note,
      };

      return {
        ...state,
        taskNotes: newTaskNotes,
      };
    }
    default: {
      return state;
    }
  }
};
