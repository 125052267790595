import {
  AuditLogResponse,
  AuditLogListRequest,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { Action } from "redux";
import { InternalNotesListRequest } from "../../../common/internal-notes/types/InternalNotesListRequest";
import { InternalNotesResponse } from "../../../common/internal-notes/types/InternalNotesResponse";
import { DOCUMENT_PURPOSES } from "../../constants/documentPurposes";
import { AssessmentAssigneeRequest } from "../../types/AssessmentAssigneeRequest";
import { AssessmentDocument } from "../../types/AssessmentDocument";
import { AssessmentResponse } from "../../types/AssessmentResponse";

export const RESET_ASSESSMENT_DETAILS = "RESET_ASSESSMENT_DETAILS";
export interface ResetAssessmentDetails extends Action {
  type: typeof RESET_ASSESSMENT_DETAILS;
}

export const GET_ASSESSMENT_DETAILS = "GET_ASSESSMENT_DETAILS";
export interface GetAssessmentDetails extends Action {
  type: typeof GET_ASSESSMENT_DETAILS;
  assessmentId: string;
}

export const GET_ASSESSMENT_DETAILS_SUCCESS = "GET_ASSESSMENT_DETAILS_SUCCESS";
export interface GetAssessmentDetailsSuccess extends Action {
  type: typeof GET_ASSESSMENT_DETAILS_SUCCESS;
  assessment: AssessmentResponse;
}

export const GET_ASSESSMENT_DETAILS_FAILED = "GET_ASSESSMENT_DETAILS_FAILED";
export interface GetAssessmentDetailsFailed extends Action {
  type: typeof GET_ASSESSMENT_DETAILS_FAILED;
}

export const UPDATE_ASSESSMENT_STATUS = "UPDATE_ASSESSMENT_STATUS";
export interface UpdateAssessmentStatus extends Action {
  type: typeof UPDATE_ASSESSMENT_STATUS;
  assessmentId: string;
  status: string;
}

export const UPDATE_ASSESSMENT_STATUS_SUCCESS =
  "UPDATE_ASSESSMENT_STATUS_SUCCESS";
export interface UpdateAssessmentStatusSuccess extends Action {
  type: typeof UPDATE_ASSESSMENT_STATUS_SUCCESS;
}

export const UPDATE_ASSESSMENT_STATUS_FAILED =
  "UPDATE_ASSESSMENT_STATUS_FAILED";
export interface UpdateAssessmentStatusFailed extends Action {
  type: typeof UPDATE_ASSESSMENT_STATUS_FAILED;
}

type AssessmentFormActions =
  | ResetAssessmentDetails
  | GetAssessmentDetails
  | GetAssessmentDetailsSuccess
  | GetAssessmentDetailsFailed
  | UpdateAssessmentStatus
  | UpdateAssessmentStatusSuccess
  | UpdateAssessmentStatusFailed;

export const GET_INTERNAL_NOTES_LIST = "GET_INTERNAL_NOTES_LIST";
export interface GetInternalNotesList extends Action {
  type: typeof GET_INTERNAL_NOTES_LIST;
  assessmentId: string;
  payload: InternalNotesListRequest;
}

export const GET_INTERNAL_NOTES_LIST_SUCCESS =
  "GET_INTERNAL_NOTES_LIST_SUCCESS";
export interface GetInternalNotesListSuccess extends Action {
  type: typeof GET_INTERNAL_NOTES_LIST_SUCCESS;
  listResponse: ListResponse<InternalNotesResponse>;
}

export const GET_INTERNAL_NOTES_LIST_ERROR = "GET_INTERNAL_NOTES_LIST_ERROR";
export interface GetInternalNotesListError extends Action {
  type: typeof GET_INTERNAL_NOTES_LIST_ERROR;
}

export const SUBMIT_INTERNAL_NOTES = "SUBMIT_INTERNAL_NOTES";
export interface SubmitInternalNotes extends Action {
  type: typeof SUBMIT_INTERNAL_NOTES;
  assessmentId: string;
  note: string;
}

export const SUBMIT_INTERNAL_NOTES_SUCCESS = "SUBMIT_INTERNAL_NOTES_SUCCESS";
export interface SubmitInternalNotesSuccess extends Action {
  type: typeof SUBMIT_INTERNAL_NOTES_SUCCESS;
}

export const SUBMIT_INTERNAL_NOTES_FAILED = "SUBMIT_INTERNAL_NOTES_FAILED";
export interface SubmitInternalNotesFailed extends Action {
  type: typeof SUBMIT_INTERNAL_NOTES_FAILED;
}

type InternalNotesActions =
  | GetInternalNotesList
  | GetInternalNotesListSuccess
  | GetInternalNotesListError
  | SubmitInternalNotes
  | SubmitInternalNotesSuccess
  | SubmitInternalNotesFailed;

export const UPLOAD_ASSESSMENT_DOCUMENT = "UPLOAD_ASSESSMENT_DOCUMENT";

export interface UploadAssessmentDocument extends Action {
  type: typeof UPLOAD_ASSESSMENT_DOCUMENT;
  document: File;
  purpose: DOCUMENT_PURPOSES;
  assessmentId: string;
}

export const SET_ASSESSMENT_UPLOAD_PROGRESS = "SET_ASSESSMENT_UPLOAD_PROGRESS";
export interface SetAssessmentUploadProgress extends Action {
  type: typeof SET_ASSESSMENT_UPLOAD_PROGRESS;
  progress: number;
}

export const UPLOAD_ASSESSMENT_DOCUMENT_SUCCESS =
  "UPLOAD_ASSESSMENT_DOCUMENT_SUCCESS";
export interface UploadAssessmentDocumentSuccess extends Action {
  type: typeof UPLOAD_ASSESSMENT_DOCUMENT_SUCCESS;
  document: AssessmentDocument;
}

export const DELETE_ASSESSMENT_DOCUMENT = "DELETE_ASSESSMENT_DOCUMENT";
export interface DeleteAssessmentDocument extends Action {
  type: typeof DELETE_ASSESSMENT_DOCUMENT;
  assessmentId: string;
  documentId: string;
}

export const DELETE_ASSESSMENT_DOCUMENT_SUCCESS =
  "DELETE_ASSESSMENT_DOCUMENT_SUCCESS";
export interface DeleteAssessmentDocumentSuccess extends Action {
  type: typeof DELETE_ASSESSMENT_DOCUMENT_SUCCESS;
}
export const DELETE_ASSESSMENT_DOCUMENT_FAILED =
  "DELETE_ASSESSMENT_DOCUMENT_FAILED";
export interface DeleteAssessmentDocumentFailed extends Action {
  type: typeof DELETE_ASSESSMENT_DOCUMENT_FAILED;
}

type InternalDocumentsActions =
  | UploadAssessmentDocument
  | SetAssessmentUploadProgress
  | UploadAssessmentDocumentSuccess
  | DeleteAssessmentDocument
  | DeleteAssessmentDocument
  | DeleteAssessmentDocumentSuccess;

export const GET_APPLICATION_AUDIT_LOG = "GET_APPLICATION_AUDIT_LOG";
export interface GetApplicationAuditLog extends Action {
  type: typeof GET_APPLICATION_AUDIT_LOG;
  payload: AuditLogListRequest;
}

export const GET_APPLICATION_AUDIT_LOG_SUCCESS =
  "GET_APPLICATION_AUDIT_LOG_SUCCESS";
export interface GetApplicationAuditLogSuccess extends Action {
  type: typeof GET_APPLICATION_AUDIT_LOG_SUCCESS;
  auditLogList: ListResponse<AuditLogResponse>;
}

export const GET_APPLICATION_AUDIT_LOG_FAILED =
  "GET_APPLICATION_AUDIT_LOG_FAILED";
export interface GetApplicationAuditLogFailed extends Action {
  type: typeof GET_APPLICATION_AUDIT_LOG_FAILED;
}

export const GET_ASSESSMENT_AUDIT_LOG = "GET_ASSESSMENT_AUDIT_LOG";
export interface GetAssessmentAuditLog extends Action {
  type: typeof GET_ASSESSMENT_AUDIT_LOG;
  payload: AuditLogListRequest;
}

export const GET_ASSESSMENT_AUDIT_LOG_SUCCESS =
  "GET_ASSESSMENT_AUDIT_LOG_SUCCESS";
export interface GetAssessmentAuditLogSuccess extends Action {
  type: typeof GET_ASSESSMENT_AUDIT_LOG_SUCCESS;
  auditLogList: ListResponse<AuditLogResponse>;
}

export const GET_ASSESSMENT_AUDIT_LOG_FAILED =
  "GET_ASSESSMENT_AUDIT_LOG_FAILED";
export interface GetAssessmentAuditLogFailed extends Action {
  type: typeof GET_ASSESSMENT_AUDIT_LOG_FAILED;
}

export const GET_ACCREDITATION_AUDIT_LOG = "GET_ACCREDITATION_AUDIT_LOG";
export interface GetAccreditationAuditLog extends Action {
  type: typeof GET_ACCREDITATION_AUDIT_LOG;
  payload: AuditLogListRequest;
}

export const GET_ACCREDITATION_AUDIT_LOG_SUCCESS =
  "GET_ACCREDITATION_AUDIT_LOG_SUCCESS";
export interface GetAccreditationAuditLogSuccess extends Action {
  type: typeof GET_ACCREDITATION_AUDIT_LOG_SUCCESS;
  auditLogList: ListResponse<AuditLogResponse>;
}

export const GET_ACCREDITATION_AUDIT_LOG_FAILED =
  "GET_ACCREDITATION_AUDIT_LOG_FAILED";
export interface GetAccreditationAuditLogFailed extends Action {
  type: typeof GET_ACCREDITATION_AUDIT_LOG_FAILED;
}

export const GET_FUNDER_AUDIT_LOGS = "GET_FUNDER_AUDIT_LOGS";
export interface GetFunderAuditLogs extends Action {
  type: typeof GET_FUNDER_AUDIT_LOGS;
  payload: AuditLogListRequest;
}

export const GET_FUNDER_AUDIT_LOGS_SUCCESS = "GET_FUNDER_AUDIT_LOGS_SUCCESS";
export interface GetFunderAuditLogSuccess extends Action {
  type: typeof GET_FUNDER_AUDIT_LOGS_SUCCESS;
  auditLogList: ListResponse<AuditLogResponse>;
}

export const GET_FUNDER_AUDIT_LOG_FAILED = "GET_FUNDER_AUDIT_LOG_FAILED";
export interface GetFunderAuditLogFailed extends Action {
  type: typeof GET_FUNDER_AUDIT_LOG_FAILED;
}

type AuditLogActions =
  | GetApplicationAuditLog
  | GetAssessmentAuditLog
  | GetAccreditationAuditLog
  | GetApplicationAuditLogSuccess
  | GetAssessmentAuditLogSuccess
  | GetAccreditationAuditLogSuccess
  | GetApplicationAuditLogFailed
  | GetAssessmentAuditLogFailed
  | GetAccreditationAuditLogFailed
  | GetFunderAuditLogs
  | GetFunderAuditLogSuccess
  | GetFunderAuditLogFailed;

export const SET_ASSIGNEE = "SET_ASSIGNEE";
export interface SetAssignee extends Action {
  type: typeof SET_ASSIGNEE;
  assessmentId: string;
  assigneeRequest: AssessmentAssigneeRequest[];
}

export const SET_ASSIGNEE_SUCCESS = "SET_ASSIGNEE_SUCCESS";
export interface SetAssigneeSuccess extends Action {
  type: typeof SET_ASSIGNEE_SUCCESS;
  assessment: AssessmentResponse;
}

export const SET_ASSIGNEE_FAILED = "SET_ASSIGNEE_FAILED";
export interface SetAssigneeFailed extends Action {
  type: typeof SET_ASSIGNEE_FAILED;
}

type SetAssigneeActions = SetAssignee | SetAssigneeSuccess | SetAssigneeFailed;

export type AssessmentDetailsActions =
  | AssessmentFormActions
  | InternalNotesActions
  | InternalDocumentsActions
  | AuditLogActions
  | SetAssigneeActions;
