import diffInYears from "date-fns/differenceInYears";

export const calcualteAgeEOT = (
  manufactureYear: number,
  repaymentTermMonth: number
): number => {
  const currentYear = new Date().getFullYear();
  const ageSinceManufacture = diffInYears(
    new Date(currentYear, 0, 0),
    new Date(manufactureYear as number, 0, 0)
  );
  const loanTermYears = repaymentTermMonth / 12;

  return ageSinceManufacture + loanTermYears;
};

export const calculateLvr = (
  amountFinanced: number,
  brokerageNominalWithGst: number,
  value?: number | null
): number => {
  let lvr = 0;

  if (value) {
    const amoundFunded = amountFinanced + brokerageNominalWithGst;
    lvr = (amoundFunded / value) * 100;
  }

  return lvr;
};
