import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import * as assessmentDetailsTypes from "../actions/types/documentList";
import { ASSESSMENT_DOCUMENT_DEFAULT_STATE } from "../constants/documentList";
import { DocumentListState } from "../types/AssessmentDocument";

export const documentListState: DocumentListState = ASSESSMENT_DOCUMENT_DEFAULT_STATE;

export const reducer = (
  state: DocumentListState = documentListState,
  action: assessmentDetailsTypes.AssessmentDocumentsActions
): DocumentListState => {
  switch (action.type) {
    case assessmentDetailsTypes.RESET_ASSESSMENT_DOCUMENTS: {
      return {
        ...documentListState,
      };
    }
    case assessmentDetailsTypes.GET_ASSESSMENT_DOCUMENTS: {
      return {
        ...state,
        status: LOADING_STATUS.LOADING,
      };
    }
    case assessmentDetailsTypes.GET_ASSESSMENT_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        documents: action.response.data,
        count: action.response.count ? action.response.count : 0,
        status: LOADING_STATUS.SUCCESS,
      };
    }
    case assessmentDetailsTypes.GET_ASSESSMENT_DOCUMENTS_FAILED: {
      return {
        ...state,
        status: LOADING_STATUS.FAILED,
      };
    }
    case assessmentDetailsTypes.GET_ASSESSMENT_DOCUMENTS_COMPLETED: {
      return {
        ...state,
        status: LOADING_STATUS.IDLE,
      };
    }
    default: {
      return state;
    }
  }
};
