import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { NoteDocument, ListRequestParams } from "../../types/Notes";
import * as actionTypes from "../types/noteList";

export const getAssessmentNotes = (
  assessmentId: string,
  queryParams: ListRequestParams
): actionTypes.GetAssessmentNotes => ({
  type: actionTypes.GET_ASSESSMENT_NOTES,
  assessmentId,
  queryParams,
});

export const getAssessmentNotesSuccess = (
  response: ListResponse<NoteDocument>
): actionTypes.GetAssessmentNotesSuccess => ({
  type: actionTypes.GET_ASSESSMENT_NOTES_SUCCESS,
  response,
});

export const getAssessmentNotesFailed = (
  error: unknown
): actionTypes.GetAssessmentNotesFailed => ({
  type: actionTypes.GET_ASSESSMENT_NOTES_FAILED,
  error,
});

export const getAssessmentNotesCompleted = (): actionTypes.GetAssessmentNotesCompleted => ({
  type: actionTypes.GET_ASSESSMENT_NOTES_COMPLETED,
});

export const resetAssessmentNotes = (
  lastResetTimestamp = 0
): actionTypes.ResetAssessmentNotes => ({
  type: actionTypes.RESET_ASSESSMENT_NOTES,
  lastResetTimestamp,
});

export const updateAssessmentNote = (
  note: NoteDocument
): actionTypes.UpdateAssessmentNote => ({
  type: actionTypes.UPDATE_ASSESSMENT_NOTE,
  note,
});
