import React from "react";
import { PageRoute } from "@quest-finance/quest-fe-shared/dist/common/types/PageRoute";

const ApplicationDetailsPage = React.lazy(
  () => import("../pages/ApplicationDetails")
);
const ApplicationList = React.lazy(() => import("../pages/ApplicationList"));

const routes: PageRoute[] = [
  {
    name: "Application Details",
    path: [
      "/application/applications/:applicationId",
      "/application/applications/:applicationId/:tabName",
    ],
    exact: true,
    component: ApplicationDetailsPage,
  },
  {
    name: "Application List",
    path: "/application/applications",
    exact: true,
    component: ApplicationList,
  },
];

export default routes;
