import "./Uploader.scss";
import React, { useCallback, useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { supportEmail } from "@quest-finance/quest-fe-shared/dist/common/constants/app";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_FILE_SIZE_MEGABYTES,
} from "@quest-finance/quest-fe-shared/dist/common/constants/validation";
import { formatBytes } from "@quest-finance/quest-fe-shared/dist/common/utils/number";
import { Upload, Alert, Progress } from "antd";
import { UploadProps } from "antd/es";
import { UploadFile } from "antd/lib/upload/interface";
import classNames from "classnames";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import { ALLOWED_MIME_TYPES } from "../../constants/allowedMimeTypes";
import { InvalidFiles } from "../../types";
import { uploadDocument } from "../../utils/document";

const { Dragger } = Upload;

const allowedFiles = ALLOWED_FILE_TYPES.map((fileType: string) =>
  fileType.substring(1)
).join(", ");

const uploadProps: UploadProps = {
  name: "document",
  headers: {
    authorization: "Bearer ",
  },
  beforeUpload: () => {
    return false;
  },
  showUploadList: false,
  multiple: true,
};

type UploaderProps = {
  className?: string;
  uploadPath: string;
  documentPurpose: string;
  onUploadComplete?: (numOfFileUploaded: number) => void;
  disabled?: boolean;
  testid?: string;
};
const Uploader: React.FunctionComponent<UploaderProps> = ({
  className,
  uploadPath,
  documentPurpose,
  onUploadComplete,
  disabled,
  testid = "",
}: UploaderProps) => {
  const [uploading, setUploading] = useState<LOADING_STATUS>(
    LOADING_STATUS.IDLE
  );
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadingCount, setUploadingCount] = useState(0);
  const [invalidFiles, setInvalidFiles] = useState<InvalidFiles[]>([]);
  const [totalFiles, setTotalFiles] = useState({
    success: 0,
    failed: 0,
    total: 0,
  });

  const onUploadCompleteCallback = useCallback(
    (numOfFileUploaded: number) => {
      if (onUploadComplete) {
        onUploadComplete(numOfFileUploaded);
      }
      setUploading(LOADING_STATUS.IDLE);
    },
    [onUploadComplete]
  );

  useEffect(() => {
    if (
      fileList.length > 0 &&
      totalFiles.total === fileList.length &&
      uploadingCount > fileList.length
    ) {
      onUploadCompleteCallback(totalFiles.success);

      if (totalFiles.success === totalFiles.total) {
        showSuccessPopUp("Successfully uploaded all the document(s)");
      } else {
        showErrorPopUp(
          "One or more documents failed to be uploaded, please refresh the page and try again"
        );
      }

      setUploading(LOADING_STATUS.IDLE);
      setTotalFiles({
        total: 0,
        failed: 0,
        success: 0,
      });
      setUploadingCount(0);
      setFileList([]);
    }
  }, [
    totalFiles,
    uploadingCount,
    fileList,
    onUploadCompleteCallback,
    uploading,
  ]);

  const handleUpload = (file: File) => {
    setUploading(LOADING_STATUS.LOADING);
    uploadDocument({
      path: uploadPath,
      formData: {
        document: file,
        purpose: documentPurpose,
      },
      onSuccess: () => {
        setUploadingCount((prevData) => prevData + 1);
        setTotalFiles((previousData) => ({
          ...previousData,
          success: previousData.success + 1,
          total: previousData.total + 1,
        }));
      },
      onError: () => {
        setUploadingCount((prevData) => prevData + 1);
        setTotalFiles((previousData) => ({
          ...previousData,
          failed: previousData.failed + 1,
          total: previousData.total + 1,
        }));
      },
    });
  };

  const validateFile = (file: File) => {
    const errors: string[] = [];
    if (!ALLOWED_MIME_TYPES.includes(file.type)) {
      errors.push("Invalid file type");
    }

    if (file.size / 1024 / 1024 > ALLOWED_FILE_SIZE_MEGABYTES) {
      errors.push("File size too large");
    }

    if (errors.length > 0) {
      setInvalidFiles((files) => {
        const newFiles = [...files];
        newFiles.push({
          fileName: file.name,
          size: file.size,
          errors,
        });
        return newFiles;
      });
      return false;
    }

    return true;
  };

  uploadProps.onChange = (info) => {
    const fileIndex = info.fileList.findIndex(
      (file) => file.uid === info.file.uid
    );

    if (fileIndex === 0) {
      setInvalidFiles([]);
    }

    if (!validateFile(info.file)) {
      return;
    }

    if (fileIndex === 0) {
      setUploadingCount(1);
    }

    setFileList((files) => {
      const newFiles = [...files];
      newFiles.push(info.file);
      return newFiles;
    });

    handleUpload(info.file);
  };

  uploadProps.disabled = uploading === LOADING_STATUS.LOADING;
  uploadProps.fileList = fileList;

  return (
    <div className={classNames("quest-uploader", className)}>
      <Dragger
        {...uploadProps}
        className="mb-3"
        disabled={disabled}
        data-testid={testid}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        {disabled ? (
          <p className="ant-upload-text">Upload not available</p>
        ) : (
          <p className="ant-upload-text">
            {uploading === LOADING_STATUS.LOADING
              ? `Uploading ${uploadingCount} of ${fileList && fileList.length}`
              : "Click or drag file to this area to upload"}
          </p>
        )}
        <div className="ant-upload-progress">
          {uploading === LOADING_STATUS.LOADING && (
            <Progress status="active" percent={100} showInfo={false} />
          )}
        </div>
      </Dragger>
      {invalidFiles.length > 0 && (
        <div>
          <Alert
            message={
              <>
                The files listed below have not been saved. Please ensure the
                format is either {allowedFiles} with a maximum size of{" "}
                {ALLOWED_FILE_SIZE_MEGABYTES} MB. Please email{" "}
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a> if you
                would like any further help.
              </>
            }
            afterClose={() => setInvalidFiles([])}
            data-testid="alert-failed-upload"
            type="error"
            showIcon
            closable
          />
          <table className="invalid-files">
            <tbody>
              {invalidFiles.map((file, index) => (
                <tr key={index}>
                  <td
                    className="validation"
                    data-testid={`file-errors-${index}`}
                  >
                    {file.errors.map((error, i) => (
                      <div key={i}>{error}</div>
                    ))}
                  </td>
                  <td className="file" data-testid={`file-name-${index}`}>
                    {file.fileName} - {formatBytes(file.size)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Uploader;
