import { combineReducers } from "redux";
import { reducer as assessmentAdminReducer } from "./assessmentAdmin";
import { reducer as assessmentApplication } from "./assessmentApplication";
import { reducer as assessmentListReducer } from "./assessmentList";

export default combineReducers({
  assessmentAdmin: assessmentAdminReducer,
  assessmentApplication: assessmentApplication,
  assessmentList: assessmentListReducer,
});
