import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import funderDetails from "./funderDetails";

export default function* funderSagas(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  yield all([fork(funderDetails)]);
}
