import { FunderRequest, FunderResponse } from "../../types/Funder";
import * as types from "../types/funderDetails";

export const getFunderDetails = (
  assessmentId: string
): types.GetFunderDetails => ({
  type: types.GET_FUNDER_DETAILS,
  assessmentId,
});

export const getFunderDetailsSuccess = (
  funder: FunderResponse | null
): types.GetFunderDetailsSuccess => ({
  type: types.GET_FUNDER_DETAILS_SUCCESS,
  funder,
});

export const getFunderDetailsFailed = (): types.GetFunderDetailsFailed => ({
  type: types.GET_FUNDER_DETAILS_FAILED,
});

export const saveFunder = (
  assessmentId: string,
  data: FunderRequest
): types.SaveFunder => ({
  type: types.SAVE_FUNDER,
  assessmentId,
  data,
});

export const saveFunderSuccess = (): types.SaveFunderSuccess => ({
  type: types.SAVE_FUNDER_SUCCESS,
});

export const saveFunderFailed = (): types.SaveFunderFailed => ({
  type: types.SAVE_FUNDER_FAILED,
});

export const resetFunderDetailsState = (): types.ResetFunderDetailsState => ({
  type: types.RESET_FUNDER_DETAILS_STATE,
});
