import { createSelector } from "@reduxjs/toolkit";
import { TaskNoteState } from "../types/TaskNote";
import { WorkflowState } from "../types/WorkflowState";

export const taskNoteSelector = (state: WorkflowState): TaskNoteState =>
  state.workflow.taskNote;

export const taskNotesSelector = createSelector(
  taskNoteSelector,
  (taskNote) => taskNote.taskNotes
);

export const taskNotesCountSelector = createSelector(
  taskNoteSelector,
  (taskNote) => taskNote.count
);

export const taskNoteStatusSelector = createSelector(
  taskNoteSelector,
  (taskNote) => taskNote.status
);
