import React, { useCallback } from "react";
import { Form, Radio, RadioChangeEvent, Select } from "antd";
import { TASK_INPUT_TYPES } from "../../../constants/workflow";

type InputTypeProps = {
  value: string;
  onChange: (value: string) => void;
  outcomes?: Record<string, unknown>[];
  disabled?: boolean;
};

const InputTypeSelect: React.FC<InputTypeProps> = ({
  value,
  onChange,
  outcomes = [],
  disabled,
}: InputTypeProps) => {
  const options = outcomes?.map((outcome) => ({
    label: outcome.description as string,
    value: outcome.value as string,
  }));

  return (
    <Form>
      <Form.Item>
        <Select
          value={value}
          onChange={onChange}
          options={options}
          disabled={disabled}
        />
      </Form.Item>
    </Form>
  );
};

const InputTypeRadio: React.FC<InputTypeProps> = ({
  value,
  onChange,
  outcomes = [],
  disabled,
}: InputTypeProps) => {
  const onRadioChange = useCallback(
    (e: RadioChangeEvent) => {
      const { value } = e.target;
      onChange(value);
    },
    [onChange]
  );

  return (
    <Radio.Group
      value={value}
      buttonStyle="solid"
      onChange={onRadioChange}
      className="qf-workflow-outcome"
      disabled={disabled}
    >
      {outcomes?.map((outcome) => {
        const className = ["YES", "NO"].includes(outcome.value as string)
          ? outcome.value
          : "ELSE";

        return (
          <Radio.Button
            key={outcome.value as string}
            value={outcome.value}
            className={`outcome-${className}`}
          >
            {outcome.description as string}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
};

export const INPUT_TYPE_MAPPING = {
  [TASK_INPUT_TYPES.RADIO]: InputTypeRadio,
  [TASK_INPUT_TYPES.SELECT]: InputTypeSelect,
};
