import { TaskNoteAttributes, TaskNoteListRequest } from "../../types/TaskNote";

export const GET_TASK_NOTES = "GET_TASK_NOTES";
export interface GetTaskNotes {
  type: typeof GET_TASK_NOTES;
  workflowId: number;
  taskId: number;
  payload?: TaskNoteListRequest;
}

export const GET_TASK_NOTES_SUCCESS = "GET_TASK_NOTES_SUCCESS";
export interface GetTaskNotesSuccess {
  type: typeof GET_TASK_NOTES_SUCCESS;
  taskNotes: TaskNoteAttributes[];
  count: number;
}

export const GET_TASK_NOTES_FAILED = "GET_TASK_NOTES_FAILED";
export interface GetTaskNotesFailed {
  type: typeof GET_TASK_NOTES_FAILED;
}

export const GET_TASK_NOTES_COMPLETED = "GET_TASK_NOTES_COMPLETED";
export interface GetTaskNotesCompleted {
  type: typeof GET_TASK_NOTES_COMPLETED;
}

export const RESET_TASK_NOTE = "RESET_TASK_NOTE";
export interface ResetTaskNote {
  type: typeof RESET_TASK_NOTE;
}

export const UPDATE_TASK_NOTE = "UPDATE_TASK_NOTE";
export interface UpdateTaskNote {
  type: typeof UPDATE_TASK_NOTE;
  note: TaskNoteAttributes;
}

export type TaskNoteActions =
  | GetTaskNotes
  | GetTaskNotesSuccess
  | GetTaskNotesFailed
  | GetTaskNotesCompleted
  | ResetTaskNote
  | UpdateTaskNote;
