import * as lodash from "lodash";
import defaultConfig from "../config/default.json";
import prodConfig from "../config/prod.json";

class Feature {
  private config: Record<string, unknown>;
  constructor(env?: string) {
    switch (env) {
      case "production": {
        this.config = prodConfig;
        break;
      }
      default: {
        this.config = defaultConfig;
        break;
      }
    }
  }

  getConfigValue(path = "") {
    return lodash.get(this.config, path, null);
  }

  checkFeature<T>(path: string, component: T): T | null {
    return this.getConfigValue(path) ? component : null;
  }
}

const FeatureService = new Feature(process.env.REACT_APP_ENV);
export default FeatureService;
