import querystring from "querystring";
import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import { ASSESSMENT_TYPES } from "../constants/assessmentTypes";
export interface AssessmentListRequest extends querystring.ParsedUrlQueryInput {
  status?: string | null;
  type: ASSESSMENT_TYPES;
  limit?: number | null;
  offset: number;
  order?: string | null;
  keyword?: string | null;
  clientId?: number;
  assignee?: number | null;
}

export interface AssessmentListParams {
  keyword?: string;
  limit?: number;
  page?: number;
  status?: string;
  clientId?: number;
  assigneeId?: number;
}

export const assessmentListRequestDefaultValue = {
  limit: DATATABLE_MAX_ROWS,
  type: ASSESSMENT_TYPES.APPLICATION,
  offset: 0,
};
