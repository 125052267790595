import React from "react";
import AuditLogList from "../../../components/assessment-audit/AssessmentAudit";

const AuditLogs: React.FunctionComponent = () => {
  return (
    <div className="content-section">
      <AuditLogList />
    </div>
  );
};

export default AuditLogs;
