import React, { useEffect, useCallback } from "react";
import { Col, Collapse, Row } from "antd";
import { useParams } from "react-router";
import { QfCollapse } from "../../../../../theme";
import { WORKFLOW_CODE } from "../../../../../workflow/constants/workflow";
import {
  resetAssessmentExtras,
  getAssessmentExtras,
} from "../../../../actions/creators/assessmentExtras";
import Applicant from "../../../../components/applicant/Applicant";
import Guarantors from "../../../../components/guarantors/Guarantors";
import WorkflowSection from "../../../../components/workflow-section/WorkflowSection";
import {
  APPLICANT_SCORE_KEY,
  APPLICANT_EQUIFAX_ENQUIRY_KEY,
  APPLICANT_EQUIFAX_TRADING_HISTORY_KEY,
  APPLICANT_EQUIFAX_INDEPTH_TRADING_HISTORY_KEY,
  GUARANTOR1_FRANKIEONE_KEY,
  GUARANTOR1_ILLION_KEY,
  GUARANTOR1_SCORE_KEY,
  GUARANTOR2_FRANKIEONE_KEY,
  GUARANTOR2_ILLION_KEY,
  GUARANTOR2_SCORE_KEY,
  GUARANTOR1_EQUIFAX_APPLY,
  GUARANTOR2_EQUIFAX_APPLY,
} from "../../../../constants/assessmentExtras";
import { useAssessmentExtrasDispatch } from "../../../../dispatchers";
import { Slug } from "../../../../types/Navigation";

const { Panel } = Collapse;
const assesmentExtraKeys = {
  [APPLICANT_SCORE_KEY]: ["equifax"],
  [APPLICANT_EQUIFAX_ENQUIRY_KEY]: ["score"],
  [APPLICANT_EQUIFAX_TRADING_HISTORY_KEY]: ["score"],
  [APPLICANT_EQUIFAX_INDEPTH_TRADING_HISTORY_KEY]: ["score"],
  [GUARANTOR1_SCORE_KEY]: ["equifax", "illion"],
  [GUARANTOR2_SCORE_KEY]: ["equifax", "illion"],
  [GUARANTOR1_ILLION_KEY]: ["score"],
  [GUARANTOR2_ILLION_KEY]: ["score"],
  [GUARANTOR1_FRANKIEONE_KEY]: ["status", "entity_id"],
  [GUARANTOR2_FRANKIEONE_KEY]: ["status", "entity_id"],
  [GUARANTOR1_EQUIFAX_APPLY]: ["score"],
  [GUARANTOR2_EQUIFAX_APPLY]: ["score"],
};

const ApplicantGuarantors: React.FunctionComponent = () => {
  const { assessmentId } = useParams<{
    assessmentId: string;
  }>();
  const assessmentExtrasDispatch = useAssessmentExtrasDispatch();

  const getCreditScores = useCallback(
    async (assessmentId: string) => {
      assessmentExtrasDispatch(
        getAssessmentExtras(assessmentId, assesmentExtraKeys)
      );
    },
    [assessmentExtrasDispatch]
  );

  useEffect(() => {
    if (assessmentId) {
      getCreditScores(assessmentId);
    }
  }, [assessmentId, getCreditScores]);

  useEffect(() => {
    return () => {
      assessmentExtrasDispatch(
        resetAssessmentExtras(Object.keys(assesmentExtraKeys))
      );
    };
  }, [assessmentExtrasDispatch]);

  return (
    <>
      <QfCollapse
        defaultActiveKey="applicant-guarantor"
        expandIconPosition="right"
        ghost
      >
        <Panel
          header={<div className="section-header-1">Application Data</div>}
          key="applicant-guarantor"
        >
          <Row gutter={[16, 16]}>
            <Col sm={24} lg={12}>
              <Applicant />
            </Col>
            <Col sm={24} lg={12}>
              <Guarantors />
            </Col>
          </Row>
        </Panel>
      </QfCollapse>
      <WorkflowSection
        activeStep={Slug.APPLICANT_GUARANTORS}
        code={WORKFLOW_CODE.CREDIT_WORKFLOW}
      />
    </>
  );
};

export default ApplicantGuarantors;
