import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import clientAdmin from "./clientAdmin";
import clientList from "./clientList";
import roleAdmin from "./roleAdmin";
import roleList from "./roleList";
import userAdmin from "./userAdmin";
import userList from "./userList";

export default function* applicationSagas(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  yield all([fork(clientAdmin)]);
  yield all([fork(clientList)]);
  yield all([fork(userAdmin)]);
  yield all([fork(userList)]);
  yield all([fork(roleAdmin)]);
  yield all([fork(roleList)]);
}
