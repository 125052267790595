import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { AxiosError } from "axios";
import { call, ForkEffect, put, takeEvery } from "redux-saga/effects";
import * as actionCreators from "../actions/creators/assessmentExtras";
import * as actionTypes from "../actions/types/assessmentExtras";
import AssessmentExtrasService from "../services/AssessmentExtrasService";
import { GetAssessmentExtrasResponse } from "../types/AssessmentExtras";

function* getAssessmentExtrasWorker({
  assessmentId,
  data,
  requestId,
}: actionTypes.GetAssessmentExtras) {
  try {
    const response: SingleResponse<GetAssessmentExtrasResponse> = yield call(
      AssessmentExtrasService.getData,
      assessmentId,
      data
    );
    yield put(
      actionCreators.getAssessmentExtrasSuccess(response.data, requestId)
    );
  } catch (e) {
    const data = (e as AxiosError)?.response?.data;
    yield put(
      actionCreators.getAssessmentExtrasFailed(
        {
          code: data.errorCode,
          message: data.errorMessage,
        },
        requestId
      )
    );
  }
}

function* watchAssessmentExtras(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(
    actionTypes.CE_GET_ASSESSMENT_EXTRAS,
    getAssessmentExtrasWorker
  );
}

export default watchAssessmentExtras;
