import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import * as notification from "@quest-finance/quest-fe-shared/dist/notification";
import { AxiosError } from "axios";
import { put, call, takeEvery, ForkEffect } from "redux-saga/effects";
import {
  getAssessmentListError,
  getAssessmentListSuccess,
} from "../actions/creators/assessmentList";
import {
  GetAssessmentList,
  GET_ASSESSMENT_LIST,
} from "../actions/types/assessmentList";
import { NOTIFICATION_IDS } from "../constants/notificationIds";
import AssessmentService from "../services/AssessmentService";
import { AssessmentResponse } from "../types/AssessmentResponse";

const {
  actionCreator: { clearNotification, setNotification },
} = notification;

function* getAssessmentListWorker({ payload }: GetAssessmentList) {
  try {
    yield put(clearNotification());
    const assessments: ListResponse<AssessmentResponse> = yield call(
      AssessmentService.getAssessmentList,
      payload
    );
    yield put(getAssessmentListSuccess(assessments));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.APPLICATION_LIST_ERROR,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(getAssessmentListError());
  }
}

function* watchAssessmentList(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(GET_ASSESSMENT_LIST, getAssessmentListWorker);
}

export default watchAssessmentList;
