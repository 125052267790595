import { createSelector } from "@reduxjs/toolkit";
import { AssessmentState } from "../types/AssessmentState";
import { ListState } from "../types/Notes";

export const noteListSelector = (state: AssessmentState): ListState => {
  return state.creditEngine.noteList;
};

export const notesSelector = createSelector(
  noteListSelector,
  (noteList) => noteList.notes
);

export const notesCountSelector = createSelector(
  noteListSelector,
  (noteList) => noteList.count
);

export const notesStatusSelector = createSelector(
  noteListSelector,
  (noteList) => {
    return noteList.status;
  }
);
