import React, { useCallback } from "react";
import { useParams } from "react-router";
import { WORKFLOW_CODE } from "../../../../workflow/constants/workflow";
import { getAssessmentDetail } from "../../../actions/creators/assessmentDetail";
import ApprovalConditions from "../../../components/approval-conditions/ApprovalConditions";
import IntroducerNotes from "../../../components/notes/introducer-notes/IntroducerNotes";
import SupportingDocuments from "../../../components/supporting-documents/SupportingDocuments";
import WorkflowSection from "../../../components/workflow-section/WorkflowSection";
import { useAssessmentDetailsDispatch } from "../../../dispatchers";
import { Slug } from "../../../types/Navigation";

const Decision: React.FunctionComponent = () => {
  const { assessmentId } = useParams<{ assessmentId: string }>();
  const dispatch = useAssessmentDetailsDispatch();

  const onConditionsUpdate = useCallback(() => {
    dispatch(getAssessmentDetail(assessmentId));
  }, [assessmentId, dispatch]);

  return (
    <>
      <ApprovalConditions onConditionsUpdate={onConditionsUpdate} />
      <IntroducerNotes />
      <SupportingDocuments />
      <WorkflowSection
        code={WORKFLOW_CODE.CREDIT_WORKFLOW}
        activeStep={Slug.DECISION}
      />
    </>
  );
};

export default Decision;
