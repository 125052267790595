import "./AuditLogList.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";
import { getOffset } from "@quest-finance/quest-fe-shared/dist/common/utils/dataTable";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { isObjectNotEmpty } from "@quest-finance/quest-fe-shared/dist/common/utils/object";
import { Button, Col, Row, Table, TableProps, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { showErrorPopUp } from "../../../common/components/ShowNotification/showNotification";
import {
  DATE_LIST_FORMAT,
  SHORT_DATE_FORMAT,
} from "../../../common/contstants/app";
import { processError } from "../../../common/utils/error";
import AuditLogsService from "../../services/AuditLogsService";
import { AuditLog, AuditLogRequest } from "../../types/AuditLog";
type AuditChangesProps = {
  values: Record<string, unknown> | null;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  boldValue?: boolean;
};

const AuditChanges: React.FunctionComponent<AuditChangesProps> = ({
  values,
  expanded,
  setExpanded,
  boldValue,
}: AuditChangesProps) => {
  const changesElement = useRef<HTMLDivElement | null>(null);
  const [expandable, setExpandable] = useState(false);
  const toggleExpanded = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (changesElement.current && changesElement.current?.clientHeight > 96) {
      setExpandable(true);
    }
  }, []);

  const getLastKeyNotaion = (key: string): string => {
    const split = key.split(".");
    return split.length > 1 ? split.slice(-1)[0] : split[0];
  };

  const renderValue = (value: unknown): string => {
    if (typeof value === "boolean") {
      return value ? "true" : "false";
    } else if (typeof value === "number") {
      return value.toString();
    } else if (typeof value === "object") {
      return "";
    }

    return value as string;
  };

  if (values && isObjectNotEmpty(values)) {
    return (
      <div className={classNames("audit-changes", { expandable })}>
        <Row>
          <Col
            span={24}
            className={classNames("audit-changes-content", {
              clamped: !expanded,
            })}
            ref={changesElement}
            style={{
              WebkitLineClamp: expanded ? "none" : 3,
              lineClamp: expanded ? "none" : 3,
            }}
          >
            {Object.keys(values).map((key) => {
              return key.startsWith("templateRef.") ||
                key.startsWith("outcomeParams.customProcess.") ? null : (
                <div key={key}>
                  <span>
                    <Tooltip title="Full key name" overlay={key}>
                      {getLastKeyNotaion(key)}
                    </Tooltip>
                  </span>
                  <span>:</span>{" "}
                  <span className={classNames({ "f-bold": boldValue })}>
                    {renderValue(values[key])}
                  </span>
                </div>
              );
            })}
          </Col>
        </Row>
        <div className="button-container">
          {expandable && (
            <Button type="link" onClick={toggleExpanded}>
              {expanded ? "Show less" : "Show more"}
            </Button>
          )}
        </div>
      </div>
    );
  }

  return null;
};

type AuditLogListProps = {
  limit?: number;
  page?: number;
  modelIds: (string | number)[];
  modelNames: string[];
  refId?: string;
  categoryLabels?: Dictionary;
  category?: string;
  onPageChange: (page?: number) => void;
  lastRequest?: number;
};

const AuditLogList: React.FunctionComponent<AuditLogListProps> = ({
  limit = DATATABLE_MAX_ROWS,
  page = 1,
  modelIds,
  modelNames,
  refId,
  categoryLabels,
  category,
  onPageChange,
  lastRequest,
}: AuditLogListProps) => {
  const [auditLogs, setAudtiLogs] = useState<AuditLog[]>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [expandedLogs, setExpandedLogs] = useState<number[]>([]);

  const getAuditLogs = useCallback(async (payload: AuditLogRequest) => {
    setIsLoading(true);
    try {
      const result = await AuditLogsService.getAuditLogs(payload);
      setAudtiLogs(result.data);
      setTotal(result.count ?? 0);
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const auditLogRequest = {
      limit,
      modelId: modelIds.join(","),
      modelName: modelNames.join(","),
      offset: page ? getOffset(page, limit) : undefined,
      category,
      refId,
    };

    getAuditLogs(auditLogRequest);
    setIsAllExpanded(false);
    setExpandedLogs([]);
  }, [
    page,
    limit,
    category,
    modelNames,
    modelIds,
    refId,
    lastRequest,
    getAuditLogs,
  ]);

  const toggleAuditItem = (id: number, expanded: boolean) => {
    setExpandedLogs((previousState) => {
      if (expanded) {
        return [...previousState, id];
      }

      return previousState.filter((logId) => logId !== id);
    });
  };

  const toggleExpandedAll = (expand: boolean) => {
    setIsAllExpanded(expand);
    setExpandedLogs(() => (expand ? auditLogs.map(({ id }) => id) : []));
  };

  const columns: ColumnsType<AuditLog> = [
    {
      title: "Date",
      className: "date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: false,
      width: "6%",
      render(_, { createdAt }) {
        const date = new Date(createdAt);
        return (
          <Tooltip title={dateFormat(date, DATE_LIST_FORMAT)}>
            {dateFormat(date, SHORT_DATE_FORMAT)}
          </Tooltip>
        );
      },
    },
    {
      title: "User",
      className: "user",
      dataIndex: "actor",
      key: "actor",
      sorter: false,
      width: "12%",
      render(_, { actor }) {
        return actor ? `${actor?.firstName} ${actor?.lastName}` : "-";
      },
    },
    {
      title: "Category",
      className: "category",
      dataIndex: "category",
      key: "category",
      width: "12%",
      render(_, { category }) {
        return category && categoryLabels ? categoryLabels[category] ?? "" : "";
      },
    },
    {
      title: "Original Values",
      className: "oldValues",
      dataIndex: "changesOld",
      key: "changesOld",
      width: "35%",
      render(_, { id, changesOld }) {
        return (
          <AuditChanges
            values={changesOld}
            expanded={expandedLogs.includes(id)}
            setExpanded={(expanded) => toggleAuditItem(id, expanded)}
          />
        );
      },
    },
    {
      title: "New Values",
      className: "changesNew",
      dataIndex: "changesNew",
      key: "changesNew",
      width: "35%",
      render(_, { id, changesNew }) {
        return (
          <AuditChanges
            values={changesNew}
            expanded={expandedLogs.includes(id)}
            setExpanded={(expanded) => toggleAuditItem(id, expanded)}
            boldValue
          />
        );
      },
    },
  ];

  const onTableChangeHandler: TableProps<AuditLog>["onChange"] = (
    pagination
  ) => {
    onPageChange(pagination.current);
  };

  return (
    <div className="audit-log-list">
      <Button
        type="link"
        className="expand-button"
        onClick={() => toggleExpandedAll(!isAllExpanded)}
      >
        {isAllExpanded ? "Collapse all" : "Expand all"}
        {isAllExpanded ? <UpOutlined /> : <DownOutlined />}
      </Button>
      <Table
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={auditLogs}
        onChange={onTableChangeHandler}
        pagination={{
          pageSize: limit,
          simple: true,
          total: total,
          current: page,
        }}
        rowKey="id"
        size="small"
      />
    </div>
  );
};

export default AuditLogList;
