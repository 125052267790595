import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import * as notification from "@quest-finance/quest-fe-shared/dist/notification";
import { AxiosError } from "axios";
import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import * as actionCreators from "../actions/creators/funderDetails";
import * as actionTypes from "../actions/types/funderDetails";
import { NOTIFICATION_IDS } from "../constants/notificationIds";
import FunderService from "../services/FunderService";
import { FunderResponse } from "../types/Funder";

const {
  actionCreator: { clearNotification, setNotification },
} = notification;

function* getFunderDetailsWorker({
  assessmentId,
}: actionTypes.GetFunderDetails) {
  try {
    const { data }: SingleResponse<FunderResponse | null> = yield call(
      FunderService.get,
      assessmentId
    );
    yield put(actionCreators.getFunderDetailsSuccess(data));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.FUNDER_SAVE,
        className: "qst-notif-danger",
        body,
      })
    );
    yield put(actionCreators.getFunderDetailsFailed());
  }
}

function* saveFunderWorker({ assessmentId, data }: actionTypes.SaveFunder) {
  try {
    yield put(clearNotification());
    yield call(FunderService.save, assessmentId, data);
    yield put(actionCreators.saveFunderSuccess());
    yield put(actionCreators.getFunderDetails(assessmentId));

    yield put(
      setNotification({
        id: NOTIFICATION_IDS.FUNDER_SAVE,
        className: "qst-notif-success",
        body: "Funder details successfully saved",
      })
    );
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.FUNDER_SAVE,
        className: "qst-notif-danger",
        body,
      })
    );
    yield put(actionCreators.saveFunderFailed());
  }
}

function* watchFunderDetails(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(actionTypes.GET_FUNDER_DETAILS, getFunderDetailsWorker);
  yield takeLatest(actionTypes.SAVE_FUNDER, saveFunderWorker);
}

export default watchFunderDetails;
