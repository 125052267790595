import { errorBlackList as applicationErrorBlackList } from "@quest-finance/quest-fe-shared/dist/application";
import { errorBlackList as authErrorBlackList } from "@quest-finance/quest-fe-shared/dist/auth/utils/errors";
import {
  errorBlackList as commonErrorBlackList,
  errorWhiteList as commonErrorWhiteList,
} from "@quest-finance/quest-fe-shared/dist/common/utils/errors";
import { AxiosError } from "axios";
import { errorBlackList as assessmentErrorBlackList } from "../assessment";

const errorBlackList: { (error: AxiosError): boolean }[] = [
  ...applicationErrorBlackList,
  ...authErrorBlackList,
  ...commonErrorBlackList,
  ...assessmentErrorBlackList,
];
const errorWhiteList: { (error: AxiosError): boolean }[] = [
  ...commonErrorWhiteList,
];

export { errorBlackList, errorWhiteList };
