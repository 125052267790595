import {
  AuditLogResponse,
  AuditService,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import * as notification from "@quest-finance/quest-fe-shared/dist/notification";
import { AxiosError } from "axios";
import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import * as actionCreators from "../actions/creators/auditLogs";
import * as actionTypes from "../actions/types/auditLogs";
import { NOTIFICATION_IDS } from "../constants/notificationIds";

const {
  actionCreator: { setNotification },
} = notification;

function* getEntityAuditLogsWorker({ payload }: actionTypes.GetEntityAuditLog) {
  try {
    const result: ListResponse<AuditLogResponse> = yield call(
      AuditService.getAuditList,
      payload
    );
    yield put(actionCreators.getEntityAuditLogsSuccess(result));
  } catch (e) {
    const body = processErrorMessage(e as AxiosError);
    yield put(
      setNotification({
        id: NOTIFICATION_IDS.ENTITY_AUDIT_LOG,
        body,
        className: "qst-notif-danger",
      })
    );
    yield put(actionCreators.getEntityAuditLogsFailed());
  }
}

function* watchAuditLogs(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(actionTypes.GET_ENTITY_AUDIT_LOG, getEntityAuditLogsWorker);
}

export default watchAuditLogs;
