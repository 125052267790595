import { IAM_STATUSES } from "../constants/iamStatuses";

export interface ClientForm {
  name: string;
  bdmId: number | null;
  configType: string | null;
  dealRecipientClientId: number | null;
  status: string;
}

export const clientFormDefaultValue: ClientForm = {
  name: "",
  bdmId: null,
  configType: null,
  dealRecipientClientId: null,
  status: IAM_STATUSES.ACTIVE,
};
