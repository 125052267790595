import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";
import { AssessmentExtrasState } from "../types/AssessmentExtras";

export const ASSESSMENT_EXTRAS_DEFAULT_VALUE: AssessmentExtrasState = {
  status: LOADING_STATUS.IDLE,
  requestStatus: {},
};

export const APPLICANT_SCORE_KEY = "QUEST_FINANCE.CREDIT_SCORE.APPLICANT";
export const GUARANTOR1_SCORE_KEY = "QUEST_FINANCE.CREDIT_SCORE.GUARANTOR_1";
export const GUARANTOR2_SCORE_KEY = "QUEST_FINANCE.CREDIT_SCORE.GUARANTOR_2";
export const APPLICANT_EQUIFAX_ENQUIRY_KEY =
  "EQUIFAX.COMPANY_ENQUIRY.APPLICANT";
export const APPLICANT_EQUIFAX_TRADING_HISTORY_KEY =
  "EQUIFAX.COMPANY_TRADING_HISTORY.APPLICANT";
export const APPLICANT_EQUIFAX_INDEPTH_TRADING_HISTORY_KEY =
  "EQUIFAX.INDEPTH_COMPANY_TRADING_HISTORY.APPLICANT";
export const GUARANTOR1_ILLION_KEY = "ILLION.CONSUMER_CREDIT.GUARANTOR_1";
export const GUARANTOR2_ILLION_KEY = "ILLION.CONSUMER_CREDIT.GUARANTOR_2";
export const GUARANTOR1_FRANKIEONE_KEY = "FRANKIEONE.CREATE_VERIFY.GUARANTOR_1";
export const GUARANTOR2_FRANKIEONE_KEY = "FRANKIEONE.CREATE_VERIFY.GUARANTOR_2";
export const GUARANTOR1_EQUIFAX_APPLY = "EQUIFAX.COMPANY_APPLY.GUARANTOR_1";
export const GUARANTOR2_EQUIFAX_APPLY = "EQUIFAX.COMPANY_APPLY.GUARANTOR_2";
export const QF_APPROVAL_ADVICE = "QUEST_FINANCE.APPROVAL.ADVICE";

export enum AML_KYC_STATUS {
  INCOMPLETE = "INCOMPLETE",
  RESOLVE = "RESOLVE",
  PASSED = "PASSED",
  PASS_MANUAL = "PASS_MANUAL",
}

export const AML_KYC_STATUS_LABELS: Dictionary = {
  [AML_KYC_STATUS.INCOMPLETE]: "Incomplete",
  [AML_KYC_STATUS.RESOLVE]: "Resolve",
  [AML_KYC_STATUS.PASSED]: "Success",
  [AML_KYC_STATUS.PASS_MANUAL]: "Manual Pass",
};

export const FIRST_TIME_REQUEST_MESSAGE =
  "Are you sure you want to procceed with this action?";
export const SUBSEQUENT_REQUEST_MESSAGE =
  "A previous request was already fulfilled. Are you sure you want to procceed?";

export enum REQUEST_KEY {
  APPROVAL_EXPIRY = "APPROVAL_EXPiRY",
}
