import {
  AuditOutlined,
  CalculatorOutlined,
  CarOutlined,
  EditOutlined,
  ExceptionOutlined,
  FileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";
import { WorkflowSummary } from "../types/Workflow";

export enum WORKFLOW_STEPS {
  QUOTE = "quote",
  APPLICATION_GUARANTORS = "applicantGuarantors",
  REF_STATEMENTS = "refStatements",
  SECURITY = "security",
  NOTES_CONTRACTS = "notesContracts",
  DECISION = "decision",
  SETTLEMENT = "settlement",
}

export const WORKFLOW_STEPS_LIST: WorkflowSummary = {
  [WORKFLOW_STEPS.QUOTE]: {
    label: "Quote",
    icon: CalculatorOutlined,
    isCompleted: false,
    slug: "quote",
  },
  [WORKFLOW_STEPS.APPLICATION_GUARANTORS]: {
    label: "Application & Guarantors",
    icon: UserOutlined,
    isCompleted: false,
    slug: "applicant-guarantors",
  },
  [WORKFLOW_STEPS.REF_STATEMENTS]: {
    label: "Ref & Statements",
    icon: FileOutlined,
    isCompleted: false,
    slug: "ref-statements",
  },
  [WORKFLOW_STEPS.SECURITY]: {
    label: "Security",
    icon: CarOutlined,
    isCompleted: false,
    slug: "security",
  },
  [WORKFLOW_STEPS.NOTES_CONTRACTS]: {
    label: "Notes & Contracts",
    icon: EditOutlined,
    isCompleted: false,
    slug: "notes-contracts",
  },
  [WORKFLOW_STEPS.DECISION]: {
    label: "Decision",
    icon: AuditOutlined,
    isCompleted: false,
    slug: "decision",
  },
  [WORKFLOW_STEPS.SETTLEMENT]: {
    label: "Settlement",
    icon: ExceptionOutlined,
    isCompleted: false,
    slug: "settlement",
  },
};

export const WORKFLOW_SLUGS: Dictionary = {
  [WORKFLOW_STEPS_LIST.quote.slug]: WORKFLOW_STEPS.QUOTE,
  [WORKFLOW_STEPS_LIST.applicantGuarantors.slug]:
    WORKFLOW_STEPS.APPLICATION_GUARANTORS,
  [WORKFLOW_STEPS_LIST.refStatements.slug]: WORKFLOW_STEPS.REF_STATEMENTS,
  [WORKFLOW_STEPS_LIST.security.slug]: WORKFLOW_STEPS.SECURITY,
  [WORKFLOW_STEPS_LIST.notesContracts.slug]: WORKFLOW_STEPS.NOTES_CONTRACTS,
  [WORKFLOW_STEPS_LIST.decision.slug]: WORKFLOW_STEPS.DECISION,
  [WORKFLOW_STEPS_LIST.settlement.slug]: WORKFLOW_STEPS.SETTLEMENT,
};

export const WORKFLOW_STEPS_MAP: Record<string, string[]> = {
  [WORKFLOW_STEPS.QUOTE]: ["QUOTE_WORKFLOW"],
  [WORKFLOW_STEPS.APPLICATION_GUARANTORS]: [
    "APPLICANT_WORKFLOW",
    "APPLICANT_EQUIFAX_WORKFLOW",
    "GUARANTOR_WORKFLOW",
    "GUARANTOR_SME_EQUIFAX_WORKFLOW",
    "GUARANTOR_ILLION_WORKFLOW",
    "GUARANTOR_FRANKIEONE_WORKFLOW",
    "GUARANTOR_INDIVIDUAL_EQUIFAX_WORKFLOW",
    "GUARANTOR_COMMERCIAL_APPLY_WORKFLOW",
  ],
  [WORKFLOW_STEPS.REF_STATEMENTS]: ["STATEMENTS_WORKFLOW"],
  [WORKFLOW_STEPS.SECURITY]: ["SECURITY_WORKFLOW"],
  [WORKFLOW_STEPS.SETTLEMENT]: ["SETTLEMENT_WORKFLOW"],
  [WORKFLOW_STEPS.DECISION]: [
    "QUOTE_WORKFLOW",
    "APPLICANT_WORKFLOW",
    "APPLICANT_EQUIFAX_WORKFLOW",
    "GUARANTOR_WORKFLOW",
    "GUARANTOR_SME_EQUIFAX_WORKFLOW",
    "GUARANTOR_ILLION_WORKFLOW",
    "GUARANTOR_FRANKIEONE_WORKFLOW",
    "GUARANTOR_INDIVIDUAL_EQUIFAX_WORKFLOW",
    "GUARANTOR_COMMERCIAL_APPLY_WORKFLOW",
    "STATEMENTS_WORKFLOW",
    "SECURITY_WORKFLOW",
  ],
};
