import { IAM_STATUSES } from "../constants/iamStatuses";

export interface RoleForm {
  name: string;
  permissions: string[];
  status: string;
}

export const roleFormDefaultValue: RoleForm = {
  name: "",
  permissions: [],
  status: IAM_STATUSES.ACTIVE,
};
