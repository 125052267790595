import {
  GetAssessmentExtrasPayload,
  GetAssessmentExtrasResponse,
} from "../../types/AssessmentExtras";
import * as actionTypes from "../types/assessmentExtras";

export const getAssessmentExtras = (
  assessmentId: string,
  data: GetAssessmentExtrasPayload,
  requestId?: string
): actionTypes.GetAssessmentExtras => ({
  type: actionTypes.CE_GET_ASSESSMENT_EXTRAS,
  assessmentId,
  data,
  requestId,
});

export const getAssessmentExtrasSuccess = (
  response: GetAssessmentExtrasResponse,
  requestId?: string
): actionTypes.GetAssessmentExtrasSuccess => ({
  type: actionTypes.CE_GET_ASSESSMENT_EXTRAS_SUCCESS,
  response,
  requestId,
});

export const getAssessmentExtrasFailed = (
  error: unknown,
  requestId?: string
): actionTypes.GetAssessmentExtrasFailed => ({
  type: actionTypes.CE_GET_ASSESSMENT_EXTRAS_FAILED,
  error,
  requestId,
});

export const resetAssessmentExtras = (
  key: string[]
): actionTypes.ResetAssessmentExtras => ({
  type: actionTypes.CE_RESET_ASSESSMENT_EXTRAS,
  key,
});
