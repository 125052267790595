import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { RoleListRequest } from "../../types/RoleListRequest";
import { RoleResponse } from "../../types/RoleResponse";
import * as types from "../types/roleList";

export const getRoleList = (payload?: RoleListRequest): types.GetRoleList => ({
  type: types.GET_ROLE_LIST,
  payload,
});

export const getRoleListSuccess = (
  rolesResponse: ListResponse<RoleResponse>
): types.GetRoleListSuccess => ({
  type: types.GET_ROLE_LIST_SUCCESS,
  roles: rolesResponse.data,
  rolesCount: rolesResponse.count ? rolesResponse.count : 0,
});

export const getRoleListError = (): types.GetRoleListError => ({
  type: types.GET_ROLE_LIST_ERROR,
});
