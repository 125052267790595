import {
  AuditLogListRequest,
  AuditLogResponse,
} from "@quest-finance/quest-fe-shared/dist/audit";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { Action } from "redux";

export const GET_ENTITY_AUDIT_LOG = "GET_ENTITY_AUDIT_LOG";
export interface GetEntityAuditLog extends Action {
  type: typeof GET_ENTITY_AUDIT_LOG;
  payload: AuditLogListRequest;
}

export const GET_ENTITY_AUDIT_LOG_SUCCESS = "GET_ENTITY_AUDIT_LOG_SUCCESS";
export interface GetEntityAuditLogSuccess extends Action {
  type: typeof GET_ENTITY_AUDIT_LOG_SUCCESS;
  auditLogList: ListResponse<AuditLogResponse>;
}

export const GET_ENTITY_AUDIT_LOG_FAILED = "GET_ENTITY_AUDIT_LOG_FAILED";
export interface GetEntityAuditLogFailed extends Action {
  type: typeof GET_ENTITY_AUDIT_LOG_FAILED;
}

export const RESET_ENTITY_AUDIT_LOG_STATE = "RESET_ENTITY_AUDIT_LOG_STATE";
export interface ResetEntityAuditLogState extends Action {
  type: typeof RESET_ENTITY_AUDIT_LOG_STATE;
}

export type AuditLogActions =
  | GetEntityAuditLog
  | GetEntityAuditLogSuccess
  | GetEntityAuditLogFailed
  | ResetEntityAuditLogState;
