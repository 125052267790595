import React from "react";
import {
  getIsAuthenticatedSelector,
  getIsTokenExpiringSelector,
  ModalExpired,
} from "@quest-finance/quest-fe-shared/dist/auth";
import { selector } from "@quest-finance/quest-fe-shared/dist/version-manager";
import { Breadcrumb } from "antd";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { isSidebarCollapsedSelector } from "../../../selectors/layout";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import "./DefaultLayout.scss";

type breadCrumbProps = {
  url?: string;
  label: string;
};

type DefaultLayoutProps = {
  children?: React.ReactNode;
  breadCrumb?: breadCrumbProps[];
  className?: string;
};

const DefaultLayout: React.FunctionComponent<DefaultLayoutProps> = ({
  children,
  breadCrumb = [],
  className,
}: DefaultLayoutProps) => {
  const hasNewVersion: boolean = useSelector(selector.hasNewVersionSelector);
  const isAuthenticated: boolean = useSelector(getIsAuthenticatedSelector);
  const isTokenExpiring: boolean = useSelector(getIsTokenExpiringSelector);
  const isSiderCollapsed: boolean = useSelector(isSidebarCollapsedSelector);

  const breadCrumbCompomnent = breadCrumb.map(({ url, label }, i) => {
    const content = url ? <a href={url}>{label}</a> : label;
    return <Breadcrumb.Item key={i}>{content}</Breadcrumb.Item>;
  });

  return (
    <div
      className={classnames("default-layout", className, {
        "sider-collapsed": isSiderCollapsed,
        "one-banner": hasNewVersion || (isTokenExpiring && isAuthenticated),
        "two-banner": hasNewVersion && isTokenExpiring && isAuthenticated,
      })}
    >
      <div className="inner">
        <Header
          hasNewVersion={hasNewVersion}
          isTokenExpiring={isTokenExpiring}
          isAuthenticated={isAuthenticated}
        />
        <Breadcrumb className="layout-crumbs">
          {breadCrumbCompomnent}
        </Breadcrumb>
        <div className="layout-body">
          <Sidebar collapsed={isSiderCollapsed} />
          <div className="layout-content">{children}</div>
        </div>
        <Footer />
      </div>
      {!isAuthenticated && <ModalExpired />}
    </div>
  );
};

export default DefaultLayout;
