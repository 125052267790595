import "./NoteList.scss";
import React, { useCallback, useEffect, useState } from "react";
import {
  DeleteFilled,
  EditFilled,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { getUserDataSelector } from "@quest-finance/quest-fe-shared/dist/auth";
import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { getOffset } from "@quest-finance/quest-fe-shared/dist/common/utils/dataTable";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { Popconfirm, Tooltip, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../../common/components/ShowNotification/showNotification";
import WordLimitter, {
  CONTENT_TYPE,
} from "../../../../common/components/WordLimitter/WordLimitter";
import {
  SHORT_DATE_FORMAT,
  DATE_LIST_FORMAT,
} from "../../../../common/contstants/app";
import { processError } from "../../../../common/utils/error";
import { getAssessmentNotes } from "../../../actions/creators/noteList";
import { SECTION_LABEL } from "../../../constants/notes";
import { useNoteListDispatch } from "../../../dispatchers/";
import { noteListSelector } from "../../../selectors/noteList";
import NoteService from "../../../services/NoteService";
import {
  NoteAttributes,
  NoteDocument,
  ListRequestParams,
  Sections,
} from "../../../types/Notes";

type NoteListProps = {
  section?: string;
  onUpdate: (note: NoteAttributes) => void;
  showSectionDetail: boolean;
  onPageChange: () => void;
  lastUpdated?: NoteDocument;
};

const filterOptions = Object.keys(SECTION_LABEL).map((key) => ({
  text: SECTION_LABEL[key],
  value: key,
}));
const sortDirections: SortOrder[] = ["ascend", "descend", "ascend"];

type RequestParams = {
  section: Sections[];
  page: number;
  dateSort: SortOrder;
  sectionSort: SortOrder;
};
const defaultRequestParams: RequestParams = {
  section: [],
  page: 1,
  dateSort: "descend",
  sectionSort: null,
};

filterOptions.sort((a, b) => {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
});

const NoteList: React.FunctionComponent<NoteListProps> = ({
  section,
  onUpdate,
  onPageChange,
  showSectionDetail = false,
  lastUpdated,
}: NoteListProps) => {
  const notesListDispatch = useNoteListDispatch();
  const { notes, status, error, count, lastResetTimestamp } = useSelector(
    noteListSelector
  );
  const user = useSelector(getUserDataSelector);
  const { section: pageTab, assessmentId } = useParams<{
    assessmentId: string;
    section: string;
  }>();
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [expandedNotes, setExpandedNotes] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [requestParams, setRequestParams] = useState<RequestParams>({
    ...defaultRequestParams,
  });

  const getNotesList = useCallback(() => {
    const { page } = requestParams;
    const order = [];

    if (requestParams.dateSort) {
      const prefix = requestParams.dateSort === "descend" ? "-" : "";
      order.push(prefix + "createdAt");
    }
    if (requestParams.sectionSort) {
      const prefix = requestParams.sectionSort === "descend" ? "-" : "";
      order.push(prefix + "section");
    }

    const queryParams: ListRequestParams = {
      order: order.join(),
      limit: DATATABLE_MAX_ROWS,
      offset: getOffset(page, DATATABLE_MAX_ROWS),
      section: showSectionDetail ? requestParams.section.join(",") : section,
    };

    notesListDispatch(getAssessmentNotes(assessmentId, queryParams));
  }, [
    assessmentId,
    notesListDispatch,
    showSectionDetail,
    section,
    requestParams,
  ]);

  useEffect(() => {
    getNotesList();
  }, [getNotesList, lastUpdated]);

  useEffect(() => {
    setRequestParams({ ...defaultRequestParams });
  }, [lastResetTimestamp, pageTab]);

  useEffect(() => {
    if (status === LOADING_STATUS.FAILED) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    }
  }, [status, error]);

  const deleteNote = async (noteId: string) => {
    setIsDeleting(true);
    try {
      await NoteService.delete(assessmentId, noteId);
      await getNotesList();
      showSuccessPopUp("Note has been deleted.");
    } catch (e) {
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const toggleNoteItem = (id: string, expanded: boolean) => {
    setExpandedNotes((previousState) => {
      if (expanded) {
        return [...previousState, id];
      }

      return previousState.filter((noteId) => noteId !== id);
    });
  };

  const toggleExpandedState = (expand: boolean) => {
    setIsAllExpanded(expand);
    setExpandedNotes(() => (expand ? notes.map(({ id }) => id) : []));
  };

  let toggleAll = null;

  if (status === LOADING_STATUS.IDLE && notes.length) {
    if (isAllExpanded) {
      toggleAll = (
        <Tooltip placement="top" title="Collapse all">
          <UpOutlined onClick={() => toggleExpandedState(false)} />
        </Tooltip>
      );
    } else {
      toggleAll = (
        <Tooltip placement="top" title="Expand all">
          <DownOutlined onClick={() => toggleExpandedState(true)} />
        </Tooltip>
      );
    }
  }

  const onTableChangeHandler: TableProps<NoteAttributes>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    const _sorter = Array.isArray(sorter) ? sorter : [sorter];
    const sortOrder: Record<string, SortOrder> = {
      dateSort: null,
      sectionSort: null,
    };
    _sorter.forEach((item) => {
      const { order, field } = item;
      sortOrder[`${field}Sort`] = (order as SortOrder) || null;
    });
    const sectionFilters = filters.section || [];

    if (
      extra.action === "filter" &&
      _.isEqual(requestParams.section, sectionFilters)
    ) {
      return;
    }

    const page =
      requestParams.dateSort !== sortOrder.dateSort ||
      requestParams.sectionSort !== sortOrder.sectionSort
        ? 1
        : pagination.current;

    setRequestParams({
      section: sectionFilters as Sections[],
      page: page ?? 1,
      dateSort: sortOrder.dateSort,
      sectionSort: sortOrder.sectionSort,
    });

    setIsAllExpanded(false);
    setExpandedNotes([]);
    onPageChange();
  };

  const columns: ColumnsType<NoteAttributes> = [
    {
      title: "Date",
      className: "date",
      dataIndex: "date",
      sorter: true,
      sortDirections,
      sortOrder: requestParams.dateSort,
      render(_, { createdAt }) {
        const date = new Date(createdAt);
        return (
          <Tooltip title={dateFormat(date, DATE_LIST_FORMAT)}>
            {dateFormat(date, SHORT_DATE_FORMAT)}
          </Tooltip>
        );
      },
    },
    {
      title: "User",
      className: "user",
      render(_, { creator }) {
        return (
          <>
            {creator?.firstName} {creator?.lastName}
          </>
        );
      },
      width: "13%",
    },
    {
      title: "Note",
      className: "note ql-editor",
      render(_, { id, note }) {
        return (
          <WordLimitter
            text={note}
            maxLines={2}
            expanded={expandedNotes.includes(id)}
            type={CONTENT_TYPE.HTML}
            setExpanded={(expanded: boolean) => toggleNoteItem(id, expanded)}
          />
        );
      },
    },
    {
      title: "",
      className: "action",
      render(_, note) {
        const { id, createdBy } = note;

        return createdBy === user?.id ? (
          <>
            <Popconfirm
              title="Sure to delete?"
              okText="Yes"
              placement="left"
              cancelText="Cancel"
              onConfirm={() => {
                deleteNote(id);
              }}
              arrowPointAtCenter
            >
              <DeleteFilled />
            </Popconfirm>
            <EditFilled onClick={() => onUpdate(note)} />
          </>
        ) : null;
      },
    },
  ];

  if (showSectionDetail) {
    columns.splice(1, 0, {
      title: "Section",
      dataIndex: "section",
      className: "section",
      filters: filterOptions,
      filteredValue: requestParams.section,
      sorter: true,
      sortDirections,
      sortOrder: requestParams.sectionSort,
      render(_, { section }) {
        return SECTION_LABEL[section];
      },
      width: "10%",
    });
  }

  return (
    <div className="notes-list">
      <div className="heading">Notes History {toggleAll}</div>
      <Table
        loading={status === LOADING_STATUS.LOADING || isDeleting}
        columns={columns}
        dataSource={notes}
        pagination={{
          simple: true,
          total: count,
          current: requestParams.page,
        }}
        onChange={onTableChangeHandler}
        rowKey="id"
        size="small"
      />
    </div>
  );
};

export default NoteList;
