import { combineReducers } from "redux";
import { reducer as assessmentDetailReducer } from "./assessmentDetail";
import { reducer as assessmentExtrasReducer } from "./assessmentExtras";
import { reducer as documentListReducer } from "./documentList";
import { reducer as noteListReducer } from "./noteList";

export default combineReducers({
  assessmentDetail: assessmentDetailReducer,
  assessmentExtras: assessmentExtrasReducer,
  documentList: documentListReducer,
  noteList: noteListReducer,
});
