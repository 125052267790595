import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import {
  UpdateAssessmentExtrasPayload,
  GetAssessmentExtrasPayload,
  GetAssessmentExtrasResponse,
} from "../types/AssessmentExtras";

class AssessmentService {
  public static async setData(
    assessmentId: string,
    data: UpdateAssessmentExtrasPayload
  ): Promise<SingleResponse<GetAssessmentExtrasPayload>> {
    const result = await APIService.jsonRequest<
      SingleResponse<GetAssessmentExtrasPayload>,
      UpdateAssessmentExtrasPayload
    >(
      {
        method: "PUT",
        path: `/assessment/assessments/${assessmentId}/extra`,
        data,
      },
      true
    );
    return result;
  }

  public static async getData(
    assessmentId: string,
    data: GetAssessmentExtrasPayload
  ): Promise<SingleResponse<GetAssessmentExtrasResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<GetAssessmentExtrasResponse>,
      GetAssessmentExtrasPayload
    >(
      {
        method: "POST",
        path: `/assessment/assessments/${assessmentId}/extra`,
        data,
      },
      true
    );
    return result;
  }
}

export default AssessmentService;
