import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { QuestDocument } from "@quest-finance/quest-fe-shared/dist/files";

export enum DocumentPurpose {
  INTERNAL = "INTERNAL",
  KYC_REPORT = "KYC_REPORT",
  CREDIT_REPORT = "CREDIT_REPORT",
}

export interface AssessmentDocument extends QuestDocument {
  id: string;
  assessmentId: string;
  uploadedBy: string;
  uploadedAt: string;
  type: string;
  purpose: string;
  createdBy: {
    firstName: string;
    lastName: string;
  };
}

export interface DocumentListState {
  documents: AssessmentDocument[];
  count: number;
  status: LOADING_STATUS;
}
