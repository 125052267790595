import React, { useCallback } from "react";
import { getUserDataSelector } from "@quest-finance/quest-fe-shared/dist/auth";
import { UserData } from "@quest-finance/quest-fe-shared/dist/auth/types/UserData";
import { LabelValue } from "@quest-finance/quest-fe-shared/dist/common/types/LabelValue";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import * as notification from "@quest-finance/quest-fe-shared/dist/notification";
import { AxiosError } from "axios";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import UserService from "../services/UserService";

const {
  dispatch: { useNotificationDispatch },
  actionCreator: { setNotification },
} = notification;

type UserListDropdownProps = {
  name?: string;
  value: LabelValue<number> | null | undefined;
  onChange: (value: LabelValue<number> | null | undefined) => void;
  className?: string;
  placeHolder?: string;
  loadOnMount?: boolean;
  isClearable?: boolean;
  isIntroducer?: boolean;
  id?: string;
};

const UserListDropdown: React.FunctionComponent<UserListDropdownProps> = ({
  name,
  value,
  onChange,
  className,
  placeHolder,
  loadOnMount = true,
  isClearable,
  isIntroducer = false,
  id,
}: UserListDropdownProps) => {
  const userData = useSelector(getUserDataSelector);
  const notifDispatch = useNotificationDispatch();
  const onChangeCallback = useCallback(
    (value: LabelValue<number> | null | undefined) => onChange(value),
    [onChange]
  );

  const loadUsers = useCallback(
    async (inputValue?: string): Promise<LabelValue<number>[]> => {
      const { clientId } = userData as UserData;
      try {
        const result = await UserService.getUserList({
          offset: 0,
          limit: 10,
          order: "firstName",
          keywords: inputValue,
          clientIds: clientId,
          isIntroducer,
        });

        return result.data.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        }));
      } catch (error) {
        const body = processErrorMessage(error as AxiosError);
        notifDispatch(
          setNotification({
            id: "user-list-error",
            body,
            className: "qst-notif-danger",
          })
        );
      }

      return [];
    },
    [userData, isIntroducer, notifDispatch]
  );

  return (
    <AsyncSelect
      name={name}
      isClearable={isClearable}
      className={className}
      loadOptions={loadUsers}
      value={value}
      onChange={onChangeCallback}
      placeholder={placeHolder}
      defaultOptions={loadOnMount}
      id={id}
    />
  );
};

export default UserListDropdown;
