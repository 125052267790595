import { createSelector } from "@reduxjs/toolkit";
import { EntityIndividualState } from "../types/Entity";
import { EntityState } from "../types/EntityState";

export const entityIndividualSelector = (
  state: EntityState
): EntityIndividualState => state.entity.entityIndividual;

export const getSaveEntityLoadingSelector = createSelector(
  entityIndividualSelector,
  (entityIndividual) => entityIndividual.saveEntityLoading
);

export const getEntityIndividualLoadingSelector = createSelector(
  entityIndividualSelector,
  (entityIndividual) => entityIndividual.getIndividualLoading
);

export const getEntityIndividualSelector = createSelector(
  entityIndividualSelector,
  (entityIndividual) => entityIndividual.individual
);
