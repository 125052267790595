import React from "react";
import { PageRoute } from "@quest-finance/quest-fe-shared/dist/common/types/PageRoute";

const ClientAdmin = React.lazy(() => import("../pages/client-admin"));
const ClientList = React.lazy(() => import("../pages/client-list"));
const UserList = React.lazy(() => import("../pages/user-list"));
const UserAdmin = React.lazy(() => import("../pages/user-admin"));
const RoleAdmin = React.lazy(() => import("../pages/role-admin"));
const RoleList = React.lazy(() => import("../pages/role-list"));

const routes: PageRoute[] = [
  {
    name: "Client List",
    path: "/iam/clients",
    exact: true,
    component: ClientList,
  },
  {
    name: "Client Admin",
    path: "/iam/clients/:clientId/admin",
    exact: true,
    component: ClientAdmin,
  },
  {
    name: "User List",
    path: "/iam/users",
    exact: true,
    component: UserList,
  },
  {
    name: "User Admin",
    path: "/iam/users/:userId/admin",
    exact: true,
    component: UserAdmin,
  },
  {
    name: "Role Admin",
    path: "/iam/roles/:roleId/admin",
    exact: true,
    component: RoleAdmin,
  },
  {
    name: "Role List",
    path: "/iam/roles",
    exact: true,
    component: RoleList,
  },
];

export default routes;
