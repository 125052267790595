import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { convertToQueryParams } from "@quest-finance/quest-fe-shared/dist/common/utils/object";
import {
  TaskNoteAttributes,
  TaskNoteListRequest,
  TaskNoteRequest,
} from "../types/TaskNote";

class TaskNoteService {
  public static async create(
    workflowId: number,
    workflowTaskId: number,
    data: TaskNoteRequest
  ): Promise<SingleResponse<TaskNoteAttributes>> {
    const result = await APIService.jsonRequest<
      SingleResponse<TaskNoteAttributes>,
      TaskNoteRequest
    >(
      {
        method: "POST",
        path: `/workflow/workflows/${workflowId}/tasks/${workflowTaskId}/notes`,
        data,
      },
      true
    );
    return result;
  }

  public static async update(
    workflowId: number,
    workflowTaskId: number,
    noteId: number,
    data: TaskNoteRequest
  ): Promise<SingleResponse<TaskNoteAttributes>> {
    const result = await APIService.jsonRequest<
      SingleResponse<TaskNoteAttributes>,
      TaskNoteRequest
    >(
      {
        method: "PUT",
        path: `/workflow/workflows/${workflowId}/tasks/${workflowTaskId}/notes/${noteId}`,
        data,
      },
      true
    );
    return result;
  }

  public static async getList(
    workflowId: number,
    workflowTaskId: number,
    payload?: TaskNoteListRequest
  ): Promise<ListResponse<TaskNoteAttributes>> {
    const queryParams = convertToQueryParams({
      ...payload,
    });
    const path = `/workflow/workflows/${workflowId}/tasks/${workflowTaskId}/notes${queryParams}`;

    const result = await APIService.jsonRequest<
      ListResponse<TaskNoteAttributes>,
      unknown
    >(
      {
        method: "GET",
        path,
      },
      true
    );
    return result;
  }

  public static async delete(
    workflowId: number,
    workflowTaskId: number,
    noteId: number
  ): Promise<SingleResponse<boolean>> {
    const result = await APIService.jsonRequest<
      SingleResponse<boolean>,
      unknown
    >(
      {
        method: "DELETE",
        path: `/workflow/workflows/${workflowId}/tasks/${workflowTaskId}/notes/${noteId}`,
      },
      true
    );
    return result;
  }
}

export default TaskNoteService;
