import "./CreditScore.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  SaveOutlined,
  LoadingOutlined,
  ReloadOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { replaceAll } from "@quest-finance/quest-fe-shared/dist/common/utils/string";
import { ERROR_CODES } from "@quest-finance/quest-fe-shared/dist/error-handler";
import { Button, Col, Form, Input, Row, Tooltip } from "antd";
import { AxiosError } from "axios";
import classNames from "classnames";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { Guarantor } from "../../../../application";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../../common/utils/error";
import {
  getAssessmentExtras,
  getAssessmentExtrasSuccess,
} from "../../../actions/creators/assessmentExtras";
import {
  AML_KYC_STATUS,
  AML_KYC_STATUS_LABELS,
} from "../../../constants/assessmentExtras";
import { useAssessmentExtrasDispatch } from "../../../dispatchers";
import {
  assessmentExtrasDataSelector,
  assessmentExtrasStatusSelector,
} from "../../../selectors/assessmentExtras";
import AssessmentExtrasService from "../../../services/AssessmentExtrasService";
import CreditService from "../../../services/CreditService";
import {
  GetAssessmentExtrasResponse,
  ScoreResponse,
  UpdateAssessmentExtrasPayload,
} from "../../../types/AssessmentExtras";
import { EquifaxApplyReportResponse } from "../../../types/CreditReference";
import { CreditButtonSingle } from "./CreditButton";

type CreditSectionType = {
  assessmentId: string;
  guarantor: Guarantor;
  guarantorNo: number;
  illionAllLoading: boolean;
  amlKycAllLoading: boolean;
  equifaxAllLoading: boolean;
};

const CreditSection: React.FC<CreditSectionType> = ({
  assessmentId,
  guarantor,
  guarantorNo,
  illionAllLoading,
  amlKycAllLoading,
  equifaxAllLoading,
}: CreditSectionType) => {
  const [equifaxScore, setEquifaxScore] = useState("");
  const [illionScore, setIllionScore] = useState("");
  const [isSavingScores, setSavingScores] = useState(false);
  const [illionRequestLoading, setIllionRequestLoading] = useState(false);
  const [amlRequestLoading, setAmlRequestLoading] = useState(false);
  const [amlRefreshLoading, setAmlRefreshLoading] = useState(false);
  const [equifaxApplyLoading, setEquifaxApplyLoading] = useState(false);
  const { entityId } = guarantor;
  const assessmentExtras = useSelector(assessmentExtrasDataSelector);
  const assessmentExtrasStatus = useSelector(assessmentExtrasStatusSelector);
  const assessmentExtrasDispatch = useAssessmentExtrasDispatch();
  const guarantorScoreKey = `QUEST_FINANCE.CREDIT_SCORE.GUARANTOR_${guarantorNo}`;
  const illionData = assessmentExtras
    ? (assessmentExtras[
        `ILLION.CONSUMER_CREDIT.GUARANTOR_${guarantorNo}`
      ] as ScoreResponse) ?? null
    : null;
  const frankieOneData = assessmentExtras
    ? (assessmentExtras[
        `FRANKIEONE.CREATE_VERIFY.GUARANTOR_${guarantorNo}`
      ] as ScoreResponse) ?? null
    : null;
  const equifaxData = assessmentExtras
    ? (assessmentExtras[
        `EQUIFAX.COMPANY_APPLY.GUARANTOR_${guarantorNo}`
      ] as ScoreResponse) ?? null
    : null;

  const amlKycStatus: string = useMemo(() => {
    if (assessmentExtras) {
      const statusData = lodash.get(
        assessmentExtras[`FRANKIEONE.CREATE_VERIFY.GUARANTOR_${guarantorNo}`],
        "status"
      );
      if (statusData) return statusData;
    }
    return AML_KYC_STATUS.INCOMPLETE as string;
  }, [assessmentExtras, guarantorNo]);

  const frankieOneId: string = useMemo(() => {
    let id = "";
    if (assessmentExtras) {
      id = lodash.get(
        assessmentExtras[`FRANKIEONE.CREATE_VERIFY.GUARANTOR_${guarantorNo}`],
        "entity_id"
      );
    }
    return id;
  }, [assessmentExtras, guarantorNo]);

  const setScores = useCallback(
    (data: GetAssessmentExtrasResponse) => {
      const equifaxScore = lodash.get(
        data[guarantorScoreKey],
        "equifax"
      ) as string;
      const illionScore = lodash.get(
        data[guarantorScoreKey],
        "illion"
      ) as string;

      setEquifaxScore(equifaxScore);
      setIllionScore(illionScore);
    },
    [guarantorScoreKey]
  );

  useEffect(() => {
    if (assessmentExtras) {
      setScores(assessmentExtras);
    }
  }, [assessmentExtras, setScores]);

  const setAssessmentExtras = async () => {
    setSavingScores(true);
    try {
      const payload: UpdateAssessmentExtrasPayload = [
        {
          type: guarantorScoreKey,
          data: {
            equifax: equifaxScore,
            illion: illionScore,
          },
        },
      ];
      const result = await AssessmentExtrasService.setData(
        assessmentId,
        payload
      );
      showSuccessPopUp(
        `Successfully saved credit score for Guarantor ${guarantorNo}`
      );
      assessmentExtrasDispatch(getAssessmentExtrasSuccess(result.data));
    } catch (error) {
      processError(error, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    } finally {
      setSavingScores(false);
    }
  };

  const generateIllionCCR = async () => {
    setIllionRequestLoading(true);
    try {
      await CreditService.getIllionReport(assessmentId, [entityId]);
      assessmentExtrasDispatch(
        getAssessmentExtras(assessmentId, {
          [`QUEST_FINANCE.CREDIT_SCORE.GUARANTOR_${guarantorNo}`]: [
            "illion",
            "equifax",
          ],
          [`ILLION.CONSUMER_CREDIT.GUARANTOR_${guarantorNo}`]: ["score"],
        })
      );
      showSuccessPopUp(
        `Successfully retrieved score/documents for Guarantor ${guarantorNo}`
      );
    } catch (error) {
      const apiError = error as AxiosError;
      if (apiError.response?.data.errorCode === ERROR_CODES.TOO_MANY_REQUESTS) {
        showErrorPopUp(
          "An ongoing request is still in progress. It will be completed short while."
        );
      } else {
        processError(error, (errorMessage) => {
          showErrorPopUp(errorMessage);
        });
      }
    } finally {
      setIllionRequestLoading(false);
    }
  };

  const generateAMLandKYC = async () => {
    setAmlRequestLoading(true);
    try {
      await CreditService.getFrankieOneReport(assessmentId, [entityId]);
      assessmentExtrasDispatch(
        getAssessmentExtras(assessmentId, {
          [`FRANKIEONE.CREATE_VERIFY.GUARANTOR_${guarantorNo}`]: [
            "entity_id",
            "status",
          ],
        })
      );
      showSuccessPopUp(
        `Successfully retrieved score/documents for Guarantor ${guarantorNo}`
      );
    } catch (error) {
      const apiError = error as AxiosError;
      if (apiError.response?.data.errorCode === ERROR_CODES.TOO_MANY_REQUESTS) {
        showErrorPopUp(
          "An ongoing request is still in progress. It will be completed short while."
        );
      } else {
        processError(error, (errorMessage) => {
          showErrorPopUp(errorMessage);
        });
      }
    } finally {
      setAmlRequestLoading(false);
    }
  };

  const getAMLandKYCStatus = async () => {
    setAmlRefreshLoading(true);
    try {
      await CreditService.getFrankieOneEntityStatus(assessmentId, frankieOneId);
      assessmentExtrasDispatch(
        getAssessmentExtras(assessmentId, {
          [`FRANKIEONE.CREATE_VERIFY.GUARANTOR_${guarantorNo}`]: [
            "entity_id",
            "status",
          ],
        })
      );
      showSuccessPopUp(
        `Successfully refreshed AML/KYC status for Guarantor ${guarantorNo}`
      );
    } catch (error) {
      const apiError = error as AxiosError;
      if (apiError.response?.data.errorCode === ERROR_CODES.TOO_MANY_REQUESTS) {
        showErrorPopUp(
          "An ongoing request is still in progress. It will be completed short while."
        );
      } else {
        processError(error, (errorMessage) => {
          showErrorPopUp(errorMessage);
        });
      }
    } finally {
      setAmlRefreshLoading(false);
    }
  };

  const getEquifaxApplyReport = async () => {
    setEquifaxApplyLoading(true);

    try {
      const response = (await CreditService.getEquifaxApplyReport(
        assessmentId,
        [entityId]
      )) as EquifaxApplyReportResponse;
      const errorData = response.data.errors;
      if (errorData.length > 0) {
        const error = errorData[0];
        error.errors.forEach((err, index) => {
          const key = `error${index}`;
          showErrorPopUp(err, key);
        });
      } else {
        assessmentExtrasDispatch(
          getAssessmentExtras(assessmentId, {
            [guarantorScoreKey]: ["equifax", "illion"],
            [`EQUIFAX.COMPANY_APPLY.GUARANTOR_${guarantorNo}`]: ["score"],
          })
        );
        showSuccessPopUp(
          `Successfully retrieved Equifax Apply for Guarantor ${guarantorNo}`
        );
      }
    } catch (error) {
      const apiError = error as AxiosError;
      if (apiError.response?.data.errorCode === ERROR_CODES.TOO_MANY_REQUESTS) {
        showErrorPopUp(
          "An ongoing request is still in progress. It will be completed short while."
        );
      } else {
        processError(error, (errorMessage) => {
          showErrorPopUp(errorMessage);
        });
      }
    } finally {
      setEquifaxApplyLoading(false);
    }
  };

  const monitoringLink = process.env.REACT_APP_FRANKIEONE_MONITORING_LINK
    ? replaceAll(
        process.env.REACT_APP_FRANKIEONE_MONITORING_LINK,
        "[FrankieID]",
        frankieOneId
      )
    : "";
  const onBoardingLink = process.env.REACT_APP_FRANKIEONE_ONBOARDING_LINK
    ? replaceAll(
        process.env.REACT_APP_FRANKIEONE_ONBOARDING_LINK,
        "[FrankieID]",
        frankieOneId
      )
    : "";

  const frankieOneLink =
    amlKycStatus === AML_KYC_STATUS.RESOLVE ? onBoardingLink : monitoringLink;

  return (
    <div className="guarantor-credit-score">
      <div className="section-header">Credit scores and AML</div>
      <Form layout="vertical">
        <Row gutter={[16, 0]} align="bottom">
          <Col xxl={8}>
            <Row gutter={[16, 0]} align="bottom">
              <Col xxl={8}>
                <Form.Item label="Equifax">
                  <Input
                    value={equifaxScore}
                    disabled={
                      assessmentExtrasStatus === LOADING_STATUS.LOADING ||
                      isSavingScores
                    }
                    onChange={(event) => {
                      setEquifaxScore(event.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xxl={8}>
                <Form.Item label="Illion">
                  <Input
                    value={illionScore}
                    disabled={
                      assessmentExtrasStatus === LOADING_STATUS.LOADING ||
                      isSavingScores
                    }
                    onChange={(event) => {
                      setIllionScore(event.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xxl={8}>
                <Form.Item>
                  <Button
                    disabled={
                      assessmentExtrasStatus === LOADING_STATUS.LOADING ||
                      isSavingScores
                    }
                    onClick={setAssessmentExtras}
                    className="score-button"
                  >
                    {isSavingScores ? <LoadingOutlined /> : <SaveOutlined />}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xxl={9}>
            <Row gutter={[16, 0]} align="bottom">
              <Col xxl={13}>
                <Form.Item className="credit-button">
                  <CreditButtonSingle
                    getPopupContainer={() =>
                      document.querySelector(
                        ".guarantor-credit-score"
                      ) as HTMLElement
                    }
                    onClick={getEquifaxApplyReport}
                    data={equifaxData}
                    requestLoading={equifaxApplyLoading}
                    allLoading={equifaxAllLoading}
                    label="Equifax Apply"
                  />
                </Form.Item>
              </Col>
              <Col xxl={11}>
                <Form.Item className="credit-button">
                  <CreditButtonSingle
                    getPopupContainer={() =>
                      document.querySelector(
                        ".guarantor-credit-score"
                      ) as HTMLElement
                    }
                    onClick={generateIllionCCR}
                    data={illionData}
                    requestLoading={illionRequestLoading}
                    allLoading={illionAllLoading}
                    label="Illion CCR"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xxl={7}>
            <Row gutter={[16, 0]} align="bottom">
              <Col xxl={12}>
                <Form.Item label="AML" className="credit-button">
                  <span
                    className={classNames("aml-status", {
                      fail: ([
                        AML_KYC_STATUS.RESOLVE,
                        AML_KYC_STATUS.INCOMPLETE,
                      ] as string[]).includes(amlKycStatus),
                    })}
                  >
                    {([
                      AML_KYC_STATUS.RESOLVE,
                      AML_KYC_STATUS.PASSED,
                      AML_KYC_STATUS.PASS_MANUAL,
                    ] as string[]).includes(amlKycStatus) ? (
                      <a
                        href={frankieOneLink}
                        className="frankieone-link"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {AML_KYC_STATUS_LABELS[amlKycStatus]}
                      </a>
                    ) : (
                      AML_KYC_STATUS_LABELS[amlKycStatus]
                    )}
                  </span>{" "}
                  {amlKycStatus === AML_KYC_STATUS.RESOLVE && (
                    <Tooltip placement="top" title="Refresh AML/KYC status">
                      {amlRefreshLoading ? (
                        <SyncOutlined spin />
                      ) : (
                        <ReloadOutlined onClick={getAMLandKYCStatus} />
                      )}
                    </Tooltip>
                  )}
                </Form.Item>
              </Col>
              <Col xxl={12}>
                <Form.Item className="credit-button">
                  <CreditButtonSingle
                    getPopupContainer={() =>
                      document.querySelector(
                        ".guarantor-credit-score"
                      ) as HTMLElement
                    }
                    onClick={generateAMLandKYC}
                    data={frankieOneData}
                    requestLoading={amlRequestLoading}
                    allLoading={amlKycAllLoading}
                    label="AML/KYC"
                    popUpPlacement="topRight"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreditSection;
