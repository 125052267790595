import { applicationReducers as application } from "@quest-finance/quest-fe-shared/dist/application";
import { authReducers as auth } from "@quest-finance/quest-fe-shared/dist/auth";
import { errorHandlerReducers as errorHandler } from "@quest-finance/quest-fe-shared/dist/error-handler";
import { reducer as notification } from "@quest-finance/quest-fe-shared/dist/notification";
import { userReducers as user } from "@quest-finance/quest-fe-shared/dist/user";
import { reducer as versionManager } from "@quest-finance/quest-fe-shared/dist/version-manager";
import { combineReducers } from "redux";
import { assessmentReducers as assessment } from "../assessment";
import { assessmentReducers as creditEngine } from "../assessment-v2";
import { entityReducers as entity } from "../entity";
import { funderReducers as funder } from "../funder";
import { iamReducers as iam } from "../iam";
import { themeReducers as theme } from "../theme";
import { workflowReducers as workflow } from "../workflow";

export const rootReducer = combineReducers({
  application,
  assessment,
  errorHandler,
  auth,
  iam,
  user,
  notification,
  entity,
  funder,
  versionManager,
  creditEngine,
  theme,
  workflow,
});
