import "./SettlementApproval.scss";
import React, { useCallback, useEffect, useState } from "react";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { Button, Collapse, Empty, message, Spin } from "antd";
import { AxiosError } from "axios";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  showErrorPopUp,
  showSuccessPopUp,
} from "../../../common/components/ShowNotification/showNotification";
import { processError } from "../../../common/utils/error";
import { QfCollapse } from "../../../theme";
import { getAssessmentDetail } from "../../actions/creators/assessmentDetail";
import { STATUS_LABELS } from "../../constants/approvalCondition";
import { useAssessmentDetailsDispatch } from "../../dispatchers";
import {
  assessmentIsLockedSelector,
  assessmentResponseSelector,
  assessmentResponseStatusSelector,
} from "../../selectors/assessmentDetail";
import ApprovalConditionService from "../../services/ApprovalCondition";
import { Status, ApprovalCondition } from "../../types/ApprovalCondition";

const { Panel } = Collapse;

type ActionButtonsProps = {
  condition: ApprovalCondition;
  isLoading: boolean;
  status: Status;
  disabled: boolean;
  onChangeStatus: (condition: ApprovalCondition, status: Status) => void;
};

const ActionButtons: React.FunctionComponent<ActionButtonsProps> = ({
  condition,
  isLoading,
  status,
  disabled,
  onChangeStatus,
}: ActionButtonsProps) => {
  return (
    <Spin spinning={isLoading}>
      {Object.keys(STATUS_LABELS).map((key) => {
        const statusKey = key as Status;
        const onClickHandler = () => {
          if (status !== statusKey) {
            onChangeStatus(condition, statusKey);
          }
        };

        return (
          <Button
            key={key}
            className={classNames("quest-button-square", {
              selected: status === statusKey,
            })}
            onClick={onClickHandler}
            disabled={disabled}
          >
            {STATUS_LABELS[statusKey]}
          </Button>
        );
      })}
    </Spin>
  );
};

const SettlementApproval: React.FunctionComponent = () => {
  const assessmentDispatch = useAssessmentDetailsDispatch();
  const [filter, setFilter] = useState<string>("");
  const { id: assessmentId } = useSelector(assessmentResponseSelector);
  const isAssessmentLocked = useSelector(assessmentIsLockedSelector);
  const [itemsOnUpdate, setItemsOnUpdate] = useState<string[]>([]);
  const status = useSelector(assessmentResponseStatusSelector);
  const [approvalConditions, setApprovalConditions] = useState<
    ApprovalCondition[]
  >([]);
  const conditions = filter
    ? approvalConditions.filter((condition) => condition.status === filter)
    : approvalConditions;

  const getApprovalConditions = useCallback(async (assessmentId: string) => {
    try {
      const result = await ApprovalConditionService.getConditions(assessmentId);

      setApprovalConditions(result.data.conditions);
    } catch (e) {
      processError(e, (errorMessage) => {
        showErrorPopUp(errorMessage);
      });
    }
  }, []);

  useEffect(() => {
    if (assessmentId) {
      getApprovalConditions(assessmentId);
    }
  }, [assessmentId, getApprovalConditions]);

  const handleFilterChange = (value = "") => {
    assessmentDispatch(getAssessmentDetail(assessmentId));
    setFilter(value);
  };

  const onChangeStatusHandler = async (
    { id, condition }: ApprovalCondition,
    status: Status
  ) => {
    message.destroy(id);
    setItemsOnUpdate((state) => [id, ...state]);
    const partialText = condition.split(" ").splice(0, 5).join(" ");

    try {
      await ApprovalConditionService.updateStatus(assessmentId, id, status);

      showSuccessPopUp(`Status of "${partialText}..." has been updated. `, id);
      getApprovalConditions(assessmentId);
    } catch (e) {
      processError(e, () => {
        const error = e as AxiosError;
        showErrorPopUp(
          error.response?.status === 422
            ? error.response?.data?.errorMessage
            : `Failed to update status of "${partialText}...".`,
          id
        );
      });
    } finally {
      setItemsOnUpdate((state) =>
        state.filter((conditionId) => id !== conditionId)
      );
    }
  };

  return (
    <div className="settlement-approval">
      <QfCollapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
        <Panel
          key="1"
          header={<div className="section-header-1">Settlement Conditions</div>}
        >
          <Spin spinning={status === LOADING_STATUS.LOADING}>
            <table className="conditions-list-table mb-3">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>Pass</th>
                  <th style={{ width: "80%" }}>Description</th>
                  <th style={{ width: "15%" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {conditions.length === 0 && (
                  <tr>
                    <td colSpan={3}>
                      <Empty description="No conditions found" />
                    </td>
                  </tr>
                )}
                {conditions.map((cond) => {
                  const { id, status, condition } = cond;
                  return (
                    <tr key={id}>
                      <td className="text-center">
                        <label
                          className={classNames("ant-checkbox-wrapper", {
                            "ant-checkbox-wrapper-checked":
                              status === Status.PASS,
                          })}
                        >
                          <span
                            className={classNames("ant-checkbox", {
                              "ant-checkbox-checked": status === Status.PASS,
                            })}
                          >
                            <input
                              type="checkbox"
                              className="ant-checkbox-input"
                              value="pass"
                              checked={status === Status.PASS}
                              readOnly
                            />
                            <span className="ant-checkbox-inner"></span>
                          </span>
                        </label>
                      </td>
                      <td>{condition}</td>
                      <td className="action-buttons">
                        <ActionButtons
                          condition={cond}
                          status={status}
                          disabled={isAssessmentLocked}
                          isLoading={itemsOnUpdate.includes(id)}
                          onChangeStatus={onChangeStatusHandler}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Spin>
          <div className="button-group">
            <Button
              className={classNames("qf-btn-green mr-3", {
                active: filter === Status.REFER,
              })}
              onClick={() => handleFilterChange(Status.REFER)}
            >
              View refer
            </Button>
            <Button
              className="qf-btn-green"
              onClick={() => handleFilterChange("")}
            >
              View all
            </Button>
          </div>
        </Panel>
      </QfCollapse>
    </div>
  );
};

export default SettlementApproval;
