import { FunderResponse } from "./Funder";

export interface FunderDetailsState {
  saveFunderLoading: boolean;
  funder: FunderResponse | null;
  getFunderLoading: boolean;
}

export const funderDetailsStateDefaultValue: FunderDetailsState = {
  saveFunderLoading: false,
  getFunderLoading: false,
  funder: null,
};

export interface FunderState {
  funder: {
    funderDetails: FunderDetailsState;
  };
}
