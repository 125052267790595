import { createSelector } from "@reduxjs/toolkit";
import { EntityState } from "../types/EntityState";
import { InternalNotesState } from "../types/InternalNotesState";

export const internalNotesSelector = (state: EntityState): InternalNotesState =>
  state.entity.internalNotes;

export const getInternalNotesSelector = createSelector(
  internalNotesSelector,
  (internalNotes) => internalNotes.internalNotes
);

export const getInternalNotesCountSelector = createSelector(
  internalNotesSelector,
  (internalNotes) => internalNotes.internalNotesCount
);

export const getInternalNotesLoadingSelector = createSelector(
  internalNotesSelector,
  (internalNotes) => internalNotes.internalNotesLoading
);

export const getSubmitInternalNotesSuccessSelector = createSelector(
  internalNotesSelector,
  (internalNotes) => internalNotes.submitInternalNotesSuccess
);
