import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import * as assessmentDetailsTypes from "../actions/types/assessmentDetail";
import { ASSESSMENT_DETAIL_DEFAULT_STATE } from "../constants/assessmentDetail";
import { AssessmentDetailState } from "../types/AssessmentDetail";

export const assessmentDetailState: AssessmentDetailState = ASSESSMENT_DETAIL_DEFAULT_STATE;

export const reducer = (
  state: AssessmentDetailState = assessmentDetailState,
  action: assessmentDetailsTypes.AssessmentDetailActions
): AssessmentDetailState => {
  switch (action.type) {
    case assessmentDetailsTypes.RESET_ASSESSMENT_DETAILS: {
      return {
        ...assessmentDetailState,
      };
    }
    case assessmentDetailsTypes.GET_ASSESSMENT_DETAILS: {
      return {
        ...state,
        status: LOADING_STATUS.LOADING,
      };
    }
    case assessmentDetailsTypes.GET_ASSESSMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        assessment: {
          ...action.assessment,
        },
        status: LOADING_STATUS.SUCCESS,
      };
    }
    case assessmentDetailsTypes.GET_ASSESSMENT_DETAILS_FAILED: {
      return {
        ...state,
        status: LOADING_STATUS.FAILED,
      };
    }
    case assessmentDetailsTypes.UPDATE_ASSESSMENT_DETAIL: {
      return {
        ...state,
        assessment: {
          ...state.assessment,
          ...action.assessment,
        },
      };
    }
    default: {
      return state;
    }
  }
};
