import React from "react";
import { Collapse } from "antd";
import { QfCollapse } from "../../../../theme";
import { WORKFLOW_CODE } from "../../../../workflow/constants/workflow";
import CreditRefList from "../../../components/credit-reference/List";
import WorkflowSection from "../../../components/workflow-section/WorkflowSection";
import { Slug } from "../../../types/Navigation";

const { Panel } = Collapse;
const RefStatements: React.FunctionComponent = () => {
  return (
    <>
      <QfCollapse
        defaultActiveKey="applicant-guarantor"
        expandIconPosition="right"
        ghost
      >
        <Panel
          header={<div className="section-header-1">Credit Reference</div>}
          key="applicant-guarantor"
        >
          <CreditRefList />
        </Panel>
      </QfCollapse>
      <WorkflowSection
        activeStep={Slug.REF_STATEMENTS}
        code={WORKFLOW_CODE.CREDIT_WORKFLOW}
      />
    </>
  );
};

export default RefStatements;
