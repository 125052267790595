import * as actionTypes from "../actions/types/auditLogs";
import {
  AuditLogState,
  auditLogStateDefaultValue,
} from "../types/AuditLogState";

export const auditLogsState: AuditLogState = auditLogStateDefaultValue;

export const reducer = (
  state: AuditLogState = auditLogsState,
  action: actionTypes.AuditLogActions
): AuditLogState => {
  switch (action.type) {
    case actionTypes.GET_ENTITY_AUDIT_LOG: {
      return {
        ...state,
        entityAuditLogsLoading: true,
      };
    }
    case actionTypes.GET_ENTITY_AUDIT_LOG_SUCCESS: {
      return {
        ...state,
        entityAuditLogs: action.auditLogList.data,
        entityAuditLogsCount: action.auditLogList.count
          ? action.auditLogList.count
          : 0,
        entityAuditLogsLoading: false,
      };
    }
    case actionTypes.GET_ENTITY_AUDIT_LOG_FAILED: {
      return {
        ...state,
        entityAuditLogsLoading: false,
      };
    }
    case actionTypes.RESET_ENTITY_AUDIT_LOG_STATE: {
      return auditLogStateDefaultValue;
    }
    default: {
      return state;
    }
  }
};
